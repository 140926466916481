import React, { memo } from 'react';
import { navigate } from "@reach/router";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

//react functional component
const UserTopBuyer = ({ user, index }) => {

	return (
		<div className='d-flex align-items-center m-auto top_buyer_card'>
			<div style={{ fontSize: '2rem', fontWeight: 'bold', marginLeft: '1rem' }}>{index + 1}</div>
			<div className="author_list_pp" style={{ cursor: 'pointer', marginLeft: '3.2rem' }
			}>
				<span onClick={() => navigate(`user/${user.address}`)}>
					<img className="lazy" src={user.profile_pic ? user.profile_pic : websiteUrl + '/img/author/author-11.jpg'} alt="" />
					{user.verified && <i className="fa fa-check"></i>}
				</span>
			</div>
			<div className="author_list_info text-truncate" style={{ fontWeight: 'bold', paddingLeft: '85px', margin: '1rem 0' }}>
				<span onClick={() => navigate(`user/${user.address}`)}>{user.address}</span>
				<span className="bot" style={{
					color: '#bbb',
					display: 'block',
					fontSize: '14px',
					fontWeight: '400',
					lineHeight: '1.2em',
				}}>{parseFloat(user.total_purchases_amount).toFixed(2)} Ⓝ</span>
			</div>
		</div>
	);
};

export default memo(UserTopBuyer);