import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import Getconfig from '../../config';
import { appStore, onAppMount } from "../../state/app";
import ColumnNewThreeColRedux from "../components/ColumnNewThreeColRedux";
import SliderFilter from "../components/SliderFilter";

export const {
	websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }

`;

const Market = function (props) {
	const { state, dispatch, update } = useContext(appStore);

	const [filters, setFilters] = useState("");

	const {
		app,
		views,
		contractAccount,
		account,
		loading,
	} = state;

	const onMount = () => {
		dispatch(onAppMount());
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onMount, []);

	const handleFilters = (filters, category) => {
		setFilters((filters.toString()));
	};

	return (
		<div>
			<GlobalStyles />
			<Helmet>
				<title>UniqART - Marketplace</title>
				<link rel="canonical" href={websiteUrl + "/market"} />
				<meta property="og:title" content="UniqART - Marketplace" />
				<meta property="og:url" content={`${websiteUrl}/market`} />
			</Helmet>
			<section className="container setMobileGap">
				<div className="row">
					<div className="col-md-12 mt-lg-5 p-2">
						<SliderFilter
							handleFilters={(filters) => handleFilters(filters)}
							defaultCategory={props.type ? props.type : null}
						/>
					</div>
				</div>
				<div className="row">
					<div
						className="col-md-12 mt-5"
					>
						<ColumnNewThreeColRedux
							{...{
								app,
								views,
								update,
								loading,
								contractAccount,
								account,
								dispatch,
								filters,
							}}
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Market;
