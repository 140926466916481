import axios from 'axios';
const contractName = 'nft.uniqart.near';
// require('dotenv').config();

const dataResult = async () => {
	try {
		const result = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=near&vs_currencies=usd`);
		const storage = window?.sessionStorage;
		if (!storage) return;
		storage.setItem("NearToUSD", result.data.near.usd);
	} catch (error) {
		console.error(error);
	}
}
dataResult();
const Getconfig = () => {

	// module.exports = function Getconfig() {
	let config = {
		networkId: "testnet",
		nodeUrl: "https://rpc.testnet.near.org",
		// walletUrl: 'http://localhost:1234',
		walletUrl: "https://wallet.testnet.near.org",
		helperUrl: "https://helper.testnet.near.org",
		contractName,
	};

	if (process.env.REACT_APP_ENV !== undefined) {
		config = {
			explorerUrl: "https://explorer.testnet.near.org",
			...config,
			GAS: "100000000000000",
			DEFAULT_NEW_ACCOUNT_AMOUNT: "5",
			DEFAULT_NEW_CONTRACT_AMOUNT: "5",
			GUESTS_ACCOUNT_SECRET:
				"5ymXCkJfcSXcUjY8ADtqacJTH9AVJW4EW2Wmv8fay4WgsGZmQZBGgvShiEz15e94zgMscMrxqEXqG5mPad4XGZK1",
			contractMethods: {
				changeMethods: [
					"new",
					"nft_mint",
					"nft_transfer",
					"add_guest",
					"remove_guest",
					"nft_approve_account_id",
					"nft_mint_guest",
					"nft_add_sale_guest",
					"nft_remove_sale_guest",
					"upgrade_guest",
				],
				viewMethods: ["get_guest", "get_token_ids", "nft_token", "get_sale"],
			},
			marketDeposit: "100000000000000000000000",
			marketId: "market." + contractName,
			websiteUrl: "https://demo.uniqart.io",
			apiUrl: "https://testapi.uniqart.io",
			rpcNode: "https://archival-rpc.testnet.near.org",
			extraFee: "0.00859",
			offerextraFee: "0.00859",
			nftApproveStorageDeposit: "0.00859",
			nftApprove: "0.00076",
			otherUserSaleFee: "0.01",
			storage_fee: "0.1",
			nearRamp_url: "https://sdk.testnet.nearamp.dev/nearamp.js",
			contractId: 'nft.uniqart.near',
			transTxnUrl: 'https://explorer.testnet.near.org'
		};
	}

	if (process.env.REACT_APP_ENV === "prod") {
		config = {
			...config,
			GAS: "100000000000000",
			contractMethods: {
				changeMethods: [
					"new",
					"nft_mint",
					"nft_transfer",
					"add_guest",
					"remove_guest",
					"nft_approve_account_id",
					"nft_mint_guest",
					"nft_add_sale_guest",
					"nft_remove_sale_guest",
					"upgrade_guest",
				],
				viewMethods: ["get_guest", "get_token_ids", "nft_token", "get_sale"],
			},
			marketDeposit: "100000000000000000000000",
			marketId: "market." + contractName,
			networkId: "mainnet",
			nodeUrl: "https://rpc.mainnet.near.org",
			walletUrl: "https://wallet.near.org",
			helperUrl: "https://helper.mainnet.near.org",
			contractName,
			websiteUrl: "https://uniqart.io",
			apiUrl: "https://uniqart.io",
			rpcNode: "https://archival-rpc.mainnet.near.org",
			extraFee: "0.00859",
			offerextraFee: "0.00859",
			nftApproveStorageDeposit: "0.00859",
			nftApprove: "0.00076",
			otherUserSaleFee: "0.01",
			storage_fee: "0.1",
			nearRamp_url: "https://sdk.nearamp.dev/nearamp.js",
			contractId: 'nft.uniqart.near',
			transTxnUrl: 'https://explorer.mainnet.near.org'
		};
	}

	return config;
};
export default Getconfig;