import React, { useState, useContext } from 'react'
import { Modal, Button } from "react-bootstrap";
import near from '../../assets/images/near.png';
import '../../Modal.css';
import { appStore } from "../../state/app";

const LoginModalNew = (props) => {

	const { state } = useContext(appStore);
	const { wallet } = state;
	return (
		<Modal {...props}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className='login-model'
		>
			<Modal.Header closeButton>
			</Modal.Header>
			<Modal.Body>
				<div className="row p-3 mb-2">
					<div className="col-12 d-flex justify-content-center">
						<span className="text-center">
							<img src={near} alt="" className="mb20" width="80" height="80" />
							<h3 style={{ fontSize: '1.9rem' }}>Please Login First</h3>
							<h4 className="mb30">Collect NFT that you can truly own.</h4>
							<p>You will be redirected to NEAR Wallet</p>
						</span>
					</div>
					{!(wallet && wallet.signedIn) ? <Button className="text-center modal-body-button mb-4" onClick={() => wallet.signIn()}>Login with NEAR</Button> : ''}
					<Button className="text-center modal-body-button modal-body-cancel-button mb-3" onClick={props.onHide}>Back</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
}
export default LoginModalNew;
