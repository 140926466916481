import React, { useContext } from 'react';
import near from '../../assets/images/near.png';
import { appStore } from "../../state/app";
import { navigate } from "@reach/router";

const Wallet = () => {
	const { state } = useContext(appStore);
	const { wallet } = state;
	return (
		<div className="row d-flex justify-content-center">
			<div className="col-lg-6 mb30">
				<span className="box-url text-center py-5">
					<span className="box-url-label">Most Popular</span>
					<img src={near} alt="" className="mb20" width="80" height="80" />
					<h4>Near</h4>
					<p>Start exploring blockchain applications in seconds.  Trusted by over 1 million users worldwide.</p>
					{!(wallet && wallet.signedIn) ? (<div className="btn-main mx-2 w-100 mt-5 py-3" onClick={() => wallet.signIn()}><span style={{ fontSize: "18px" }}>Login with NEAR</span></div>) : <><p>Congratulation ! You are already logged in.</p>
						<div className="btn-main mx-2 w-100 mt-3 py-3" onClick={() => navigate("/market")}><span style={{ fontSize: "18px" }}>Explore Market</span></div></>
					}
				</span>

			</div>
		</div>
	)
};
export default Wallet;