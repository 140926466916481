import axios from "axios";
import { create } from "ipfs-http-client";
import { CID } from "multiformats/cid";
import { useContext, useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import "../../Modal.css";
import { appStore, onAppMount } from "../../state/app";
import Footer from "../components/footer";
import Getconfig from '../../config';
import Compressor from 'compressorjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
export const {
	websiteUrl, apiUrl
} = Getconfig();

const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET_KEY;
const auth ='Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const Images = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 250ms ease;
  &:hover {
    transform: scale(1.1);
    border: 3px solid #8364e2;
  }
`;
// import { Button } from "reactstrap";
const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

const Profile = () => {
	const [ProfileImage, setProfileImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState();

	const [preview, setPreview] = useState(
		ProfileImage
			? ProfileImage
			: "https://via.placeholder.com/266x266/212428/e3e3e3?text=Image should be square."
	);

	const { state, dispatch } = useContext(appStore);
	const { account } = state;
	const [name, setName] = useState("File name");
	const [crop, setCrop] = useState(null);
	const [btnText, setbtnTest] = useState("In progress...");
	const [imageType, setImageType] = useState(true);
	const [loadingOn, setLoadingOn] = useState(false);
	const [error, setError] = useState(false);
	const [activeIndex, setActiveIndex] = useState(null);
	const [Checkboxchecked, setcheckboxChecked] = useState(false);
	const [btnStatus, setBtnStatus] = useState(true);
	const [receiver, setReceiver] = useState(account ? account.accountId : "");
	const [showModal, setShow] = useState(false);
	const [showModal_new, setShow2_new] = useState(true);
	const handleClose_new = () => setShow2_new(false);
	const handleClose = () => setShow(false);
	const [image, setImage] = useState(null);
	const [cropper, setCropper] = useState(null);
	const [showModal2, setShow2] = useState(false);
	const [showModal3, setShow3] = useState(false);
	const cropperRef = useRef(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();


	function CreateUpdateImg(raw) {
		fetch(`${apiUrl}/api/v1/user_profile_pic`, {
			method: "POST",
			headers: new Headers({ "Content-Type": "application/json" }),
			body: raw,
		})
			.then((response) => response.text())
			.then((responseText) => {
				console.log(responseText);
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
			});
	}
	var fetchProfile = async function fetchData(userAddress) {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/fetch_profile_pic?address=` + userAddress);
			if (result.data["status"] === "Success") {
				const newList = result.data["results"].profile_pic;
				console.log("ACCOUNT Listt", newList);
				setProfileImage(newList);
				setPreview(newList);
			} else {
				setProfileImage(null);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const accountId = account ? account.accountId : null;

	const onMount = () => {
		dispatch(onAppMount());
	};
	useEffect(onMount, []);
	const [files, setFiles] = useState(
		"https://via.placeholder.com/266x266/212428/e3e3e3?text=Image should be square."
	);

	// function isFileImage(file) {
	// 	const acceptedImageTypes = [
	// 		"image/gif",
	// 		"image/jpeg",
	// 		"image/png",
	// 		"image/webp",
	// 	];
	// 	return file && acceptedImageTypes.includes(file["type"]);
	// }
	function dataURLtoFile(dataurl, filename) {

		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
	useEffect(() => {
		if (accountId) {
			fetchProfile(accountId);
		}
	}, [accountId]);

	// test start
	useEffect(() => {
		if (!selectedFile) {
			setPreview(
				ProfileImage
					? ProfileImage
					: "https://via.placeholder.com/266x266/212428/e3e3e3?text=Image should be square."
			);
			return;
		}

		// setImageType(isFileImage(selectedFile));
		setLoadingOn(false);
		// const objectUrl = (selectedFile);
		console.log(selectedFile, 'selected file');
		setPreview(selectedFile);
		setBtnStatus(false);
		// return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const onSelectFile = (file) => {
		const fileSize = file.size;
		if (fileSize > 1024 * 100) {
			alert("File must not exceed 100 KB!");
		} else {
			var image = new Image();
			image.src = file;
			image.onload = function () {
				var height = this.height;
				var width = this.width;
				console.log('height and width', height, width);
				setLoadingOn(true);
				setSelectedFile(file);
				setFiles(file);
				setName(file.name);
				document.getElementById("file_name").style.display = "none";
				setShow2_new(false);

			};
		}
	};

	async function uploadImageIPFS(files) {
		if (Checkboxchecked) {
			try {
				const added = await client.add(files);
				const v0 = CID.parse(added.path);
				const encodePath = v0.toV1().toString();
				const url = `https://ipfs.io/ipfs/${encodePath}`;
				setFiles(url);
				setbtnTest("Success");
				setLoadingOn(false);
				if (account) {
					const U_adderess = account.accountId;
					const raw = JSON.stringify({
						address: U_adderess,
						profile_pic: url,
					});
					CreateUpdateImg(raw);
				}
			} catch (error) {
				console.log("Error uploading file: ", error);
				setError(true);
			}
		} else {
			setbtnTest("Success");
			setLoadingOn(false);
			if (account) {
				const U_adderess = account.accountId;
				const raw = JSON.stringify({
					address: U_adderess,
					profile_pic: files,
				});
				CreateUpdateImg(raw);
			}
		}
	}

	const handleShow = () => {
		if (
			files ===
			`https://via.placeholder.com/266x266/212428/e3e3e3?text=Image should be square.`
		) {
			setBtnStatus(true);
		} else {
			setShow(true);
			setBtnStatus(false);
		}
	};



	const handleClose3 = () => {
		setShow3(false)
		setShow2(false);
		setShow(false);
		setbtnTest("In progress...");
		setImage(null);
		setShow2_new(true);
		setSelectedFile();
		setPreview(null);
	};
	const handleClose2 = () => {
		setShow3(false)
		setShow2(false);
		setShow(false);
		setbtnTest("In progress...");
		setImage(null);
		setShow2_new(true);
		setSelectedFile();
		setPreview(null);
	};
	const handleShow2 = () => {
		setShow2(true);
		if (Checkboxchecked) {
			new Compressor(dataURLtoFile(files), {
				quality: 0.1,
				success(result) {
					uploadImageIPFS(result);
				},
				error(err) {
					console.log(err.message);
				},
			});
		} else {
			uploadImageIPFS(files);
		}
	};

	const setProfile = (index) => {
		setBtnStatus(false);
		setActiveIndex(index); // remove the curly braces
		setPreview(`${websiteUrl}/img/author/author-${index}.jpg`);
		setFiles(`${websiteUrl}/img/author/author-${index}.jpg`);
	};

	const selectShortlistedApplicant = (e) => {
		const checked = e.target.checked;
		if (checked) {
			setcheckboxChecked(true);
		} else {
			setcheckboxChecked(false);
		}
	};

	/* ----------------------------- new code start ----------------------------- */


	const getCropData = () => {
		if (typeof cropper !== "undefined") {
			const imageElement = cropperRef?.current;
			const cropper = imageElement?.cropper;
			onSelectFile(cropper.getCroppedCanvas().toDataURL());
		}
	};
	const onChange = (e) => {
		setImage(null);
		setShow2_new(true);
		setSelectedFile();
		setPreview(null);
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result);
		};
		reader.readAsDataURL(files[0]);
	};

	const resetCropData = () => {
		setImage(null);
		setShow2_new(true);
		setSelectedFile();
		setPreview(null);
	}
	/* ------------------------------ new code end ------------------------------ */
	return (
		<div>
			{error ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						width: "100vw",
						position: "fixed",
						backgroundColor: "black",
						zIndex: 1111,
					}}
				>
					<div
						style={{
							backgroundColor: "white",
							padding: "50px",
							borderRadius: "50px",
						}}
					>
						<h4 style={{ color: "black" }}>Failed To Upload!</h4>
						<input
							type="button"
							id="submit"
							className="btn-main"
							value="Try Again!"
							style={{
								marginRight: "auto",
								marginLeft: "auto",
								marginTop: "20px",
							}}
							onClick={() => window.location.reload()}
						/>
					</div>
				</div>
			) : (
				""
			)}
			<section className="jumbotron breadcumb no-bg">
				<div className="mainbreadcumb">
					<div className="container">
						<div className="row m-10-hor">
							<div className="col-12">
								<h1 className="text-center">Choose Your Avatar</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="container">
				<div className="row">
					<div className="col-lg-7 offset-lg-1 mb-5">
						{/* <Button  onClick={handleShow}>
							Launch demo modal
						</Button> */}
						<div className="row w-100">
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-1.jpg"
									alt=""
									key="1"
									onClick={() => setProfile(1)}
									className={activeIndex === 1 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-2.jpg"
									alt=""
									onClick={() => setProfile(2)}
									className={activeIndex === 2 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-3.jpg"
									alt=""
									onClick={() => setProfile(3)}
									className={activeIndex === 3 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-4.jpg"
									alt=""
									onClick={() => setProfile(4)}
									className={activeIndex === 4 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-5.jpg"
									alt=""
									onClick={() => setProfile(5)}
									className={activeIndex === 5 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-6.jpg"
									alt=""
									onClick={() => setProfile(6)}
									className={activeIndex === 6 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-7.jpg"
									alt=""
									onClick={() => setProfile(7)}
									className={activeIndex === 7 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-8.jpg"
									alt=""
									onClick={() => setProfile(8)}
									className={activeIndex === 8 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-9.jpg"
									alt=""
									onClick={() => setProfile(9)}
									className={activeIndex === 9 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-10.jpg"
									alt=""
									onClick={() => setProfile(10)}
									className={activeIndex === 10 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-11.jpg"
									alt=""
									onClick={() => setProfile(11)}
									className={activeIndex === 11 ? "active-profile" : "unactive"}
								/>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 demo-icon-wrap-s2 mt-3">
								<Images
									src="./img/author/author-12.jpg"
									alt=""
									onClick={() => setProfile(12)}
									className={activeIndex === 12 ? "active-profile" : "unactive"}
								/>
							</div>
						</div>
						<div className="spacer-30"></div>
						<form
							id="form-create-item"
							className="form-border"
							action="#"
							onSubmit={handleSubmit(handleShow)}
						>
							<div className="field-set">
								<div className="form-check mb-4">
									<input
										className="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefault"
										onClick={(e) => {
											selectShortlistedApplicant(e);
										}}
										style={{ cursor: "pointer" }}
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault"
										style={{ cursor: "pointer" }}
									>
										Upload your own image
									</label>
								</div>
								{Checkboxchecked && (
									<>
										<div className="d-create-file">
											<p id="file_name">PNG, JPG. Max 100 KB.</p>
											<p key="{index}">{name}</p>
											<div className="browse">
												<input
													type="button"
													id="get_file"
													className="btn-main"
													value="Browse"
												/>
												<input
													id="upload_file"
													type="file"
													onChange={onChange}
													accept="image/png, image/gif, image/jpeg"
												/>
											</div>
										</div>
										{/* ----------------------------- new code start ----------------------------- */}
										{
											image && (
												<Modal
													show={showModal_new}
													onHide={handleClose_new}
													keyboard={false}
													size="xl"
													aria-labelledby="contained-modal-title-vcenter"
													centered
													className='login-model'
													backdrop='static'
												>
													<Modal.Body>
														<div className="row p-3 mb-2">
															<div className="col-12 d-flex justify-content-center">
																<div>
																	<div style={{ width: "100%", height: "100%" }}>
																		<Cropper
																			style={{ height: 400, width: "100%" }}
																			zoomTo={0.5}
																			initialAspectRatio={1}
																			preview=".img-preview"
																			src={image}
																			viewMode={1}
																			minCropBoxHeight={10}
																			minCropBoxWidth={10}
																			background={false}
																			responsive={true}
																			autoCropArea={1}
																			aspectRatio={1}
																			ref={cropperRef}
																			checkOrientation={false}
																			onInitialized={(instance) => {
																				setCropper(instance);
																			}}
																			guides={true}
																		/>
																	</div>
																</div>
															</div>
															<Button className="text-center modal-body-button modal-body-cancel-button mb-3" onClick={getCropData}>Crop</Button>
															<Button className="text-center modal-body-button modal-body-cancel-button mb-3" onClick={resetCropData}>Back</Button>
														</div>
													</Modal.Body>
												</Modal>
											)
										}

										{/* ------------------------------ new code end ------------------------------ */}

									</>
								)}
								<div className="spacer-single"></div>
								{/* test start */}
								<div className="spacer-10"></div>
								<input
									type="submit"
									id="submit"
									className={
										btnStatus ? "btn-main btn-disabled-start" : "btn-main"
									}
									value="Upload Image"
									style={{
										marginRight: "auto",
										marginLeft: "auto",
										marginTop: "20px",
									}}
									{...(btnStatus ? "disabled" : "")}
								/>
							</div>
						</form>
					</div>

					<div className="col-lg-3 col-sm-6 col-xs-12">
						<h5>Preview</h5>
						<div className="nft__item m-0">
							<div className="nft__item_wrap my-5">
								<span className="setProfileImageStyle">
									{loadingOn ? (
										<div className="spinner-border text-light" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									) : (preview ? (
										<img
											src={preview}
											id="get_file_2"
											className="lazy nft__item_preview"
											style={{
												height: "100%",
												width: "100%",
												borderRadius: "50%",
											}}
											alt=""
										/>
									) : (<img
										src={websiteUrl + '/img/author/author-11.jpg'}
										id="get_file_2"
										className="lazy nft__item_preview"
										style={{
											height: "100%",
											width: "100%",
											borderRadius: "50%",
										}}
										alt=""
									/> ? (loadingOn ? (
										<div className="spinner-border text-light" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									) : ("")
									) : ("")
									)
									)
									}
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Modal
				show={showModal3}
				onHide={handleClose3}
				className="mint-model w-100"
				centered
				backdrop="static"
			>
				<Modal.Header>
					<h3 className="mb-0">Crop image</h3>
				</Modal.Header>
				<Modal.Body>
					{/* <ReactCrop crop={crop} onChange={c => setCrop(c)}>
						<img src="./img/banner/banner-2.jpeg"/>
					</ReactCrop>  */}

					<p>Uploading your image Please wait...</p>
					<div className="d-flex justify-content-left btn-inprogress mb-5">
						<Button
							className="text-center modal-body-button"
							onClick={handleClose3}
							disabled
						>
							{btnText}
						</Button>
					</div>
					<div className="d-flex justify-content-left">
						<Button
							className="text-center modal-body-button modal-body-cancel-button mb-3"
							onClick={handleClose3}
						>
							Close
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={showModal2}
				onHide={handleClose2}
				className="mint-model w-100"
				centered
				backdrop="static"
			>
				<Modal.Header>
					<h3 className="mb-0">Upload</h3>
				</Modal.Header>
				<Modal.Body>
					<p>Uploading your image Please wait...</p>
					<div className="d-flex justify-content-left btn-inprogress mb-5">
						<Button
							className="text-center modal-body-button"
							onClick={handleClose2}
							disabled
						>
							{btnText}
						</Button>
					</div>
					<div className="d-flex justify-content-left">
						<Button
							className="text-center modal-body-button modal-body-cancel-button mb-3"
							onClick={handleClose2}
						>
							Close
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={showModal}
				onHide={handleClose}
				centered
				className="confirm-model w-100"
				backdrop="static"
			>
				<Modal.Body>
					<div className="container confirm">
						<div className="row confirm-row">
							<div className="col-md-6 col-lg-6 col-sm-12 confirm-img">
								<div className="shadow-lg bg-gray p-3 h-100">
									<div className="nft__item_wrap my-4">
										<span className="confirm-preview setMobileConfirmPreview">
											{imageType && (
												preview ? (
													<img
														src={preview}
														id="get_file_2"
														className="lazy nft__item_preview"
														style={{
															height: "100%",
															width: "100%",
															borderRadius: "50%",
														}}
														alt=""
													/>
												) : (<img
													src={websiteUrl + '/img/author/author-11.jpg'}
													id="get_file_2"
													className="lazy nft__item_preview"
													style={{
														height: "100%",
														width: "100%",
														borderRadius: "50%",
													}}
													alt=""
												/>)


											)}
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-6 col-sm-12 confirm-info d-flex align-items-center">
								<div className="confirm-info-body w-100">
									<h2 style={{ fontSize: "27px", lineHeight: "27px" }}>
										Profile Image
									</h2>
									<h5>Confirm profile image?</h5>
									<div className="d-flex justify-content-left mt-4">
										<Button
											className="text-center modal-body-button mb-3"
											onClick={handleShow2}
										>
											Confirm
										</Button>
									</div>
									<div className="d-flex justify-content-left">
										<Button
											className="text-center modal-body-button modal-body-cancel-button mb-3"
											onClick={handleClose}
										>
											Cancel
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Footer />
		</div>
	);
};

export default Profile;

