import { useNavigate } from "@reach/router";
import axios from "axios";
import * as nearAPI from "near-api-js";
import React, { memo, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { formatAccountId } from "../../utils/near-utils";
import Clock from "./Clock";
import { navigate } from "@reach/router";
import Getconfig from "../../config";
import FileType from 'file-type/browser';
import { acceptedImageTypes, acceptedAudioTypes, acceptedVideoTypes } from "../../utils";
export const { websiteUrl, apiUrl } = Getconfig();

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  height: 100%;
`;
const {
	utils: {
		format: { formatNearAmount },
	},
} = nearAPI;

//react functional component
const NftCardMarket = ({
	current_copy,
	nft,
	media,
	copies,
	title,
	clicks,
	description,
	token_id,
	sale_conditions,
	bids,
	royalty,
	owner_id,
	marketStoragePaid,
	accountId,
	account,
	path,
	extra,
	className = "d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4",
	clockTop = true,
	height,
	onImgLoad,
	is_auction,
	collection_name,
	collection_id,
	profile_pic,
	verified,
	is_unlockable,
	nftObject,
	compressed_url,
	extension,
	min_sell_price,
	fav_count
}) => {
	const [profileImg, setProfileImg] = useState(
		websiteUrl + "/img/author/author-11.jpg"
	);
	const [loadingImg, setLoading] = useState(true);
	const [compressedImageUrl, setCompressedImageUrl] = useState(null);
	const [FileExtension, setFileExtension] = useState(null);
	const [FileUrl, setFileUrl] = useState(null);
	const [changedPrice, setChangedPrice] = useState(null);
	const [newCompressed, setNewCompressed] = useState(null);

	useEffect(() => {
		if (profile_pic !== null) {
			setProfileImg(profile_pic);
		} else {
			setProfileImg(websiteUrl + "/img/author/author-11.jpg");
		}
	}, []);

	let imageType = true;



	function isFileImage(file) {
		return file && acceptedImageTypes.includes(file);
	}
	useEffect(() => {
		const storage = window?.sessionStorage;
		if (!storage) return;
		const NearToUSD = storage.getItem("NearToUSD");
		setChangedPrice(NearToUSD);
	}, [])

	useEffect(() => {
		if (extra) {
			if (extra.includes("**")) {
				const myArray = extra.split("**");
				setFileExtension(myArray[0]);
				setFileUrl(myArray[1]);
			} else {
				let fetchRes = fetch(extra);
				fetchRes
					.then((res) => res.json())
					.then((d) => {
						setFileExtension(d.fileExtension);
						setFileUrl(d.filesCover);
						if (d.fileExtension !== "gif") {
							setCompressedImageUrl(d.compressedImage);
						}

					})
					.catch((e) => {
						console.log("error in metadata loading", e);
					});
			}
		}

		if (FileExtension) {
			imageType = isFileImage(FileExtension.toLowerCase());
		} else {
			imageType = true;
			setFileExtension("jpg");
		}
	}, [extra]);

	useEffect(() => {
		/* ------------------------------- test start ------------------------------- */
		const getMedia = async (data) => {
			try {
				const resp = await axios.get(`${data}`, {
					responseType: 'blob',
				});			
				const fileType = await FileType.fromBlob(resp.data);			
				const objectUrl = URL.createObjectURL(resp.data);
				// console.log(objectUrl,fileType,'file-type');
				setNewCompressed(objectUrl);
			} catch (err) {
				console.log(err,'err');
				setNewCompressed(compressed_url);
			}
		}
		/* -------------------------------- test end -------------------------------- */
		// new code start
		if (extension !== "gif") {
			if (compressed_url) {
				getMedia(compressed_url);
			} else {
				getMedia(media);
			}
		} else {
			getMedia(media);
		}
		// new code end
	}, []);

	const likeButtonPressed = async (accountId, token_id) => {
		const rawLikeData = JSON.stringify(
			{
				"address": accountId,
				"nft_token": token_id
			});

		fetch(`${apiUrl}/api/v1/add_favourite_nft`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json', }),
			body: rawLikeData
		}).then((response) => response.text())
			.then((responseText) => {
				console.log(responseText);
			})
			.catch((error) => {
				console.error(error);
			});
	}


	return (
		<div className={className} key={token_id}>
			<div className="nft__item">
				<span>
					{/* <div className="icontype d-flex justify-content-center align-items-center heartStyle" style={{ right: "auto" }}>
						<i className="fa fa-heart" onClick={() => likeButtonPressed(accountId, token_id)}></i>
					</div> */}
					<div className="icontype d-flex justify-content-center align-items-center">
						{
							acceptedAudioTypes.includes(nftObject.extension) &&
							<i className="fa fa-headphones"></i>
						}
						{
							acceptedVideoTypes.includes(nftObject.extension) &&
							<i className="fa fa-video-camera"></i>
						}
						{
							(acceptedImageTypes.includes(nftObject.extension) || nftObject.extension == null || nftObject.extension === "") &&
							<i className="fa fa-image"></i>
						}
						{is_unlockable &&
							<div className="d-flex justify-content-center align-items-center">
								<i
									className="fa fa-lock text-danger"
									data-tip="Includes unlockable content"
									style={{ fontSize: "1.1rem", paddingLeft: '8px' }}
								></i>
								<ReactTooltip
									textColor="#000"
									backgroundColor="#fff"
									className="tooltipStyle"
								/>
							</div>
						}
					</div>
					{nft.deadline && clockTop && (
						<div className="de_countdown">
							<Clock deadline={nft.deadline} />
						</div>
					)}
					<div
						className="text-truncate pb-2 pt-2 text-center text-capitalize"
						style={{ fontWeight: "bold", fontSize: "1.1rem" }}
					>
						<span
							className="cardCollectionClick"
							onClick={() =>
								navigate(`user-collection/${collection_id}`)
							}
						>
							{collection_name ? collection_name : "New Collection"}
						</span>
					</div>
					<div className="author_list_pp" style={{ zIndex: '2' }}>
						<span onClick={() => navigate(`user/${owner_id}`)}>
							<img
								className="lazy"
								src={
									profileImg
										? profileImg
										: websiteUrl + "/img/author/author-11.jpg"
								}
								alt=""
							/>
							{verified && <i className="fa fa-check"></i>}
						</span>
					</div>

					<div className="nft__item_wrap" style={{ height: `${height}px` }}>
						<Outer>
							<div style={{ display: loadingImg ? "block" : "none" }}>
								<SkeletonTheme baseColor="#202020" highlightColor="#444" style={{ height: '200px' }}>
									<Skeleton height={180} width={200} style={{ display: 'flex' }} />
								</SkeletonTheme>
							</div>
							<span style={{ display: loadingImg ? "none" : "block" }}>
								{imageType ? (
									<img
										style={{ cursor: "pointer", objectFit: "cover" }}
										onLoad={() => setLoading(false)}
										src={newCompressed ? newCompressed : media}
										className="lazy nft__item_preview"
										alt=""
										onClick={() => {
											navigate("/token/" + token_id);
										}}
									/>
								) : (
									<img
										style={{ cursor: "pointer", objectFit: "cover" }}
										onLoad={() => setLoading(false)}
										src={newCompressed ? newCompressed : FileUrl}
										className="lazy nft__item_preview"
										alt=""
										onClick={() => {
											navigate("/token/" + token_id);
										}}
									/>
								)}
							</span>
						</Outer>
					</div>
					<div className="nft__item_info">
						<p
							className="text-center text-truncate"
							style={{ color: "white" }}
							onClick={() => navigate(`user/${owner_id}`)}
						>
							{accountId !== owner_id
								? token_id.indexOf(":") > -1
									? `Owned by ${formatAccountId(owner_id)}`
									: `Created by ${formatAccountId(owner_id)}`
								: `You own this!`}
						</p>
						<span
							onClick={() => {
								navigate("/token/" + token_id);
							}}
						>
							<h4
								className="text-truncate"
								style={{ textTransform: "capitalize" }}
							>
								{title}
							</h4>
						</span>
						{Object.keys(sale_conditions).length > 0 && (
							<>
								{Object.keys(sale_conditions).length > 0 && (
									<>
										{Object.entries(sale_conditions).map(
											([ft_token_id, price]) => (
												<>
													<div className="nft__item_price" key={ft_token_id}>
														{price === "0" ? (
															<del>SALE</del>
														) : (
															`${min_sell_price} Ⓝ`
														)}{" "}
														<small className="text-warning">(${parseFloat(changedPrice * min_sell_price).toFixed(2)})</small>
														<span>
															{current_copy == "0"
																? (copies ? copies : "1") + " copies"
																: `#${current_copy} /${copies ? copies : "1"}`}
														</span>
													</div>
													<div className="nft__item_action">
														<span
															onClick={() => {
																navigate("/token/" + token_id);
															}}
														>
															{price === "0" ? "See Details" : "Buy Now"}
														</span>
													</div>
												</>
											)
										)}
									</>
								)}
							</>
						)}
						{Object.keys(sale_conditions).length <= 0 && (
							<>
								{
									<>
										<div className="nft__item_price">
											<del>SALE</del>
											<span>
												{current_copy == "0"
													? (copies ? copies : "1") + " copies"
													: `#${current_copy} /${copies ? copies : "1"}`}
											</span>
										</div>
										<div className="nft__item_action">
											<span
												onClick={() => {
													navigate("/token/" + token_id);
												}}
											>
												See Details
											</span>
										</div>
									</>
								}
							</>
						)}
						<div className="nft__item_like" style={{cursor:'default'}}>
							<i className="fa fa-heart"></i>
							<span>{fav_count}</span>
						</div>
					</div>
				</span>
			</div >
		</div >
	);
};

export default memo(NftCardMarket);
