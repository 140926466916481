import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import "./faqs.css";
import { Helmet } from "react-helmet";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const accordion = () => (
	<div>
		<GlobalStyles />
		<Helmet>
			<title>UniqART - FAQ</title>
			<link rel="canonical" href={websiteUrl + "/faqs"} />
			<meta property="og:title" content={`UniqART - FAQ`} />
			<meta property="og:url" content={`${websiteUrl}/faqs`} />
		</Helmet>
		<section className="jumbotron breadcumb no-bg">
			<div className="mainbreadcumb pb-5">
				<div className="container">
					<div className="row m-10-hor">
						<div className="col-12">
							<h1 className="text-center">Frequently Asked Questions</h1>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="container">
			<div className="row">
				<div className="px-md-5 col-12">
					<Accordion className="px-md-5">
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="0"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									What is Uniqart?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<strong>Uniqart </strong>
									is an NFT Marketplace on the NEAR Blockchain; created by the Artists, for the Artists! Our platform allows creators to mint/buy/sell NFTs and connect with fans and collectors from around the world. (Read more on our Medium: <a href="https://uniqart.medium.com/what-is-uniqart-4e0ca08ab3e7">https://uniqart.medium.com/what-is-uniqart-4e0ca08ab3e7</a>)
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="1"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									How do I mint an NFT on UniqArt?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body>
									{" "}
									To mint an NFT, you will need a NEAR wallet. Your wallet can be created from https://wallet.near.org. After creation, connect your wallet to the website and fill up the verification form here to get yourself verified – <a href="https://uniqart.io/verification-form">https://uniqart.io/verification-form</a> <br />(Contact johnreyam#3582 on Discord for queries).
									<br />
									Read more on How to Mint/Sell an NFT here: <a href="https://uniqart.medium.com/">https://uniqart.medium.com/</a> how-to-mint-sell-an-nft-on-uniqart-a-step-by-step-guide-e421e108b7ce
									Read more on NEAR Wallet Initialization here: <a href="https://artsandcrypto.medium.com">https://artsandcrypto.medium.com</a>

								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="2"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									How can I buy an NFT on UniqArt?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="2">
								<Card.Body>
									{" "}
									To buy an NFT, you will need a NEAR wallet. Create your wallet from <a href="https://wallet.near.org">https://wallet.near.org</a>. After creation, connect your wallet and choose your desired NFT. Buy your NFT with NEAR(N) and support your favourite artists! Your purchased NFTs will show in the “Collectibles” page on your profile.
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="3"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									How can I become a creator on UniqArt?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="3">
								<Card.Body>
									{" "}
									To create NFTs on our platform, fill up the verification form here – <a href="https://uniqart.io/verification-form">https://uniqart.io/verification-form</a>, and contact johnreyam#3582 on Discord. Once creators are verified to mint, follow steps here to mint your first NFT! <a href="https://uniqart.medium.com/how-to-mint-sell-an-nft-on-uniqart-a-step-by-step-guide-e421e108b7ce">https://uniqart.medium.com/how-to-mint-sell-an-nft-on-uniqart-a-step-by-step-guide-e421e108b7ce</a>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="4"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									What is a NEAR wallet? Why do I need one?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="4">
								<Card.Body>
									{" "}
									Your physical wallet probably contains your money, a form of ID, and maybe pictures of your loved ones (we’ll ignore that 3-year-old Delhi Metro card with Rs.15 on it). Your crypto wallet does that on a blockchain, in UniqArt’s case, it’s a NEAR wallet (since we run on the NEAR Blockchain)
									<br />
									It has an “ID” (a long string of numbers and letters), your cryptocurrencies, and any NFTs you bought with those cryptocurrencies.
									<br />
									When you create a crypto wallet, you get a “seed phrase”(password) —  a series of words that lets you recover your currencies or NFTs. At UniqArt, we have partnered with NEAR to provide you the seamless ability to transact with cryptocurrencies or NFTs at super-fast speeds!

								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="5"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									How much does it cost to create an NFT on UniqArt?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="5">
								<Card.Body>
									{" "}
									When minting an NFT in the Marketplace, users have to interact with the blockchain. This requires a small fee called “gas”, and on the NEAR blockchain, this fee is extremely low! (&#x276E; 0.01N = 0.134$).
									<br />
									When selling your minted NFT, users will be asked to pay a storage fee of &#x276E; 0.1N = 1.34$ (at the time of writing). This fee is enough to mint 10+ unique NFTs with multiple copies!
									<br />
									Compared to other blockchains, NEAR has the lowest fees and this makes it the go-to marketplace for artists, fans and NFT collectors.

								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="6"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									Where can I buy some NEAR?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="6">
								<Card.Body>
									{" "}
									You can get NEAR from a crypto exchange platform such as Binance, Huobi, etc. (You could also buy it directly at wallet.near.org once your wallet setup is complete) When you get some NEAR from the exchange, you can transfer it to your NEAR wallet and you are good to go!.
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="7"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									What are the fees on UniqArt?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="7">
								<Card.Body>
									UniqArt takes 2% on the buyers side and 2% on the seller side from every sale happening on the marketplace. That’s it. That's what we need to run the platform and the rest goes to the person who's at the forefront – the Artist
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						{/* <Card>
              <Card.Header className="faqs_header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="8"
                  style={{ fontSize: "20px", textDecoration: "none" }}
                >
                  What is blockchain?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  {" "}
                  In simple words, blockchain is essentially a database. It is a
                  network of computers that tracks transactions in the network
                  and generates a giant ledger of who owns what (and how much of
                  it). That’s a lot of beep-boop action! As the name suggests,
                  blockchain is a sequence of blocks that contains a number of
                  transactions, and every time a new transaction occurs, a
                  record of that is added to the user’s digital ledger. What
                  makes blockchain technology revolutionary is the fact that it
                  is impossible to change, cheat or hack the system. The ledger
                  gets permanently altered with every transaction and is
                  unchangeable. So if you ignore your friends’ well-meaning
                  advice and spend six figures on a CryptoPunk, computers around
                  the world confirm you as the NFTs new owner – and ensure it
                  stays in your wallet.
                </Card.Body>
              </Accordion.Collapse>
            </Card>{" "} */}
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="9"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									Which blockchains does UniqArt support?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="9">
								<Card.Body>
									{" "}
									Currently, we only support the NEAR Blockchain, although we’re planning to integrate more blockchains in the near future.
								</Card.Body>
							</Accordion.Collapse>
						</Card>{" "}
						{/* <Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="10"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									How much does it cost to create an NFT?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="10">
								<Card.Body>
									{" "}
									When you first create an NFT, you “mint” it with a few clicks
									— which might mean you have to pay a fee to the network (none
									of this goes to UniqArt) Fees depend on the blockchain and
									minting option. Blockchains charge a fee for minting, buying,
									and selling. Imagine it like highways on a road trip. Some are
									free. Others charge you a toll, which can be cheap or
									expensive. We have partnered with NEAR blockchain since it has
									one of the lowest fees. Creating a new NFT requires two
									interactions: • Minting your NFT • Making your NFT available
									for sale
								</Card.Body>
							</Accordion.Collapse>
						</Card>{" "}
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="11"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									What are UniqArt’s fees?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="11">
								<Card.Body>
									{" "}
									UniqArt will take 2% on the buyer side and 2% on the seller
									side from every sale happening on the marketplace. That’s it.
									That's what we need to run the platform and the rest goes to
									the person who's at the forefront – the Artist.
								</Card.Body>
							</Accordion.Collapse>
						</Card>{" "}
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="12"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									Which blockchains does UniqArt support?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="12">
								<Card.Body>
									{" "}
									UniqArt currently only supports NEAR, although we’re planning
									to integrate more blockchains in the near future..
								</Card.Body>
							</Accordion.Collapse>
						</Card>{" "}
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="13"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									How do I sell my NFT on UniqArt?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="13">
								<Card.Body>
									{" "}
									Your first NFT sale might become a life-changing memory, so
									we’re happy you want us to be part of it! Once you mint your
									NFT on UniqArt, you can press “sell”. Then you have three
									options: Fixed price: Put your NFT on the market at a price
									anyone can buy it at. Timed auction: Your NFT will be sold to
									the highest bidder. You can set exact starting and ending
									times as well as a minimum price so you don’t give your NFT
									away for too cheap Open auction: This shows your NFT as “open
									for bids”. Anyone can make you an offer at any time—and you
									can decline or accept any offers you get. (A separate text
									channel dedicated to the minting process will be uploaded
									soon)
								</Card.Body>
							</Accordion.Collapse>
						</Card>{" "}
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="14"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									Where can I get some NEAR?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="14">
								<Card.Body>
									{" "}
									You can get NEAR from a crypto exchange platform such as
									Binance, Huobi, etc. (You could also buy it directly at
									wallet.near.org once your wallet setup is complete) When you
									get some NEAR from the exchange, you can transfer it to your
									NEAR wallet and you are good to go!
								</Card.Body>
							</Accordion.Collapse>
						</Card>{" "}
						<Card>
							<Card.Header className="faqs_header">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="15"
									style={{ fontSize: "20px", textDecoration: "none" }}
								>
									If I mint NFTs on UniqArt, will I get royalties on secondary
									sales?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="15">
								<Card.Body>
									{" "}
									Yes! As a creator, you can choose your royalties when you mint
									your NFT. After that, you get a percentage of the sale price
									(usually 5-10%) deposited into your wallet every time your NFT
									sells. Waking up to new assets in your wallet is a nice
									feeling!
								</Card.Body>
							</Accordion.Collapse>
						</Card>{" "} */}
						{/* <Card>
              <Card.Header className="faqs_header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="16"
                  style={{ fontSize: "20px", textDecoration: "none" }}
                >
                  My friends, parents even the news (duh) tells me that
                  cryptocurrency and NFTs are illegal. Why should I make an NFT
                  then? I don’t want to go to jail.
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="16">
                <Card.Body>
                  {" "}
                  The craze for non-fungible tokens (NFTs) in India is obvious
                  after the fabulous response to the auction of megastar Amitabh
                  Bachchan's NFT collection. The collection saw the highest-ever
                  bid of more than ₹7 crores. Many other prominent figures like
                  Salman Khan, A.R. Rahman, Kamal Haasan, Sunny Leone, and
                  others have also released their own NFT collections. Unlike
                  cryptocurrency, NFTs are digital assets, and extrapolating
                  existing provisions under FEMA, crypto-assets and NFTs could
                  be treated as intangible assets like software and IP.
                  Currently, art and collectible NFTs, like the ones launched by
                  the Bollywood actors, are capturing mainstream attention.
                  However, experts believe NFTs will be the standard
                  representation for digital assets in the next three-five
                  years. – "Identity NFTs, legal documents represented as NFTs,
                  tickets as NFTs are a few use cases we are excited about and
                  thus, we see NFTs as an omnipresent technology which will be
                  integrated in everyone’s lives"
                </Card.Body>
              </Accordion.Collapse>
            </Card>{" "}
            <Card>
              <Card.Header className="faqs_header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="17"
                  style={{ fontSize: "20px", textDecoration: "none" }}
                >
                  What do you mean by Decentralized Crowdfunding?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="17">
                <Card.Body>
                  {" "}
                  Decentralized crowdfunding means the ability to raise funds
                  without the involvement of an intermediary. Running a
                  successful crowdfunding campaign in the cryptocurrency space
                  is one of our main visions for independent artists. We plan to
                  achieve this through the implementation of smart contracts
                  through NFTs. The internet has given way to crowdfunding
                  opportunities in many different forms. For some artists, the
                  traditional and centralized approach - with its lack of
                  transparency, high fees, and other drawbacks - remains
                  favorable due to its "legacy appeal". Centralized platforms
                  are more commonly used than their decentralized counterparts,
                  but that narrative can change over the coming years. The
                  advantages of decentralized crowdfunding solutions are visible
                  for everyone to see. More transparency, global availability,
                  and lower fees will benefit both artists and their
                  fans/investors alike.
                </Card.Body>
              </Accordion.Collapse>
            </Card>{" "}
            <Card>
              <Card.Header className="faqs_header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="18"
                  style={{ fontSize: "20px", textDecoration: "none" }}
                >
                  Why should I even create an NFT? I’m happy with things as they
                  are.
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="18">
                <Card.Body>
                  {" "}
                  History has always favored the brave. This is your chance to
                  join the next tech revolution! The era of NFTs and the
                  metaverse is here. You can either embrace this new technology
                  or ignore it. If you’re still not convinced, let me give you
                  some benefits of NFTs: •You are able to sell music, merch,
                  tickets (you can sell whatever you want in the form of an NFT)
                  directly to your fans •You can build a community of fans who
                  support you through decentralized crowdfunding programs and
                  more •NFT museums are slowly coming into existence, enabling
                  Artists to connect with fans on a global scale, without the
                  need for middlemen •There are endless possibilities of
                  collaboration and innovation in the field with other creators
                  in the NFT space
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
					</Accordion>
				</div>
			</div>
		</section>

		<Footer />
	</div>
);
export default accordion;
