import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { navigate } from "@reach/router";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const slidermainparticle = ({ nftArtist, nftcollectibles, nftauctions }) => (
	<div className="container">
		<div className="row align-items-center mt-5">
			<div className="col-md-6">
				<div className="spacer-single"></div>
				<h6> <span className="color">UniqArt-The Artist's Marketplace</span></h6>
				<Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
					<h1 className="col-white">Create, sell or collect digital items.</h1>
				</Reveal>
				<Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
					<p className="lead col-white">
						An NFT Marketplace on the NEAR Blockchain; by the Artists, for the Artists.
					</p>
				</Reveal>
				<div className="spacer-10"></div>
				<Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
					<span onClick={() => navigate("/market")} className="btn-main inline lead">Market</span>
					<span onClick={() => navigate("/create")} className="btn-main inline lead">Create</span>
					<div className="mb-sm-30"></div>
				</Reveal>

				<Reveal className='onStep d-inline' keyframes={inline} delay={900} duration={1200} triggerOnce>
					<div className="row">
						<div className="spacer-single"></div>
						<div className="row setNftData">
							<div className="col-lg-4 col-md-6 col-sm-4 mb30">
								<div className="de_count text-left">
									<h3><span>{nftcollectibles}</span></h3>
									<h5 className="id-color">Collectibles</h5>
								</div>
							</div>

							<div className="col-lg-4 col-md-6 col-sm-4 mb30">
								<div className="de_count text-left">
									<h3><span>{nftauctions}</span></h3>
									<h5 className="id-color">Auctions</h5>
								</div>
							</div>

							<div className="col-lg-4 col-md-6 col-sm-4 mb30">
								<div className="de_count text-left">
									<h3><span>{nftArtist}</span></h3>
									<h5 className="id-color">NFT Artists</h5>
								</div>
							</div>
						</div>
					</div>
				</Reveal>
			</div>
			<div className="col-md-6 xs-hide">
				<Reveal className='onStep d-inline' keyframes={inline} delay={300} duration={1200} triggerOnce>
					<img src="./img/misc/women-with-vr.png" className="img-fluid" alt="" />
				</Reveal>
			</div>
		</div>
	</div>
);
export default slidermainparticle;