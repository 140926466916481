import { Location, Redirect, Router } from "@reach/router";
import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import Header from "./menu/header";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Activity from "./pages/activity";
import MyActivity from "./pages/myActivity";
import Alerts from "./pages/alerts";
import Auction from "./pages/Auction";
import Author from "./pages/Author";
import Check from "./pages/check";
import Collection from "./pages/collection";
import Contact from "./pages/contact";
import Create from "./pages/create";
import CreateCollection from "./pages/createCollection";
import ElegantIcons from "./pages/elegantIcons";
import EtlineIcons from "./pages/etlineIcons";
import Explore from "./pages/explore";
import Faqs from "./pages/Faqs";
import FontAwesomeIcons from "./pages/fontAwesomeIcons";
import Helpcenter from "./pages/helpcenter";
import Home from "./pages/home";
import Home1 from "./pages/home1";
import Home2 from "./pages/home2";
// import ItemDetail from './pages/ItemDetail';
import ItemDetailRedux from "./pages/ItemDetailRedux";
import Login from "./pages/login";
import LoginTwo from "./pages/loginTwo";
import Market from "./pages/market";
import Token from "./pages/token";
import News from "./pages/news";
import Price from "./pages/price";
import Profile from "./pages/profile";
import Progressbar from "./pages/progressbar";
// import Rangking from './pages/rangking';
import RankingRedux from "./pages/RankingRedux";
import Register from "./pages/register";
import Search from "./pages/search";
import Tabs from "./pages/tabs";
import UserCollection from "./pages/userCollection";
import UserCollectionType from "./pages/userCollectionType";
import Wallet from "./pages/wallet";
import Works from "./pages/works";
import Mybids from "./pages/mybids2";
import MyOffers from "./pages/myOffers";
import Form from "./pages/NftForm";
import Getconfig from "../config";
import EditCollection from "./pages/editCollection";
import CreateAccount from "./pages/CreateAccount";
import CreateAccountNearamp from "./pages/CreateAccountNearamp";
import Success from "./pages/success";
export const { websiteUrl, apiUrl } = Getconfig();

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);

const app = () => (
  <div className="wraper">
    <GlobalStyles />
    <Header />
    <Helmet>
      <meta charSet="utf-8" />
      <title>UniqArt - NFT Marketplace</title>
      <link rel="canonical" href={websiteUrl + "/"} />
      <meta content="text/html;charset=utf-8" http-equiv="Content-Type" />
      <meta
        content="At UniqART, we aim to create an inclusive and comfortable environment for musicians, which gives them freedom to establish a 2 way interaction with their community facilitated via NFT’s."
        name="description"
      />
      <meta
        content="Uniqart,NFT,Blockchain,Marketplace,Near Marketplaces"
        name="keywords"
      />
      <meta content="Uniqart" name="author" />
      <meta property="og:title" content="UniqArt - NFT Marketplace" />
      <meta property="og:url" content={websiteUrl + "/"} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={websiteUrl + "/img/author/metaImage.jpg"}
      />
    </Helmet>
    <PosedRouter>
      <ScrollTop path="/">
        <Home exact path="/">
          <Redirect to="/home" />
        </Home>
        <Home1 path="/home1" />
        <Home2 path="/home2" />
        <Explore path="/explore" />
        <Market path="/market" />
        <Market path="/market/:type" />
        <Token path="/token/:tokenId" />
        <EditCollection path="/editCollection/:collectionId" />
        <MyActivity path="/myActivity" />
        <Helpcenter path="/helpcenter" />
        <RankingRedux path="/rangking" />
        <CreateAccount path="/create-account" />
        <CreateAccountNearamp path="/create-account-new" />
        <Collection path="/collection" />
        <UserCollection path="/user/:userId" />
        <Success path="/success/:successId" />
        <UserCollectionType path="/user-collection/:collectionId" />
        <ItemDetailRedux path="/ItemDetail" />
        <Author path="/Author" />
        <Wallet path="/wallet" />
        <Login path="/login" />
        <Form path="/verification-form" />
        <LoginTwo path="/loginTwo" />
        <Register path="/register" />
        <Price path="/price" />
        <Works path="/works" />
        <News path="/news" />
        <Create path="/create" />
        <CreateCollection path="/createCollection" />
        <Check path="/check" />
        <Auction path="/Auction" />
        <Activity path="/activity" />
        <Contact path="/contact" />
        <ElegantIcons path="/elegantIcons" />
        <EtlineIcons path="/etlineIcons" />
        <FontAwesomeIcons path="/fontAwesomeIcons" />
        <Faqs path="/faqs" />
        <Alerts path="/alerts" />
        <Progressbar path="/progressbar" />
        <Search path="/search" />
        <Tabs path="/tabs" />
        <Profile path="/profile" />
        <MyOffers path="/myOffers" />
        <Mybids path="/mybids" />
      </ScrollTop>
    </PosedRouter>
    <ScrollToTopBtn />
  </div>
);
export default app;
