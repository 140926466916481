import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Getconfig from "../../config";
import { v4 } from "uuid";
import { roundToUp } from "round-to";
export const {
  websiteUrl,
  apiUrl,
  extraFee,
  offerextraFee,
  storage_fee,
  nftApproveStorageDeposit,
  nftApprove,
  transTxnUrl,
} = Getconfig();

const AttributeWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const AttributeDiv = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  white-space: nowrap;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
`;

const SingleAttribute = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid #333;
  border-radius: 10px;
  &:hover {
    color: #fff;
    border-color: #8364e2 !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
`;

const SingleAnchor = styled.a`
  font-weight: 500;
  color: inherit;
  text-decoration: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const SinglePara1 = styled.p`
  opacity: 0.7;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.025em;
  margin-bottom: 0;
`;
const SinglePara2 = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.025em;
  margin-bottom: 0.2rem;
  color: #f1f1f1;
`;

const SinglePara3 = styled.p`
  opacity: 0.7;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.025em;
  margin-bottom: 0;
`;

const AttributeComponent = ({ sell_id, collection_id }) => {
  const [attributes, setAttribute] = useState([]);
  const [rarityScore, setRarityScore] = useState(0);

  const ftechRarity = async (sell_id, collection_id) => {
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/attribute_rarity?sell_id=${sell_id}&collection_id=${collection_id}`
      );
      if (result.data["status"] === "Success") {
        const newList = result.data["data"];
        if (newList !== null) {
          setAttribute(newList);
          var rarityValue = 0;
          newList.map((data, key) => {
            rarityValue = rarityValue + 1 / data.trait_value;
            setRarityScore(rarityValue);
          });
        }
      } else {
        console.log("click not count");
        setAttribute([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    ftechRarity(sell_id, collection_id);
  }, [sell_id, collection_id]);

  return (
    <AttributeWrapper className="my-3">
      {attributes && Object.keys(attributes).length > 0 && (
        <>
          <h4>Attributes</h4>
          <AttributeDiv>
            {attributes.map((data, key) => (
              <>
                <SingleAttribute className="shadow" key={v4()}>
                  <SingleAnchor href={`/user-collection/${collection_id}?trait_type=${data.attribute.trait_type}&trait_value=${data.attribute.value}`}>
                    <SinglePara1 className="text-white font-light opacity-70 text-sm truncate mb-0">
                      {data.attribute.trait_type}
                    </SinglePara1>
                    <SinglePara2 className="text-white font-medium text-sm text-truncate mb-1 mb-0">
                      {data.attribute.value}
                    </SinglePara2>
                    <SinglePara3 className="text-gray-300 font-light opacity-70 text-sm mb-0">
                      {roundToUp(data.trait_value, 2)}% rarity
                    </SinglePara3>
                  </SingleAnchor>
                </SingleAttribute>
              </>
            ))}
          </AttributeDiv>
          <SinglePara3 className="text-gray-300 font-light opacity-100 text-md mb-0 my-3 text-white">
            Rarity Score :{" "}
            <span className="text-warning" style={{ fontWeight: "bold" }}>
              {roundToUp(rarityScore * 100, 2)}
            </span>
          </SinglePara3>
        </>
      )}
    </AttributeWrapper>
  );
};

export default AttributeComponent;