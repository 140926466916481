import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { appStore, snackAttack, onAppMount } from "../../state/app";
import axios from "axios";
import Getconfig from '../../config';
import { navigate } from "@reach/router";
export const {
  websiteUrl, apiUrl, extraFee, offerextraFee, storage_fee, nftApproveStorageDeposit, nftApprove, transTxnUrl
} = Getconfig();


class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowPopularCollection: [],
    };
  }
  async componentDidMount() {
    this.fetchPopularCollection()
  }

  async fetchPopularCollection() {
    try {
      const result = await axios.get(`${apiUrl}/api/v1/fetch_popular_collections/`);
      if (result.data["status"] === "success") {
        const newList = (result.data['result']);
        if (newList !== null) {
          this.setState({ ShowPopularCollection: newList })
        } else {
          this.setState({ ShowPopularCollection: [] })
        }
      } else {
        this.setState({ ShowPopularCollection: [] })
      }
    } catch (error) {
      console.error(error);
    }
  }


  render() {
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    };
    return (
      <div className='nft'>
        <Slider {...settings}>
          {
            this.state.ShowPopularCollection.map((data, index) => (
              <CustomSlide className='itm' index={index} key={index}>
                <div className="nft_coll collectionCard">
                  <div className="nft_wrap" onClick={() => navigate(`../user-collection/${data.collection_id}`)}>
                    <span><img src={data.cover_image ? data.cover_image : './img/collections/coll-1.jpg'} className="lazy img-fluid" alt="" style={{ height: '171px', width: '304px', objectFit: 'cover' }} /></span>
                  </div>
                  <div className="nft_coll_pp d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '5rem', height: '5rem' }} onClick={() => navigate(`../user-collection/${data.collection_id}`)}><img className="lazy" style={{ width: '5rem', height: '5rem', objectFit: 'cover' }} src={data.logo} alt="" /></div>
                  </div>
                  <div className="nft_coll_info mt-2">
                    <span onClick={() => navigate(`../user-collection/${data.collection_id}`)}>
                      <h3 className="text-truncate" style={{ fontSize: '1.3rem', marginBottom: '0.5rem' }}>{data.name}</h3>
                    </span>
                    <span className="text-truncate d-flex justify-content-center align-items-center" style={{ fontSize: '1rem', marginTop: '1rem' }}>Collection by&nbsp;<span style={{ fontSize: '1rem', fontWeight: 'bold' }} className='collectionCardText' onClick={() => navigate(`/user/${data.user_id}`)}>{data.user_id}</span>
                      {data.user_verified && (<i className="fa fa-check verifiedUser mx-1"></i>)}
                    </span>
                  </div>
                </div>
              </CustomSlide>
            ))
          }
        </Slider>
      </div >
    );
  }
}
