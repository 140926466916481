import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import UserTopBuyer from './UserTopBuyer';
import * as selectors from '../../store/selectors';
import { fetchAuthorList } from "../../store/actions/thunks";
import axios from "axios";
import { carouselNew } from './constants';
import Slider from "react-slick";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();


const AuthorList = () => {

	const dispatch = useDispatch();
	const authorsState = useSelector(selectors.authorsState);
	const [authors, setAuthor] = useState([]);

	var fetchnftdata = async () => {
		try {
			const result = await axios.get(apiUrl + "/api/v1/top_buyers");
			if (result.data["status"] === "success") {
				// const newList = concat(...authors, result.data['data']);
				setAuthor(result.data['data']);

			} else {
				// setItemsStatus(false);
			}
		} catch (error) {
			console.error(error);
		}
	}


	useEffect(() => {
		dispatch(fetchAuthorList());
		fetchnftdata();
	}, [dispatch]);

	return (
		<>
		{authors.length > 0 &&
		<Slider {...carouselNew}>
			{authors && authors.map((author, index) => (
				<UserTopBuyer user={author} index={index} key={index} />
			))}
		</Slider>
		}</>
	);
};
export default memo(AuthorList);