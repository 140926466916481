import { navigate } from "@reach/router";
import { memo, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import moment from 'moment';

const Icon = styled.div`
  font-family: "FontAwesome";
  margin-right: 15px;
  float: right;
  position: absolute;
  right: 20px;
  top: 30%;
  font-size: 26px;
  color: #8364e2;
  background: rgba(255, 255, 255, 0.025);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 30%);
  height: 48px;
  width: 48px;
  padding: 6px 0;
  text-align: center;
  border-radius: 100%;
`;

function renderNFTType(type) {
	if (type === 'mint') {
		return 'received'
	}
	if (type === 'create') {
		return 'created'
	}
	if (type === 'sell') {
		return 'sold'
	}
	if (type === 'buy') {
		return 'bought'
	}
}

const ActivityComponent = ({ item }) => {
	const [loadingImg, setLoading] = useState(true);
	const [compressedImageUrl, setCompressedImageUrl] = useState(null);
	const [FileExtension, setFileExtension] = useState(null);
	const [FileUrl, setFileUrl] = useState(null);
	const [imageType, setimageType] = useState(true);


	function isFileImage(file) {
		const acceptedImageTypes = ["gif", "jpeg", "png", "webp", "jpg", "avif", "apng", "svg+xml", "bmp", "x-icon", "tiff"];
		return (file && acceptedImageTypes.includes(file));
	}

	useEffect(() => {
		if (item.extra) {
			if (item.extra.includes("**")) {
				const myArray = item.extra.split("**");
				setFileExtension(myArray[0]);
				setFileUrl(myArray[1]);

			} else {
				let fetchRes = fetch(item.extra);
				fetchRes.then(res => res.json()).then(d => {
					setFileExtension(d.fileExtension);
					setFileUrl(d.filesCover);
					if(d.fileExtension !== "gif"){
						setCompressedImageUrl(d.compressedImage);
					}
				})
					.catch(e => {
						console.log('error in metadata loading', e)
					})
			}
		}
		if (FileExtension) {
			setimageType(isFileImage(FileExtension.toLowerCase()));
		} else {
			setimageType(true);
			setFileExtension('jpg');
		}
	}, [item.extra]);


	return (
		<li className="act_sale">
			<span
				style={{
					display: loadingImg ? "block" : "none",
					position: "absolute",
				}}
			>
				<SkeletonTheme baseColor="#202020" highlightColor="#444" display="flex">
					<Skeleton height={85} width={85} />
				</SkeletonTheme>
			</span>

			<span style={{ display: loadingImg ? "none" : "block" }}>
				{
					imageType ? (
						<img
							style={{ cursor: "pointer" }}
							className="lazy"
							src={compressedImageUrl ? compressedImageUrl : item.media_url}
							onLoad={() => setLoading(false)}
							alt="profile"
							onClick={() => {
								navigate("/token/" + item.sell_id);
							}}
						/>
					) : (
						<img
							style={{ cursor: "pointer" }}
							className="lazy"
							src={compressedImageUrl ? compressedImageUrl : FileUrl}
							onLoad={() => setLoading(false)}
							alt="profile"
							onClick={() => {
								navigate("/token/" + item.sell_id);
							}}
						/>
					)
				}
			</span>
			<div className="act_list_text">
				<h4
					className="text-capitalize"
					onClick={() => {
						navigate("/token/" + item.sell_id);
					}}
					style={{ cursor: "pointer" }}
				>
					{item.name ? item.name : item.nft_title.split("#")[0]}{" "}
					{item.current_copy > 0 ? `#${item.current_copy}` : ""}
				</h4>

				NFT {renderNFTType(item.type)} by{" "}

				<span

					onClick={() =>
						navigate(
							`user/${item.seller_address
								? item.seller_address
								: item.buyer}`
						)
					}
					className="color"
					style={{ cursor: "pointer" }}
				>
					{item.seller_address
						? item.seller_address
						: item.buyer}
				</span>
				<span className="act_list_date">
					on {moment(item.updatedAt).format('lll')}
					{/* on {item.updatedAt} */}
				</span>
			</div>
			{item.type === "sell" ? (
				<Icon className="fa fa-shopping-basket"></Icon>
			) : (
				""
			)}
			{item.type === "mint" ? (
				<Icon className="fa fa-check"></Icon>
				// <Icon className="fa fa-pencil"></Icon>
			) : (
				""
			)}
			{item.type === "create" ? (
				<Icon className="fa fa-pencil"></Icon>
			) : (
				""
			)}
			{item.type === "buy" ? (
				<Icon className="fa fa-check"></Icon>
			) : (
				""
			)}

		</li>
	)
}
export default memo(ActivityComponent);