import { useEffect, useState, useContext } from 'react';
import { Spinner } from "reactstrap";
import Cookies from 'js-cookie';
import { navigate } from "@reach/router";
import { appStore, onAppMount } from "../../state/app";

const Success = (props) => {
    const [redirectUrl, setRedirectUrl] = useState(null);
    const { state } = useContext(appStore);
    const { wallet, account } = state;
    let urlParams2 = new URLSearchParams(window.location.search);
    let redirect = urlParams2.get('redirect');

    useEffect(() => {
        if (wallet && wallet.signedIn) {
            if (redirect === '/market') {
                setRedirectUrl('/market');
            } else {
                setRedirectUrl(`https://${redirect}?username=${account.accountId}&nearbalance=${wallet.balance}`);
            }
        }

        if (redirectUrl) {
            if (props.successId && (!Cookies.get('User_IP'))) {
                Cookies.set('User_IP', props.successId, { expires: 1, path: '/' });
                window.location.replace(redirectUrl);
            } else {
                navigate('/market');
            }
        }
    }, [props.successId, redirect, redirectUrl, wallet]);


    return (
        <div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
            <Spinner>
                Loading...
            </Spinner>
        </div>
    )
}

export default Success;