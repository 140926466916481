import { navigate } from "@reach/router";
import axios from 'axios';
import * as nearAPI from 'near-api-js';
import React, { memo, useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from "styled-components";
import Clock from "./Clock";
import Getconfig from '../../config';
import ReactTooltip from 'react-tooltip';
import FileType from 'file-type/browser';
import { acceptedImageTypes, acceptedAudioTypes, acceptedVideoTypes } from "../../utils";
export const {
	websiteUrl, apiUrl
} = Getconfig();

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  height: 100%;
`;
const {
	utils: { format: { formatNearAmount } }
} = nearAPI;
const NftStyle = styled.input`
	padding: 4px 11px;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 34px;
    outline: none;
    transition: 0.7s;
	&:hover{
	transition: 0.7s;
    box-shadow: 0px 0px 0px 4px rgb(255 52 53 / 30%);
  }
`;
//react functional component
const NftCard = ({ reference, nft, media, title, description, token_id, sale_conditions, bids, royalty, owner_id, marketStoragePaid, accountId, account, path, extra, copies, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad, is_auction, profileImg, verified, is_unlockable, extension, compressed_url, min_sell_price,nft_count }) => {
	// console.log(title,token_id,'title')
	const [price, setPrice] = useState('');
	const [ft, setFT] = useState('near');
	const [saleConditions, setSaleConditions] = useState({});
	// const [profileImg, setProfileImg] = useState(websiteUrl + '/img/author/author-11.jpg');
	const [loadingImg, setLoading] = useState(true);
	const [useCollectionName, setUseCollectionName] = useState('');
	const [useCollectionId, setUseCollectionId] = useState('');
	const [useCollectionDesc, setUseCollectionDesc] = useState('');
	const [useCollectionImage, setUseCollectionImage] = useState('');
	const [tokenSale, setTokenSalePrice] = useState(null);
	const [compressedImageUrl, setCompressedImageUrl] = useState(null);
	const [FileExtension, setFileExtension] = useState(null);
	const [FileUrl, setFileUrl] = useState(null);
	const [checkIsUnlockable, setCheckIsUnlockable] = useState(false);
	const [total_clicks, setTotalClicks] = useState(1);
	const [fav_count,setFavCount] = useState(0);
	const [changedPrice, setChangedPrice] = useState(null);
	const [newCompressed, setNewCompressed] = useState(null);

	let copy = '0';
	if(token_id && token_id.match(":")){
		const new_copy = (token_id.split(':'));
		copy = (new_copy[1].split(" ").join("")).toString();
	}else{
		copy = '0';
	}
	
	async function fetchClick(token_id, copy) {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/get_nft_token?nft_token_id=${token_id}&sell_id=${token_id}&current_copy=${copy}`);
			if (result.data["status"] === "success") {
				const collection_id = (result.data['data'][0].collection_id);
				const collection_name = (result.data['data'][0].collection_name);
				const collection_desc = (result.data['data'][0].collection_desc);
				const collection_logo = (result.data['data'][0].collection_logo);
				const tokenSalePrice = (result.data['data'][0].min_sell_price);
				const isUnlockable = (result.data['data'][0].is_unlockable);
				const total_clicks = (result.data['data'][0].total_clicks);
				const fav_count = (result.data['data'][0].fav_count);
				if(fav_count){
					setFavCount(fav_count);
				}else{
					setFavCount(0);
				}
				if (total_clicks) {
					setTotalClicks(total_clicks);
				} else {
					setTotalClicks(1);
				}
				if (isUnlockable) {
					setCheckIsUnlockable(true);
				} else {
					setCheckIsUnlockable(false);
				}
				if (tokenSalePrice) {
					setTokenSalePrice(tokenSalePrice);
				}
				if (collection_id) {
					setUseCollectionId(collection_id);
				}
				if (collection_name) {
					setUseCollectionName(collection_name);
				}
				if (collection_desc) {
					setUseCollectionDesc(collection_desc);
				}
				if (collection_desc) {
					setUseCollectionImage(collection_logo);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}

	localStorage.setItem('item-details', "false")

	let imageType = true;

	function isFileImage(file) {
		// const acceptedImageTypes = ["gif", "jpeg", "png", "webp", "jpg", "avif", "apng", "svg+xml", "bmp", "x-icon", "tiff"];
		return (file && acceptedImageTypes.includes(file));
	}
	useEffect(() => {
		const storage = window?.sessionStorage;
		if (!storage) return;
		const NearToUSD = storage.getItem("NearToUSD");
		setChangedPrice(NearToUSD);
	}, [])
	useEffect(() => {
		if (extra) {
			if (extra.includes("**")) {
				const myArray = extra.split("**");
				setFileExtension(myArray[0]);
				setFileUrl(myArray[1]);

			} else {
				let fetchRes = fetch(extra);
				fetchRes.then(res => res.json()).then(d => {
					setFileExtension(d.fileExtension);
					setFileUrl(d.filesCover);
					if (d.fileExtension !== "gif") {
						setCompressedImageUrl(d.compressedImage);
					}
				})
					.catch(e => {
						console.log('error in metadata loading', e)
					})
			}
			// let fetchRes = fetch(extra);
			// fetchRes.then(res => res.json()).then(d => {
			// 	FileExtension = d.FileExtension;
			// 	FileUrl = d.FileUrl;
			// })
		}

		if (FileExtension) {
			imageType = (isFileImage(FileExtension.toLowerCase()));
		} else {
			imageType = true;
			setFileExtension('jpg');
		}
	}, [extra]);

	useEffect(() => {
				/* ------------------------------- test start ------------------------------- */
		const getMedia = async (data) => {
			try {
				const resp = await axios.get(`${data}`, {
					responseType: 'blob',
				});			
				const fileType = await FileType.fromBlob(resp.data);			
				const objectUrl = URL.createObjectURL(resp.data);
				// console.log(objectUrl,fileType,'file-type');
				setNewCompressed(objectUrl);
			} catch (err) {
				console.log(err,'err');
				setNewCompressed(compressed_url);
			}
		}
		/* -------------------------------- test end -------------------------------- */
		// new code start
		if (extension !== "gif") {
			if (compressed_url) {
				getMedia(compressed_url);
			} else {
				getMedia(media);
			}
		} else {
			getMedia(media);
		}
		// new code end
		fetchClick(token_id, copy);
	}, []);

	return (
		<div className={className} key={token_id}>
			<div className="nft__item">
				<span>

					<div className='icontype d-flex justify-content-center align-items-center'>
						{
							acceptedAudioTypes.includes(extension) &&
							<i className="fa fa-headphones"></i>
						}
						{
							acceptedVideoTypes.includes(extension) &&
							<i className="fa fa-video-camera"></i>
						}
						{
							(acceptedImageTypes.includes(extension) || extension == null || extension === "") &&
							<i className="fa fa-image"></i>
						}
						{checkIsUnlockable &&
							<div className="d-flex justify-content-center align-items-center">
								<i
									className="fa fa-lock text-danger"
									data-tip="Includes unlockable content"
									style={{ fontSize: "1.1rem", paddingLeft: '8px' }}
								></i>
								<ReactTooltip
									textColor="#000"
									backgroundColor="#fff"
									className="tooltipStyle"
								/>
							</div>
						}
					</div>

					{nft.deadline && clockTop &&
						<div className="de_countdown">
							<Clock deadline={nft.deadline} />
						</div>
					}
					<div className='text-truncate pb-2 pt-2 text-center text-capitalize' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}><span className='cardCollectionClick' onClick={() => navigate(`user-collection/${useCollectionId}`)}>{useCollectionName ? useCollectionName : 'New Collection'}</span></div>
					<div className="author_list_pp" style={{ zIndex: '2' }}>
						<span onClick={() => navigate(`user/${owner_id}`)}>

							<img className="lazy" src={profileImg ? profileImg : websiteUrl + '/img/author/author-11.jpg'} alt="" />
							{verified && <i className="fa fa-check"></i>}
						</span>
					</div>

					<div className="nft__item_wrap loaderSet" style={{ height: `${height}px` }}>
						<Outer>
							<div style={{ display: loadingImg ? "block" : "none" }}>
								<SkeletonTheme baseColor="#202020" highlightColor="#444" style={{ height: '200px' }}>
									<Skeleton height={180} width={200} style={{ display: 'flex' }} />
								</SkeletonTheme>
							</div>
							<span style={{ display: loadingImg ? "none" : "block" }}>
								{
									imageType
										?
										<img style={{ cursor: "pointer", objectFit: 'cover' }} onLoad={() => setLoading(false)} src={(newCompressed ? newCompressed : media)} className="lazy nft__item_preview" alt="" onClick={() => {
											navigate(path)
										}} />

										:
										<img style={{ cursor: "pointer", objectFit: 'cover' }} onLoad={() => setLoading(false)} src={newCompressed ? newCompressed : FileUrl} className="lazy nft__item_preview" alt="" onClick={() => {
											navigate(path)
										}} />
								}
							</span>
						</Outer>
					</div>
					<div className="nft__item_info">
						<span onClick={() => {
							navigate(path)
						}}>
							<h4 className='text-truncate' style={{ textTransform: "capitalize" }}>{reference}</h4>
						</span>
						{/* test start */}
						{
							tokenSale > 0 && <>
								{
									tokenSale > 0 && <>
										{
											(
												<>
													<div className="nft__item_price">
														{tokenSale === '0' ? '0' : tokenSale} {'Ⓝ'}
														{/* <small className="text-warning">(${parseFloat(changedPrice * formatNearAmount(price, 4)).toFixed(2)})</small> */}
														<span>{copy == '0' ? (copies ? copies : '1') + ' copies' : `#${copy} /${copies ? copies : '1'}`}</span>
													</div>
													<div className="nft__item_action">
														<span onClick={() => {
															navigate(path)
														}}>{tokenSale === '0' ? 'See Details' : "Buy Now"}</span>
													</div>
												</>
											)
										}
									</>
								}
							</>
						}
						{
							tokenSale <= 0 && <>
								{
									<>
										<div className="nft__item_price">
											<del>SALE</del><span>{copy == '0' ? (copies ? copies : '1') + ' copies' : `#${copy} /${copies ? copies : '1'}`}</span>
										</div>
										<div className="nft__item_action">
											<span onClick={() => {
												navigate(path)
											}}>See Details</span>
										</div>
									</>
								}
							</>
						}
						<div className="nft__item_like" style={{cursor:'default'}}>
							<i className="fa fa-heart"></i><span>{fav_count}</span>
						</div>
					</div>
				</span>
			</div>
		</div>
	);
};

export default memo(NftCard);