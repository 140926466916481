import React from 'react';
import near from '../../assets/images/near_new.png';
import filecoin from '../../assets/images/filecoin.png';
import { Link } from '@reach/router';

const footer = ({ customStyles = {} }) => (
	<footer className="footer-light py-0 pt-4" style={customStyles}>
		<div className="container pt-4">
            <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <img src="./img/logo-light.png"/>
                        <p>The Artist's NFT Marketplace</p>
                        {/* <h5>Newsletter</h5>
                        <p>Signup for our newsletter to get the latest news in your inbox.</p>
                        <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                            <div className="col text-center">
                                <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" />
                                <Link to="" id="btn-subscribe">
                                    <i className="arrow_right bg-color-secondary"></i>
                                </Link>
                                <div className="clearfix"></div>
                            </div>
                        </form>
                        <div className="spacer-10"></div>
                        <small>Your email is safe with us. We don't spam.</small> */}
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Marketplace</h5>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/market">Market</Link></li>
                            <li><Link to="/activity">Activity</Link></li>
                            
                            <li><a href="/market/art">Art</a></li>
                            <li><a href="/market/music">Music</a></li>
                            {/* <li><a href="/market/metaverse">Metaverse</a></li> */}
                            {/* <li><Link to="/market/games">Games</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Resources</h5>
                        <ul>
                            <li><a href="https://uniqart-io.notion.site/FAQs-1c2ad2a5cb5649bcb34afeb3bfaa5fd4" target="_blank">FAQs</a></li>
                            <li><a href="https://uniqart-io.notion.site/NFT-License-665841053a1f4fdf9c65e57fb820f34e" target="_blank">License</a></li>
                            {/* <li><Link to="">Suggestions</Link></li>
                            <li><Link to="">Discord</Link></li>
                            <li><Link to="">Docs</Link></li>
                            <li><Link to="">Newsletter</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Community</h5>
                        <ul>
                            <li><a href="https://discord.com/invite/fgax8BvBvM" target="_blank">Discord</a></li>
                            <li><a href="https://uniqart.medium.com" target="_blank">Medium</a></li>
                            <li><a href="https://twitter.com/uniqartnft" target="_blank">Twitter</a></li>
                            <li><a href="https://www.instagram.com/uniqart.io/" target="_blank">Instagram</a></li>
                            <li><a href="https://www.linkedin.com/company/76562636" target="_blank">LinkedIn</a></li>
                            {/* <li><Link to="">Brand Assets</Link></li>
                            <li><Link to="">Blog</Link></li>
                            <li><Link to="">Forum</Link></li>
                            <li><Link to="/faqs">Faqs</Link></li>
                            <li><Link to="">Mailing List</Link></li> */}
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
		<div className="subfooter mt-0">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="de-flex">
							<div className="de-flex-col">
								<span onClick={() => window.open("", "_self")}>
									<span className="copy">&copy; Copyright 2021 - UniqArt | Powered by </span>
                                    <img src={near} style={{width: '20px', filter: 'grayscale(100%)', margin: '0 5px'}}/>+ 
                                    <img src={filecoin} style={{width: '20px', filter: 'grayscale(100%)', margin: '0 5px'}}/>
								</span>
							</div>
							<div className="de-flex-col">
								<div className="social-icons">
									<span onClick={() => window.open("https://www.facebook.com/uniqart.io/", "_self")}><i className="fa fa-facebook fa-lg"></i></span>
									<span onClick={() => window.open("https://twitter.com/uniqartnft", "_self")}><i className="fa fa-twitter fa-lg"></i></span>
									<span onClick={() => window.open("https://www.linkedin.com/company/76562636", "_self")}><i className="fa fa-linkedin fa-lg"></i></span>
									<span onClick={() => window.open("https://www.instagram.com/uniqart.io/", "_self")}><i className="fa fa-instagram fa-lg"></i></span>
									<span onClick={() => window.open("mailto:Info@uniqart.io", "_self")}><i className="fa fa-envelope-o fa-lg"></i></span>
									<span onClick={() => window.open("https://uniqart.medium.com", "_self")}><i className="fa fa-medium fa-lg"></i>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
);
export default footer;