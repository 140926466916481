import React, { useCallback, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import "./mybids2.scss";
import Footer from "../components/footer";
import { useState } from "react";
import axios from "axios";
import { Spinner } from "reactstrap";
import { navigate } from "@reach/router";
import throttle from "lodash/throttle";
import { useMemo } from "react";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

// const bidsObj = {};
// const bidsObj2 = {
//   imgUrl:
//     "https://th.bing.com/th/id/OIP.MOzDS_KKWOvkMzR0HZSwggHaFj?pid=ImgDet&rs=1",
//   title: "greeen Dice $1",
//   detail: "It is good to have",
//   monthsAgo: "Two Months ago",
//   youOffer: "You offer 1 Ⓝ",
// };

const MYBIDS = "My Bids";
const RECEIVEDBIDS = "Received Bids";


const Mybids = () => {
	const [activeTab, setActiveTab] = useState(MYBIDS);

	const [myBidList, setMybidList] = useState([]);
	const [myBidListLoading, setMybidListLoading] = useState(false);

	const [receiveBids, SetReceivedBids] = useState([]);
	const [receiveBidsLoading, SetReceivedBidsLoading] = useState(false);



	const getMyBids = useCallback(() => {
		setMybidListLoading(true);

		axios
			.get(
				apiUrl + "/api/v1/owner_bids/0?owner_address=nmarich.testnet"
			)
			.then((res) => {
				console.log(res);
				setMybidList(res.data.data);
				setMybidListLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setMybidListLoading(false);
			});
	}, []);

	const getReceivedBids = () => {
		SetReceivedBidsLoading(true);

		axios
			.get(
				apiUrl + "/api/v1/owner_received_bids/0?owner_address=ritik22.testnet"
			)
			.then((res) => {
				console.log(res);
				SetReceivedBids(res.data.data);
				SetReceivedBidsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				SetReceivedBidsLoading(false);
			});
	};

	const getMyBidsThrottled = useMemo(() => throttle(getMyBids, 3000), []);
	const getReceivedBidsThrottled = useMemo(
		() => throttle(getReceivedBids, 3000),
		[]
	);

	// async function shiva() {
	//   try {
	//     const response = await axios.get(
	//       "https://api.uniqart.io/api/v1/owner_bids/0?owner_address=nmarich.testnet"
	//     );
	//     const data=response.data;
	//     console.log(data);

	//   } catch (error) {
	//     console.log(error);
	//   }
	// }
	// dipt()
	const isMyBidsTabsctive = activeTab == MYBIDS;
	const isReceiveBidsTabsActive = activeTab == RECEIVEDBIDS;

	const bidsList = isMyBidsTabsctive
		? myBidList
		: isReceiveBidsTabsActive
			? receiveBids
			: [];
	// console.log("dipt", bidsList);

	useEffect(() => {
		if (isMyBidsTabsctive) {
			getMyBidsThrottled();
		}
	}, [getMyBidsThrottled, isMyBidsTabsctive]);

	useEffect(() => {
		if (isReceiveBidsTabsActive) {
			getReceivedBidsThrottled();
		}
	}, [isReceiveBidsTabsActive, getReceivedBidsThrottled]);

	return (
		<>
			<div>
				<GlobalStyles />

				<Helmet>
					<title>UniqART - Activity</title>
					<link rel="canonical" href={websiteUrl + "/activity"} />
					<meta property="og:title" content={`UniqART - Activity`} />
					<meta
						property="og:url"
						content={`${websiteUrl}/activity`}
					/>
				</Helmet>
				{myBidListLoading && receiveBidsLoading && (
					<div
						style={{ height: "100vh" }}
						className="d-flex align-items-center justify-content-center"
					>
						<Spinner>Loading...</Spinner>
					</div>
				)}

				<section className="jumbotron breadcumb no-bg">
					<div
						className="mainbreadcumb"
						style={{ padding: 0, paddingTop: "150px" }}
					>
						<div className="container">
							<div className="bids-row mb-5" style={{ cursor: "pointer" }}>
								<div
									onClick={() => setActiveTab(MYBIDS)}
									className={activeTab === MYBIDS ? "active-tab" : ""}
								>
									My Bids
								</div>
								<div
									onClick={() => setActiveTab(RECEIVEDBIDS)}
									className={activeTab === RECEIVEDBIDS ? "active-tab" : ""}
								>
									Received Bids
								</div>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className="mybids-container">
						<div className="bids-list">
							{bidsList.map((bid, i) => {
								return (
									<div key={i} className="bids-item">
										<div className="bid-left">
											<img
												src={bid.media_url}
												onClick={() => {
													navigate("/market?t=" + bid.sell_id);
												}}
											/>
										</div>
										<div className="bid-right">
											<span
												className="bid-title text-white"
												onClick={() => {
													navigate("/market?t=" + bid.sell_id);
												}}
											>
												{bid.name}
											</span>
											{(activeTab === RECEIVEDBIDS && (
												<span
													onClick={() =>
														navigate(`user/${bid.buyer_id}`)
													}
												>
													{bid.buyer_id}
												</span>
											)) || (
													<span className="bid-description">
														{bid.collection_desc}
													</span>
												)}
											<span className="bid-detail">{bid.detail}</span>

											<span className="bid-youoffer text-white">
												{bid.type}
											</span>
											<span
												className="bid-monthsago"
												onClick={() =>
													navigate(`user/${bid.seller_address}`)
												}
											>
												{" "}
												{bid.seller_address}
											</span>
											{activeTab === RECEIVEDBIDS && <span>{bid.price} Ⓝ</span>}
											<span className="bid-buttons">
												<div>Update</div>
												<div>Cancel</div>
											</span>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</section>
				<Footer
					customStyles={{
						background: "#212529",
						position: "fixed",
						width: "100%",
						bottom: 0,
						zIndex: "25",
					}}
				/>
			</div>
		</>
	);
};

export default Mybids;
