import axios from "axios";
import React, { useContext, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import Getconfig from '../../config';
import { appStore } from '../../state/app';
import AuthorListRedux from '../components/AuthorListRedux';
import AuthorListReduxSeller from '../components/AuthorListReduxSeller';
import CarouselNewRedux from '../components/CarouselNewRedux';
import FeatureBox from '../components/FeatureBox';
import Footer from '../components/footer';
import NewItems from '../components/NewItems';
import Particle from '../components/Particle';
import SliderMainParticle from '../components/SliderMainParticle';
import SliderCarousel from '../components/HomepageSlider';
import CarouselCollection from '../components/CarouselCollection';
export const {
  websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .navbar .mainside a{
    background: #8364e2;
    &:hover{
      box-shadow: 2px 2px 20px 0px #8364e2;
    }
  }
  .item-dropdown{
    .dropdown{
      a{
        &:hover{
          background: #8364e2;
        }
      }
    }
  }
  .btn-main{
    background: #8364e2;
    &:hover{
      box-shadow: 2px 2px 20px 0px #8364e2;
    }
  }
  p.lead{
    color: #a2a2a2;
  }
  .navbar .navbar-item .lines{
    border-bottom: 2px solid #8364e2;
  }
  .jumbotron.no-bg{
    height: 100vh;
    overflow: hidden;
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  #tsparticles{
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: url('');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .text-uppercase.color{
    color: #8364e2;
  }
  .de_count h3 {
    font-size: 36px;
    margin-bottom: 0px;
  }
  .de_count h5{
    font-size: 14px;
    font-weight: 500;
  }
  h2 {
    font-size: 30px;
  }
  .box-url{
    text-align: center;
    h4{
      font-size: 16px;
    }
  }
  .de_countdown{
    border: solid 2px #8364e2;
  }
  .author_list_pp, .author_list_pp i, 
  .nft_coll_pp i, .feature-box.style-3 i, 
  footer.footer-light #form_subscribe #btn-subscribe i, 
  #scroll-to-top div{
    background: #8364e2;
  }
  footer.footer-light .subfooter .social-icons span i{
    background: #403f83;
  }
  .author_list_pp:hover img{
    box-shadow: 0px 0px 0px 2px #8364e2;
  }
  .nft__item_action span{
    color: #8364e2;
  }
  .feature-box.style-3 i.wm{
    color: rgba(131,100,226, .1);
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


const Homeone = () => {
  const { state, dispatch, update } = useContext(appStore);
  const [nftArtist, setNftArtist] = React.useState(0);
  const [nftcollectibles, setNftcollectibles] = React.useState(0);
  const [nftauctions, setNftauctions] = React.useState(0);

  //   console.log(state);
  const { app, views, app: { tab, snack }, near, wallet, contractAccount, account, loading } = state;

  var fetchTotalnftdata = async () => {
    try {
      const result = await axios.get(apiUrl + "/api/v1/basic_info");
      if (result.data["status"] === "success") {
        // const newList = concat(...authors, result.data['data']);

        setNftArtist(result.data['nft_artist'])
        setNftcollectibles(result.data['collectibles'])
        setNftauctions(result.data['auctions'])


      } else {
        // setItemsStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchTotalnftdata();

  }, []);

  return (
    <div>
      <GlobalStyles />
      {/* <section className="jumbotron no-bg setBg" style={{ backgroundImage: `url(${'./img/background/8.jpg'})` }}>
        <Particle />
        <SliderMainParticle {...{ nftArtist, nftcollectibles, nftauctions }} />

      </section> */}
      {/* <Particle /> */}
      <section className="jumbotron no-bg setBg sliderPadding">
        <SliderCarousel />
      </section>
      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-double"></div>
          <div className='col-lg-12 mb-2'>
            <h2>Popular Collections</h2>
          </div>
        </div>
        <CarouselCollection/>
      </section>

      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-double"></div>
          <div className='col-lg-12'>
            <h2>Top Collectors</h2>
          </div>
          <div className='col-lg-12 topSellerStyle'>
            <AuthorListRedux />
          </div>
        </div>
      </section>
      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-double"></div>
          <div className='col-lg-12'>
            <h2>Top Creators</h2>
          </div>
          <div className='col-lg-12 topSellerStyle'>
            <AuthorListReduxSeller />
          </div>
        </div>
      </section>

      <section className='container no-top no-bottom'>
        <div className='row'>
          <div className="spacer-double"></div>
          <div className='col-lg-12 mb-2'>
            <h2>New Items</h2>
          </div>
          <div className='col-lg-12'>
            <NewItems {...{ app, views, update, loading, contractAccount, account, dispatch }} />
          </div>
        </div>
      </section>

      <section className='container no-top'>
        <div className='row'>
          <div className="spacer-double"></div>
          <div className='col-lg-12 mb-3'>
            <h2>Create and Sell Now</h2>
          </div>
          <FeatureBox />
        </div>
      </section>

      <Footer />

    </div>
  )
};
export default Homeone;