import axios from 'axios';
import copy from 'copy-to-clipboard';
import React, { useContext, useEffect, useState, memo, useRef } from "react";
import { Helmet } from "react-helmet";
import { Spinner } from "reactstrap";
import { createGlobalStyle } from "styled-components";
import { appStore } from "../../state/app";
import ColumnNewRedux from "../components/ColumnNewRedux";
import ColumnNewReduxFavourite from "../components/ColumnNewReduxFavourite";
import ColumnNewReduxCreate from "../components/ColumnNewReduxCreate";
import CollectionComponent from "../components/CollectionComponent";
import Footer from "../components/footer";
import LoginModal from './../components/LoginModal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Getconfig from '../../config';
import { FaEdit } from "react-icons/fa";
import { navigate } from "@reach/router";
import ReactTooltip from 'react-tooltip';
import { Modal, Button as Btn } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './extra.scss';
export const {
	websiteUrl, apiUrl
} = Getconfig();


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
	// background: rgb(18,18,18);
  }
`;

const Collection = function () {
	const { state, dispatch, update } = useContext(appStore);
	const { app, views, app: { tab, snack }, near, wallet, contractAccount, account, loading } = state;
	const [modalShow, setModalShow] = useState(true);
	const [loader, setLoader] = useState(true);
	const [profileImg, setProfileImg] = useState(websiteUrl + '/img/author/author-11.jpg');
	const [profileData, setProfileData] = useState([]);
	const [userVerified, setUserVerified] = useState(false);
	const [editProfileBio, seteditProfileBio] = useState("Citizen of UniqArt");
	const [editProfileWebsite, seteditProfileWebsite] = useState("");
	const [editProfileInstagram, seteditProfileInstagram] = useState("");
	const [editProfileTwitter, seteditProfileTwitter] = useState("");
	const [showModal, setShow] = useState(false);
	const [showModal_new, setShow2_new] = useState(false);
	const handleClose_new = () => setShow2_new(false);
	const [image, setImage] = useState(null);
	const [cropper, setCropper] = useState(null);
	const [userAdd, setUserAdd] = useState(null);
	const [loginUser, setLoginUser] = useState(null);
	const [userId, setUserId] = useState(null);
	const [collectionBackground, setCollectionBackground] = useState('./img/background/4.jpg');
	const paramsCheck = "false";
	const cropperRef = useRef(null);
	useEffect(() => {
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			fetchData(user).then(() => setTimeout(() => {
				setLoader(false);
			}, 3000));
		} else {
			setTimeout(() => {
				setLoader(false);
			}, 3000);
		}
		setTimeout(() => {
			setLoader(false);
		}, 3000);
	}, [wallet]);

	useEffect(() => {
		if ((wallet && wallet.signedIn) && account) {
			setLoginUser(account.accountId)
		}
	}, [wallet, account])

	const handleClose = () => setShow(false);

	function imgClick() {
		setShow(false);
		navigate(`/profile`);
	}


	async function fetchData(user) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_profile_pic?address=" + user);
			if (result.data["status"] === "Success") {
				const newList = (result.data['results']);
				setProfileData(newList);
				seteditProfileWebsite(result.data["results"].website_url);
				seteditProfileInstagram(result.data["results"].instagram_username);
				seteditProfileTwitter(result.data["results"].twitter_username);
				if (result.data["results"].Bio) {
					seteditProfileBio(result.data["results"].Bio);
				}
				if (result.data["results"].address) {
					setUserAdd(result.data["results"].address);
				}
				if (result.data["results"].user_id) {
					setUserId(result.data["results"].user_id);
				}
				if (newList !== null) {
					setProfileData(newList);
					setProfileImg(newList.profile_pic);
					setUserVerified(newList.verified);
				} else {
					setProfileImg(websiteUrl + '/img/author/author-11.jpg');
					setUserVerified(false);
				}
				if (result.data['results'].user_cover_img) {
					setCollectionBackground(result.data['results'].user_cover_img);
				} else {
					setCollectionBackground('./img/background/4.jpg')
				}
			} else {
				setProfileImg(websiteUrl + '/img/author/author-11.jpg');
				setUserVerified(false);
			}
		} catch (error) {
			console.error(error);
		}
	}

	function EditProfile(
		editProfileBio,
		editProfileWebsite,
		editProfileInstagram,
		editProfileTwitter,
		user
	) {
		if (user) {
			const ProfileData = JSON.stringify({
				address: user,
				Bio: editProfileBio,
				website_url: editProfileWebsite,
				instagram_username: editProfileInstagram,
				twitter_username: editProfileTwitter,
			});
			fetch(apiUrl + "/api/v1/user_profile_pic", {
				method: "POST",
				headers: new Headers({ "Content-Type": "application/json" }),
				body: ProfileData,
			})
				.then((response) => response.text())
				.then((responseText) => {
					console.log(responseText);
					// setCollectionLogoCreate('');
					// setCollectionCreateName('');
					// setCollectionCreateDesc('');
					navigate(`/collection`);
					setShow(false);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			alert("* Please fill all required details.");
		}
	}
	/* ----------------------------- new code start ----------------------------- */
	const showNewModal = () => {
		setShow2_new(true);
	}
	async function UploadCroppedImage(raw) {
		fetch(`${apiUrl}/api/v1/upload_user_images`, {
			method: 'POST',
			body: raw
		}).then((response) => response.text())
			.then((responseText) => {
				console.log(responseText);
				setShow2_new(false);
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
			});
	}

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	const getCropData = () => {
		if (typeof cropper !== "undefined") {
			const imageElement = cropperRef?.current;
			const cropper = imageElement?.cropper;
			const CropeedImageUrl = cropper.getCroppedCanvas().toDataURL();
			const file = (dataURLtoFile(CropeedImageUrl, 'bgUser.jpg'))
			//code start
			var formdata = new FormData();
			formdata.append("user_id", userId);
			formdata.append("image", file, file.name);
			//code end
			if ((wallet && wallet.signedIn) && account.accountId) {
				UploadCroppedImage(formdata);
			}
		}
	};
	const onChange = (e) => {
		setImage(null);
		setShow2_new(true);
		// setSelectedFile();
		// setPreview(null);
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result);
		};
		reader.readAsDataURL(files[0]);
	};

	const resetCropData = () => {
		setImage(null);
		// setShow2_new(false);
		// setSelectedFile();
		// setPreview(null);
	}
	const BackData = () => {
		setImage(null);
		setShow2_new(false);
		// setSelectedFile();
		// setPreview(null);
	}
	return (
		<div>
			<GlobalStyles />
			<Helmet>
				<title>UniqART - Collection</title>
				<link rel="canonical" href={websiteUrl + "/collection"} />
				<meta property="og:title" content={`UniqART - Collection`} />
				<meta property="og:url" content={`${websiteUrl}/collection`} />
			</Helmet>
			{loader &&
				<div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
					<Spinner>
						Loading...
					</Spinner>
				</div>
			}
			{paramsCheck === "false" && !loader && (
				<section
					id="profile_banner"
					className="jumbotron breadcumb no-bg"
					style={{ backgroundImage: `url(${collectionBackground})`,marginTop: '5.5rem',
    backgroundPosition: 'center',backgroundRepeat:'no-repeat' }}
				>
					{(loginUser) === userAdd && <>
						<div className="collectionEditStyle collectionEditStyleCover d-flex" data-tip="Change profile cover" onClick={() => setShow2_new(true)}><FaEdit /></div>
						<div className="mainbreadcumb"></div>
					</>}
				</section>
			)}

			{paramsCheck === "false" && !loader && (
				<section className="container d_coll no-top no-bottom">
					<div className="row">
						<div className="col-md-12">
							<div className="d_profile">
								<div className="profile_avatar">
									<span className="collectionEditStyle" data-tip="Change profile avatar" onClick={() => navigate('/profile')}><FaEdit /></span>
									<ReactTooltip textColor='#000' backgroundColor='#fff' className='tooltipStyle' />
									<div className="d_profile_img imgSetting">
										<img src={profileImg ? profileImg : websiteUrl + '/img/author/author-11.jpg'} alt="" />
										{userVerified && <i className="fa fa-check"></i>}
									</div>

									<div className="profile_name">
										<h4>
											My Collection
											<div className="clearfix"></div>
											<span id="wallet" className="profile_wallet mx-2">{(wallet && wallet.signedIn) ? (account.accountId) : 'Loading...'}</span>
											<span className='changeCopyColor' style={{ position: 'absolute', display: 'inline-block', fontSize: '12px', margin: '0', cursor: 'pointer' }} onClick={() => copy((wallet && wallet.signedIn) ? (account.accountId) : '')} title="Copy Text"><i className="fa fa-copy m-0"></i></span>
										</h4>
									</div>
									<div className='w-100 d-flex justify-content-center'>
										<div className='ProfileInfo'>
											<h5 className="text-center" style={{whiteSpace: 'pre-wrap'}}>{(profileData.Bio && profileData.Bio !== '') ? profileData.Bio : 'Citizen of UniqArt'}
												<span className="collectionEditStyle" onClick={() => setShow(true)} style={{ position: 'relative', top: '-2px', right: '-3px', background: 'transparent' }} data-tip="Edit profile" ><FaEdit />
												</span>
											</h5>
											<div className="d-flex justify-content-center">
												{(profileData.website_url !== '' && profileData.website_url) && <i className="fa fa-link m-0 position-relative mx-2" style={{ background: '#383838' }} onClick={() => window.open('https://'+profileData.website_url, "_blank")}></i>}
												{(profileData.instagram_username !== '' && profileData.instagram_username) && <i className="fa fa-instagram m-0 position-relative mx-2" style={{ background: '#383838' }} onClick={() => window.open(`https://www.instagram.com/${profileData.instagram_username}`, "_blank")}></i>}
												{(profileData.twitter_username !== '' && profileData.twitter_username) && <i className="fa fa-twitter m-0 position-relative mx-2" style={{ background: '#383838' }} onClick={() => window.open(`https://twitter.com/${profileData.twitter_username}`, "_blank")}></i>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			<Tabs>
				{!loader && ((wallet && wallet.signedIn) ?
					<section className="container no-top">
						{paramsCheck === "false" && (
							<TabList style={{ borderBottom: 'none', margin: '0 0 1rem 0', padding: '0' }}>
								<div className="row">
									<div className="col-lg-12">
										<div className="items_filter de_nav">
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Creations</span></Tab>
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Collectibles</span></Tab>
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Favourites</span></Tab>
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Collections</span></Tab>
										</div>
									</div>
								</div>
							</TabList>
						)}
						<TabPanel>
							<div id="zero1" className="onStep fadeIn">
								<ColumnNewRedux
									shuffle
									showLoadMore={false}
									{...{
										app,
										views,
										update,
										loading,
										contractAccount,
										account,
										dispatch,
										profileImg,
										userVerified
									}}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div id="zero2" className="onStep fadeIn">
								<ColumnNewReduxCreate
									shuffle
									showLoadMore={false}
									{...{
										app,
										views,
										update,
										loading,
										contractAccount,
										account,
										dispatch,
										profileImg,
										userVerified
									}}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div id="zero4" className="onStep fadeIn">
								<ColumnNewReduxFavourite
									shuffle
									showLoadMore={false}
									{...{
										app,
										views,
										update,
										loading,
										contractAccount,
										account,
										dispatch,
										profileImg,
										userVerified
									}}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div id="zero3" className="onStep fadeIn">
								<CollectionComponent
									shuffle
									showLoadMore={false}
									{...{
										app,
										views,
										update,
										loading,
										contractAccount,
										account,
										dispatch,
										profileImg,
										userVerified
									}}
								/>
							</div>
						</TabPanel>
					</section>
					:
					<LoginModal onHide={() => setModalShow(false)} />)}
			</Tabs>
			<Footer />
			<Modal
				show={showModal}
				onHide={handleClose}
				className="mint-model w-100 setCollection"
				centered
				backdrop="static"
			>
				<Modal.Header style={{ backgroundColor: "#212428" }} closeButton>
					<h2 className="mb-0">Edit Profile</h2>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: "#212428" }}>
					<h4 className="mb-0">Avatar</h4>
					<div className="coverEditButton"><FaEdit style={{ color: "#fff" }} onClick={() => setShow2_new(true)} /></div>
					<div className="coverStyle"><img src={collectionBackground} alt="" /></div>
					<div
						className="mt-3"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<div style={{ position: "relative" }}>
							<img
								src={profileImg ? profileImg : websiteUrl + '/img/author/author-11.jpg'}
								alt=""
								style={{
									height: "120px",
									width: "120px",
									borderRadius: "50%",
									objectFit: "cover",
									cursor: "pointer",
								}}
							/>
							<div
								style={{
									top: 10,
									right: 1,
									position: "absolute",
									cursor: "pointer",
									background: "#8364e2",
									display: "flex",
									alignItems: "center",
									padding: "5px",
									borderRadius: "5px",
								}}
								onClick={() => imgClick()}
							>
								<FaEdit style={{ color: "#fff" }} />
							</div>
						</div>
					</div>
					<div className="spacer-20"></div>
					<h5>Bio</h5>
					<textarea
						data-autoresize
						name="item_desc"
						id="item_desc"
						className="form-control form-highlight"
						placeholder="e.g. 'This is very limited item'"
						value={editProfileBio && editProfileBio !== "" && editProfileBio}
						onChange={(e) => seteditProfileBio(e.target.value)}
					></textarea>
					<div className="spacer-10"></div>
					<h5>Website</h5>
					<div class="input-group mb-2">
						<div class="input-group-prepend">
							<div class="input-group-text" style={{padding:'0.5rem 0.75rem'}}>https://</div>
        				</div>
						<input
						type="text"
						name="item_title"
						id="item_title"
						className="form-control form-highlight"
						placeholder="e.g. 'your_website.com'"
						value={editProfileWebsite}
						onChange={(e) => seteditProfileWebsite(e.target.value)}
						/>
					</div>
					<div className="spacer-10"></div>
					<div className="row">
						<div className="col-md-12">
							<h5>Instagram</h5>
							<div class="input-group mb-2">
								<div class="input-group-prepend">
									<div class="input-group-text" style={{padding:'0.5rem 0.75rem'}}>https://www.instagram.com/</div>
								</div>
								<input
									type="text"
									name="item_title"
									id="item_title"
									className="form-control form-highlight col-md-6"
									placeholder="Username"
									value={editProfileInstagram}
									onChange={(e) => seteditProfileInstagram(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<h5>Twitter</h5>
							<div class="input-group mb-2">
								<div class="input-group-prepend">
									<div class="input-group-text" style={{padding:'0.5rem 0.75rem'}}>https://twitter.com/</div>
								</div>
								<input
									type="text"
									name="item_title"
									id="item_title"
									className="form-control form-highlight col-md-6"
									value={editProfileTwitter}
									placeholder="Username"
									onChange={(e) => seteditProfileTwitter(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="spacer-10"></div>
					<div className="d-flex justify-content-left">
						<Btn
							className="text-center modal-body-button mb-3"
							onClick={() => {
								EditProfile(
									editProfileBio,
									editProfileWebsite,
									editProfileInstagram,
									editProfileTwitter,
									account.accountId
								);
							}}
						>
							Save
						</Btn>
					</div>
					<div className="d-flex justify-content-left">
						<Btn
							className="text-center modal-body-button modal-body-cancel-button mb-3"
							onClick={() => setShow(false)}
						>
							Cancel
						</Btn>
					</div>
				</Modal.Body>
			</Modal>
			{/* ------------------------- cover popup code start ------------------------- */}
			<Modal
				show={showModal_new}
				onHide={handleClose_new}
				keyboard={false}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className='login-model'
				backdrop='static'
			>
				<Modal.Header style={{ backgroundColor: "#212428" }} closeButton>
					<h2 className="mb-0">Edit Profile Cover</h2>
				</Modal.Header>
				<Modal.Body>
					{
						!image ? (<div className="d-create-file mb-3">
							<p id="file_name">PNG, JPG. Max 100 KB.</p>
							<div className="browse">
								<input
									type="button"
									id="get_file"
									className="btn-main"
									value="Browse"
								/>
								<input
									id="upload_file"
									type="file"
									onChange={onChange}
									accept="image/png, image/gif, image/jpeg"
								/>
							</div>
						</div>) : (
							<>
								<span className='text-danger removeButtonStyle' onClick={resetCropData}>Remove</span>
								<div className="row p-3">
									<div className=" mb-3 col-12 d-flex justify-content-center">
										<div>
											<div style={{ width: "100%", height: "100%" }}>
												<Cropper
													style={{ height: 400, width: "100%" }}
													zoomTo={0.5}
													initialAspectRatio={16 / 3}
													preview=".img-preview"
													src={image}
													viewMode={1}
													minCropBoxHeight={10}
													minCropBoxWidth={10}
													background={false}
													responsive={true}
													autoCropArea={1}
													aspectRatio={16 / 3}
													ref={cropperRef}
													checkOrientation={false}
													onInitialized={(instance) => {
														setCropper(instance);
													}}
													guides={true}
												/>
											</div>
										</div>
									</div>
									<Btn className="text-center modal-body-button mb-3" onClick={getCropData}>Crop</Btn>

								</div>
							</>
						)
					}


					< Btn className="text-center modal-body-button modal-body-cancel-button mb-3" onClick={BackData}>Back</Btn>


				</Modal.Body>
			</Modal>
			{/* ------------------------- cover popup code end ------------------------- */}
		</div >
	);
};
export default memo(Collection);
