import { marketId, contractId } from '../state/near';
import JSBI from 'jsbi';

const STORAGE_ADD_MARKET_FEE = '8590000000000000000000';

export const getMarketStoragePaid = (account) => async ({ update, getState }) => {
	if (!account) return;
	const { contractAccount } = getState();
	//console.log(contractAccount);
	update('views', {
		marketStoragePaid: await contractAccount.viewFunction(marketId, 'storage_paid', { account_id: account.accountId })
	});
};

export const hasStorageBalance = (account) => async ({ update, getState }) => {
	if (!account) return;
	const { contractAccount } = getState();
	try {
		const currentStorage = await contractAccount.viewFunction(marketId, `storage_balance_of`, {
			account_id: account.accountId,
			from_index: '0',
		})

		const supplyPerOwner = await contractAccount.viewFunction(marketId, `get_supply_by_owner_id`, {
			account_id: account.accountId,
			from_index: '0',
		})

		const usedStorage = JSBI.multiply(
			JSBI.BigInt(parseInt(supplyPerOwner) + 1),
			JSBI.BigInt(STORAGE_ADD_MARKET_FEE)
		)

		if (JSBI.greaterThanOrEqual(JSBI.BigInt(currentStorage), usedStorage)) {
			update('views', { marketPaidStatus: true });
			return true
		}
		update('views', { marketPaidStatus: false });
		return false

		// update('views', { marketPaidStatus: true });
		// return true
	} catch (err) {
		console.warn(err);
	}
}

export const getMarketData = (account, token_id) => async ({ update, getState }) => {
	if (!account) return;
	const { contractAccount } = getState();
	//console.log(contractAccount);
	// 	let data = [];
	// 	if(await contractAccount.viewFunction(marketId, 'get_market_data', {nft_contract_id:contractId,token_id:token_id })){
	// 	update('views', {tokenSellData:await contractAccount.viewFunction(marketId, 'get_market_data', {nft_contract_id:contractId,token_id:token_id })});
	// }
};

export const getTokenSeriesInfo = (account, token_id) => async ({ update, getState }) => {
	const { contractAccount } = getState();
	const dataSupply = await contractAccount.viewFunction(contractId, 'nft_supply_for_type', { token_type_id: token_id }).catch(() => { });
	if (dataSupply > 0) {
		var count = 0;
		var data=[];
		while(dataSupply >= count){
			var array = await contractAccount.viewFunction(contractId, 'nft_tokens_by_type', {
				token_type_id: token_id,
				from_index: count.toString(),
				limit: 50
			})
			data.push(...array);
			count = count + 50;
		}
		update('views', {
			tokenSeriesData: data 
		});
	} else {
		update('views', { tokenSeriesData: [] });
	}
};

export const getCurrentTokenInfo = (account, token_id, isTokenType) => async ({ update, getState }) => {
	const { contractAccount } = getState();
	if (!token_id) return;
	if (isTokenType) {
		update('views', {
			tokenAllInfo: await contractAccount.viewFunction(contractId, 'nft_get_type', {
				token_type_id: token_id,
				from_index: '0',
				limit: 45
			})
		});
	} else {
		update('views', {
			tokenAllInfo: await contractAccount.viewFunction(contractId, 'nft_token', {
				token_id: token_id,
				from_index: '0',
				limit: 45
			})
		})
	}
};

export const isMintable = (account, token_id) => async ({ update, getState }) => {
	const { contractAccount } = getState();
	if (!token_id) return;
	update('views', {
		mintable: await contractAccount.viewFunction(contractId, 'nft_supply_for_type', {
			token_type_id: token_id,
			from_index: '0',
			limit: 45
		})
	});
};

export const loadItems = (account) => async ({ update, getState }) => {
	const { contractAccount } = getState();
	let tokens = [];
	let tokensCollect = [];
	let sales = [];
	let allTokens = [];

	if (account) {
		const { accountId } = account;
		tokens = await contractAccount.viewFunction(contractId, 'nft_get_type_by_owner', {
			account_id: accountId,
			from_index: '0',
			limit: 250
		});

		tokensCollect = await contractAccount.viewFunction(contractId, 'nft_tokens_for_owner', {
			account_id: accountId,
		});
	}

	const contract_owner = await contractAccount.viewFunction(contractId, 'get_owner');

	update('views', { tokens, tokensCollect, sales, allTokens, contract_owner });
	return { tokens, tokensCollect, sales, allTokens, contract_owner };
};
