/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo, useEffect, useState } from 'react';
import * as actions from '../../store/actions/thunks';
import NftCard from './NftCard';
import { token2symbol } from '../../state/near';
import { getMarketStoragePaid, loadItems } from '../../state/views';
import InfiniteScroll from 'react-infinite-scroll-component';

const ColumnNewReduxCreate = ({ showLoadMore = true, shuffle = false, app, views, update, loading, contractAccount, account, dispatch, profileImg, userVerified }) => {
	//test start
	if (!contractAccount) return null;
	const [height, setHeight] = useState(0);
	useEffect(() => {
		if (!loading) {
			dispatch(loadItems(account))
			dispatch(getMarketStoragePaid(account))
		}
	}, []);

	const { tokensCollect, marketStoragePaid } = views;
	let accountId = '';
	if (account) accountId = account.accountId;

	const onImgLoad = ({ target: img }) => {
		let currentHeight = height;
		if (currentHeight < img.offsetHeight) {
			setHeight(img.offsetHeight);
		}
	}

	const loadMore = () => {
		dispatch(actions.fetchNftsBreakdown());
	}

	// console.log('TokensData',tokens);
	if (tokensCollect === undefined || tokensCollect.length === 0) return (<h3 style={{ textAlign: 'center', height: '30vh', marginTop: '3rem' }}>Please Mint or Buy an NFT's to view your Collectibles items.</h3>);
	return (
		<div>
			<div className='row'>
				<InfiniteScroll style={{ overflow: "hidden" }}
					dataLength={tokensCollect.length} //This is important field to render the next data
					next={loadMore}
					loader={<h4 className="text-center">Loading...</h4>}
				>
					<div className='row'>
						{
							tokensCollect.map(({
								metadata: { media, title, description, extra, copies, reference },
								owner_id,
								token_id: token_type_id,
								verified,
								sale_conditions = {},
								bids = {},
								royalty = {},
								is_auction,
								extension,
								compressed_url,
								min_sell_price,
								fav_count
							}) => (
								<NftCard media={media} title={title} description={description} owner_id={owner_id} nft={media} token_id={token_type_id} sale_conditions={sale_conditions} bids={bids} royalty={royalty} marketStoragePaid={marketStoragePaid} token2symbol={token2symbol} accountId={accountId} account={account} key={token_type_id} path={'/token/' + token_type_id} extra={extra} onImgLoad={onImgLoad} height={height} copies={copies} reference={reference} is_auction={is_auction} profileImg={profileImg} verified={userVerified} is_unlockable={false} extension={extension} compressed_url={compressed_url} min_sell_price={min_sell_price}/>
							))
						}
					</div>
				</InfiniteScroll>
				{showLoadMore && tokensCollect.length <= 20 &&
					<div className='col-lg-12'>
						<div className="spacer-single"></div>
						<span className="btn-main lead m-auto">Please Wait ...</span>
					</div>
				}
			</div>
		</div>
	);
};

export default memo(ColumnNewReduxCreate);