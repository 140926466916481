import React, { useState, useEffect } from 'react'

const Check = () => {
	const [selectedFile, setSelectedFile] = useState()
	const [preview, setPreview] = useState()

	// create a preview as a side effect, whenever selected file is changed
	useEffect(() => {
		if (!selectedFile) {
			setPreview(undefined)
			return
		}
		const objectUrl = URL.createObjectURL(selectedFile)
		setPreview(objectUrl)
		return () => URL.revokeObjectURL(objectUrl)
	}, [selectedFile])

	const onSelectFile = e => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined)
			return
		}
		setSelectedFile(e.target.files[0])
	}

	return (
		<div style={{height:"90vh",position:"absolute",display:"flex",justifyContent:"center",alignItems:"center"}}>
			<input type='file' onChange={onSelectFile} />
			{selectedFile && <img src={preview} />}
		</div>
	)
}

export default Check
