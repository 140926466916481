import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Getconfig from '../../config';
import Footer from '../components/footer';
import { navigate } from "@reach/router";
import Cookies from 'js-cookie';
import axios from 'axios';

var ip = require('ip');
export const { websiteUrl, apiUrl, nearRamp_url, contractId } = Getconfig();
const jwt = require('jsonwebtoken');

const privateKey = process.env.REACT_APP_RSA_SECRET_KEY; // RSA Private Key
const developerId = process.env.REACT_APP_DEVELOPER_ID;



function generateJWT(privateKey) {
	const exp = Math.floor(Date.now() / 1000) + (60 * 60); // token expiry window
	const token = jwt.sign({ exp }, privateKey, { algorithm: 'RS256' });
	return token;
}

const CreateAccountNearamp = () => {
	const [newAccount, oldAccount] = useState(true);
	const [redirectUrlUser, setRedirectUrl] = useState(null);
	const [myIp, setMyIp] = useState(ip.address());
	const [isEnabled, setIsEnabled] = useState(true);

	useEffect(() => {
		var url_string = window.location.href;
		var url = new URL(url_string);
		let redirect = url.searchParams.get('redirect');
		// console.log(redirect, 'redirect');
		if (redirect) {
			setRedirectUrl(redirect)
		} else {
			setRedirectUrl('/market')
		}

		checkIp()

	}, []);

	const checkIp = async () =>{
		const result = await axios.get(apiUrl + "/api/v1/stat_user_ip");
		if (result.data["status"] === "failure" && result.data["msg"] === "Not Registered!") {
			// ip has to claim an account yet
			console.log('Ip has yet to claim account')
			setIsEnabled(true)
		} else {
			//  ip has claimed an account already
			console.log('Ip has claimed account already')
			setIsEnabled(false)
		}
	}

	const onSuccess = async (accountId, pubKey) => {
		console.log('Account id', accountId, pubKey);
		const result = await axios.get(apiUrl + "/api/v1/add_user_ip");
		if (result.data["status"] === "success") {
			// setIsEnabled(true)
			console.log('account created')
		} else {
			// setIsEnabled(false)
			console.log('account creation api error');
		}
	}

	const setData = () => {
		console.log(redirectUrlUser, 'redirectUrlUser');
		if (newAccount && isEnabled) {
			window.NR('init', {
				developerID: developerId, // Required: Onboarded developer id
				grantToken: generateJWT(privateKey.toString()), // Required: Token signed by developer private key
				targetElement: 'widget-box',       // Required: ID of Parent DOM element for sdk instance
				// loginConfig: {                      // Optional: Pass login config authenticate the newly created NEAR account into the app
				// 	contractId: contractId,
				// 	methods: [
				// 		"new",
				// 		"nft_mint",
				// 		"nft_transfer",
				// 		"add_guest",
				// 		"remove_guest",
				// 		"nft_approve_account_id",
				// 		"nft_mint_guest",
				// 		"nft_add_sale_guest",
				// 		"nft_remove_sale_guest",
				// 		"upgrade_guest",
				// 		"get_guest",
				// 		"get_token_ids",
				// 		"nft_token",
				// 		"get_sale",
				// 		"nft_create_type",
				// 		"nft_mint_type",
				// 		"nft_transfer",
				// 		"nft_set_price",
				// 		"nft_approve",
				// 		"buy",
				// 		"nft_mint_single_and_approve",
				// 		"delete_offer",
				// 		"nft_mint_single",
				// 		"nft_buy"
				// 	],
				// 	redirectUrl: `${websiteUrl}/success/${myIp}?redirect=${redirectUrlUser}`
				// },
				darkMode: true,
				onSuccess: onSuccess
			});
		} else {
			// navigate('/market')
		}
	}

	useEffect(() => {
		setMyIp(ip.address());
		if (redirectUrlUser) {
			setData();
		}
		if (Cookies.get('User_IP')) {
			oldAccount(false);
			navigate('/market')
		} else {
			oldAccount(true);
		}

	}, [redirectUrlUser]);

	if (newAccount && isEnabled){
		return (
			(
				<div>
					<Helmet>
						<title>UniqART - Create Account</title>
						<link rel="canonical" href={websiteUrl + "/create-account-new"} />
						<meta property="og:title" content={`UniqART - Create Account`} />
						<meta property="og:url" content={`${websiteUrl}/create-account-new`} />
						<script>
							{`			
						${(function (w, d, s, o, f, js, fjs) {
									w['NEARamp'] = o;
									w[o] = w[o] || function () {
										(w[o].q = w[o].q || []).push(arguments)
									};
									// eslint-disable-next-line no-unused-expressions
									js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
									js.id = o;
									js.src = f;
									js.async = 1;
									fjs.parentNode.insertBefore(js, fjs);
								}(window, document, 'script', 'NR', nearRamp_url))};	
					`}
						</script>
					</Helmet>
					<section className='jumbotron breadcumb no-bg' >
						<div className='mainbreadcumb'>
							<div className='container'>
								<div className='row m-10-hor'>
									<div className='col-12'>
										<h1 className='text-center'>Create Account</h1>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className='container'>
						<div id="widget-box"></div>
					</section>
					<Footer />
				</div>
			)
		)
	}else {
		return (
			(
				<div>
					<Helmet>
						<title>UniqART - Create Account</title>
						<link rel="canonical" href={websiteUrl + "/create-account-new"} />
						<meta property="og:title" content={`UniqART - Create Account`} />
						<meta property="og:url" content={`${websiteUrl}/create-account-new`} />
						
					</Helmet>
					<section className='jumbotron breadcumb no-bg' >
						<div className='mainbreadcumb'>
							<div className='container'>
								<div className='row m-10-hor'>
									<div className='col-12'>
										<h1 className='text-center'>Create Account</h1>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className='container'>
						
						<div className='col-12'>
							<p className='text-center'>You are getting this notification because either an account has already been created by this user or there has been some issue with the system. Please go to market and start exploring different NFTS to trade in.</p>
							<div className="mainside d-flex justify-content-center align-items-center">
								<a
									className="btn-main"
									onClick={() => navigate('/market')}
									style={{ cursor: "Pointer" }}
								>
									Go to Market
								</a>
							</div>

						</div>
					</section>
					<Footer />
				</div>
			)
		)
	}
	
}

export default CreateAccountNearamp