/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { memo, useEffect, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {
    BiCategory,
    BiCollection,
    BiDollar,
    BiSliderAlt
} from "react-icons/bi";
import { BsArrowDownUp, BsLightningCharge, BsXLg } from "react-icons/bs";
import { FcApproval, FcCheckmark, FcMoneyTransfer, FcNumericalSorting12, FcNumericalSorting21, FcPlus, FcPrivacy, FcTodoList, FcBinoculars } from "react-icons/fc";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
    setDefaultBreakpoints
} from "react-socks";
import styled from "styled-components";
import Illustion from "../../assets/images/filterImages/001-drawing.png";
import Video from "../../assets/images/filterImages/002-video.png";
import Music from "../../assets/images/filterImages/003-music.png";
import Art from "../../assets/images/filterImages/005-paint-palette.png";
import Album from "../../assets/images/filterImages/009-photo-album.png";
import Photography from "../../assets/images/filterImages/001-camera.png";
import Meme from "../../assets/images/filterImages/002-laughing.png";
import ThreeD from "../../assets/images/filterImages/004-cubes.png";
import Games from "../../assets/images/filterImages/003-joystick.png";
import Metaverse from "../../assets/images/filterImages/006-vr-camera.png";
import Avatar from "../../assets/images/filterImages/005-man.png";
import PoetryImage from "../../assets/images/filterImages/001-poem.png";
import Getconfig from '../../config';
import "./filterStyle.scss";


export const {
    websiteUrl, apiUrl
} = Getconfig();

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const FilterGroup = styled.div`
  padding: 0 10px;
  display: flex;
`;

const FilterButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-flow: row nowrap;
  white-space: nowrap;
  color: #fff;
  background-color: rgba(4, 4, 5, 0.08);
  line-height: 43px;
  height: 43px;
  padding-left: 22px;
  padding-right: 22px;
  min-width: auto;
  border: 1px solid transparent;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 900;
  font-family: inherit;
  transition: all 0.12s ease-in-out 0s;
  transform-origin: center center;
  user-select: none;
  cursor: pointer;
  margin-right: 1rem;
  border-color: #ffffff1a;
//   background: transparent;
background: rgb(18,18,18);
  &:hover {
    border-color: #fff;
  }
`;
const FilterButton2 = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-flow: row nowrap;
  white-space: nowrap;
  color: #fff;
  background-color: rgba(4, 4, 5, 0.08);
  line-height: 43px;
  height: 43px;
  width: 43px;
  padding-left: 22px;
  padding-right: 22px;
  min-width: auto;
  border: 1px solid transparent;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 900;
  font-family: inherit;
  transition: all 0.12s ease-in-out 0s;
  transform-origin: center center;
  user-select: none;
  cursor: pointer;
  margin-right: 1rem;
  border-color: #ffffff1a;
//   background: transparent;
	background: rgb(18,18,18);
  &:hover {
    border-color: #fff;
  }
`;

const ButtonTopText = styled.span`
  z-index: 5;
  position: absolute;
  top: -5px;
  max-width: 80%;
  left: 14px;
  background-color: #212529;
  display: inline-block;
  padding: 0px 5px;
  font-size: 11px;
  color: #adb5bd;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-family: inherit;
  font-weight: 700;
  vertical-align: inherit;
`;
const ButtonContainer = styled.div`
  padding-bottom: 0px;
  padding-top: 12px;
  margin-top: 8px;
  border-top: 1px solid rgba(4, 4, 5, 0.1);
`;

const SliderPart = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
`;

const NftStyle = styled.input`
  padding: 4px 15px;
  border: 1px solid grey;
  border-radius: 10px;
  font-size: 15px;
  /* color: #fff; */
  background: rgba(255, 255, 255, 0.1);
  width: 70%;
  height: 2.5rem;
  outline: none;
  transition: 0.7s;
  &:hover {
    transition: 0.7s;
    box-shadow: 0px 0px 0px 1px rgb(131 100 226 / 80%);
  }
  @media (max-width: 1400px) and (min-width:1200px) {
    width:50%;
  }
  @media (max-width: 1200px) and (min-width:991px) {
    width:40%;
  }
`;

const CollectionImg = styled.img`
	border-radius : 50%;
	object-fit:cover;
`;

const ImageContainer = styled.span`
display:flex;
justify-content:center;
align-items:center;
`;

const SliderFilterNew = (props) => {
    const gridRef = useRef();
    const [openMenu1, setOpenMenu1] = useState(false);
    const [openMenu2, setOpenMenu2] = useState(false);
    const [openMenu3, setOpenMenu3] = useState(false);
    const [openMenu4, setOpenMenu4] = useState(false);
    const [openMenu5, setOpenMenu5] = useState(false);
    const [searchFilter, setSearchFilter] = useState([]);
    const [collectionAllResult, setCollectionAllResult] = useState([]);
    const [loadingImg, setLoading] = useState(true);
    const [selectCategory, setSelectCategory] = useState('All');
    const [selectCollection, setSelectCollection] = useState('All');
    const [selectSaleType, setSelectSaleType] = useState('All');
    const [selectFilter, setSelectFilter] = useState('All');
    const [showCat, setShowCat] = useState('Category');
    const [showCollection, setShowCollection] = useState('Collections');
    const [showSaleType, setShowSaleType] = useState('On Sale');
    const [showFilter, setShowFilter] = useState('Recently added');
    const [showPopularFilter, setShowPopularFilter] = useState('1');
    const [data, setData] = useState(`collections=${props.collectionId}&category=All&sell_type=All&price=All&verified=All&sort=All`);
    const [showReset, setShowReset] = useState(false);
    const [SetCount, setSelectCount] = useState(null);
    const [SetCount2, setSelectCount2] = useState(null);
    const [SetCount3, setSelectCount3] = useState(null);
    const [SetCount4, setSelectCount4] = useState('Filter-Recently');
    const [isVerified, setIsVerified] = useState('All');
    const [priceBox, setPriceBox] = useState('All');
    const [startPrice, SetStartPrice] = useState(0);
    const [endPrice, SetEndPrice] = useState(0);
    const [priceShow, setShowPrice] = useState('Price Range');
    const handleBtnClick1 = () => {
        setOpenMenu1(!openMenu1);
    };
    const closeMenu1 = () => {
        setOpenMenu1(false);
    };
    const ref1 = useOnclickOutside(() => {
        closeMenu1();
    });
    const handleBtnClick2 = () => {
        setOpenMenu2(!openMenu2);
    };
    const closeMenu2 = () => {
        setOpenMenu2(false);
    };
    const ref2 = useOnclickOutside(() => {
        closeMenu2();
    });
    const handleBtnClick3 = () => {
        setOpenMenu3(!openMenu3);
    };
    const closeMenu3 = () => {
        setOpenMenu3(false);
    };
    const ref3 = useOnclickOutside(() => {
        closeMenu3();
    });
    const handleBtnClick4 = () => {
        setOpenMenu4(!openMenu4);
    };
    const closeMenu4 = () => {
        setOpenMenu4(false);
    };
    const ref4 = useOnclickOutside(() => {
        closeMenu4();
    });
    const handleBtnClick5 = () => {
        setOpenMenu5(!openMenu5);
    };
    const closeMenu5 = () => {
        setOpenMenu5(false);
    };
    const ref5 = useOnclickOutside(() => {
        closeMenu5();
    });
    /* ------------------------------- api work start --------------------------- */

    const CategoryFunction = (imgValue, value, selectCount) => {
        setSelectCategory(value);
        setShowCat(imgValue);
        setShowPopularFilter('0');
        setSelectCount4('Filter-Recently');
        setShowFilter('Recently added');
        setSelectFilter('recently');
        setSelectCount(selectCount);
        closeMenu1();
    }


    const CollectionFunction = (ShowValue, value, selectCount) => {
        setShowCollection(ShowValue);
        setSelectCollection(value);
        setShowPopularFilter('0');
        setSelectCount4('Filter-Recently');
        setShowFilter('Recently added');
        setSelectFilter('recently');
        setSelectCount2(selectCount);
        closeMenu2();
    }

    const SaleTypeFunction = (ShowValue, value, selectCount) => {
        setShowSaleType(ShowValue);
        setSelectSaleType(value);
        setShowPopularFilter('0');
        setSelectCount3(selectCount);
        setSelectCount4('Filter-Recently');
        setShowFilter('Recently added');
        setSelectFilter('recently');
        closeMenu3();
    }

    const SortFilterFunction = (ShowValue, value, selectCount) => {
        setShowFilter(ShowValue);
        setSelectFilter(value);
        setShowPopularFilter('0');
        setSelectCount4(selectCount);
        closeMenu5();
    }

    const SortPopularFilterFunction = (ShowValue, value, selectCount) => {
        setShowFilter(ShowValue);
        setSelectFilter('All');
        setSelectCategory('All');
        setSelectCollection('All');
        setSelectSaleType('All');
        setShowCat('Category');
        setShowCollection('Collections');
        setShowSaleType('On Sale');
        setShowPopularFilter(value);
        setSelectCount4(selectCount);
        closeMenu5();
    }


    const selectUnlockableContent = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setIsVerified('true');
        } else {
            setIsVerified('All');
        }
    };
    const clearPriceBox = () => {
        setPriceBox('All');
        SetStartPrice(0);
        SetEndPrice(0);
        setShowPopularFilter('0');
        document.getElementById('start_price').value = '';
        document.getElementById('end_price').value = '';
        setShowPrice('Price Range');
    }

    const showPriceFunction = (startPrice, endPrice) => {
        setPriceBox(`${startPrice},${endPrice}`);
        setShowPrice(`Price: ${startPrice} Ⓝ to ${endPrice} Ⓝ`);
        setSelectCount4('Filter-Recently');
        setShowFilter('Recently added');
        setSelectFilter('recently');
        setShowPopularFilter('0');
    }

    const ResetFilterFunction = () => {

        setData(`collections=${props.collectionId}&category=All&sell_type=All&price=All&verified=All&sort=All`);
        setSelectCategory('All');
        setSelectCollection('All');
        setSelectSaleType('All');
        setSelectFilter('All');
        setShowPopularFilter('1');
        setShowCat('Category');
        setShowCollection('Collections');
        setShowSaleType('On Sale');
        setShowFilter('Recently added');
        setSelectCount(null);
        setSelectCount2(null);
        setSelectCount3(null);
        setSelectCount4('Filter-Recently');
        setPriceBox('All');
        SetStartPrice(0);
        SetEndPrice(0);
        setIsVerified('All');
        fetchAllCollectionData();
        setShowPrice('Price Range');
    }

    async function fetchAllCollectionData() {
        try {
            const result = await axios.get(apiUrl + "/api/v1/fetch_random_collections");
            if (result.data["status"] === "Success") {
                const newList = (result.data['results']);
                if (newList !== null) {
                    setCollectionAllResult(newList);
                } else {
                    setCollectionAllResult([])
                }
            } else {
                setCollectionAllResult([])
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchFilterCollectionData(filterValue) {
        try {
            const result = await axios.get(apiUrl + `/api/v1/search_collections?search=${filterValue}`);
            if (result.data["status"] === "Success") {
                const newList = (result.data['results']);
                if (newList !== null) {
                    setCollectionAllResult(newList);
                } else {
                    setCollectionAllResult([])
                }
            } else {
                setCollectionAllResult([])
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {

        fetchAllCollectionData();
        if (data === `collections=${props.collectionId}&category=All&sell_type=All&price=All&verified=All&sort=All`) {
            setShowReset(false);
        } else {
            setShowReset(true);
        }
    }, []);

    useEffect(() => {
        fetchFilterCollectionData(searchFilter)
    }, [searchFilter]);

    useEffect(() => {
        setData(`collections=${props.collectionId}&category=${selectCategory}&sell_type=${selectSaleType}&price=${priceBox}&verified=${isVerified}&sort=${selectFilter}`);
        console.log('data is set here', `collections=${props.collectionId}&category=${selectCategory}&sell_type=${selectSaleType}&price=${priceBox}&verified=${isVerified}&sort=${selectFilter}`)
    }, [props.collectionId, selectCategory, selectSaleType, priceBox, isVerified, selectFilter]);

    useEffect(() => {
        console.log('props.defaultCategory', props.defaultCategory)
        if (props.defaultCategory === 'art') {
            CategoryFunction('Art', 'Art', 'Category-2')
        } else if (props.defaultCategory === 'music') {
            CategoryFunction('Music', 'Music', 'Category-3')
        } else if (props.defaultCategory === 'metaverse') {
            CategoryFunction('Metaverse', 'Metaverse', 'Category-13')
        } else if (props.defaultCategory === 'games') {
            CategoryFunction('Games', 'Games', 'Category-10')
        }
    }, []);

    useEffect(() => {
        console.log('data', data)

        props.handleFilters(data);
        console.log('prop handler', data)
        if (data === `collections=${props.collectionId}&category=All&sell_type=All&price=All&verified=All&sort=All`) {
            setShowReset(false);
        } else {
            setShowReset(true);
        }
    }, [data]);

    /* ------------------------------ api work end ------------------------------ */
    return (
        <>
            <FilterGroup className="row d-flex justify-content-center">
                <div className="col-9 d-flex justify-content-start py-1">
                    <SliderPart className="py-1">
                        <div className="menu d-flex justify-content-start align-items-center">
                            {/* <div className="navbar-item">
                                <div ref={ref1}>
                                    <div
                                        style={{ marginRight: "1rem" }}
                                        className='position-relative'
                                    >
                                        <FilterButton
                                            id="myButton"
                                            onClick={handleBtnClick1}
                                            style={{ marginRight: "0" }}
                                            className={openMenu1 ? 'filterButtonActive text-center shadow' : 'position-relative text-center shadow'}
                                        >
                                            <BiCategory
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "1rem",
                                                    marginRight: "0.25rem",
                                                }}
                                            />
                                            {showCat}
                                        </FilterButton>
                                    </div>
                                    {openMenu1 && (
                                        <div className="item-dropdown ItemDropdownStyle">
                                            <div
                                                className="dropdown"
                                                style={{
                                                    background: "#fff",
                                                    height: "17rem",
                                                    overflowY: "scroll",
                                                }}
                                            >
                                                <a onClick={() => CategoryFunction('All', 'All', 'Category-1')} ref={gridRef}>
                                                    <span>
                                                        <FcTodoList className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>All</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-1' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Art', 'Art', 'Category-2')} ref={gridRef}>
                                                    <span>
                                                        <img src={Art} alt="art" className="ImagePadding" />
                                                        <span>Art</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-2' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Music', 'Music', 'Category-3')} ref={gridRef}>
                                                    <span>
                                                        <img src={Music} alt="music" className="ImagePadding" />
                                                        <span>Music</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-3' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Short Reels', 'Short Reels', 'Category-4')} ref={gridRef}>
                                                    <span>
                                                        <img src={Video} alt="short" className="ImagePadding" />
                                                        <span>Short Reels</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-4' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Illustration', 'Illustration', 'Category-6')} ref={gridRef}>
                                                    <span>
                                                        <img
                                                            src={Illustion}
                                                            alt="illustration"
                                                            className="ImagePadding"
                                                        />
                                                        <span>Illustration</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-6' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Album Art', 'Album Art', 'Category-7')} ref={gridRef}>
                                                    <span>
                                                        <img src={Album} alt="album-art" className="ImagePadding" />
                                                        <span>Album Art</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-7' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Photography', 'Photography', 'Category-8')} ref={gridRef}>
                                                    <span>
                                                        <img src={Photography} alt="Photography" className="ImagePadding" />
                                                        <span>Photography</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-8' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Memes', 'Memes', 'Category-9')} ref={gridRef}>
                                                    <span>
                                                        <img src={Meme} alt="Memes" className="ImagePadding" />
                                                        <span>Memes</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-9' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Games', 'Games', 'Category-10')} ref={gridRef}>
                                                    <span>
                                                        <img src={Games} alt="Games" className="ImagePadding" />
                                                        <span>Games</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-10' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('3D', '3D', 'Category-11')} ref={gridRef}>
                                                    <span>
                                                        <img src={ThreeD} alt="3D" className="ImagePadding" />
                                                        <span>3D</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-11' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Avatars', 'Avatars', 'Category-12')} ref={gridRef}>
                                                    <span>
                                                        <img src={Avatar} alt="Avatars" className="ImagePadding" />
                                                        <span>Avatars</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-12' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Metaverse', 'Metaverse', 'Category-13')} ref={gridRef}>
                                                    <span>
                                                        <img src={Metaverse} alt="Metaverse" className="ImagePadding" />
                                                        <span>Metaverse</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-13' && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => CategoryFunction('Poetry', 'Poetry', 'Category-14')} ref={gridRef}>
                                                    <span>
                                                        <img src={PoetryImage} alt="Poetry" className="ImagePadding" />
                                                        <span>Poetry</span>
                                                    </span>
                                                    {
                                                        SetCount === 'Category-14' && <FcCheckmark />
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div className="navbar-item">
                                <div ref={ref3}>
                                    <div
                                        style={{ marginRight: "1rem" }}
                                        className="position-relative"
                                    >
                                        <ButtonTopText>Sale type</ButtonTopText>
                                        <FilterButton
                                            id="myButton2"
                                            onClick={handleBtnClick3}
                                            style={{ marginRight: "0" }}
                                            className={openMenu3 ? 'filterButtonActive text-center shadow' : 'position-relative text-center shadow'}
                                        >
                                            <BsLightningCharge
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "1rem",
                                                    marginRight: "0.25rem",
                                                }}
                                            />
                                            {showSaleType}
                                        </FilterButton>
                                    </div>
                                    {openMenu3 && (
                                        <div className="item-dropdown ItemDropdownStyle">
                                            <div className="dropdown" style={{ background: "#fff" }}>
                                                <a onClick={() => SaleTypeFunction('All', 'All', 'SaleType-All')}>
                                                    <span>
                                                        <FcTodoList className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>All</span>
                                                    </span>
                                                    {
                                                        SetCount3 === `SaleType-All` && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => SaleTypeFunction('Buy Now', 'false', 'SaleType-Buy')}>
                                                    <span>
                                                        <FcMoneyTransfer className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>Buy Now</span>
                                                    </span>
                                                    {
                                                        SetCount3 === `SaleType-Buy` && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => SaleTypeFunction('Unlockable', 'true', 'SaleType-Unlockable')}>
                                                    <span>
                                                        <FcPrivacy className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>Unlockable</span>
                                                    </span>
                                                    {
                                                        SetCount3 === `SaleType-Unlockable` && <FcCheckmark />
                                                    }
                                                </a>                                                
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="navbar-item">
                                <div ref={ref4}>
                                    <div className="position-relative">
                                        <FilterButton
                                            id="myButton2"
                                            onClick={handleBtnClick4}
                                            style={{ marginRight: "0" }}
                                            className={openMenu4 ? 'filterButtonActive text-center shadow' : 'position-relative text-center shadow'}
                                        >
                                            <BiDollar
                                                style={{
                                                    color: "#fff",
                                                    fontSize: "1rem",
                                                    marginRight: "0.25rem",
                                                }}
                                            />
                                            {priceShow}
                                        </FilterButton>
                                    </div>
                                    {openMenu4 && (
                                        <div className="item-dropdown ItemDropdownStyle">
                                            <div className="dropdown" style={{ background: "#fff" }}>
                                                <h6 style={{ color: '#495057' }}>Price in Ⓝ</h6>
                                                <div className="d-lg-flex d-sm-inline justify-content-between p-0">
                                                    <NftStyle type="number" id="start_price" placeholder="From" className="m-2" onChange={(e) => SetStartPrice(e.target.value)} />
                                                    <NftStyle type="number" id="end_price" placeholder="To" className="m-2" onChange={(e) => SetEndPrice(e.target.value)} />
                                                </div>
                                                <ButtonContainer className="px-0">
                                                    <div className="mainside d-flex justify-content-between">
                                                        <div
                                                            className="btn-main mx-1 w-100"
                                                            style={{ cursor: "pointer", background: "#adb5bd" }}
                                                            onClick={() => clearPriceBox()}
                                                        >
                                                            Clear
                                                        </div>
                                                        <div
                                                            className={(startPrice > 0 && endPrice > 0) ? "btn-main mx-1 w-100 activeClickButton" : "btn-main mx-1 w-100 deactiveClickButton"}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => showPriceFunction(startPrice, endPrice)}
                                                        >
                                                            Apply
                                                        </div>
                                                    </div>
                                                </ButtonContainer>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </SliderPart>
                    {
                        showReset && (
                            <div className="d-flex justify-content-center align-items-center" style={{ marginLeft: '1rem', cursor: 'pointer' }}>
                                <span className="mx-auto d-none d-lg-block" onClick={() => ResetFilterFunction()} style={{ color: '#8364e2' }}>Reset</span>
                                <span className="mx-auto d-flex d-lg-none" onClick={() => ResetFilterFunction()} style={{ color: '#8364e2', padding: '0.5rem' }}><BsXLg /></span>
                            </div>
                        )
                    }
                </div>
                <div className="col-3 d-flex justify-content-end py-1">
                    <div className="menu d-flex justify-content-center align-items-center">
                        <div className="navbar-item d-flex">
                            <div ref={ref5}>
                                <FilterButton2 className={openMenu5 ? 'd-flex d-lg-none align-items-center justify-content-center filterButtonActive shadow' : 'd-flex d-lg-none align-items-center justify-content-center shadow'} id="myButton2" onClick={handleBtnClick5} style={{ marginRight: '0' }}>
                                    <BiSliderAlt style={{ color: "#fff", fontSize: '1rem', position: 'absolute' }} />
                                </FilterButton2>
                                <div className="d-none d-lg-block position-relative">
                                    <ButtonTopText>Filter & Sort</ButtonTopText>
                                    <FilterButton
                                        id="myButton2"
                                        onClick={handleBtnClick5}
                                        style={{ marginRight: "0" }}
                                        className={openMenu5 ? 'filterButtonActive text-center shadow' : 'position-relative text-center shadow'}
                                    >
                                        <BsArrowDownUp
                                            style={{
                                                color: "#fff",
                                                fontSize: "1.2rem",
                                                marginRight: "0.25rem",
                                            }}
                                        />
                                        {showFilter}
                                    </FilterButton>
                                </div>
                                {openMenu5 && (
                                    <div className="item-dropdown ItemDropdownStyle">
                                        <div className="dropdown" style={{ background: "#fff" }}>
                                            <span onClick={closeMenu5}>
                                                <span style={{ display: "flex", padding: "8px 16px" }}>Sort by</span>
                                                <a onClick={() => SortFilterFunction('Recently added', 'recently', 'Filter-Recently')}>
                                                    <span>
                                                        <FcPlus className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>Recently added</span>
                                                    </span>
                                                    {
                                                        SetCount4 === `Filter-Recently` && <FcCheckmark />
                                                    }
                                                </a>                                                
                                                <a onClick={() => SortFilterFunction('Price: Low to High', 'LTH', 'Filter-PriceLow')}>
                                                    <span>
                                                        <FcNumericalSorting12 className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>Price: Low to High</span>
                                                    </span>
                                                    {
                                                        SetCount4 === `Filter-PriceLow` && <FcCheckmark />
                                                    }
                                                </a>
                                                <a onClick={() => SortFilterFunction('Price: High to Low', 'HTL', 'Filter-PriceHigh')}>
                                                    <span>
                                                        <FcNumericalSorting21 className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>Price: High to Low</span>
                                                    </span>
                                                    {
                                                        SetCount4 === `Filter-PriceHigh` && <FcCheckmark />
                                                    }
                                                </a>
                                            </span>
                                            {/* <span>
                                                <span style={{ display: "flex", padding: "8px 16px" }}>Options</span>
                                                <a className="stopHoverMenu">
                                                    <span>
                                                        <FcApproval className="ImageMargin" style={{ height: '25px', width: '25px' }} />
                                                        <span>Verified only</span>
                                                    </span>
                                                    <div className="form-check form-switch p-0">
                                                        <input className="form-check-input" checked={isVerified !== 'All' && 'checked'} style={{ fontSize: '1.3rem', cursor: 'pointer' }} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e) => {
                                                            selectUnlockableContent(e);
                                                        }} />
                                                    </div>
                                                </a>
                                            </span> */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </FilterGroup>
        </>
    );
};

export default memo(SliderFilterNew);
