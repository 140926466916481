import { navigate } from "@reach/router";
import axios from "axios";
import { create } from "ipfs-http-client";
import { CID } from "multiformats/cid";
import * as nearAPI from "near-api-js";
import React, { useContext, useEffect, useState } from "react";
import { Button as Btn, Modal, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactPlayer from "react-player";
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import styled, { createGlobalStyle } from "styled-components";
import { handleRegisterStorage, handleSaleUpdate, removeSale, TransferToken, otherUserSale, otherUserBuy, addOffer, acceptOffer, SelfMint, deleteOffer } from "../../state/actions";
import { appStore, snackAttack } from "../../state/app";
import { getTokenOptions, handleOffer, parseNearAmount } from "../../state/near";
import { share } from "../../utils/mobile";
import { getTokenSeriesInfo, hasStorageBalance, getCurrentTokenInfo, isMintable } from '../../state/views';
import LoginModalNew from "../components/LoginModalNew";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET_KEY;
const auth ='Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

function toPlainString(num) {
	return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
		function (a, b, c, d, e) {
			return e < 0
				? b + '0.' + Array(1 - e - c.length).join(0) + c + d
				: b + c + d + Array(e - d.length + 1).join(0);
		});
}

const NftStyle = styled.input`
  padding: 4px 15px;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  width: 60%;
  height: 3.2rem;
  outline: none;
  transition: 0.7s;
  &:hover {
    transition: 0.7s;
    box-shadow: 0px 0px 0px 4px rgb(255 52 53 / 30%);
  }
  @media (max-width: 1400px) and (min-width:1200px) {
    width:50%;
  }
  @media (max-width: 1200px) and (min-width:991px) {
    width:40%;
  }
`;

const PositionFixedRemoval = styled.div`
	max-width: 750px;
  @media (min-width:1000px) {
    position: fixed;
  }
  @media (max-width: 999px) {
    
  }
`;

const HeightRemoval = styled.div`
  
  @media (min-width:1000px) {
    height: 70vh;
  }
  @media (max-width: 999px) {
    
  }
`;

const ButtonStyle = styled.button`
  position: absolute;
  font-size: 12px;
  padding: 5px 10px;
  line-height: 1em;
  border: solid 1px #dddddd;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: none;
  margin-left: 1rem;
  &:hover {
    color: #fff;
    background: #8364e2;
    border: 0;
  }
`;

const FaIconStyle = styled.div`
	cursor: pointer;
	&:hover{		
		color:#fff;
		background: #8364e2 !important;
		box-shadow: 2px 2px 20px 0px #8364e2;
    	transition: all 0.3s ease;
		i{
			color:#fff !important;
		}
	}
`;

const Refresh = styled.div`
height: 2.5rem;
width: 2.5rem;
border-radius: 50%;
float:right;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
margin-right : 5%;
margin-bottom: 1rem;
&:hover{
		border: 1px solid #8364e2;
		color:#fff;
		background: #8364e2;
		box-shadow: 2px 2px 20px 0px #8364e2;
    	transition: all 0.3s ease;
}
i{
	font-size: 1rem;
}
`;

const TypeStyle = styled.div`
  	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: auto;
	gap: 10px;
`;
const TypeButtonStyle = styled.button`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
	border:1px solid #a2a2a2;
    height: 80px;
    width: 100%;
    padding: 0px 5px;	
    border-radius: 16px;
    transition: all 0.12s ease-in-out 0s;
    cursor: pointer;
    color: inherit;
    overflow: visible;
	background: transparent;
	&:hover{
		border: 1px solid #8364e2;
		color:#fff;
		background: #8364e2;
		box-shadow: 2px 2px 20px 0px #8364e2;
    	transition: all 0.3s ease;
	}
	
`;
const {
	utils: {
		format: { formatNearAmount },
	},
} = nearAPI;

const ItemDetailMarket = function ({ dispatch, account, token, token: { metadata, owner_id, conditions, bids, is_auction = false }, }) {
	if (typeof token.conditions === "undefined") {
		token.conditions = {};
	}

	let token_type_id = null;
	let token_id = null;

	let urlParams2 = new URLSearchParams(window.location.search);
	let tokenNft2 = urlParams2.get('t');
	var NotMainToken2 = null;
	if (tokenNft2) {
		NotMainToken2 = (tokenNft2.indexOf(':') > -1);
		if (NotMainToken2 === true) {
			token_id = tokenNft2;
		} else if (NotMainToken2 === false) {
			token_type_id = tokenNft2;
		}
	}

	useEffect(() => {
		if (NotMainToken2 === true && token_id !== null) {
			dispatch(getCurrentTokenInfo(account, token_id, false));
		} else if (NotMainToken2 === false && token_type_id !== null) {
			dispatch(getCurrentTokenInfo(account, token_type_id, true));
		}
	}, [NotMainToken2, tokenNft2]);

	let edition = null;
	let copy = null;
	let tokenMintable = true;

	if (token_type_id) {
		edition = token_type_id;
	} else if (token_id) {
		const edition_and_copy = (decodeURIComponent(token_id)).split(":");
		edition = edition_and_copy[0].split(" ").join("");
		copy = edition_and_copy[1].split(" ").join("");
	}

	const { state } = useContext(appStore);
	const { views, wallet } = state;
	// console.log('views',views);
	const { marketStoragePaid, tokenAllInfo, tokenSeriesData, marketPaidStatus, mintable, tokens, contract_owner: nft_contract_id } = views;


	if (tokenAllInfo && token_type_id != null) {
		const isTokenMintable = (tokenAllInfo.metadata.copies) - parseInt(mintable);
		if (isTokenMintable === 0) {
			tokenMintable = false;
		} else {
			tokenMintable = true;
		}
	}
	useEffect(() => {
		if (token_id) {
			dispatch(getTokenSeriesInfo(account, (token_id.split(":"))[0].split(" ").join("")));
		} else if (token_type_id) {
			dispatch(getTokenSeriesInfo(account, token_type_id));
			dispatch(hasStorageBalance(account));
			dispatch(isMintable(account, token_type_id));
		}
	}, [account, token_type_id]);

	console.log(marketPaidStatus, 'marketPaidStatus');

	const [offerPrice, setOfferPrice] = useState("");
	const [offerText, setOfferText] = useState("");
	const [offerToken, setOfferToken] = useState("near");
	const [price, setPrice] = useState("");
	const [receiver, setReceiver] = useState("");
	const [receiver2, setReceiver2] = useState("");
	const [ft, setFT] = useState("near");
	const [openMenu, setOpenMenu] = useState(true);
	const [openMenu1, setOpenMenu1] = useState(false);
	const [saleConditions, setSaleConditions] = useState({});
	const [directSale, setDirectSale] = useState(1);
	const [auctionCondition, setAuctionCondition] = useState(false);
	const [shareUrl, setShareUrl] = useState('');
	const [profileImg, setProfileImg] = useState(websiteUrl + '/img/author/author-11.jpg');
	const [clickCount, setClickCount] = useState(0);
	const [royaltiesAmount, setRoyaltiesAmount] = useState(null);
	const [ShowCurrentOffer, setShowCurrentOffer] = useState([]);
	const [royaltiesReciever, setRoyaltiesReciever] = useState({});
	const [loadingImg, setLoading] = useState(true);
	const [dotClick, setDotClick] = useState(false);
	const [shareTo, setShareTo] = useState(false);
	const [transferToken, setTransferToken] = useState(false);
	const [mintSelfToken, setMintSelfToken] = useState(false);
	const [removeToken, setRemoveToken] = useState(false);
	const [removeInfo, setInfo] = useState(false);
	const [copyText, setCopyText] = useState('Copy Link');
	const [bidderImage, setBidderImage] = useState(websiteUrl + '/img/author/author-11.jpg');
	const [copyClass, setCopyClass] = useState('fa fa-copy mx-2');
	const [copyClass1, setCopyClass1] = useState('fa fa-copy mx-2');
	const [collectionData, setCollectionData] = useState([]);
	const [collectionName, setCollectionName] = useState('');
	const [collectionDescription, setCollectionDescription] = useState('');
	const [collectionImage, setCollectionImage] = useState('');
	const [collectionLogoCreate, setCollectionLogoCreate] = useState('');
	const [collecectionCreateName, setCollectionCreateName] = useState('');
	const [collectionCreateDesc, setCollectionCreateDesc] = useState('');
	const [selectCollectionId, setSelectSelectionId] = useState('');
	const [useCollectionName, setUseCollectionName] = useState('');
	const [useCollectionId, setUseCollectionId] = useState('');
	const [useCollectionDesc, setUseCollectionDesc] = useState('');
	const [useCollectionImage, setUseCollectionImage] = useState('');
	const [uploadLogoCollection, setUploadLogoCollection] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [showModalCreateCollection, setShowCreateCollection] = useState(false);
	const [showModalCollection, setShowCollection] = useState(false);
	const [error, setError] = useState(false);
	const [sendData, setSendData] = useState([]);
	const [BuyPrice, setBuyPrice] = useState(null);
	const [checked, setChecked] = useState(true);
	const [dataVisiable, setDataVisiable] = useState(true);
	const [seriesList, setSeriesList] = useState([]);
	const [modalShow, setModalShow] = useState(false);

	let offerType = null;
	const signedIn = wallet && wallet.signedIn;
	let imageType = true;
	// console.log('views', views);
	function isFileImage(file) {
		const acceptedImageTypes = ["gif", "jpeg", "png", "webp", "jpg", "avif", "apng", "svg+xml", "bmp", "x-icon", "tiff"];
		return file && acceptedImageTypes.includes(file);
	}

	let FileExtension = null;
	let FileUrl = null;

	const new_copy = (copy ? copy : '0');
	//current copy end

	const handleBtnClick = () => {
		setOpenMenu(!openMenu);
		setOpenMenu1(false);
		document.getElementById("Mainbtn").classList.add("active");
		document.getElementById("Mainbtn1").classList.remove("active");
	};
	const handleBtnClick1 = () => {
		setOpenMenu1(!openMenu1);
		setOpenMenu(false);
		document.getElementById("Mainbtn1").classList.add("active");
		document.getElementById("Mainbtn").classList.remove("active");
	};

	async function handleShareFacebook() {
		const ShareData = window.location.origin + `/token/${encodeURIComponent(tokenNft2)}`;
		setShareUrl(ShareData);
	}
	async function fetchData(owner_id) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_profile_pic?address=" + owner_id);
			if (result.data["status"] === "Success") {
				const newList = (result.data['results'].profile_pic);
				if (newList !== null) {
					setProfileImg(newList)
				} else {
					setProfileImg(websiteUrl + '/img/author/author-11.jpg')
				}

			} else {
				setProfileImg(websiteUrl + '/img/author/author-11.jpg')
			}
		} catch (error) {
			console.error(error);
		}
	}

	async function fetchOffer(token_id) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_offer?token_id=" + token_id);
			if (result.data["status"] === "success") {
				const newList = (result.data['result']);
				if (newList !== null) {
					setShowCurrentOffer(newList);
					const findOfferToken = newList.filter(({ buyer_id }) => accountId === buyer_id);
					if (findOfferToken.length > 0) {
						setOfferPrice(findOfferToken[0].price);
						setOfferText(findOfferToken[0].price);
					}
				} else {
					setShowCurrentOffer([]);
				}
			} else {
				setShowCurrentOffer([]);
			}
		} catch (error) {
			console.error(error);
		}
	}

	async function fetchClick(tokenNft2, new_copy) {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/get_nft_token?nft_token_id=${tokenNft2}&sell_id=${tokenNft2}&current_copy=${new_copy}`);
			if (result.data["status"] === "success") {
				setSendData(result.data['data'][0]);
				const fetchPrice = result.data['data'][0].min_sell_price;
				if (fetchPrice > 0) {
					setBuyPrice(result.data['data'][0].min_sell_price);
				} else {
					setBuyPrice(null);
				}
				// console.log('Buy_price', BuyPrice)
				const count = (result.data['data'][0].clicks);
				const royalty = (result.data['data'][0].royalty);
				const royalty_address = (result.data['data'][0].royalty_address);
				const collection_id = (result.data['data'][0].collection_id);
				const collection_name = (result.data['data'][0].collection_name);
				const collection_desc = (result.data['data'][0].collection_desc);
				const collection_logo = (result.data['data'][0].collection_logo);
				if (count !== null) {
					setClickCount(count)
				}
				if (royalty_address) {
					setRoyaltiesAmount(royalty);
					setRoyaltiesReciever(royalty_address);
				}
				if (collection_id) {
					setUseCollectionId(collection_id);
				}
				if (collection_name) {
					setUseCollectionName(collection_name);
				}
				if (collection_desc) {
					setUseCollectionDesc(collection_desc);
				}
				if (collection_desc) {
					setUseCollectionImage(collection_logo);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}

	async function Clicks_count(tokenNft2, new_copy) {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/add_clicks?nft_token_id=${tokenNft2}&sell_id=${tokenNft2}&current_copy=${new_copy}`);
			// console.log(result)
			if (result.data["status"] === "success") {
				console.log('click count successfully');

			} else {
				console.log('click not count');
			}
		} catch (error) {
			console.error(error);
		}
	}

	async function fetchBidderData(bidder_id) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_profile_pic?address=" + bidder_id);
			if (result.data["status"] === "Success") {
				const newList = (result.data['results'].profile_pic);
				if (newList !== null) {
					setBidderImage(newList)
				} else {
					setBidderImage(websiteUrl + '/img/author/author-11.jpg')
				}

			} else {
				setBidderImage(websiteUrl + '/img/author/author-11.jpg')
			}
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		// fetchData(owner_id);
		handleShareFacebook();
		Clicks_count(tokenNft2, new_copy);

		fetchClick(tokenNft2, new_copy);

		if (is_auction) {
			setDirectSale(2);
			setAuctionCondition(true);
		}
		return () => (document.body.style.overflow = "scroll");

	}, [tokenNft2]);


	useEffect(() => {
		fetchOffer(tokenNft2);
	}, [tokenNft2]);

	useEffect(() => {
		if (bids) {
			Object.entries(bids).map(([ft_token_id, ft_token_bids]) => {
				fetchBidderData(ft_token_bids.owner_id);
			});
		}
		setSeriesList(tokenSeriesData);

	});

	useEffect(() => {
		if ((owner_id)) {
			fetchData(owner_id);
			fetchCollectionData(owner_id);
		}
	}, [owner_id]);

	const setRefreshData = () => {
		window.location.reload();
	}

	const handleShare = async (e) => {
		e.stopPropagation();
		e.preventDefault();
		const ShareData = window.location.origin + `/token/${encodeURIComponent(tokenNft2)}`;
		const result = share(ShareData);
		if (!result.mobile) {
			dispatch(snackAttack("Link Copied!"));
		}
		setCopyText('Copied');
		setTimeout(setText, 3000);
	};
	function setText() {
		setCopyText('Copy Link');
	}
	function copyMediaData(mediaData) {
		share((mediaData) ? (mediaData) : '');
		setCopyClass('fa fa-check mx-2');
		setTimeout(setCopyClassFunction, 3000);
	}
	function copyContractData(contractData) {
		share((contractData) ? (contractData) : '');
		setCopyClass1('fa fa-check mx-2');
		setTimeout(setCopyClassFunction1, 3000);
	}
	function setCopyClassFunction() {
		setCopyClass('fa fa-copy mx-2');
	}
	function setCopyClassFunction1() {
		setCopyClass1('fa fa-copy mx-2');
	}
	const setBidType = index => {
		setDirectSale(index);
		if (index === 1) {
			setAuctionCondition(false);
		} else if (index === 2) {
			setAuctionCondition(true);
		}
	};



	async function uploadImageIPFSLogo(files) {
		try {
			const added = await client.add(files);
			const v0 = CID.parse(added.path);
			const encodePath = v0.toV1().toString();
			const url = `https://ipfs.io/ipfs/${encodePath}`;
			// console.log(url);
			setCollectionLogoCreate(url)
		} catch (error) {
			console.log("Error uploading file: ", error);
			setError(true);
		}
	}
	async function fetchCollectionData(owner_id) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_collections?user_id=" + owner_id);
			if (result.data["status"] === "success") {
				const newList = (result.data['result']);
				if (newList !== null) {
					setCollectionData(newList);
				}
			}
		} catch (error) {
			console.error(error);
		}
		// console.log(collectionData);
	}

	function CreateCollection(collectionLogoCreate, collecectionCreateName, collectionCreateDesc, user) {
		if (collectionLogoCreate && collecectionCreateName && collectionCreateDesc && user) {
			const CollectionData = JSON.stringify(
				{
					"name": collecectionCreateName,
					"logo": collectionLogoCreate,
					"description": collectionCreateDesc,
					"user_id": user
				}
			);
			fetch(apiUrl + "/api/v1/create_collection", {
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json', }),
				body: CollectionData
			}).then((response) => response.text())
				.then((responseText) => {
					console.log(responseText);
					fetchCollectionData(user);
					setUploadLogoCollection(false);
					setCollectionLogoCreate('');
					setCollectionCreateName('');
					setCollectionCreateDesc('');
					handleCloseCreateCollection();
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			alert('* Please fill all required details.');
		}
	}
	useEffect(() => {
		if (token.price) {
			setBuyPrice(formatNearAmount(toPlainString(token.price)));
		}

	}, [tokenNft2]);

	const { accountId } = account || {};
	let tokenId;
	let urlParams = new URLSearchParams(window.location.search);
	let tokenNft = urlParams.get('t');
	tokenId = tokenNft;

	if (token_type_id) {
		offerType = 'tokenType';
		const findToken = tokenAllInfo;
		metadata = findToken.metadata;
	} else if (token_id) {
		offerType = 'token';
		const MyToken = tokenAllInfo
		metadata = MyToken.metadata;
	}
	let collection_name = null;
	let collection_id = null;
	let collection_logo = null;

	if (metadata.extra) {
		let fetchRes = fetch(metadata.extra);
		fetchRes.then(res => res.json()).then(d => {
			FileExtension = d.FileExtension;
			FileUrl = d.FileUrl;
			collection_id = d.collection_id;
			collection_name = d.collection_name;
			collection_logo = d.collection_logo;
		})
	}
	if (FileExtension) {
		imageType = isFileImage(FileExtension.toLowerCase());
	} else {
		imageType = true;
		FileExtension = "jpg";
	}

	let previous_token = '';
	if (metadata.title) {
		const new_value = (metadata.title).split("—");
		previous_token = new_value[0].split(" ").join("");
	}

	function setCheckboxData() {
		if (checked) {
			setChecked(false);
		} else {
			setChecked(true)
		}
	}
	useEffect(() => {
		if (checked) {
			setDataVisiable(false);
			if (signedIn) {
				setReceiver2(account.accountId);
			} else {
				setReceiver2('');
			}
		} else {
			setDataVisiable(true);
			setReceiver2('');
		}
	}, [checked, account, signedIn])

	const handleClose2 = () => {
		setDotClick(false);
	};
	const handleShareTo = () => {
		setShareTo(false);
	};
	const handleTransferToken = () => {
		setTransferToken(false);
	};
	const handleMintSelfToken = () => {
		setMintSelfToken(false);
	};
	const handleRemoveToken = () => {
		setRemoveToken(false);
	};
	const handleInfo = () => {
		setInfo(false);
	};

	const handleCloseCreateCollection = () => setShowCreateCollection(false);
	const handleCloseCollection = () => setShowCollection(false);
	function uploadLogo(event) {
		uploadImageIPFSLogo(event.target.files[0]);
		setUploadLogoCollection(URL.createObjectURL(event.target.files[0]));
	}
	const handleShowCreateCollection = () => {
		setShowCreateCollection(true);
	};
	const handleBtnClick2 = (index, name, description, image, collectionId) => {
		setActiveIndex(index);
		setCollectionName(name);
		setCollectionDescription(description);
		setCollectionImage(image);
		setSelectSelectionId(collectionId);
	};
	const handleBtnClick3 = (selectCollectionId, collectionName, collectionDescription, collectionImage, previous_token, tokenId, copy) => {
		if (selectCollectionId && collectionName && collectionDescription && collectionImage && previous_token && tokenId && copy) {
			const raw = JSON.stringify(
				{
					"nft_token_id": previous_token,
					"sell_id": tokenId,
					"current_copy": copy,
					"collection_id": selectCollectionId,
					"collection_name": collectionName,
					"collection_desc": collectionDescription,
					"collection_logo": collectionImage
				}
			);
			fetch(apiUrl + "/api/v1/update_nft_create", {
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json', }),
				body: raw
			}).then((response) => response.text())
				.then((responseText) => {
					console.log(responseText);
					fetchClick(tokenId, new_copy);
					handleCloseCollection();
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			alert('* Choose any one or create new collection before click confirm button')
		}
	};

	return (
		<div>
			<Helmet>
				<title>UniqART -{metadata.reference} {copy ? `#${copy}` : ''}</title>
				<link rel="canonical" href={`${websiteUrl}/token/${tokenNft2}`} />
				<meta property="og:title" content={`UniqART - ${metadata.reference} ${copy ? `#${copy}` : ''}`} />
				<meta property="og:url" content={`${websiteUrl}/token/${tokenNft2}`} />
			</Helmet>
			<GlobalStyles />
			<section className="container p-0">
				<div className="row pt-md-4">
					<PositionFixedRemoval className="col-md-6 text-center">
						{!imageType ? (
							<ReactPlayer
								// Disable download button
								config={{ file: { attributes: { controlsList: 'nodownload' } } }}
								// Disable right click
								onContextMenu={e => e.preventDefault()}
								url={metadata.media}
								light={FileUrl}
								className="d-flex justify-content-center mt-3"
								id="get_file_2"
								width="100%"
								height="80%"
								playing
								controls={true}
								style={{ width: "95%" }}
							/>
						) : (
							<>
								<div style={{ display: loadingImg ? "block" : "none", zIndex: "0" }}>
									<SkeletonTheme baseColor="#202020" highlightColor="#444" display='flex'>
										<Skeleton height={450} />
									</SkeletonTheme>
								</div>
								<HeightRemoval style={{ display: loadingImg ? "none" : "flex", alignItems: 'center' }}>
									<img
										src={metadata.media}
										style={{
											maxWidth: '100%',
											maxHeight: '100%',
											display: 'block', borderRadius: '10px', border: '1px solid #f9f9f9'
										}}
										className="img-fluid img-rounded mb-sm-30"
										alt=""
										onLoad={() => setLoading(false)}
									/>
								</HeightRemoval>
							</>
						)}
					</PositionFixedRemoval>
					<div className="col-md-5 offset-md-7">
						<div className="item_info p-0">
							<div className="d-flex justify-content-center mb-2">
								<p style={{ width: "80%", marginBottom: '0', fontWeight: 'bold' }}>{token_type_id ? (`SERIES  #${edition}`) : (`SERIES  #${(token_id.split(":"))[0].split(" ").join("")} // NFT #${copy} of ${metadata.copies}`)}</p>
								<span className="d-flex justify-content-center" style={{ width: "20%", transform: 'rotate(90deg)' }} onClick={() => setDotClick(true)}><svg style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"><path d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
								</span>
							</div>
							<h2 className="text-capitalize">{metadata.reference} {copy ? `#${copy}` : ''}</h2>
							<div className="item_info_counts flex-wrap justify-content-around justify-content-lg-start">
								<FaIconStyle className="item_info_type my-2">
									<i className="fa fa-file"></i>
									{FileExtension}
								</FaIconStyle>
								<FaIconStyle className="item_info_views my-2">
									<i className="fa fa-eye"></i>{clickCount}
								</FaIconStyle>
								<FaIconStyle className="item_info_like my-2" onClick={(e) => handleShare(e)}>
									<i className="fa fa-copy"></i>
								</FaIconStyle>
								<FaIconStyle className="item_info_like my-2" onClick={() => setInfo(true)}>
									<i className="fa fa-info-circle"></i>
								</FaIconStyle>
							</div>
							<p>{metadata.description}</p>
							<h6>Owner</h6>
							<div className="item_author">
								<div className="author_list_pp">
									<span onClick={() => navigate(`user/${token.owner_id}`)}>
										<img
											className="lazy"
											src={profileImg}
											alt=""
										/>
										<i className="fa fa-check"></i>
									</span>
								</div>
								<div className="author_list_info">
									<span onClick={() => navigate(`user/${token.owner_id}`)}>{token.owner_id}</span>
								</div>
							</div>
							<div className="spacer-40"></div>
							{
								<>
									{(royaltiesReciever) && Object.keys(royaltiesReciever).length > 0 && (
										<>
											<h4>Royalties</h4>
											{
												Object.entries(royaltiesReciever).map(([receiver, amount]) => (
													<>

														<p key={receiver + '&' + Math.floor(100000 + Math.random() * 900000)}>
															{receiver} - {amount / 100}%
														</p>
													</>
												))
											}
										</>
									)}
									{/* test start */}
									<h4>Copies</h4>
									<p>{metadata.copies}</p>
									{/* Extra code Add start */}
									<h4>Collection</h4>
									<p className='text-capitalize' ><span className='collectionStyle' onClick={() => navigate(`userCollectionType?collectionId=${useCollectionId}`)} style={{ cursor: 'pointer' }}>{useCollectionName ? useCollectionName : 'New Collection'}</span>{
										accountId === owner_id && (
											<span className='mx-3' style={{ cursor: "pointer", color: '#8364e2', fontWeight: 'bold' }} onClick={() => { setShowCollection(true) }}>
												Change
											</span>)
									}</p>
									<div className='my-3'>
										<h4>Token Info</h4>
										{nft_contract_id &&
											(<div className='d-flex mt-2' style={{ textAlign: 'center', alignItems: 'center' }}>
												<p className='text-capitalize p-0 m-0' style={{ color: '#e9ecef', width: '30%', textAlign: 'left' }}>Smart Contract</p>
												<p className='p-0 my-0 text-truncate' style={{ fontWeight: 'bold', maxWidth: '185px' }}>{nft_contract_id}</p>
												<span className='copyBtnStyleSingleItem'><i className={copyClass1} onClick={() => { copyContractData(nft_contract_id) }}></i></span>
											</div>)
										}
										<div className='d-flex mb-2' style={{ textAlign: 'center', alignItems: 'center' }}>
											<p className='text-capitalize p-0 m-0' style={{ color: '#e9ecef', width: '30%', textAlign: 'left' }}>File Link</p>
											<p className='p-0 my-0 text-truncate' style={{ fontWeight: 'bold', maxWidth: '185px' }}>{metadata.media}</p>
											<span className='copyBtnStyleSingleItem'><i className={copyClass} onClick={() => { copyMediaData(metadata.media) }}></i></span>
										</div>
									</div>
									{/* extra code Add End */}
									{(BuyPrice) > 0 && (
										<>
											<h4>{is_auction ? 'Fixed Price' : 'Fixed Price'}</h4>
											{
												<p className="margin-bottom" style={{ fontSize: '1.3rem', fontWeight: 'bold' }} key={'near&' + Math.floor(100000 + Math.random() * 900000)}>
													{price === "0" ? "0" : BuyPrice} {'Ⓝ'}
												</p>

											}
										</>
									)}
									{accountId === owner_id && (
										marketStoragePaid !== '0' ?
											<>
												{
													token_type_id ? (
														<>
															<div className="spacer-10"></div>
															<div className="d-flex justify-content-left setMobileUI">
																<NftStyle
																	type="number"
																	min="0"
																	placeholder="Price"
																	value={price}
																	onChange={(e) => setPrice(e.target.value)}
																/>
																{getTokenOptions(ft, setFT)}
																<div
																	className="btn-main mx-2"
																	style={{ padding: "6px 40px", lineHeight: "2.5rem", width: '40%' }}
																	onClick={() => {
																		if (!price.length) {
																			return alert("Enter a price");
																		}
																		const newSaleConditions = {
																			...saleConditions,
																			[ft]: parseNearAmount(price),
																		};
																		setSaleConditions(newSaleConditions);
																		setPrice("");
																		setFT("near");
																		handleSaleUpdate(account, tokenNft2, newSaleConditions, auctionCondition, BuyPrice, previous_token, copy);
																	}}
																>
																	<span>{(BuyPrice > 0) ? 'Update price' : 'Sell on marketplace'}</span>
																</div>
															</div>
														</>
													) : (
														<>
															<div className="spacer-10"></div>
															<div className="d-flex justify-content-left setMobileUI">
																<NftStyle
																	type="number"
																	min="0"
																	placeholder="Price"
																	value={price}
																	onChange={(e) => setPrice(e.target.value)}
																	style={{ width: '60%' }}
																/>
																{getTokenOptions(ft, setFT)}
																<div
																	className="btn-main mx-2"
																	style={{ padding: "6px 40px", lineHeight: "2.5rem", width: '40%' }}
																	onClick={() => {
																		if (!price.length) {
																			return alert("Enter a price");
																		}
																		const newSaleConditions = {
																			...saleConditions,
																			[ft]: parseNearAmount(price),
																		};
																		setSaleConditions(newSaleConditions);
																		setPrice("");
																		setFT("near");
																		otherUserSale(account, tokenNft2, newSaleConditions, auctionCondition, token.conditions, previous_token, copy);
																	}}
																>
																	<span>{(BuyPrice > 0) ? 'Update price' : 'Sell on marketplace'}</span>
																</div>
															</div>
														</>
													)
												}
												<div className="mb-3 mt-2">
													<i style={{ fontSize: "0.75rem" }}>
														Note: price 0 means open offers.
													</i>
												</div>
												<div className="spacer-10"></div>
											</>
											:
											<>
												<div className="spacer-40"></div>
												<div className="d-flex justify-content-left mb-3">
													<div className="btn-main mx-2" style={{
														padding: "6px 40px",
														lineHeight: "2.5rem",
														width: '100%'
													}}
														onClick={() => handleRegisterStorage(account)}
													>
														<span>Register with Market to Sell</span>
													</div>
												</div>
												<div className="spacer-40"></div>
											</>
									)}
									{
										signedIn ?
											BuyPrice > 0 && (
												<>
													{BuyPrice > 0 && (
														<>
															{accountId.length > 0 && accountId !== owner_id && (
																<>
																	{
																		tokenMintable && (
																			<>
																				<h4>Buy Type</h4>
																				<TypeStyle className={(Object.keys(token.conditions).length > 0) ? "my-4 bid-disabled" : "my-4"}>
																					<TypeButtonStyle type="button" onClick={() => setBidType(1)} className={directSale === 1 ? "bidActive" : "unactive"} {...is_auction ? 'disabled' : ''}>
																						<span aria-hidden="true" className="icon_tag_alt mb-2" style={{ fontSize: "1.5rem" }} />
																						<div>
																							<span>Fixed price</span>
																						</div>
																					</TypeButtonStyle>
																					<TypeButtonStyle type="button" onClick={() => setBidType(2)} className={directSale === 2 ? "bidActive" : "unactive"} {...is_auction ? '' : 'disabled'}>
																						<span aria-hidden="true" className="icon-pencil mb-2" style={{ fontSize: "1.5rem" }} />
																						<div>
																							<span>Give an Offer</span>
																						</div>
																					</TypeButtonStyle>
																				</TypeStyle>
																			</>
																		)}
																	{
																		tokenMintable && (
																			auctionCondition ? <>
																				<div className="spacer-20"></div>
																				<div className="d-flex justify-content-left mb-3 setMobileUI">
																					<NftStyle
																						type="number"
																						min="0"
																						placeholder="Price"
																						value={offerPrice}
																						onChange={(e) => setOfferPrice(e.target.value)}
																					/>
																					{getTokenOptions(
																						offerToken,
																						setOfferToken,
																						Object.keys(token.conditions)
																					)}
																					<div
																						className="btn-main mx-2"
																						style={{
																							padding: "6px 40px",
																							lineHeight: "2.5rem",
																							width: '30%'
																						}}
																						onClick={() =>
																							addOffer(account, tokenNft2, offerToken, offerPrice, previous_token, copy, owner_id, (`${metadata.reference} #${copy}`), is_auction, marketPaidStatus, (offerText !== "") ? 'update_offer' : 'new_offer', offerType)
																						}
																					>
																						<span>{(offerText !== "") ? 'Update Offer' : 'Offer'}</span>
																					</div>
																				</div>
																				<div className="mb-1">
																					<i style={{ fontSize: "0.75rem" }}>
																						Note: It will take couple of minuts to reflect your NFT's data.
																					</i>
																				</div>
																				<div className="spacer-20"></div>
																			</> :
																				token_type_id ? (
																					<>
																						<div className="spacer-20"></div>
																						<div className="d-flex justify-content-left mb-3">
																							{getTokenOptions(
																								offerToken,
																								setOfferToken,
																								Object.keys(token.conditions)
																							)}
																							<div
																								className="btn-main mx-2"
																								style={{
																									padding: "6px 40px",
																									lineHeight: "2.5rem",
																									width: '100%'
																								}}
																								onClick={() =>
																									handleOffer(account, tokenNft2, offerToken, BuyPrice, previous_token, copy, owner_id, (`${metadata.reference} #${copy}`), is_auction, sendData)
																								}
																							>
																								<span>Buy for {BuyPrice} Ⓝ</span>
																							</div>
																						</div>
																						<div className="mb-1">
																							<i style={{ fontSize: "0.75rem" }}>
																								Note: It will take couple of minuts to reflect your NFT's data.
																							</i>
																						</div>
																						<div className="spacer-20"></div>
																					</>
																				) : (
																					<>
																						<div className="spacer-20"></div>
																						<div className="d-flex justify-content-left mb-3">
																							{getTokenOptions(
																								offerToken,
																								setOfferToken,
																								Object.keys(token.conditions)
																							)}
																							<div
																								className="btn-main mx-2"
																								style={{
																									padding: "6px 40px",
																									lineHeight: "2.5rem",
																									width: '100%'
																								}}
																								onClick={() =>
																									otherUserBuy(account, tokenNft2, offerToken, BuyPrice, previous_token, copy, owner_id, (`${metadata.reference} #${copy}`), is_auction)
																								}
																							>
																								<span>Buy for {BuyPrice} Ⓝ</span>
																							</div>
																						</div>
																					</>
																				)

																		)
																	}
																</>
															)}
														</>
													)}
												</>
											) :
											<>
												<div className="spacer-20"></div>
												<div
													className="btn-main mx-2"
													style={{
														padding: "6px 40px",
														lineHeight: "2.5rem",
														width: '100%'
													}}
													onClick={() => setModalShow(true)}
												>
													<span>Buy Now</span>
												</div>
												<div className="spacer-40"></div>
											</>
									}
								</>
							}

							{/* test end */}
							<div className="de_tab">
								<ul className="de_nav">
									<li id="Mainbtn" className="active">
										<span onClick={handleBtnClick}>Offers</span>
									</li>
									<li id="Mainbtn1" className="">
										<span onClick={handleBtnClick1}>History</span>
									</li>
								</ul>

								<div className="de_tab_content">
									{openMenu ? (
										ShowCurrentOffer.length > 0 ? <>
											<div className="tab-1 onStep fadeIn">
												{ShowCurrentOffer.length > 0 && (
													<>
														{
															ShowCurrentOffer.map((data, index) => (
																<div
																	className="offers p_list"
																	key={index}
																>
																	<div className="p_list_pp">
																		<span>
																			<img
																				className="lazy"
																				src={data.profile_pic ? data.profile_pic : websiteUrl + '/img/author/author-11.jpg'}
																				alt=""
																			/>
																			<i className="fa fa-check"></i>
																		</span>
																	</div>
																	<div className="p_list_info d-flex">
																		<p>
																			Offer{" "}
																			<b>
																				{data.price === "0"
																					? "open"
																					: data.price}{" Ⓝ "}

																			</b>
																			<span>
																				by <b>{data.buyer_id}</b>
																			</span>
																		</p>
																		{accountId === (token.owner_id) && (
																			<ButtonStyle
																				style={{
																					position: "relative",
																					marginTop: "0.3rem",
																					height: "1.7rem",
																				}}
																				className="ml-2"
																				onClick={() =>
																					acceptOffer(account, tokenNft2, offerToken, data.price, previous_token, copy, owner_id, (`${metadata.reference} #${copy}`), is_auction, sendData, data.buyer_id, offerType)
																				}
																			>
																				Accept
																			</ButtonStyle>
																		)}
																		{accountId === (data.buyer_id) && (
																			<ButtonStyle
																				style={{
																					position: "relative",
																					marginTop: "0.3rem",
																					height: "1.7rem",
																				}}
																				className="ml-2"
																				onClick={() =>
																					deleteOffer(account, tokenNft2, offerToken, data.price, previous_token, copy, owner_id, (`${metadata.reference} #${copy}`), is_auction, sendData, data.buyer_id, offerType)
																				}
																			>
																				Cancel
																			</ButtonStyle>
																		)}
																	</div>
																</div>
															))}
													</>
												)}
											</div>
										</>
											: <p>No offer available for this NFT.</p>)
										: ''
									}

									{openMenu1 && (
										<div className="tab-2 onStep fadeIn">
											<p>This feature will be available soon.</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<LoginModalNew show={modalShow} onHide={() => setModalShow(false)} />
			</section>
			<Modal show={dotClick} onHide={handleClose2} className="mint-model w-100" centered>
				<Modal.Body style={{ backgroundColor: "#212428" }}>
					<h4 className="mb-3 mt-2 modal_click" style={{ cursor: "pointer", fontWeight: "100" }} onClick={(e) => handleShare(e)}>{copyText}</h4>
					<h4 className="my-3 modal_click" style={{ cursor: "pointer", fontWeight: "100" }} onClick={() => setShareTo(true)}>Share to...</h4>
					{
						accountId === owner_id && token_id && (
							<h4 className="my-3 modal_click" style={{ cursor: "pointer", fontWeight: "100" }} onClick={() => setTransferToken(true)}>Transfer Token</h4>
						)
					}
					{
						accountId === owner_id && token_type_id && (
							<h4 className="my-3 modal_click" style={{ cursor: "pointer", fontWeight: "100" }} onClick={() => setMintSelfToken(true)}>Mint to myself</h4>
						)
					}
					{
						accountId === owner_id && token_id && (
							<h4 className="mt-3 modal_click" style={{ cursor: "pointer", fontWeight: "100" }} onClick={() => setRemoveToken(true)}>Remove Token</h4>
						)
					}
					<h4 className="mt-3 modal_click" style={{ cursor: "pointer", fontWeight: "100" }} onClick={setRefreshData}>Refresh Data</h4>
				</Modal.Body>
			</Modal>
			<Modal show={shareTo} onHide={handleShareTo} className="mint-model w-100" centered>
				<Modal.Body style={{ backgroundColor: "#212428" }}>
					<Modal.Header style={{ backgroundColor: "#212428" }}>
						<div>
							<FacebookShareButton className="Demo__some-network__share-button d-flex my-2"
								url={shareUrl} quote={metadata.reference}>
								<FacebookIcon size={32} round={true} /> <h4 className="mx-3" style={{ cursor: "pointer", fontWeight: "100", marginTop: '2%' }}>Facebook</h4>
							</FacebookShareButton>
							<TwitterShareButton
								url={shareUrl}
								title={metadata.reference}
								className="Demo__some-network__share-button d-flex my-2"
							>
								<TwitterIcon size={32} round={true} /><h4 className="mx-3" style={{ cursor: "pointer", fontWeight: "100", marginTop: '3%' }}>Twitter</h4>
							</TwitterShareButton>
							<TelegramShareButton
								url={shareUrl}
								title={metadata.reference}
								className="Demo__some-network__share-button d-flex my-2"
							>
								<TelegramIcon size={32} round={true} /><h4 className="mx-3" style={{ cursor: "pointer", fontWeight: "100", marginTop: '3%' }}>Telegram</h4>
							</TelegramShareButton>
							<WhatsappShareButton
								url={shareUrl}
								title={metadata.reference}
								separator=":: "
								className="Demo__some-network__share-button d-flex my-2"
							>
								<WhatsappIcon size={32} round={true} /><h4 className="mx-3" style={{ cursor: "pointer", fontWeight: "100", marginTop: '3%' }}>Whatsapp</h4>
							</WhatsappShareButton>
						</div>
					</Modal.Header>
				</Modal.Body>
			</Modal>
			<Modal show={transferToken} onHide={handleTransferToken} className="mint-model w-100" centered>
				<Modal.Header closeButton className="p-0">
				</Modal.Header>
				<Modal.Body>
					<div className="row px-3 mb-2">
						<div className="col-12 d-flex justify-content-center p-0">
							<span className="text-left">
								<h4 style={{ fontSize: '1.7rem' }}>Confirm Transfer</h4>
								<h5 className="mb20">You are about to send <span style={{ color: '#8364e2', fontWeight: 'bold' }}>{metadata.reference} #{copy}</span> to:</h5>
								<NftStyle
									type="text"
									placeholder="Account Id"
									value={receiver}
									onChange={(e) => setReceiver(e.target.value)}
									className="w-100 mb-2"
									style={{ height: '3rem' }}
								/>
								<p>You will be redirected to NEAR Web Wallet to confirm your transaction.</p>
							</span>
						</div>
						<Btn className="text-center modal-body-button mb-2" onClick={() => {
							if (!receiver.length) {
								return alert("Enter Account ID");
							}
							TransferToken(account, tokenNft2, receiver);
						}}>Transfer</Btn>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={mintSelfToken} onHide={handleMintSelfToken} className="mint-model w-100" centered>
				<Modal.Header closeButton className="p-0">
				</Modal.Header>
				<Modal.Body>
					<div className="row px-3 mb-2">
						<div className="col-12 d-flex justify-content-center p-0">
							<span className="text-left">
								<h4 style={{ fontSize: '1.7rem' }}>Confirm Mint</h4>
								<h5 className="mb20">You are about to mint <span style={{ color: '#8364e2', fontWeight: 'bold' }}>{metadata.reference}</span></h5>
								<Form.Check className='mb-4 mt-1' type="checkbox" label="Mint to myself" checked={checked} onChange={() => { setCheckboxData() }} />
								{dataVisiable &&
									<NftStyle
										type="text"
										placeholder="Account Id"
										value={receiver2}
										onChange={(e) => setReceiver2(e.target.value)}
										className={dataVisiable ? "w-100 mb-2" : 'd-none'}
										style={{ height: '3rem' }}
										required
									/>
								}
								<p>You will be redirected to NEAR Web Wallet to confirm your transaction.</p>
							</span>
						</div>
						<Btn className="text-center modal-body-button mb-2" onClick={() => {
							if (!receiver2.length) {
								return alert("Enter Account ID");
							}
							SelfMint(account, tokenNft2, receiver2);
						}}>Mint</Btn>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={removeToken} onHide={handleRemoveToken} className="mint-model w-100" centered>
				<Modal.Header closeButton className="p-0">
				</Modal.Header>
				<Modal.Body>
					<div className="row px-3 mb-2">
						<div className="col-12 d-flex justify-content-center p-0">
							<span className="text-left">
								<h4 style={{ fontSize: '1.7rem' }}>Confirm Remove Token</h4>
								<h5 className="mb20">You are about to remove <span style={{ color: '#8364e2', fontWeight: 'bold' }}>{metadata.reference} #{copy}</span> token.</h5>
								<p>You will be redirected to NEAR Web Wallet to confirm your transaction.</p>
							</span>
						</div>
						<Btn className="text-center modal-body-button mb-2" onClick={() => {
							removeSale(account, tokenNft2);
						}}>Confirm</Btn>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={removeInfo} onHide={handleInfo} className="mint-model seriesInfoModal w-100" centered>
				<Modal.Header closeButton className="p-0">
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-12">
							<span className="text-left">
								<h4 style={{ fontSize: '1.7rem' }}>Owners Info of series <span onClick={() => navigate(`/token/${token_type_id ? (tokenNft2) : (token_id.split(":")[0].split(" ").join(""))}`)} style={{ cursor: "pointer", color: '#8364e2', fontWeight: 'bold' }}>#{token_type_id ? (tokenNft2) : (token_id.split(":")[0].split(" ").join(""))}</span></h4>
								<div style={{ height: '29rem', overflowX: 'hidden', display: 'block' }}>
									{
										(seriesList && seriesList.length > 0) ? (
											seriesList && (
												seriesList.map((data, index) => (
													<div className='my-2' key={index}>
														<div style={{ background: '#181b1f' }} className='py-2 px-3'>
															<div className='row d-flex justify-content-center align-items-center'>
																<div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center'>
																	{/* <img src={profileImg} alt='img' style={{ width: '50px', height: '50px', borderRadius: '50%' }}></img> */}
																	<span className='mx-2'><h4>{data.owner_id}</h4></span>
																</div>
																<div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end'><h4 className='mx-3'>Edition #{(data.token_id.split(":"))[1].split(" ").join("")}</h4></div>
															</div>
															<div className='row d-flex justify-content-center align-items-center mt-1'>
																<div className='col-lg-6 col-md-6 col-sm-12'><h5 style={{ color: '#adb5bd', fontSize: '1rem' }}>Owner of NFT #{(data.token_id)}</h5></div>
																<div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end'><div className='btn-main mx-2' style={{ padding: "6px 40px" }} onClick={() => navigate(`/token/${data.token_id}`)}>View</div></div>
															</div>
														</div>
													</div>
												)))
										) : <>
											<h3 className='text-center my-5 pt-5'>No Info Found</h3>
										</>
									}
								</div>
							</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={showModalCreateCollection} onHide={handleCloseCreateCollection} className="mint-model w-100 setCollection" centered backdrop="static">
				<Modal.Header style={{ backgroundColor: "#212428" }} closeButton>
					<h2 className="mb-0">Create Collection</h2>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: "#212428" }}>
					<h4 className="mb-0">Logo</h4>
					<div className="d-create-file mt-3 p-4">
						<div className="browse">
							{!uploadLogoCollection ? (
								<>
									<p>Upload Logo</p>
									<input
										type="button"
										id="get_file"
										className="btn-main"
										value="Browse"
									/>
									<input
										id="upload_file"
										type="file"
										onChange={uploadLogo}
										accept="image/png, image/gif, image/jpeg"
									/>
								</>
							) : (
								<div
									style={{ display: "flex", flexDirection: "column" }}
								>
									<span
										style={{ textAlign: "right", fontSize: "12px" }}

									>
										<span aria-hidden="true" className="icon_close text-danger" style={{ marginRight: '-2rem', fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => {
											setUploadLogoCollection(false);
										}}></span>
									</span>
									<img
										src={uploadLogoCollection}
										style={{ width: '100%' }}
										className="lazy nft__item_preview"
										alt="cover"
									/>
								</div>
							)}
						</div>
					</div>
					<div className="spacer-20"></div>
					<h5>Name</h5>
					<input
						type="text"
						name="item_title"
						id="item_title"
						className="form-control form-highlight"
						placeholder="e.g. 'Crypto Funk'"
						onChange={(e) => setCollectionCreateName(e.target.value)}
					/>
					<div className="spacer-10"></div>
					<h5>Description</h5>
					<textarea
						data-autoresize
						name="item_desc"
						id="item_desc"
						className="form-control form-highlight"
						placeholder="e.g. 'This is a limited item'"
						onChange={(e) => setCollectionCreateDesc(e.target.value)}
					></textarea>
					<div className="spacer-10"></div>
					<div className="d-flex justify-content-left">
						<Btn className="text-center modal-body-button mb-3" onClick={() => { CreateCollection(collectionLogoCreate, collecectionCreateName, collectionCreateDesc, account.accountId) }}>
							Create
						</Btn>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={showModalCollection} onHide={handleCloseCollection} centered className="confirm-model" backdrop="static" >
				<Modal.Header style={{ backgroundColor: "#212428" }} closeButton>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: "#212428" }}>
					<div className="container confirm py-3" style={{ backgroundColor: "#212428" }}>
						<div className="row confirm-row">
							<div className="col-md-6 col-lg-6 col-sm-12 confirm-img">
								{activeIndex ?
									<div className="shadow-lg bg-gray p-3" style={{ border: '1px solid #343a40', borderRadius: '10px' }}>
										<div className="nft__item_wrap my-4">
											<span className="confirm-preview otherImage">
												<img src={collectionImage} alt="img" height='150' width='150' style={{ borderRadius: '50%', height: '150px', width: '150px', objectFit: 'cover' }} />
											</span>
										</div>
										<div className="text-center">
											<h3>{collectionName}</h3>
											<span style={{ height: '5.5rem', overflowX: 'hidden', display: 'block' }}>
												<p>{collectionDescription}</p>
											</span>
										</div>
									</div>
									:
									<div className="shadow-lg bg-gray p-3" style={{ border: '1px solid #343a40', borderRadius: '10px' }}>
										<div className="nft__item_wrap my-4">
											<span className="confirm-preview">
												<img src='https://via.placeholder.com/250x350/212428/e3e3e3?text=Collection Preview' alt="img" style={{ height: '300px', width: '100%' }} />
											</span>
										</div>
									</div>}
							</div>
							<div className="col-md-6 col-lg-6 col-sm-12 confirm-info d-flex align-items-center">
								<div className="confirm-info-body w-100">
									<h2 style={{ fontSize: "27px", lineHeight: "27px" }}>Choose Collection</h2>
									<h6>Choose any one or create new collection before create NFT</h6>
									<div className="d-flex justify-content-left mt-4 collectionButton">
										<div className="text-center modal-body-button mb-3" onClick={handleShowCreateCollection}> + Create New Collection</div>
									</div>
									<span style={{ height: '13.5rem', overflowX: 'hidden', display: 'block' }}>
										{collectionData.map((data, index) => (
											<div className="d-flex justify-content-left collectionButton" key={index}>
												<div className={activeIndex === (index + 1) ? 'modal-body-button mb-3 d-flex justify-content-start text-center align-items-center p-1 collectionActive' : 'modal-body-button mb-3 d-flex justify-content-start text-center align-items-center p-1'} onClick={() => handleBtnClick2((index + 1), (data.name), (data.description), (data.logo), (data.collection_id))} style={{ border: '3px solid transparent' }}>
													<img src={data.logo} alt="" style={{ height: '40px', width: '40px' }} />
													<h6 className="p-0 m-0 mx-2">{data.name}</h6>
												</div>
											</div>
										))}
									</span>
								</div>
							</div>
						</div>
						<div className='row d-flex justify-content-end'>
							<div className='col-md-8 col-lg-8 col-sm-12'>
								<div className="d-flex justify-content-left mt-4">
									<Btn className="text-center modal-body-button modal-body-cancel-button mb-3" style={{ backgroundColor: '#343a40', border: 'none', padding: '12px' }} onClick={() => handleCloseCollection()} >
										Close
									</Btn>
									<Btn className={(selectCollectionId && collectionName && collectionDescription && collectionImage) ? 'text-center modal-body-button mb-3 mx-2' : 'disabled text-center modal-body-button mb-3 mx-2'} style={{ padding: '12px' }} onClick={() => { handleBtnClick3(selectCollectionId, collectionName, collectionDescription, collectionImage, previous_token, tokenId, new_copy) }}>Confirm</Btn>
								</div>

							</div>
						</div>
					</div>

				</Modal.Body>
			</Modal>
			{/* <Footer /> */}
		</div>
	);
};
export default ItemDetailMarket;
