/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo, useEffect, useState } from 'react';
import CollectionCard from './CollectionCard';
import { getMarketStoragePaid, loadItems } from '../../state/views';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { concat } from 'lodash';
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const CollectionComponent = ({ showLoadMore = true, shuffle = false, app, views, update, loading, contractAccount, account, dispatch }) => {
	if (!contractAccount) return null;
	const [height, setHeight] = useState(0);
	const [filterchange, setFilterChange] = useState([]);
	const [loadmorecountfliter, setloadmorecountfilter] = useState(0);
	const [filteritemsStatus, setFilterItemsStatus] = useState(true);


	const fetchCollectionData = async (user, loadmorecountfliter) => {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/fetch_collections_01/${loadmorecountfliter}?user_id=${user}`);
			if (result.data["status"] === "success") {
				const newListFilter = concat(...filterchange, result.data['result']);
				setFilterChange(newListFilter);
			} else {
				setFilterItemsStatus(false);
			}
		} catch (error) {
			console.error(error);
		}
	}


	useEffect(() => {
		if (!loading) {
			dispatch(loadItems(account))
			dispatch(getMarketStoragePaid(account))
		}
	}, []);

	useEffect(() => {
		if ((account)) {
			const user = account.accountId;
			fetchCollectionData(user, 0);
		}
	}, [account]);


	useEffect(() => {
		if (account) {
			const user = account.accountId;
			if (loadmorecountfliter > 0) {
				fetchCollectionData(user, loadmorecountfliter);
			}
		}
	}, [loadmorecountfliter]);

	const onImgLoad = ({ target: img }) => {
		let currentHeight = height;
		if (currentHeight < img.offsetHeight) {
			setHeight(img.offsetHeight);
		}
	}

	const loadMoreFilter = () => {
		setloadmorecountfilter(loadmorecountfliter + 1);
	}

	if (filterchange.length === 0) return (<h3 style={{ textAlign: 'center', height: '30vh', marginTop: '3rem' }}>Please Mint or Buy an NFT's to view your Collectibles items.</h3>);

	return (
		<div>
			<div className='row'>
				<InfiniteScroll style={{ overflow: "hidden" }}
					dataLength={filterchange.length} //This is important field to render the next data
					next={loadMoreFilter}
					hasMore={filteritemsStatus}
					loader={<h4 className="text-center">Loading...</h4>}
					endMessage={
						<p style={{ textAlign: 'center', margin: '10rem 0' }}>
							<b>Yay! You have seen it all</b>
						</p>
					}
				>
					<div className='row'>
						{
							filterchange.map(({
								collection_id,
								name,
								logo,
								description,
								user_id,
								createdAt,
								nft_count,
							}) => (
								<CollectionCard collection_id={collection_id} name={name} description={description} logo={logo} user_id={user_id} createdAt={createdAt} key={collection_id} path={`user-collection/` + collection_id} nft_count={nft_count} onImgLoad={onImgLoad} height={height} />
							))
						}
					</div>
				</InfiniteScroll>
				{showLoadMore && filterchange.length <= 20 &&
					<div className='col-lg-12'>
						<div className="spacer-single"></div>
						<span className="btn-main lead m-auto">Please Wait ...</span>
					</div>
				}
			</div>
		</div>
	);
};

export default memo(CollectionComponent);