import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { createGlobalStyle } from 'styled-components';
import { appStore, onAppMount } from '../../state/app';
import Footer from '../components/footer';
import SearchComponents from '../components/SearchComponenets';
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;


const Search = function (props) {

	const [searchInput, SetsearchInput] = useState('all');
	const [filters, setFilters] = useState('');
	const { state, dispatch, update } = useContext(appStore);

	const { app, views, app: { tab, snack }, near, wallet, contractAccount, account, loading } = state;
	const onMount = () => {
		dispatch(onAppMount());
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onMount, []);


	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const q = params.get('q');
		SetsearchInput(q)
	}, []);

	const handleFilters = (filters, category) => {
		// const newFilters = {...filters};
		// // newFilters[category] = filters;		
		// // if(category==="aution"){			
		// // }
		setFilters((encodeURIComponent(filters.toString())));

	}

	return (
		<div>
			<Helmet>
				<title>UniqART - Search</title>
				<link rel="canonical" href={`${websiteUrl}/search`} />
				<meta property="og:title" content='UniqART - Search' />
				<meta property="og:url" content={`${websiteUrl}/search`} />
			</Helmet>
			<GlobalStyles />
			<section className='container'>
				<div className='row'>
					<div className="col-12 mt-4 text-center">
						<h2>Search Result for <span style={{ color: "#8364e2" }}>{searchInput}</span></h2>
					</div>
					<div className="spacer-double"></div>

					<div className="col-md-12">
						<SearchComponents {...{ app, views, update, loading, contractAccount, account, dispatch, filters }} />
					</div>
				</div>
			</section>
			<Footer />
		</div>

	);
}

export default Search;