import React, { useCallback, useState } from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { navigate } from "@reach/router";
import "./nftform.css";

import { Modal } from "react-bootstrap";
import axios from "axios";
import { FaExclamationCircle } from "react-icons/fa";

import Getconfig from '../../config';
export const {
  websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
  
`;

export default function Form() {
  const [data, setData] = useState({
    fullName: { value: "", error: false },
    email: { value: "", error: false },
    nearid: { value: "", error: false },
    phone: { value: "", error: false },
    city: { value: "", error: false },
    country: { value: "", error: false },
    creativeField: { value: "", error: false },
    communityMsg: { value: "", error: false },
    knowAboutNft: { value: "", error: false },
    nftListed: { value: "", error: false },
    socialShare: { value: "", error: false },
  });
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAlreadySubmitted, setShowAlreadySubmitted] = useState(false);
  function handleClose() {
    setShowModal(false);
  }
  const handleChange = useCallback((e, type) => {
    let value = e.target.value;
    let tempData = { ...data };
    tempData[type].value = value;
    // console.log(tempData);
    setData(tempData);
  }, []);

  const formValidation = (key, value) => {
    let check = null;
    if (key === "email") {
      let pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      check = pattern.test(value);
    } else {
      check = value.length > 0;
    }

    return check;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let check = false;
    const tempData = Object.entries(data).reduce((acc, curr) => {
      if (!formValidation(curr[0], curr[1].value)) {
        check = true;
      }
      acc[curr[0]] = {
        value: curr[1].value,
        error: !formValidation(curr[0], curr[1].value),
      };
      return acc;
    }, {});
    if (check) {
      setShowErrorModal(true);
      setData(tempData);
      return;
    }

    // const api = .create({ baseURL: "" });

    axios
      .post(apiUrl + "/api/v1/nft_form", {
        name: data.fullName.value,
        email: data.email.value,
        near_id: data.nearid.value,
        phone: data.phone.value,
        city: data.city.value,
        country: data.country.value,
        creative_field: data.creativeField.value,
        about_work: data.communityMsg.value,
        know_about_nft: data.knowAboutNft.value,
        listed_nft: data.nftListed.value,
        share_media: data.socialShare.value,
      })
      .then((result) => {
        console.log("result", result);
        if (result.data.status === "failure") {
          setShowAlreadySubmitted(true);
          return;
        }
        // setData(result);
        setShowModal(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="row justify-content-center form-container">
      <GlobalStyles />

      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb pb-5">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h4 className="text-center text-uppercase fw-bold text-white">
                  BECOME A VERIFIED CREATOR NOW
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="col-lg-8 col-sm-12">
        <div className="form-div row m-2">
          <div className="mt-5 form-group mb-3 col-lg-12 col-md-12 col-sm-12">
            <label for="fullName" className="label fw-bold mb-1">
              Full Name
            </label>
            <input
              type="text"
              value={data.fullName.value}
              onChange={(e) => handleChange(e, "fullName")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="fullName"
              placeholder="Full Name"
              required=""
              style={{ border: data.fullName.error ? `1px solid red` : "" }}
            />
          </div>

          <div className="form-group mb-3 col-lg-6 col-md-12 col-sm-12">
            <label for="emailAddress" className="label fw-bold mb-1">
              Email Address
            </label>
            <input
              type="email"
              value={data.email.value}
              onChange={(e) => handleChange(e, "email")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="emailAddress"
              placeholder="Email Address"
              required=""
              style={{ border: data.email.error ? `1px solid red` : "" }}
            />
          </div>

          <div className="form-group mb-3 col-lg-6 col-md-12 col-sm-12">
            <label for="nearid" className="label fw-bold mb-1">
              Near Id
            </label>
            <input
              type="text"
              value={data.nearid.value}
              onChange={(e) => handleChange(e, "nearid")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="nearid"
              placeholder="Near Id"
              required=""
              style={{ border: data.nearid.error ? `1px solid red` : "" }}
            />
          </div>

          <div className="form-group mb-3 col-lg-6 col-md-12 col-sm-12">
            <label for="phoneNumber" className="label fw-bold mb-1">
              Phone Number
            </label>
            <input
              type="number"
              value={data.phone.value}
              onChange={(e) => handleChange(e, "phone")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="phoneNumber"
              placeholder="Phone Number"
              required=""
              style={{ border: data.phone.error ? `1px solid red` : "" }}
            />
          </div>
          <div className="form-group mb-3 col-lg-6 col-md-12 col-sm-12">
            <label for="city" className="label fw-bold mb-1">
              City
            </label>
            <input
              type="text"
              value={data.city.value}
              onChange={(e) => handleChange(e, "city")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="city"
              placeholder="City"
              required=""
              style={{ border: data.city.error ? `1px solid red` : "" }}
            />
          </div>
          <div className="form-group mb-3 col-lg-6 col-md-12 col-sm-12">
            <label for="country" className="label fw-bold mb-1">
              Country
            </label>
            <input
              type="text"
              value={data.country.value}
              onChange={(e) => handleChange(e, "country")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="country"
              placeholder="Country"
              required=""
              style={{ border: data.country.error ? `1px solid red` : "" }}
            />
          </div>

          <div className="form-group mb-3 col-lg-12 col-md-12 col-sm-12">
            <label for="creativeField" className="label fw-bold mb-1">
              Creative Field
            </label>
            <input
              type="text"
              value={data.creativeField.value}
              onChange={(e) => handleChange(e, "creativeField")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="creativeField"
              placeholder="eg: Music, Literature, Digital Art/Illustration..."
              required=""
              style={{
                border: data.creativeField.error ? `1px solid red` : "",
              }}
            />
          </div>

          <div className="form-group mb-3 col-lg-12 col-md-12 col-sm-12">
            <label for="communityMsg" className="label fw-bold mb-1">
              What would you like to bring to the community? Tell us a bit about
              your work/projects
            </label>
            <input
              type="text"
              value={data.communityMsg.value}
              onChange={(e) => handleChange(e, "communityMsg")}
              className="form-control form-control-lg input-primary bg-transparent text-white"
              id="communityMsg"
              placeholder="What would you like to bring to the community? Tell us a bit about your work/projects"
              required=""
              style={{ border: data.communityMsg.error ? `1px solid red` : "" }}
            />
          </div>

          <div
            className="mb-3 col-lg-6 col-md-12 col-sm-12"
            onChange={(e) => handleChange(e, "knowAboutNft")}
          >
            <label for="nft" className="label fw-bold mb-1">
              Do you know about NFT's?*
            </label>
            <div className="form-check ">
              <input
                className="form-check-input"
                type="radio"
                name="nft"
                value="yes"
                id="nft"
                checked={data.knowAboutNft.value === "yes"}
              />
              <label className="form-check-label" for="nft">
                Yes
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="nfts"
                value="no"
                id="nft"
                checked={data.knowAboutNft.value === "no"}
              />
              <label className="form-check-label" for="nft">
                No
              </label>
            </div>
          </div>

          <div
            className="mb-3 col-lg-6 col-md-12 col-sm-12"
            onChange={(e) => handleChange(e, "nftListed")}
          >
            <label for="listednft" className="label fw-bold mb-1">
              Have you listed your own NFT before?*
            </label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="listednft"
                value="yes"
                id="listednft"
                checked={data.nftListed.value === "yes"}
              />
              <label className="form-check-label" for="nft">
                Yes
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input "
                type="radio"
                name="listednft"
                value="no"
                id="listednft"
                checked={data.nftListed.value === "no"}
              />
              <label className="form-check-label" for="listednft">
                No
              </label>
            </div>
          </div>

          <div className="form-group mb-4 col-lg-12 col-md-12 col-sm-12">
            <label for="socialShare" className="label fw-bold mb-1">
              Share your social media links (Instagram, YouTube, Spotify, etc.)
            </label>
            <textarea
              className="form-control input-primary bg-transparent text-white"
              id="socialShare"
              rows="5"
              placeholder="eg: https://www.facebook.com/username, https://www.instagram.com/username,..."
              required=""
              style={{ border: data.socialShare.error ? `1px solid red` : "" }}
              value={data.socialShare.value}
              onChange={(e) => handleChange(e, "socialShare")}
            ></textarea>
          </div>

          <div className="d-flex justify-content-center gap-2 mb-3">
            <button
              type="button"
              className="btn-main inline lead  "
              id="registerNft"
              onClick={handleSubmit}
            >
              Become a Verified Creator Now
            </button>
          </div>
          <div className="mb-2 msg"></div>
          <div className="mb-2 msg2"></div>
        </div>
      </div>
      <Footer customStyles={{ position: "sticky", width: "100%", bottom: 0 }} />

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="confirm-modal"
        backdrop="static"
        style={{
          borderRadius: "20px",
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body
          style={{
            backgroundColor: "#212428",
            display: "flex",
            alignItems: "center",
            gap: `10px`,
            borderRadius: "20px",
          }}
          className="d-flex flex-column"
        >
          <AiOutlineCheckCircle
            size={50}
            className="text-success text-center m-auto"
          />
          <h3 className="text-white text-center">
            Thankyou for filling out your information.we'll get back to you within 24 hours regarding your information.
          </h3>
          <button
            className="btn-main inline lead "

            onClick={() => {
              navigate("/market");
            }}
          >
            Go to Market
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        centered
        className="confirm-modal"
        backdrop="static"
        style={{
          borderRadius: "20px",
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body
          style={{
            backgroundColor: "#212428",
            display: "flex",
            alignItems: "center",
            gap: `10px`,
            borderRadius: "20px",
          }}
          className="d-flex flex-column"
        >
          <MdCancel size={50} className="text-danger text-center m-auto" />
          <h3 className="text-white text-center">
            There was a problem with your submission.
          </h3>
          <button
            style={{ width: 100, boxShadow: "none" }}
            onClick={() => setShowErrorModal(false)}
            className="btn-main inline lead bg-danger "
          >
            OK
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAlreadySubmitted}
        onHide={handleClose}
        centered
        className="confirm-modal"
        backdrop="static"
        style={{
          borderRadius: "20px",
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body
          style={{
            backgroundColor: "#212428",
            display: "flex",
            alignItems: "center",
            gap: `10px`,
            borderRadius: "20px",
          }}
          className="d-flex flex-column"
        >

          <FaExclamationCircle
            size={50}
            className="text-danger text-center m-auto"
          />
          <h3 className="text-white text-center">
            You have already applied for verification.
            Uniqart team will contact you to proceed with your verification.
          </h3>
          <button
            className="btn-main inline lead"

            onClick={() => {
              navigate("/nftform");
            }}
          >
            Back to form
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
