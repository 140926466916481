import axios from "axios";
import { memo, useEffect, useState } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { getMarketStoragePaid, loadItems } from '../../state/views';
import { carouselNew } from './constants';
import HomepageCardPopular from "./HomepageCardPopular";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const CarouselNewRedux = ({ app, views, loading, contractAccount, account, dispatch }) => {

	const [datanft, setdatanft] = useState([])
	var fetchnftdata = async function fetchData(loadmorecount) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/get_popular_nft/" + loadmorecount)
			if (result.data["status"] === "success") {
				const newList = (result.data['data']);
				setdatanft(newList);
			} else {
				setdatanft([]);
			}
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		fetchnftdata(0);
	}, []);

	useEffect(() => {
		if (!loading && account) {
			dispatch(loadItems(account))
			dispatch(getMarketStoragePaid(account))
		}
	}, [account, loading]);

	if (!contractAccount) {
		return null;
	}

	return (
		<div className='nft'>
			<Slider {...carouselNew}>
				{datanft.map((nft, i) => (
					<HomepageCardPopular nft={nft} key={i} />
				))}
			</Slider>
		</div>
	);
}

export default memo(CarouselNewRedux);
