import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { navigate } from "@reach/router";
import Getconfig from '../../config';
export const {
	walletUrl
} = Getconfig();

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox = () => (
	<div className='row'>
		<div className="col-lg-4 col-md-6 mb-3">
			<div className="feature-box f-boxed style-3" style={{ cursor: 'pointer' }} onClick={() => window.open(walletUrl, "_self")}>
				<Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
					<i className="bg-color-2 i-boxed icon_wallet"></i>
				</Reveal>
				<div className="text">
					<Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
						<h4 className="">Set up your wallet</h4>
					</Reveal>
					{/* <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
                        <p className="">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>
                      </Reveal> */}
				</div>
				<i className="wm icon_wallet"></i>
			</div>
		</div>

		<div className="col-lg-4 col-md-6 mb-3">
			<div className="feature-box f-boxed style-3" style={{ cursor: 'pointer' }} onClick={() => navigate('/create')}>
				<Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
					<i className=" bg-color-2 i-boxed icon_cloud-upload_alt"></i>
				</Reveal>
				<div className="text">
					<Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
						<h4 className="">Add your NFT's</h4>
					</Reveal>
					{/* <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
                      <p className="">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>
                    </Reveal> */}
				</div>
				<i className="wm icon_cloud-upload_alt"></i>
			</div>
		</div>

		<div className="col-lg-4 col-md-6 mb-3">
			<div className="feature-box f-boxed style-3" style={{ cursor: 'pointer' }} onClick={() => navigate('/market')}>
				<Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
					<i className=" bg-color-2 i-boxed icon_cart_alt"></i>
				</Reveal>
				<div className="text">
					<Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
						<h4 className="">Explore Marketplace</h4>
					</Reveal>
					{/* <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
                      <p className="">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>
                    </Reveal> */}
				</div>
				<i className="wm icon_cart_alt"></i>
			</div>
		</div>
	</div>
);
export default featurebox;