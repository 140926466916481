import React from "react";
import VideoJS from './VideoPlayer' // point to where the functional component is stored

const VideoApp = ({url,cover}) => {
  const playerRef = React.useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: url,
      type: 'video/mp4'
    }],
    poster:cover,
    loop:true,
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  return (
    <>
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </>
  );
}

export default VideoApp