import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { concat } from 'lodash';
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import "./myOffers.scss"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { appStore } from "../../state/app";
import { navigate } from "@reach/router";
import { Button as Btn, Modal, Form } from "react-bootstrap";
import { handleRegisterStorage, handleSaleUpdate, removeSale, TransferToken, otherUserSale, otherUserBuy, addOffer, acceptOffer, SelfMint, deleteOffer } from "../../state/actions";
import Getconfig from '../../config';
import MyofferComponent from "../components/MyofferComponent";
export const {
	websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const NftStyle = styled.input`
padding: 4px 15px;
border: none;
border-radius: 30px;
font-size: 15px;
color: #fff;
background: rgba(255, 255, 255, 0.1);
width: 70%;
height: 3.2rem;
outline: none;
transition: 0.7s;
&:hover {
  transition: 0.7s;
  box-shadow: 0px 0px 0px 4px rgb(255 52 53 / 30%);
}
@media (max-width: 1400px) and (min-width:1200px) {
  width:50%;
}
@media (max-width: 1200px) and (min-width:991px) {
  width:40%;
}
`;

const MyOffers = () => {
	const { state } = useContext(appStore);
	const { wallet, account } = state;
	const [filterchange, setFilterChange] = useState([]);
	const [loadmorecountfliter, setloadmorecountfilter] = useState(0);
	const [filteritemsStatus, setFilterItemsStatus] = useState(true);
	const [filterchange2, setFilterChange2] = useState([]);
	const [loadmorecountfliter2, setloadmorecountfilter2] = useState(0);
	const [filteritemsStatus2, setFilterItemsStatus2] = useState(true);
	const [receiver2, setReceiver2] = useState("");
	const [updateOffer, setUpdateOffer] = useState(false);
	const [removeOffer, setRemoveOffer] = useState(false);
	const [acceptOfferTab, setAcceptOffer] = useState(false);
	const [dataUpdateValues, setDataUpdateValues] = useState({});
	const [dataRemoveValues, setDataRemoveValues] = useState({});
	const [dataAcceptValues, setDataAcceptValues] = useState({});
	const [sendData, setSendData] = useState([]);

	const fetchOfferData = async (user, loadmorecountfliter) => {
		console.log(user, loadmorecountfliter, 'user, loadmorecountfliter')
		try {
			const result = await axios.get(`${apiUrl}/api/v1/owner_bids/${loadmorecountfliter}?owner_address=${user}`);
			if (result.data["status"] === "success") {
				const newListFilter = concat(...filterchange, result.data['data']);
				setFilterChange(newListFilter);
			} else {
				setFilterItemsStatus(false);
			}
		} catch (error) {
			console.error(error);
		}
	}

	const fetchRecievedBids = async (user2, loadmorecountfliter2) => {
		console.log(user2, loadmorecountfliter2, 'user2, loadmorecountfliter2')
		try {
			const result = await axios.get(`${apiUrl}/api/v1/owner_received_bids/${loadmorecountfliter2}?owner_address=${user2}`);
			if (result.data["status"] === "success") {
				const newListFilter2 = concat(...filterchange2, result.data['data']);
				setFilterChange2(newListFilter2);
			} else {
				setFilterItemsStatus2(false);
			}
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			fetchOfferData(user, 0);
			fetchRecievedBids(user, 0);
		}
	}, [wallet]);

	useEffect(() => {
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			if (loadmorecountfliter > 0) {
				fetchOfferData(user, loadmorecountfliter);
			}
		}
	}, [loadmorecountfliter]);

	useEffect(() => {
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			if (loadmorecountfliter2 > 0) {
				fetchRecievedBids(user, loadmorecountfliter2);
			}
		}
	}, [loadmorecountfliter2]);

	const loadMoreFilter = () => {
		setloadmorecountfilter(loadmorecountfliter + 1);
	}
	const loadMoreFilter2 = () => {
		setloadmorecountfilter2(loadmorecountfliter2 + 1);
	}

	const handleUpdateOffer = () => {
		setUpdateOffer(false);
	};
	const handleRemoveOffer = () => {
		setRemoveOffer(false);
	};
	const handleAcceptOffer = () => {
		setAcceptOffer(false);
	};
	function passDataAndUpdate(sell_id, name, bid, previous_token, current_copy, seller_address) {
		setUpdateOffer(true);
		setDataUpdateValues({
			'sell_id': sell_id,
			'name': name,
			'bid': bid,
			'previous_token': previous_token,
			'current_copy': current_copy,
			'is_auction': false,
			'owner_id': seller_address
		})
	}

	function passDataAndRemove(sell_id, name, bid, previous_token, current_copy, seller_address) {
		setRemoveOffer(true);
		setDataRemoveValues({
			'sell_id': sell_id,
			'name': name,
			'bid': bid,
			'previous_token': previous_token,
			'current_copy': current_copy,
			'is_auction': false,
			'owner_id': seller_address,
			'sendData': sendData
		})
	}
	async function fetchNftData(tokenNft2, new_copy) {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/get_nft_token?nft_token_id=${tokenNft2}&sell_id=${tokenNft2}&current_copy=${new_copy}`);
			if (result.data["status"] === "success") {
				setSendData(result.data['data'][0]);
			}
		} catch (error) {
			console.error(error);
		}
	}


	function passDataAndAccept(sell_id, name, bid, previous_token, current_copy, buyer_id) {
		setAcceptOffer(true);
		fetchNftData(sell_id, current_copy);
		setDataAcceptValues({
			'sell_id': sell_id,
			'name': name,
			'bid': bid,
			'previous_token': previous_token,
			'current_copy': current_copy,
			'is_auction': false,
			'buyer_id': buyer_id,
			'sendData': sendData
		})
	}

	return (
		<>
			<div>
				<GlobalStyles />

				<Helmet>
					<title>UniqART - MyOffer</title>
					<link rel="canonical" href={websiteUrl + "/myOffers"} />
					<meta property="og:title" content={`UniqART - MyOffer`} />
					<meta
						property="og:url"
						content={`${websiteUrl}/myOffers`}
					/>
				</Helmet>

				<section className="jumbotron breadcumb no-bg">
					<div className="mainbreadcumb">
						<div className="container">
							<div className="row m-10-hor">
								<div className="col-12">
									<h1 className="text-center">My Offers</h1>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="container">
					<div className="row d-flex justify-content-center">
						<div className="col-lg-10 col-md-12">
							<Tabs>
								<section className="container no-top">

									<TabList style={{ borderBottom: 'none', margin: '0 0 1rem 0', padding: '0' }}>
										<div className="row">
											<div className="col-lg-12">
												<div className="items_filter de_nav">
													<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>My Bids</span></Tab>
													<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Received Bids</span></Tab>
												</div>
											</div>
										</div>
									</TabList>
									<TabPanel>
										<div id="zero1" className="onStep fadeIn">
											<ul className="activity-list offer-list">
												<InfiniteScroll style={{ overflow: "hidden" }}
													dataLength={filterchange.length} //This is important field to render the next data
													next={loadMoreFilter}
													hasMore={filteritemsStatus}
													loader={<h4 className="text-center">Loading...</h4>}
													endMessage={
														<p style={{ textAlign: 'center', margin: '10rem 0' }}>
															<b>Yay! You have seen it all</b>
														</p>
													}
												>
													{
														filterchange.map((bid, i) => (
															<li className="act_sale offer-block my-4" key={i}>
																{!bid.flag && (
																	<img className="offerSoldOutStyle" src={websiteUrl + "/img/sold-out.png"} alt="" />
																)}
																<div className="act_list_text bid_text row">
																	<div className="bid-left col-lg-3 col-md-5 col-sm-12 d-flex justify-content-center">
																		<MyofferComponent bid={bid} />
																	</div>
																	<div className="col-lg-9 col-md-7 col-sm-12 infoClass">
																		<div className="bid-right row">
																			<div className="col-lg-7 col-md-7 col-sm-12 p-4 justify-content-start text-info-bids">
																				<h3 className="bid-title text-truncate"><span onClick={() => navigate('/token/' + bid.sell_id)}>{bid.name}</span></h3>
																				{/* <p className="bid-description">{bid.description}</p> */}
																				<h5 className="bid-youoffer">You have offered {bid.bid} Ⓝ to</h5>
																				<h5><span style={{ color: '#8364e2', cursor: 'pointer' }} onClick={() => navigate('/user/' + bid.seller_address)}>{bid.seller_address}</span> for <span style={{ color: '#8364e2', cursor: 'pointer' }} onClick={() => navigate('/token/' + bid.sell_id)}>#{bid.sell_id}</span></h5>
																				<p className="bid-monthsago">on date {(bid.createdAt.split('T'))[0]}</p>
																			</div>
																			<div className="col-lg-4 col-md-5 col-sm-12 text-center">
																				{bid.flag && (
																					<button className="bids-button update-button my-2 w-75" onClick={() => passDataAndUpdate(bid.sell_id, bid.name, bid.bid, bid.nft_token_id, bid.current_copy)}>Update</button>
																				)}
																				<button className="bids-button cancel-button bg-danger my-2 w-75" onClick={() => passDataAndRemove(bid.sell_id, bid.name, bid.bid, bid.nft_token_id, bid.current_copy)}>Cancel</button>
																			</div>
																		</div>
																	</div>
																</div>
																{!bid.flag && (
																	<p className="text-center p-0 m-0 text-warning">* Press Cancel button to take your refund amount in your wallet.</p>
																)}
															</li>
														))
													}

												</InfiniteScroll>
											</ul>
										</div>
									</TabPanel>
									<TabPanel>
										<div id="zero2" className="onStep fadeIn">
											<ul className="activity-list offer-list">
												<InfiniteScroll style={{ overflow: "hidden" }}
													dataLength={filterchange2.length}
													next={loadMoreFilter2}
													hasMore={filteritemsStatus2}
													loader={<h4 className="text-center">Loading...</h4>}
													endMessage={
														<p style={{ textAlign: 'center', margin: '10rem 0' }}>
															<b>Yay! You have seen it all</b>
														</p>
													}
												>
													{
														filterchange2.map((bid, i) => (
															bid.flag &&
															(
																<li className="act_sale offer-block my-4" key={i}>
																	<div className="act_list_text bid_text row">
																		<div className="bid-left col-lg-3 col-md-5 col-sm-12 d-flex justify-content-center">
																			<MyofferComponent bid={bid} />
																		</div>
																		<div className="col-lg-9 col-md-7 col-sm-12 infoClass">
																			<div className="bid-right row">
																				<div className="col-lg-7 col-md-7 col-sm-12 p-4 justify-content-start text-info-bids">
																					<h3 className="bid-title text-truncate"><span onClick={() => navigate('/token/' + bid.sell_id)}>{bid.name}</span></h3>
																					{/* <p className="bid-description">{bid.description}</p> */}
																					<h5 className="bid-youoffer">You recieved {bid.price} Ⓝ offer from</h5>
																					<h5><span style={{ color: '#8364e2', cursor: 'pointer' }} onClick={() => navigate('/user/' + bid.buyer_id)}>{bid.buyer_id}</span> for <span style={{ color: '#8364e2', cursor: 'pointer' }} onClick={() => navigate('/token/' + bid.sell_id)}>#{bid.sell_id}</span></h5>
																					<p className="bid-monthsago">on date {(bid.createdAt.split('T'))[0]}</p>
																				</div>
																				<div className="col-lg-4 col-md-5 col-sm-12 text-center">
																					<button className="bids-button accept-button my-2 w-75 bg-success" onClick={() => passDataAndAccept(bid.sell_id, bid.name, bid.price, bid.nft_token_id, bid.current_copy, bid.buyer_id)}>Accept</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</li>
															)
														))
													}

												</InfiniteScroll>
											</ul>
										</div>
									</TabPanel>
								</section>
							</Tabs>
						</div>
					</div>
				</section>
				{/* model start */}
				<Modal show={updateOffer} onHide={handleUpdateOffer} className="mint-model w-100" centered backdrop="static">
					<Modal.Header closeButton className="p-0">
					</Modal.Header>
					<Modal.Body>
						<div className="row px-3 mb-2">
							<div className="col-12 d-flex justify-content-center p-0">
								<span className="text-left">
									<h4 style={{ fontSize: '1.7rem' }}>Update Offer</h4>
									<p>Current Offer <span style={{ fontWeight: 'bold' }}>{dataUpdateValues.bid} Ⓝ</span></p>
									<h5 className="mb20">You are about to update offer on <span style={{ color: '#8364e2', fontWeight: 'bold' }}>{dataUpdateValues.name}</span></h5>
									<p className="mb-2">Amount in Ⓝ</p>
									<NftStyle
										type="number"
										placeholder="Place new Offer"
										onChange={(e) => setReceiver2(e.target.value)}
										className="w-100 mb-3"
										style={{ height: '3rem' }}
										min="0"
										required
									/>

									<p>You will be redirected to NEAR Web Wallet to confirm your transaction.</p>
								</span>
							</div>
							<Btn className="text-center modal-body-button mb-3 my-2" onClick={() => {
								if (!receiver2.length) {
									return alert("Enter Amount");
								}
								addOffer(account, dataUpdateValues.sell_id, 'near', receiver2, dataUpdateValues.previous_token, dataUpdateValues.current_copy, dataUpdateValues.owner_id, (`${dataUpdateValues.name} #${dataUpdateValues.current_copy}`), dataUpdateValues.is_auction, true, 'update_offer', (dataUpdateValues.sell_id.indexOf(':') > -1) ? 'token' : 'tokenType')
							}}>Update Offer</Btn>
						</div>
					</Modal.Body>
				</Modal>
				<Modal show={removeOffer} onHide={handleRemoveOffer} className="mint-model w-100" centered>
					<Modal.Header closeButton className="p-0">
					</Modal.Header>
					<Modal.Body>
						<div className="row px-3 mb-2">
							<div className="col-12 d-flex justify-content-center p-0">
								<span className="text-left">
									<h4 style={{ fontSize: '1.7rem' }}>Confirm Remove Offer</h4>
									<h5 className="mb20">You are about to remove offer from <span style={{ color: '#8364e2', fontWeight: 'bold' }}>{dataRemoveValues.name}</span> token.</h5>
									<p>You will be redirected to NEAR Web Wallet to confirm your transaction.</p>
								</span>
							</div>
							<Btn className="text-center modal-body-button mb-2" onClick={() => {
								deleteOffer(account, dataRemoveValues.sell_id, 'near', '0', dataRemoveValues.previous_token, dataRemoveValues.current_copy, dataRemoveValues.owner_id, (`${dataRemoveValues.name} #${dataRemoveValues.current_copy}`), 'false', '', account.accountId, (dataRemoveValues.sell_id.indexOf(':') > -1) ? 'token' : 'tokenType')
							}}>Confirm</Btn>
						</div>
					</Modal.Body>
				</Modal>
				<Modal show={acceptOfferTab} onHide={handleAcceptOffer} className="mint-model w-100" centered>
					<Modal.Header closeButton className="p-0">
					</Modal.Header>
					<Modal.Body>
						<div className="row px-3 mb-2">
							<div className="col-12 d-flex justify-content-center p-0">
								<span className="text-left">
									<h4 style={{ fontSize: '1.7rem' }}>Confirm Accept Offer</h4>
									<h5 className="mb20">You are about to accept offer from <span style={{ color: '#8364e2', fontWeight: 'bold' }}>{dataAcceptValues.name}</span> token.</h5>
									<p>You will be redirected to NEAR Web Wallet to confirm your transaction.</p>
								</span>
							</div>
							<Btn className="text-center modal-body-button mb-2" onClick={() => {
								acceptOffer(account, dataAcceptValues.sell_id, 'near', dataAcceptValues.bid, dataAcceptValues.previous_token, dataAcceptValues.current_copy, account.accountId, (`${dataAcceptValues.name} #${dataAcceptValues.current_copy}`), 'false', sendData, dataAcceptValues.buyer_id, (dataAcceptValues.sell_id.indexOf(':') > -1) ? 'token' : 'tokenType')
							}}>Confirm</Btn>
						</div>
					</Modal.Body>
				</Modal>
				{/* model end */}
			</div>
		</>
	);
};

export default MyOffers;
