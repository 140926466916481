/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo, useEffect, useState } from 'react';
import NftCard from './NftCard';
import { token2symbol } from '../../state/near';
import { getMarketStoragePaid, loadItems } from '../../state/views';
import { useHistory } from '../../utils/history';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { concat } from 'lodash';
import Getconfig from '../../config';
export const {
    websiteUrl, apiUrl
} = Getconfig();

//react functional component
const ColumnNewRedux = ({ showLoadMore = true, shuffle = false, app, views, update, loading, contractAccount, account, dispatch, profileImg, userVerified }) => {
    if (!contractAccount) return null;
    const [height, setHeight] = useState(0);
    const [filterchange, setFilterChange] = useState([]);
    const [loadmorecountfliter, setloadmorecountfilter] = useState(0);
    const [filteritemsStatus, setFilterItemsStatus] = useState(true);

    const fetchCreateData = async (user, loadmorecountfliter) => {
        try {
            const result = await axios.get(`${apiUrl}/api/v1/fetch_favourite_nft/${loadmorecountfliter}?address=${user}`);
            if (result.data["status"] === "Success") {
                const newListFilter = concat(...filterchange, result.data['all_nft']);
                setFilterChange(newListFilter);
            } else {
                setFilterItemsStatus(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!loading) {
            dispatch(loadItems(account))
            dispatch(getMarketStoragePaid(account))
        }
    }, []);

    useEffect(() => {
        if ((account)) {
            const user = account.accountId;
            fetchCreateData(user, 0);
        }
    }, [account]);


    useEffect(() => {
        if (account) {
            const user = account.accountId;
            if (loadmorecountfliter > 0) {
                fetchCreateData(user, loadmorecountfliter);
            }
        }
    }, [loadmorecountfliter]);

    // path to token
    const [path, setPath] = useState(window.location.href);
    useHistory(() => {
        setPath(window.location.href);
    });

    const { marketStoragePaid } = views;
    let accountId = '';
    if (account) accountId = account.accountId;

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }

    const loadMoreFilter = () => {
        setloadmorecountfilter(loadmorecountfliter + 1);
    }
    if (filterchange.length === 0) return (<h3 style={{ textAlign: 'center', height: '30vh', marginTop: '3rem' }}>Please add an NFT's to view your Favourite Collection items.</h3>);
    return (
        <div>
            <div className='row'>
                <InfiniteScroll style={{ overflow: "hidden" }}
                    dataLength={filterchange.length} //This is important field to render the next data
                    next={loadMoreFilter}
                    hasMore={filteritemsStatus}
                    loader={<h4 className="text-center">Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center', margin: '10rem 0' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    <div className='row'>
                        {
                            filterchange.map(({
                                media_url, name, description, extra, no_copies,
                                seller_address,
                                verified,
                                sell_id,
                                nft_token_id,
                                sale_conditions = {},
                                bids = {},
                                royalty = {},
                                is_auction,
                                is_unlockable,
                                extension,
                                compressed_url,
                                min_sell_price,
                                fav_count
                            }) => (
                                <NftCard media={media_url} title={name} description={description} owner_id={seller_address} nft={media_url} token_id={sell_id ? sell_id : nft_token_id} sale_conditions={sale_conditions} bids={bids} royalty={royalty} marketStoragePaid={marketStoragePaid} token2symbol={token2symbol} accountId={accountId} account={account} key={sell_id ? sell_id : nft_token_id} path={'/token/' + (sell_id ? sell_id : nft_token_id)} extra={extra} onImgLoad={onImgLoad} height={height} copies={no_copies} reference={name} is_auction={is_auction} profileImg={profileImg} verified={userVerified} is_unlockable={is_unlockable} extension={extension} compressed_url={compressed_url} min_sell_price={min_sell_price} fav_count={fav_count} />
                            ))
                        }

                    </div>
                </InfiniteScroll>
                {showLoadMore && filterchange.length <= 20 &&
                    <div className='col-lg-12'>
                        <div className="spacer-single"></div>
                        <span className="btn-main lead m-auto">Please Wait ...</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default memo(ColumnNewRedux);