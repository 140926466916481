import React, { Component } from "react";
import { navigate } from "@reach/router";
import axios from "axios";
import { create } from "ipfs-http-client";
import { CID } from "multiformats/cid";
import * as nearAPI from "near-api-js";
import { Button as Btn, Modal, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactPlayer from "react-player";
import FileType from "file-type/browser";
import { v4 } from 'uuid';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import styled, { createGlobalStyle } from "styled-components";
import {
  handleRegisterStorage,
  handleSaleUpdate,
  removeSale,
  TransferToken,
  otherUserSale,
  otherUserBuy,
  addOffer,
  acceptOffer,
  SelfMint,
  deleteOffer,
} from "../../state/actions";
import { appStore, snackAttack, onAppMount } from "../../state/app";
import {
  getTokenOptions,
  handleOffer,
  parseNearAmount,
  contractId,
} from "../../state/near";
import { share } from "../../utils/mobile";
import {
  getTokenSeriesInfo,
  hasStorageBalance,
  getCurrentTokenInfo,
  isMintable,
} from "../../state/views";
import LoginModalNew from "../components/LoginModalNew";
import { Spinner } from "reactstrap";
import Footer from "../components/footer";
import ReactTooltip from "react-tooltip";
import CryptoJS from "crypto-js";
import Getconfig from "../../config";
import AudioPlayer from "../components/AudioPlayer";
import VideoApp from "../components/VideoApp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttributeComponent from "../components/AttributeComponent";
export const {
  websiteUrl,
  apiUrl,
  extraFee,
  offerextraFee,
  storage_fee,
  nftApproveStorageDeposit,
  nftApprove,
  transTxnUrl,
} = Getconfig();

const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET_KEY;
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

function toPlainString(num) {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
}
const CardImage = styled.img`
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
`;

const TxnCard = styled.div`
  background: rgba(18, 18, 18, 0.3);
  border-radius: 10px;
`;

const NftStyle = styled.input`
  padding: 4px 15px;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  width: 60%;
  height: 3.2rem;
  outline: none;
  transition: 0.7s;
  &:hover {
    transition: 0.7s;
    box-shadow: 0px 0px 0px 4px rgb(255 52 53 / 30%);
  }
  @media (max-width: 1400px) and (min-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 1200px) and (min-width: 991px) {
    width: 40%;
  }
`;

const PositionFixedRemoval = styled.div`
  max-width: 650px;
  @media (min-width: 1000px) {
    position: fixed;
  }
  @media (max-width: 999px) {
  }
`;

const HeightRemoval = styled.div`
  @media (min-width: 1000px) {
    height: 70vh;
  }
  @media (max-width: 999px) {
  }
`;

const ButtonStyle = styled.button`
  position: absolute;
  font-size: 12px;
  padding: 5px 10px;
  line-height: 1em;
  border: solid 1px #dddddd;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: none;
  margin-left: 1rem;
  &:hover {
    color: #fff;
    background: #8364e2;
    border: 0;
  }
`;

const FaIconStyle = styled.div`
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #8364e2 !important;
    box-shadow: 2px 2px 20px 0px #8364e2;
    transition: all 0.3s ease;
    i {
      color: #fff !important;
    }
  }
`;

const Refresh = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
  margin-bottom: 1rem;
  &:hover {
    border: 1px solid #8364e2;
    color: #fff;
    background: #8364e2;
    box-shadow: 2px 2px 20px 0px #8364e2;
    transition: all 0.3s ease;
  }
  i {
    font-size: 1rem;
  }
`;

const TypeStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
`;
const TypeButtonStyle = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #a2a2a2;
  height: 80px;
  width: 100%;
  padding: 0px 5px;
  border-radius: 16px;
  transition: all 0.12s ease-in-out 0s;
  cursor: pointer;
  color: inherit;
  overflow: visible;
  background: transparent;
  &:hover {
    border: 1px solid #8364e2;
    color: #fff;
    background: #8364e2;
    box-shadow: 2px 2px 20px 0px #8364e2;
    transition: all 0.3s ease;
  }
`;
const {
  utils: {
    format: { formatNearAmount },
  },
} = nearAPI;

export default class Token extends Component {
  static contextType = appStore;

  constructor(props) {
    super(props);
    this.state = {
      offerPrice: "",
      offerText: "",
      offerToken: "near",
      price: "",
      receiver: "",
      receiver2: "",
      ft: "near",
      openMenu: true,
      openMenu1: false,
      openMenu2: false,
      saleConditions: {},
      directSale: 1,
      auctionCondition: false,
      shareUrl: "",
      profileImg: `${websiteUrl}/img/author/author-11.jpg`,
      clickCount: 0,
      favCount: 0,
      royaltiesAmount: null,
      ShowCurrentOffer: [],
      royaltiesReciever: {},
      loadingImg: true,
      dotClick: false,
      shareTo: false,
      transferToken: false,
      mintSelfToken: false,
      removeToken: false,
      extraFeeBuy: false,
      extraFeeBuyOther: false,
      extraFeeOffer: false,
      setTokenPrice: false,
      setSeriesPrice: false,
      payStorageFee: false,
      showUnlockable: false,
      is_unlockable: false,
      /* ----------------------------- new code start ----------------------------- */
      showBuyFeedback: false,
      is_buyFeebdack: true,
      compressed_url: null,
      SuccessloadingImg: true,
      txn_Url: null,
      viewToken: true,
      newTokenUrl: null,
      /* ------------------------------ new code end ------------------------------ */
      unlockable_content: null,
      creater_id: null,
      removeInfo: false,
      copyText: "Copy Link",
      bidderImage: `${websiteUrl}/img/author/author-11.jpg`,
      copyClass: "fa fa-copy mx-2",
      copyClass1: "fa fa-copy mx-2",
      collectionData: [],
      collectionName: "",
      collectionDescription: "",
      collectionImage: "",
      collectionLogoCreate: "",
      collecectionCreateName: "",
      collectionCreateDesc: "",
      selectCollectionId: "",
      useCollectionName: "",
      useCollectionId: "",
      useCollectionDesc: "",
      useCollectionImage: "",
      uploadLogoCollection: false,
      activeIndex: 0,
      showModalCreateCollection: false,
      showModalCollection: false,
      error: false,
      sendData: [],
      BuyPrice: null,
      checked: true,
      dataVisiable: false,
      seriesList: [],
      modalShow: false,
      // my variables
      tokenId: this.props.tokenId,
      token_id: null,
      token_type_id: null,
      token: {},
      is_auction: null,
      metadata: null,
      previous_token: null,
      signedIn: null,
      new_copy: null,
      copy: null,
      imageType: true,
      videoType: false,
      FileUrl: null,
      edition: null,
      FileExtension: null,
      ItemDescription: null,
      accountId: null,
      tokenMintable: true,
      isStateLoaded: false,
      nft_contract_id: null,
      offerType: null,
      marketPaidStatus: false,
      isVerified: false,
      isFavourite: false,
      isNoDownloadble: false,
      no_downloadble_file: null,
      no_downloadble_cover: null,
      attributeReciever: {},
      // blobUrl:null
    };

    // console.log(this.state.tokenId)
  }

  async componentDidMount() {
    await Promise.all([this.context.dispatch(onAppMount())]);

    // console.log(this.context)
    let token_type_id = null;
    let token_id = null;
    let tokenNft2 = this.state.tokenId;
    // console.log("tokenNft2", tokenNft2)
    var NotMainToken2 = null;
    if (tokenNft2) {
      NotMainToken2 = tokenNft2.indexOf(":") > -1;
      if (NotMainToken2 === true) {
        token_id = tokenNft2;
        this.setState({ token_id: token_id });
      } else if (NotMainToken2 === false) {
        token_type_id = tokenNft2;
        this.setState({ token_type_id: token_type_id });
      }
    }

    let account = this.context.state.account;
    if (NotMainToken2 === true && token_id !== null) {
      await Promise.all([
        this.context.dispatch(getCurrentTokenInfo(account, token_id, false)),
        this.context.dispatch(
          getTokenSeriesInfo(
            account,
            token_id.split(":")[0].split(" ").join("")
          )
        ),
        this.context.dispatch(hasStorageBalance(this.context.state.account)),
      ]);
    } else if (NotMainToken2 === false && token_type_id !== null) {
      await Promise.all([
        this.context.dispatch(
          getCurrentTokenInfo(account, token_type_id, true)
        ),
        this.context.dispatch(hasStorageBalance(this.context.state.account)),
        this.context.dispatch(
          isMintable(this.context.state.account, token_type_id)
        ),
        this.context.dispatch(getTokenSeriesInfo(account, token_type_id)),
      ]);
    }

    // console.log(this.context.state.views)

    let token = this.context.state.views.tokenAllInfo;
    if (token !== null) {
      if (typeof token.conditions === "undefined") {
        token.conditions = {};
      }
    } else {
      // todo: handle if token is not present
    }

    this.setState({ token: token });

    let edition = null;
    let copy = null;
    let tokenMintable = true;

    if (token_type_id) {
      edition = token_type_id;
      this.setState({ edition: edition });
    } else if (token_id) {
      const edition_and_copy = decodeURIComponent(token_id).split(":");
      edition = edition_and_copy[0].split(" ").join("");
      copy = edition_and_copy[1].split(" ").join("");
      this.setState({ copy: copy, edition: edition });
    }

    // const { state } = useContext(appStore);
    // const { views, wallet } = state;
    // console.log('views', views);
    const {
      marketStoragePaid,
      tokenAllInfo,
      tokenSeriesData,
      marketPaidStatus,
      mintable,
      tokens,
      contract_owner: nft_contract_id,
    } = this.context.state.views;
    // console.log('views again', this.context.state.views)
    this.setState({ nft_contract_id: contractId });
    const { accountId } = this.context.state.account || {};
    // console.log('accountId', accountId)
    this.setState({ accountId: accountId });

    if (tokenAllInfo && token_type_id != null) {
      const isTokenMintable = tokenAllInfo.metadata.copies - parseInt(mintable);
      if (isTokenMintable === 0) {
        tokenMintable = false;
        this.setState({ tokenMintable: false });
      } else {
        tokenMintable = true;
        this.setState({ tokenMintable: true });
      }
    }

    let offerType = null;
    const signedIn =
      this.context.state.wallet && this.context.state.wallet.signedIn;
    this.setState({ signedIn: signedIn });
    let imageType = true;
    let videoType = false;
    // console.log('views', views);

    let FileExtension = null;
    let FileUrl = null;

    const new_copy = copy ? copy : "0";
    this.setState({ new_copy: new_copy });
    //current copy end

    this.handleShareFacebook();
    this.Clicks_count(tokenNft2, new_copy);

    await this.fetchClick(tokenNft2, new_copy);

    if (this.state.is_auction) {
      this.setState({ directSale: 2, auctionCondition: true });
    }
    // return () => (document.body.style.overflow = "scroll");

    this.fetchOffer(tokenNft2);
    this.fetchTranTxn(mintable, this.state.tokenId);
    if (signedIn) {
      this.fetchFavourite(accountId, tokenNft2);
    }

    // if (bids) {
    //     Object.entries(bids).map(([ft_token_id, ft_token_bids]) => {
    //       fetchBidderData(ft_token_bids.owner_id);
    //     });
    //   }
    var lookup = {};
    var items = tokenSeriesData;
    var owner_list = [];

    for (var item, i = 0; (item = items[i++]); ) {
      var owner = item.owner_id;

      if (!(owner in lookup)) {
        lookup[owner] = 1;
        owner_list.push(owner);
      }
    }

    // console.log('result', owner_list)

    const owner_profile_images = await axios.post(
      `${apiUrl}/api/v1/multiple_user_profile_pic`,

      {
        address: owner_list,
      }
    );
    // console.log('owner_profile_images', owner_profile_images)
    var seriesOwnerData = [];
    var profile_images_array = owner_profile_images.data.data;
    // console.log('tokenSeriesData again', tokenSeriesData)
    if (owner_profile_images.data.status === "success") {
      for (var i = 0; i < tokenSeriesData.length; i++) {
        for (var j = 0; j < profile_images_array.length; j++) {
          // console.log(i, tokenSeriesData)
          if (tokenSeriesData[i].owner_id === profile_images_array[j].address) {
            // console.log('inside if')
            var obj = tokenSeriesData[i];
            if (profile_images_array[j].profile_pic !== null) {
              if (profile_images_array[j].profile_pic.startsWith("./img")) {
                obj["profile_pic"] =
                  `${websiteUrl}/` +
                  profile_images_array[j].profile_pic.substring(1);
              } else {
                obj["profile_pic"] = profile_images_array[j].profile_pic;
              }
            } else {
              obj["profile_pic"] = `${websiteUrl}/img/author/author-11.jpg`;
            }
            seriesOwnerData.push(obj);
            break;
          }
        }
      }
      // const newList = (owner_profile_images.data['results'].profile_pic);
      // console.log('owner_profile_images', owner_profile_images)
      // if (newList !== null) {
      //     if (newList.startsWith("./img")) {
      //         this.setState({ profileImg: "https://demo.uniqart.io/" + newList.substring(1) })
      //     } else {
      //         this.setState({ profileImg: newList })
      //     }
      // } else {
      //     this.setState({ profileImg: 'https://demo.uniqart.io/img/author/author-11.jpg' })
      // }
    } else {
      console.log("some error occured");
      // this.setState({ profileImg: 'https://demo.uniqart.io/img/author/author-11.jpg' })
    }

    this.setState({ seriesList: seriesOwnerData });
    // console.log('tokenSeriesData', tokenSeriesData)
    this.setState({ marketPaidStatus: marketPaidStatus });
    if (this.state.token.owner_id) {
      this.fetchData(this.state.token.owner_id);
      this.fetchCollectionData(this.state.token.owner_id);
    }

    if (this.state.token.price) {
      this.setState({ BuyPrice: formatNearAmount(toPlainString(token.price)) });
    }
    let metadata, previous_token;
    if (token_type_id) {
      offerType = "tokenType";
      const findToken = tokenAllInfo;
      metadata = tokenAllInfo.metadata;
      this.setState({ metadata: metadata, offerType: offerType });
    } else if (token_id) {
      offerType = "token";
      const MyToken = tokenAllInfo;
      metadata = tokenAllInfo.metadata;
      this.setState({ metadata: metadata, offerType: offerType });
    }
    let collection_name = null;
    let collection_id = null;
    let collection_logo = null;

    if (metadata.extra) {
      if (metadata.extra.includes("**")) {
        const myArray = metadata.extra.split("**");
        // console.log('array', myArray);
        this.setState({ FileUrl: myArray[1], FileExtension: myArray[0] });
        if (FileExtension) {
          imageType = this.isFileImage(FileExtension.toLowerCase());
          this.setState({ imageType: imageType });
        } else {
          imageType = true;
          FileExtension = "jpg";
          this.setState({ imageType: imageType, FileExtension: FileExtension });
        }
        if (FileExtension && imageType === false) {
          videoType = this.isFileVideo(FileExtension.toLowerCase());
          this.setState({ videoType: videoType });
        }
        // console.log('FileExtension', FileExtension)
        // console.log('imageType', imageType);
        if (metadata.description) {
          this.setState({ ItemDescription: metadata.description });
        } else {
          let fetchRes = fetch(metadata.extra);
          fetchRes
            .then((res) => res.json())
            .then((d) => {
              this.setState({ ItemDescription: d.description });
            })
            .catch((e) => {
              console.log("error in metadata loading", e);
            });
        }
      } else {
        let fetchRes = fetch(metadata.extra);
        fetchRes
          .then((res) => res.json())
          .then((d) => {
            // console.log('d', d)
            FileExtension = d.fileExtension;
            FileUrl = d.filesCover;
            this.setState({ FileUrl: FileUrl, FileExtension: FileExtension });
            collection_id = d.collection_id;
            collection_name = d.collection_name;
            collection_logo = d.collection_logo;
            if (metadata.description) {
              this.setState({ ItemDescription: metadata.description });
            } else {
              this.setState({ ItemDescription: d.description });
            }
            if (FileExtension) {
              imageType = this.isFileImage(FileExtension.toLowerCase());
              this.setState({ imageType: imageType });
            } else {
              imageType = true;
              FileExtension = "jpg";
              this.setState({
                imageType: imageType,
                FileExtension: FileExtension,
              });
            }
            if (FileExtension && imageType === false) {
              videoType = this.isFileVideo(FileExtension.toLowerCase());
              this.setState({ videoType: videoType });
            }
            // console.log('FileExtension', FileExtension)
            // console.log('imageType', imageType)
          })
          .catch((e) => {
            console.log("error in metadata loading", e);
          });
      }
    }

    if (metadata.title) {
      const new_value = metadata.title.split("—");
      previous_token = new_value[0].split(" ").join("");
      if (previous_token.startsWith("token-")) {
        this.setState({ previous_token: previous_token });
      } else {
        this.setState({ previous_token: this.state.tokenId });
      }
    }

    // console.log('metadata here', this.state.metadata)

    this.setState({
      isStateLoaded: true,
      receiver2: this.context.state?.account?.accountId,
    });

    const block_token_id = process.env.REACT_APP_BLOCK_TOKEN_DOWNLOAD;
    const no_download = block_token_id.split("***");
    no_download.map((value) => {
      const arrToken = value.split("**");
      if (token_type_id) {
        if (token_type_id === arrToken[0]) {
          document.addEventListener("contextmenu", (event) =>
            event.preventDefault()
          );
          document.getElementById("preventDrag").ondragstart = function () {
            return false;
          };
          this.setState({ isNoDownloadble: true });
          this.setState({ no_downloadble_file: arrToken[1] });
          this.setState({ no_downloadble_cover: arrToken[2] });
          this.setState({ imageType: arrToken[4] });
          this.setState({ videoType: arrToken[4] });
        }
      } else {
        if (token_id.split(":")[0] === arrToken[0]) {
          document.addEventListener("contextmenu", (event) =>
            event.preventDefault()
          );
          document.getElementById("preventDrag").ondragstart = function () {
            return false;
          };
          this.setState({ isNoDownloadble: true });
          this.setState({ no_downloadble_file: arrToken[1] });
          this.setState({ no_downloadble_cover: arrToken[2] });
          this.setState({ imageType: arrToken[4] });
          this.setState({ videoType: arrToken[4] });
        }
      }
    });
  }

  isFileImage(file) {
    const acceptedImageTypes = [
      "gif",
      "jpeg",
      "png",
      "webp",
      "jpg",
      "avif",
      "apng",
      "svg+xml",
      "bmp",
      "x-icon",
      "tiff",
    ];
    return file && acceptedImageTypes.includes(file);
  }

  isFileVideo(file) {
    const acceptedVideoTypes = [
      "webm",
      "mkv",
      "flv",
      "vob",
      "ogv",
      "ogg",
      "rrc",
      "gifv",
      "mng",
      "mov",
      "avi",
      "qt",
      "wmv",
      "yuv",
      "rm",
      "asf",
      "amv",
      "mp4",
      "m4p",
      "m4v",
      "mpg",
      "mp2",
      "mpeg",
      "mpe",
      "mpv",
      "m4v",
      "svi",
      "3gp",
      "3g2",
      "mxf",
      "roq",
      "nsv",
      "flv",
      "f4v",
      "f4p",
      "f4a",
      "f4b",
      "mod",
    ];
    return file && acceptedVideoTypes.includes(file);
  }

  handleBtnClick = () => {
    this.setState({ openMenu: !this.state.openMenu });
    this.setState({ openMenu1: false, openMenu2: false });
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
  };
  handleBtnClick1 = () => {
    this.setState({ openMenu1: !this.state.openMenu1 });
    this.setState({ openMenu: false, openMenu2: false });
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
  };

  handleOwnerTabClick() {
    // console.log('button clicked')
    this.setState({ openMenu2: !this.state.openMenu2 });
    this.setState({ openMenu: false, openMenu1: false });
    document.getElementById("Mainbtn2").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
  }

  async handleShareFacebook() {
    const ShareData =
      window.location.origin +
      `/token/${encodeURIComponent(this.state.tokenId)}`;
    this.setState({ shareUrl: ShareData });
  }
  async fetchData(owner_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/fetch_profile_pic?address=` + owner_id
      );
      if (result.data["status"] === "Success") {
        const newList = result.data["results"].profile_pic;
        const verified = result.data["results"].verified;
        if (newList !== null) {
          if (newList.startsWith("./img")) {
            this.setState({
              profileImg: `${websiteUrl}/` + newList.substring(1),
            });
          } else {
            this.setState({ profileImg: newList });
          }
          // console.log(newList, "newList");
        } else {
          this.setState({
            profileImg: `${websiteUrl}/img/author/author-11.jpg`,
          });
        }
        if (verified) {
          this.setState({ isVerified: true });
        } else {
          this.setState({ isVerified: false });
        }
      } else {
        this.setState({ profileImg: `${websiteUrl}/img/author/author-11.jpg` });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async fetchOffer(token_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/fetch_offer?token_id=` + token_id
      );
      if (result.data["status"] === "success") {
        const newList = result.data["result"];
        if (newList !== null) {
          this.setState({ ShowCurrentOffer: newList });
          const findOfferToken = newList.filter(
            ({ buyer_id }) => this.state.accountId === buyer_id
          );
          if (findOfferToken.length > 0) {
            this.setState({
              offerPrice: findOfferToken[0].price,
              offerText: findOfferToken[0].price,
            });
          }
        } else {
          this.setState({ ShowCurrentOffer: [] });
        }
      } else {
        this.setState({ ShowCurrentOffer: [] });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async fetchTranTxn(mintable, tokenId) {
    try {
      var url_string = window.location.href;
      var url = new URL(url_string);
      var TX_HASH = url.searchParams.get("transactionHashes");
      if (url_string.indexOf("transactionHashes") > -1 && TX_HASH && tokenId) {
        console.log(TX_HASH, "result TXN", mintable, tokenId);
        this.setState({ showBuyFeedback: true });
        if (tokenId.indexOf(":") > -1) {
          this.setState({ viewToken: false });
        } else {
          this.setState({ newTokenUrl: `../token/${tokenId}:${mintable}` });
        }
        this.setState({ txn_Url: TX_HASH });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async fetchFavourite(accountId, token_id) {
    const rawLikeData = JSON.stringify({
      address: accountId,
      nft_token: token_id,
    });
    fetch(`${apiUrl}/api/v1/status_favourite_nft`, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: rawLikeData,
    })
      .then((response) => response.text())
      .then((responseText) => {
        const result = JSON.parse(responseText).status;
        // console.log(result, "result");
        if (result === "Success") {
          this.setState({ isFavourite: true });
        } else {
          this.setState({ isFavourite: false });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async fetchClick(tokenNft2, new_copy) {
    // console.log('fetchClick', true);
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/get_nft_token?nft_token_id=${tokenNft2}&sell_id=${tokenNft2}&current_copy=${new_copy}`
      );
      if (result.data["status"] === "success") {
        // console.log("result", result);
        this.setState({ sendData: result.data["data"][0] });
        const fetchPrice = result.data["data"][0].min_sell_price;
        const compressed_url_link = result.data["data"][0].compressed_url;
        const FileLink = result.data["data"][0].media_url;
        if (compressed_url_link) {
          this.setState({ compressed_url: compressed_url_link });
        }
        if (fetchPrice > 0) {
          this.setState({
            BuyPrice: result.data["data"][0].min_sell_price,
            is_auction: result.data["data"][0].is_auction,
            is_unlockable: result.data["data"][0].is_unlockable,
          });
        } else {
          this.setState({
            BuyPrice: null,
            is_auction: result.data["data"][0].is_auction,
            is_unlockable: result.data["data"][0].is_unlockable,
          });
        }
        // console.log('Buy_price', BuyPrice)
        const count = result.data["data"][0].total_clicks;
        const fav = result.data["data"][0].fav_count;
        const royalty = result.data["data"][0].royalty;
        const royalty_address = result.data["data"][0].royalty_address;
        const attributes = result.data["data"][0].attributes;
        const collection_id = result.data["data"][0].collection_id;
        const collection_name = result.data["data"][0].collection_name;
        const collection_desc = result.data["data"][0].collection_desc;
        const collection_logo = result.data["data"][0].collection_logo;
        const unlockable_data = result.data["data"][0].unlockable_desc;
        const creater_address = result.data["data"][0].creater_id;
        // Decrypt
        if (unlockable_data) {
          var bytes = CryptoJS.AES.decrypt(
            unlockable_data,
            process.env.REACT_APP_UNLOCKABLE_SECRET_KEY
          );
          var originalText = bytes.toString(CryptoJS.enc.Utf8);
          this.setState({ unlockable_content: originalText });
        }

        if (count) {
          this.setState({ clickCount: count });
        }
        if (fav) {
          this.setState({ favCount: fav });
        }
        if (creater_address) {
          this.setState({ creater_id: creater_address });
        }
        if (royalty_address) {
          this.setState({
            royaltiesAmount: royalty,
            royaltiesReciever: royalty_address,
          });
        }
        if (attributes) {
          this.setState({
            attributeReciever: attributes,
          });
        }
        if (collection_id) {
          this.setState({ useCollectionId: collection_id });
        }
        if (collection_name) {
          this.setState({ useCollectionName: collection_name });
        }
        if (collection_desc) {
          this.setState({ useCollectionDesc: collection_desc });
        }
        if (collection_logo) {
          this.setState({ useCollectionImage: collection_logo });
        }
        // if(FileLink){
        //   const getMedia = async (data) => {
        //     try {
        //       const resp = await axios.get(`${data}`, {
        //         responseType: 'blob',
        //       });
        //       const fileType = await FileType.fromBlob(resp.data);
        //       const objectUrl = URL.createObjectURL(resp.data);
        //       // console.log(objectUrl,fileType,'file-type');
        //       this.setState({blobUrl: objectUrl});
        //     } catch (err) {
        //       console.log(err,'err');
        //       this.setState({blobUrl: data});
        //     }
        //   }
        //   getMedia(FileLink);
        // }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async Clicks_count(tokenNft2, new_copy) {
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/add_clicks?nft_token_id=${tokenNft2}&sell_id=${tokenNft2}&current_copy=${new_copy}`
      );
      // console.log(result)
      if (result.data["status"] === "success") {
        console.log("click count successfully");
      } else {
        console.log("click not count");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async fetchBidderData(bidder_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/fetch_profile_pic?address=` + bidder_id
      );
      if (result.data["status"] === "Success") {
        const newList = result.data["results"].profile_pic;
        if (newList !== null) {
          this.setState({ bidderImage: newList });
        } else {
          this.setState({
            bidderImage: `${websiteUrl}/img/author/author-11.jpg`,
          });
        }
      } else {
        this.setState({
          bidderImage: `${websiteUrl}/img/author/author-11.jpg`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  setRefreshData = () => {
    window.location.reload();
  };

  handleShare = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const ShareData =
      window.location.origin +
      `/token/${encodeURIComponent(this.state.tokenId)}`;
    const result = share(ShareData);
    if (!result.mobile) {
      this.context.dispatch(snackAttack("Link Copied!"));
    }
    this.setState({ copyText: "Copied" });
    setTimeout(this.setText, 3000);
  };

  setText = () => {
    this.setState({ copyText: "Copy Link" });
  };

  copyMediaData(mediaData) {
    share(mediaData ? mediaData : "");
    this.setState({ copyClass: "fa fa-check mx-2" });
    setTimeout(this.setCopyClassFunction, 3000);
  }

  copyContractData(contractData) {
    share(contractData ? contractData : "");
    this.setState({ copyClass1: "fa fa-check mx-2" });
    setTimeout(this.setCopyClassFunction1, 3000);
  }

  setCopyClassFunction() {
    this.setState({ copyClass: "fa fa-copy mx-2" });
  }

  setCopyClassFunction1() {
    this.setState({ copyClass1: "fa fa-copy mx-2" });
  }

  setBidType = (index) => {
    this.setState({ directSale: index });
    if (index === 1) {
      this.setState({ auctionCondition: false });
    } else if (index === 2) {
      this.setState({ auctionCondition: true });
    }
  };

  async uploadImageIPFSLogo(files) {
    try {
      const added = await client.add(files);
      const v0 = CID.parse(added.path);
      const encodePath = v0.toV1().toString();
      const url = `https://ipfs.io/ipfs/${encodePath}`;
      // console.log(url);
      this.setState({ collectionLogoCreate: url });
    } catch (error) {
      console.log("Error uploading file: ", error);
      this.setState({ error: true });
    }
  }
  async fetchCollectionData(owner_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/fetch_collections?user_id=` + owner_id
      );
      if (result.data["status"] === "success") {
        const newList = result.data["result"];
        if (newList !== null) {
          this.setState({ collectionData: newList });
          // console.log('collectonData', newList)
          // for (var i = 0; i < newList.length; i++) {
          //     if (newList[i].collection_id == this.state.useCollectionId) {
          //         console.log('activeIndex', i)
          //         this.setState({ activeIndex: i });
          //         break;
          //     }
          // }
        }
      }
    } catch (error) {
      console.error(error);
    }
    // console.log(collectionData);
  }

  CreateCollection(
    collectionLogoCreate,
    collecectionCreateName,
    collectionCreateDesc,
    user
  ) {
    if (
      collectionLogoCreate &&
      collecectionCreateName &&
      collectionCreateDesc &&
      user
    ) {
      const CollectionData = JSON.stringify({
        name: collecectionCreateName,
        logo: collectionLogoCreate,
        description: collectionCreateDesc,
        user_id: user,
      });
      fetch(`${apiUrl}/api/v1/create_collection`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: CollectionData,
      })
        .then((response) => response.text())
        .then((responseText) => {
          // console.log(responseText);
          this.fetchCollectionData(user);
          this.setState({
            collectionLogoCreate: "",
            collecectionCreateName: "",
            collectionCreateDesc: "",
            uploadLogoCollection: false,
          });
          this.handleCloseCreateCollection();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("* Please fill all required details.");
    }
  }

  handleSubmit() {
    this.render();
  }

  setCheckboxData() {
    // console.log('cheked', this.state.checked)
    // console.log('receiver2', this.state.receiver2)
    if (this.state.checked) {
      if (this.state.signedIn) {
        this.setState({ receiver2: "", dataVisiable: true, checked: false });
      } else {
        this.setState({ receiver2: "", dataVisiable: true, checked: false });
      }
    } else {
      if (this.state.signedIn) {
        this.setState({
          dataVisiable: false,
          checked: true,
          receiver2: this.context.state.account.accountId,
        });
      } else {
        this.setState({ dataVisiable: false, receiver2: "", checked: true });
      }
    }
  }
  // useEffect(() => {
  //     if (checked) {
  //         setDataVisiable(false);
  //         if (signedIn) {
  //             setReceiver2(account.accountId);
  //         } else {
  //             setReceiver2('');
  //         }
  //     } else {
  //         setDataVisiable(true);
  //         setReceiver2('');
  //     }
  // }, [checked, account, signedIn])

  handleClose2 = () => {
    this.setState({ dotClick: false });
  };
  handleShareTo = () => {
    this.setState({ shareTo: false });
  };
  handleTransferToken = () => {
    this.setState({ transferToken: false });
  };
  handleMintSelfToken = () => {
    this.setState({ mintSelfToken: false });
  };
  handleRemoveToken = () => {
    this.setState({ removeToken: false });
  };
  handleExtraFee = () => {
    this.setState({ extraFeeBuy: false });
  };
  handleExtraFeeOffer = () => {
    this.setState({ extraFeeOffer: false });
  };
  handleTokenPrice = () => {
    this.setState({ setTokenPrice: false });
  };
  handleSeriesPrice = () => {
    this.setState({ setSeriesPrice: false });
  };
  handlePayStorageFee = () => {
    this.setState({ payStorageFee: false });
  };
  handleShowUnlockable = () => {
    this.setState({ showUnlockable: false });
  };
  /* ----------------------------- new code start ----------------------------- */
  handleShowBuyFeedback = () => {
    navigate(`../token/${this.state.tokenId}`);
    this.setState({ showBuyFeedback: false });
  };
  /* ------------------------------ new code end ------------------------------ */
  handleExtraFeeOther = () => {
    this.setState({ extraFeeBuyOther: false });
  };
  handleInfo = () => {
    this.setState({ removeInfo: false });
  };

  handleCloseCreateCollection = () => {
    this.setState({ showModalCreateCollection: false });
  };

  handleCloseCollection = () => {
    this.setState({ showModalCollection: false });
  };

  uploadLogo = (event) => {
    this.uploadImageIPFSLogo(event.target.files[0]);
    this.setState({
      uploadLogoCollection: URL.createObjectURL(event.target.files[0]),
    });
  };

  handleShowCreateCollection = () => {
    this.setState({ showModalCreateCollection: true });
  };

  handleBtnClick2 = (index, name, description, image, collectionId) => {
    this.setState({
      activeIndex: index,
      collectionName: name,
      collectionDescription: description,
      collectionImage: image,
      selectCollectionId: collectionId,
    });
  };

  handleBtnClick3 = (
    selectCollectionId,
    collectionName,
    collectionDescription,
    collectionImage,
    previous_token,
    tokenId,
    copy
  ) => {
    if (
      selectCollectionId &&
      collectionName &&
      collectionDescription &&
      collectionImage &&
      previous_token &&
      tokenId &&
      copy
    ) {
      const raw = JSON.stringify({
        nft_token_id: previous_token,
        sell_id: tokenId,
        current_copy: copy,
        collection_id: selectCollectionId,
        collection_name: collectionName,
        collection_desc: collectionDescription,
        collection_logo: collectionImage,
      });
      fetch(`${apiUrl}/api/v1/update_nft_create`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: raw,
      })
        .then((response) => response.text())
        .then((responseText) => {
          // console.log(responseText);
          this.fetchClick(tokenId, this.state.new_copy);
          this.handleCloseCollection();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert(
        "* Choose any one or create new collection before click confirm button"
      );
    }
  };

  setFT(e) {
    this.setState({ ft: e });
  }

  setOfferToken(e) {
    this.setState({ offerToken: e });
  }

  getOfferPic(profile_pic) {
    if (profile_pic) {
      if (profile_pic.startsWith("./img")) {
        return `${websiteUrl}/` + profile_pic.substring(1);
      } else {
        return profile_pic;
      }
    } else {
      return `${websiteUrl}/img/author/author-11.jpg`;
    }
  }

  AddNotify = () =>
    toast.success("Added in Favourite!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  RemoveNotify = () =>
    toast.warn("Removed from Favourite!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  likeButtonPressed = async (accountId, token_id) => {
    const rawLikeData = JSON.stringify({
      address: accountId,
      nft_token: token_id,
    });

    if (!this.state.isFavourite) {
      fetch(`${apiUrl}/api/v1/add_favourite_nft`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: rawLikeData,
      })
        .then((response) => response.text())
        .then((responseText) => {
          // console.log(responseText);
          this.AddNotify();
          this.setState({ isFavourite: true });
          this.fetchClick(token_id, this.state.new_copy);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      fetch(`${apiUrl}/api/v1/delete_favourite_nft`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: rawLikeData,
      })
        .then((response) => response.text())
        .then((responseText) => {
          // console.log(responseText);
          this.RemoveNotify();
          this.setState({ isFavourite: false });
          this.fetchClick(token_id, this.state.new_copy);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  render() {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.state.isStateLoaded && (
          <div>
            <Helmet>
              <title>
                UniqART - {this.state.metadata.reference}{" "}
                {this.state.copy ? `#${this.state.copy}` : ""}
              </title>
              <link
                rel="canonical"
                href={`${websiteUrl}/token/${this.state.tokenId}`}
              />
              <meta
                property="og:title"
                content={`UniqART - ${this.state.metadata.reference} ${
                  this.state.copy ? `#${this.state.copy}` : ""
                }`}
              />
              <meta
                property="og:url"
                content={`${websiteUrl}/token/${this.state.tokenId}`}
              />
            </Helmet>
            <GlobalStyles />
            <section className="container mt-3 px-3">
              <div className="row mt-md-5 pt-md-4">
                <PositionFixedRemoval className="col-md-6 text-center d-flex justify-content-center align-items-center">
                  {!this.state.imageType ? (
                    <div
                      style={{ position: "relative" }}
                      className="w-100 h-100"
                    >
                      {!this.state.videoType ? (
                        <AudioPlayer
                          url={
                            this.state.isNoDownloadble
                              ? this.state.no_downloadble_file
                              : this.state.metadata.media
                          }
                          cover={
                            this.state.isNoDownloadble
                              ? this.state.no_downloadble_cover
                              : this.state.FileUrl
                          }
                        />
                      ) : (
                        <VideoApp
                          url={
                            this.state.isNoDownloadble
                              ? this.state.no_downloadble_file
                              : this.state.metadata.media
                          }
                          cover={
                            this.state.isNoDownloadble
                              ? this.state.no_downloadble_cover
                              : this.state.FileUrl
                          }
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        className="mt-5"
                        style={{
                          display: this.state.loadingImg ? "block" : "none",
                          zIndex: "0",
                          width: "100%",
                        }}
                      >
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                          display="flex"
                        >
                          <Skeleton height={600} />
                        </SkeletonTheme>
                      </div>
                      <HeightRemoval
                        style={{
                          display: this.state.loadingImg ? "none" : "flex",
                          alignItems: "center",
                          maxHeight: "400px",
                        }}
                        className="mt-5"
                      >
                        <img
                          src={
                            this.state.isNoDownloadble
                              ? this.state.no_downloadble_file
                              : this.state.metadata.media
                          }
                          style={{
                            maxWidth: "100%",
                            maxHeight: "400px",
                            display: "block",
                            borderRadius: "10px",
                            border: "1px solid #f9f9f9",
                          }}
                          className="img-fluid img-rounded mb-sm-30"
                          alt=""
                          onLoad={() => this.setState({ loadingImg: false })}
                          id="preventDrag"
                        />
                      </HeightRemoval>
                    </>
                  )}
                </PositionFixedRemoval>
                <div className="col-md-5 offset-md-7">
                  <div className="item_info p-0">
                    <div className="d-flex justify-content-center align-items-center mb-2">
                      {this.state.is_unlockable && (
                        <>
                          <i
                            className="fa fa-lock"
                            data-tip="Includes unlockable content"
                            style={{
                              fontSize: "1.5rem",
                              marginRight: "0.5rem",
                            }}
                          ></i>
                          <ReactTooltip
                            textColor="#000"
                            backgroundColor="#fff"
                            className="tooltipStyle"
                          />
                        </>
                      )}
                      <p
                        style={{
                          width: "80%",
                          marginBottom: "0",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (this.state.token_type_id) {
                            navigate(`token/${this.state.token_type_id}`);
                          }
                        }}
                      >
                        {this.state.token_type_id
                          ? `SERIES  #${this.state.edition}`
                          : `SERIES  #${this.state.token_id
                              .split(":")[0]
                              .split(" ")
                              .join("")} // NFT #${this.state.copy} of ${
                              this.state.metadata.copies
                            }`}
                      </p>
                      <span
                        className="d-flex justify-content-center"
                        style={{ width: "20%", transform: "rotate(90deg)" }}
                        onClick={() => this.setState({ dotClick: true })}
                      >
                        <svg
                          style={{ cursor: "pointer" }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                        >
                          <path
                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                            stroke="#ffffff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    {this.state.metadata.copies == 1 ? (
                      <h2
                        className="text-capitalize"
                        style={{ wordBreak: "break-all" }}
                      >
                        {this.state.metadata.reference}
                      </h2>
                    ) : (
                      <h2
                        className="text-capitalize"
                        style={{ wordBreak: "break-all" }}
                      >
                        {this.state.metadata.reference}{" "}
                        {this.state.copy ? `#${this.state.copy}` : ""}
                      </h2>
                    )}

                    {this.state.creater_id && this.state.token_id && (
                      <h5 className="text-truncate">
                        <span style={{ color: "#a2a2a2", fontSize: "1rem" }}>
                          Created by
                        </span>{" "}
                        <span
                          onClick={() =>
                            navigate(`../user/${this.state.creater_id}`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {this.state.creater_id}
                        </span>
                      </h5>
                    )}

                    <div className="item_info_counts flex-wrap justify-content-around justify-content-lg-start">
                      <FaIconStyle className="item_info_type my-2">
                        <i className="fa fa-file"></i>
                        {this.state.FileExtension}
                      </FaIconStyle>
                      <FaIconStyle className="item_info_views my-2">
                        <i className="fa fa-eye"></i>
                        {this.state.clickCount}
                      </FaIconStyle>
                      <FaIconStyle
                        className="item_info_like my-2"
                        onClick={(e) => this.handleShare(e)}
                      >
                        <i className="fa fa-copy"></i>
                      </FaIconStyle>
                      {this.state.signedIn ? (
                        <>
                          <FaIconStyle
                            className="item_info_like my-2"
                            onClick={(e) =>
                              this.likeButtonPressed(
                                this.state.accountId,
                                this.state.tokenId
                              )
                            }
                          >
                            <i
                              className={
                                this.state.isFavourite
                                  ? "fa fa-heart favourite-Active"
                                  : "fa fa-heart"
                              }
                            ></i>{" "}
                            {this.state.favCount}
                          </FaIconStyle>
                        </>
                      ) : (
                        <>
                          <FaIconStyle
                            className="item_info_like my-2"
                            style={{ cursor: "default" }}
                          >
                            <i className="fa fa-heart"></i>{" "}
                            {this.state.favCount}
                          </FaIconStyle>
                        </>
                      )}

                      <FaIconStyle
                        className="item_info_like my-2"
                        onClick={() => this.setState({ removeInfo: true })}
                      >
                        <i className="fa fa-info-circle"></i>
                      </FaIconStyle>
                    </div>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {this.state.ItemDescription}
                    </p>
                    {this.state.token_id && (
                      <div>
                        <h4>Owner</h4>
                        <div className="item_author">
                          <div className="author_list_pp">
                            <span
                              onClick={() =>
                                navigate(`../user/${this.state.token.owner_id}`)
                              }
                            >
                              <img
                                className="lazy"
                                src={this.state.profileImg}
                                alt=""
                              />
                              {this.state.isVerified && (
                                <i className="fa fa-check"></i>
                              )}
                            </span>
                          </div>
                          <div
                            className="author_list_info"
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              className="d-block text-truncate"
                              style={{ maxWidth: "17rem" }}
                              onClick={() =>
                                navigate(`../user/${this.state.token.owner_id}`)
                              }
                            >
                              {this.state.token.owner_id}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.token_type_id && (
                      <div>
                        <h4>Creator</h4>
                        <div className="item_author">
                          <div className="author_list_pp">
                            <span
                              onClick={() =>
                                navigate(`../user/${this.state.creater_id}`)
                              }
                            >
                              <img
                                className="lazy"
                                src={this.state.profileImg}
                                alt=""
                              />
                              {this.state.isVerified && (
                                <i className="fa fa-check"></i>
                              )}
                            </span>
                          </div>
                          <div
                            className="author_list_info"
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              className="d-block text-truncate"
                              style={{ maxWidth: "17rem" }}
                              onClick={() =>
                                navigate(`../user/${this.state.creater_id}`)
                              }
                            >
                              {this.state.creater_id}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="spacer-40"></div>
                    {
                      <>
                        {this.state.royaltiesReciever &&
                          Object.keys(this.state.royaltiesReciever).length >
                            0 && (
                            <>
                              <h4>Royalties</h4>
                              {Object.entries(this.state.royaltiesReciever)
                                .sort(([, a], [, b]) => b - a)
                                .map(([receiver, amount], index) => (
                                  <>
                                    <p key={v4()}>
                                      {receiver} - {amount / 100}%
                                    </p>
                                  </>
                                ))}
                            </>
                          )}
                        {/* test start */}
                        <h4>Copies</h4>
                        <p>{this.state.metadata.copies}</p>
                        {/* Extra code Add start */}
                        <h4>Collection</h4>
                        <p className="text-capitalize">
                          <span
                            className="collectionStyle"
                            onClick={() =>
                              navigate(
                                `../user-collection/${this.state.useCollectionId}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.useCollectionName
                              ? this.state.useCollectionName
                              : "New Collection"}
                          </span>
                          {this.state.accountId ===
                            this.state.token.owner_id && (
                            <span
                              className="mx-3"
                              style={{
                                cursor: "pointer",
                                color: "#8364e2",
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                this.setState({ showModalCollection: true });
                              }}
                            >
                              Change
                            </span>
                          )}
                        </p>
                        {this.state.is_unlockable &&
                          this.state.accountId ===
                            this.state.token.owner_id && (
                            <>
                              <h4
                                className="my-4 hiddenContent"
                                style={{
                                  border: "1px solid #f1f1f1",
                                  padding: "1.5rem",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                                onClick={() =>
                                  this.setState({ showUnlockable: true })
                                }
                              >
                                <i
                                  className="fa fa-lock"
                                  style={{ marginRight: "1rem" }}
                                ></i>
                                Reveal unlockable content
                              </h4>
                            </>
                          )}
                        {/* Attribute code start */}
                        <AttributeComponent sell_id= {this.state.tokenId} collection_id={this.state.useCollectionId} />
                        {/* Attributes code end */}
                        <div className="my-3">
                          <h4>Token Info</h4>
                          {this.state.nft_contract_id && (
                            <div
                              className="d-flex mt-2"
                              style={{
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <p
                                className="text-capitalize p-0 m-0"
                                style={{
                                  color: "#e9ecef",
                                  width: "30%",
                                  textAlign: "left",
                                }}
                              >
                                Smart Contract
                              </p>
                              <p
                                className="p-0 my-0 text-truncate"
                                style={{
                                  fontWeight: "bold",
                                  maxWidth: "185px",
                                }}
                              >
                                {this.state.nft_contract_id}
                              </p>
                              <span className="copyBtnStyleSingleItem">
                                <i
                                  className={this.state.copyClass1}
                                  onClick={() => {
                                    this.copyContractData(
                                      this.state.nft_contract_id
                                    );
                                  }}
                                ></i>
                              </span>
                            </div>
                          )}
                          {!this.state.isNoDownloadble && (
                            <div
                              className="d-flex mb-2"
                              style={{
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <p
                                className="text-capitalize p-0 m-0"
                                style={{
                                  color: "#e9ecef",
                                  width: "30%",
                                  textAlign: "left",
                                }}
                              >
                                File Link
                              </p>
                              <p
                                className="p-0 my-0 text-truncate"
                                style={{
                                  fontWeight: "bold",
                                  maxWidth: "185px",
                                }}
                              >
                                {this.state.metadata.media}
                              </p>
                              <span className="copyBtnStyleSingleItem">
                                <i
                                  className={this.state.copyClass}
                                  onClick={() => {
                                    this.copyMediaData(
                                      this.state.metadata.media
                                    );
                                  }}
                                ></i>
                              </span>
                            </div>
                          )}
                        </div>
                        {/* extra code Add End */}
                        {this.state.BuyPrice > 0 && (
                          <>
                            <h4>
                              {this.state.is_auction
                                ? "Fixed Price"
                                : "Fixed Price"}
                            </h4>
                            {
                              <p
                                className="margin-bottom"
                                style={{
                                  fontSize: "1.3rem",
                                  fontWeight: "bold",
                                }}
                                key={
                                  "near&" +
                                  Math.floor(100000 + Math.random() * 900000)
                                }
                              >
                                {this.state.price === "0"
                                  ? "0"
                                  : this.state.BuyPrice}{" "}
                                {"Ⓝ"}
                              </p>
                            }
                          </>
                        )}
                        {this.state.accountId === this.state.token.owner_id && (
                          // this.state.marketPaidStatus ?
                          <>
                            {this.state.token_type_id ? (
                              this.state.tokenMintable && (
                                <>
                                  <div className="spacer-10"></div>
                                  <div className="d-flex justify-content-left setMobileUI">
                                    <NftStyle
                                      type="number"
                                      min="0"
                                      placeholder="Price"
                                      value={this.state.price}
                                      onChange={(e) =>
                                        this.setState({ price: e.target.value })
                                      }
                                    />
                                    {getTokenOptions(this.state.ft, this.setFT)}
                                    <div
                                      className="btn-main mx-2"
                                      style={{
                                        padding: "6px 40px",
                                        lineHeight: "2.5rem",
                                        width: "49%",
                                      }}
                                      onClick={() =>
                                        this.setState({ setSeriesPrice: true })
                                      }
                                    >
                                      <span>
                                        {this.state.BuyPrice > 0
                                          ? "Update price"
                                          : "Sell on marketplace"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )
                            ) : (
                              <>
                                <div className="spacer-10"></div>
                                <div className="d-flex justify-content-left setMobileUI">
                                  <NftStyle
                                    type="number"
                                    min="0"
                                    placeholder="Price"
                                    value={this.state.price}
                                    onChange={(e) =>
                                      this.setState({ price: e.target.value })
                                    }
                                    style={{ width: "60%" }}
                                  />
                                  {getTokenOptions(this.state.ft, this.setFT)}
                                  <div
                                    className="btn-main mx-2"
                                    style={{
                                      padding: "6px 40px",
                                      lineHeight: "2.5rem",
                                      width: "49%",
                                    }}
                                    onClick={() =>
                                      this.setState({ setTokenPrice: true })
                                    }
                                  >
                                    <span>
                                      {this.state.BuyPrice > 0
                                        ? "Update price"
                                        : "Sell on marketplace"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="mb-3 mt-2">
                              {/* <i style={{ fontSize: "0.75rem" }}>
																Note: price 0 means open offers.
															</i> */}
                            </div>
                            <div className="spacer-10"></div>
                            {!this.state.tokenMintable && (
                              <div>
                                <p>
                                  Congratulations! All tokens in this series are
                                  now minted. Check the owners section below if
                                  you are interest to buy from this collection.
                                </p>
                                <div className="spacer-10"></div>
                              </div>
                            )}
                          </>
                          // :
                          // <>
                          // 	<div className="spacer-40"></div>
                          // 	<div className="d-flex justify-content-left mb-3">
                          // 		<div className="btn-main mx-2" style={{
                          // 			padding: "6px 40px",
                          // 			lineHeight: "2.5rem",
                          // 			width: '100%'
                          // 		}}
                          // 			onClick={() => this.setState({ payStorageFee: true })}
                          // 		>
                          // 			<span>Register with Market to Sell</span>
                          // 		</div>
                          // 	</div>
                          // 	<div className="spacer-40"></div>
                          // </>
                        )}
                        {this.state.signedIn ? (
                          this.state.BuyPrice > 0 && (
                            <>
                              {this.state.BuyPrice > 0 && (
                                <>
                                  {this.state.accountId.length > 0 &&
                                    this.state.accountId !==
                                      this.state.token.owner_id && (
                                      <>
                                        {this.state.tokenMintable && (
                                          <>
                                            <h4>Buy Type</h4>
                                            <TypeStyle
                                              className={
                                                Object.keys(
                                                  this.state.token.conditions
                                                ).length > 0
                                                  ? "my-4 bid-disabled"
                                                  : "my-4"
                                              }
                                            >
                                              <TypeButtonStyle
                                                type="button"
                                                onClick={() =>
                                                  this.setBidType(1)
                                                }
                                                className={
                                                  this.state.directSale === 1
                                                    ? "bidActive"
                                                    : "unactive"
                                                }
                                                {...(this.state.is_auction
                                                  ? "disabled"
                                                  : "")}
                                              >
                                                <span
                                                  aria-hidden="true"
                                                  className="icon_tag_alt mb-2"
                                                  style={{ fontSize: "1.5rem" }}
                                                />
                                                <div>
                                                  <span>Fixed price</span>
                                                </div>
                                              </TypeButtonStyle>
                                              <TypeButtonStyle
                                                type="button"
                                                onClick={() =>
                                                  this.setBidType(2)
                                                }
                                                className={
                                                  this.state.directSale === 2
                                                    ? "bidActive"
                                                    : "unactive"
                                                }
                                                {...(this.state.is_auction
                                                  ? ""
                                                  : "disabled")}
                                              >
                                                <span
                                                  aria-hidden="true"
                                                  className="icon-pencil mb-2"
                                                  style={{ fontSize: "1.5rem" }}
                                                />
                                                <div>
                                                  <span>Give an Offer</span>
                                                </div>
                                              </TypeButtonStyle>
                                            </TypeStyle>
                                          </>
                                        )}
                                        {this.state.tokenMintable &&
                                          (this.state.auctionCondition ? (
                                            <>
                                              <div className="spacer-20"></div>
                                              <div className="d-flex justify-content-left mb-3 setMobileUI">
                                                <NftStyle
                                                  type="number"
                                                  min="0"
                                                  placeholder="Price"
                                                  value={this.state.offerPrice}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      offerPrice:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                                {getTokenOptions(
                                                  this.state.offerToken,
                                                  this.setOfferToken,
                                                  Object.keys(
                                                    this.state.token.conditions
                                                  )
                                                )}
                                                <div
                                                  className="btn-main mx-2"
                                                  style={{
                                                    padding: "6px 40px",
                                                    lineHeight: "2.5rem",
                                                    width: "40%",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      extraFeeOffer: true,
                                                    })
                                                  }
                                                >
                                                  <span>
                                                    {this.state.offerText !== ""
                                                      ? "Update Offer"
                                                      : "Offer"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="mb-1">
                                                <i
                                                  style={{
                                                    fontSize: "0.75rem",
                                                  }}
                                                >
                                                  Note: It will take a couple of
                                                  minutes to reflect your NFT
                                                  data in the
                                                  Marketplace/Activity/My
                                                  Collection tabs.
                                                </i>
                                              </div>
                                              <div className="spacer-20"></div>
                                            </>
                                          ) : this.state.token_type_id ? (
                                            <>
                                              <div className="spacer-20"></div>
                                              <div className="d-flex justify-content-left mb-3">
                                                {getTokenOptions(
                                                  this.state.offerToken,
                                                  this.setOfferToken,
                                                  Object.keys(
                                                    this.state.token.conditions
                                                  )
                                                )}
                                                <div
                                                  className="btn-main mx-2"
                                                  style={{
                                                    padding: "6px 40px",
                                                    lineHeight: "2.5rem",
                                                    width: "100%",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      extraFeeBuy: true,
                                                    })
                                                  }
                                                >
                                                  <span>
                                                    Buy for{" "}
                                                    {this.state.BuyPrice} Ⓝ
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="mb-1">
                                                <i
                                                  style={{
                                                    fontSize: "0.75rem",
                                                  }}
                                                >
                                                  Note: It will take a couple of
                                                  minutes to reflect your NFT
                                                  data in the
                                                  Marketplace/Activity/My
                                                  Collection tabs.
                                                </i>
                                              </div>
                                              <div className="spacer-20"></div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="spacer-20"></div>
                                              <div className="d-flex justify-content-left mb-3">
                                                {getTokenOptions(
                                                  this.state.offerToken,
                                                  this.setOfferToken,
                                                  Object.keys(
                                                    this.state.token.conditions
                                                  )
                                                )}
                                                <div
                                                  className="btn-main mx-2"
                                                  style={{
                                                    padding: "6px 40px",
                                                    lineHeight: "2.5rem",
                                                    width: "100%",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      extraFeeBuyOther: true,
                                                    })
                                                  }
                                                >
                                                  <span>
                                                    Buy for{" "}
                                                    {this.state.BuyPrice} Ⓝ
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                      </>
                                    )}
                                </>
                              )}
                            </>
                          )
                        ) : (
                          <>
                            <div className="spacer-20"></div>
                            {this.state.tokenMintable ? (
                              <div
                                className="btn-main mx-2"
                                style={{
                                  padding: "6px 40px",
                                  lineHeight: "2.5rem",
                                  width: "100%",
                                }}
                                onClick={() =>
                                  this.setState({ modalShow: true })
                                }
                              >
                                <span>Buy Now</span>
                              </div>
                            ) : (
                              <div>
                                <p>
                                  Congratulations! All tokens in this series are
                                  now minted. Check the owners section below if
                                  you are interest to buy from this collection.
                                </p>
                                <div className="spacer-10"></div>
                              </div>
                            )}

                            <div className="spacer-40"></div>
                          </>
                        )}
                      </>
                    }

                    {/* test end */}
                    <div className="de_tab">
                      <ul className="de_nav">
                        <li id="Mainbtn" className="active">
                          <span onClick={this.handleBtnClick}>Offers</span>
                        </li>
                        <li id="Mainbtn1" className="">
                          <span onClick={this.handleBtnClick1}>History</span>
                        </li>
                        <li id="Mainbtn2" className="">
                          <span
                            onClick={() => {
                              // console.log("clicked");
                              this.handleOwnerTabClick();
                            }}
                          >
                            Owners
                          </span>
                        </li>
                      </ul>

                      <div className="de_tab_content">
                        {this.state.openMenu ? (
                          this.state.ShowCurrentOffer.length > 0 ? (
                            <>
                              <div className="tab-1 onStep fadeIn">
                                {this.state.ShowCurrentOffer.length > 0 && (
                                  <>
                                    {this.state.ShowCurrentOffer.map(
                                      (data, index) => (
                                        <div
                                          className="offers p_list"
                                          key={v4()}
                                        >
                                          <div className="p_list_pp">
                                            <span>
                                              <img
                                                className="lazy"
                                                src={this.getOfferPic(
                                                  data.profile_pic
                                                )}
                                                alt=""
                                              />
                                              {data.verified && (
                                                <i className="fa fa-check"></i>
                                              )}
                                            </span>
                                          </div>
                                          <div className="p_list_info d-flex">
                                            <p>
                                              Offer{" "}
                                              <b>
                                                {data.price === "0"
                                                  ? "open"
                                                  : data.price}
                                                {" Ⓝ "}
                                              </b>
                                              <span>
                                                by <b>{data.buyer_id}</b>
                                              </span>
                                            </p>
                                            {this.state.accountId ===
                                              this.state.token.owner_id &&
                                              this.state.tokenMintable && (
                                                <ButtonStyle
                                                  style={{
                                                    position: "relative",
                                                    marginTop: "0.3rem",
                                                    height: "1.7rem",
                                                  }}
                                                  className="ml-2"
                                                  onClick={() =>
                                                    acceptOffer(
                                                      this.context.state
                                                        .account,
                                                      this.state.tokenId,
                                                      this.state.offerToken,
                                                      data.price,
                                                      this.state.previous_token,
                                                      this.state.copy,
                                                      this.state.token.owner_id,
                                                      `${this.state.metadata.reference} #${this.state.copy}`,
                                                      this.state.is_auction,
                                                      this.state.sendData,
                                                      data.buyer_id,
                                                      this.state.offerType
                                                    )
                                                  }
                                                >
                                                  Accept
                                                </ButtonStyle>
                                              )}
                                            {this.state.accountId ===
                                              data.buyer_id && (
                                              <ButtonStyle
                                                style={{
                                                  position: "relative",
                                                  marginTop: "0.3rem",
                                                  height: "1.7rem",
                                                }}
                                                className="ml-2"
                                                onClick={() =>
                                                  deleteOffer(
                                                    this.context.state.account,
                                                    this.state.tokenId,
                                                    this.state.offerToken,
                                                    data.price,
                                                    this.state.previous_token,
                                                    this.state.copy,
                                                    this.state.token.owner_id,
                                                    `${this.state.metadata.reference} #${this.state.copy}`,
                                                    this.state.is_auction,
                                                    this.state.sendData,
                                                    data.buyer_id,
                                                    this.state.offerType
                                                  )
                                                }
                                              >
                                                Cancel
                                              </ButtonStyle>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <p>No offer available for this NFT.</p>
                          )
                        ) : (
                          ""
                        )}

                        {this.state.openMenu1 && (
                          <div className="tab-2 onStep fadeIn">
                            <p>This feature will be available soon.</p>
                          </div>
                        )}

                        {this.state.openMenu2 && (
                          <div className="tab-2 onStep fadeIn">
                            <div style={{ display: "block" }}>
                              {this.state.seriesList &&
                              this.state.seriesList.length > 0 ? (
                                this.state.seriesList &&
                                this.state.seriesList.map((data, index) => (
                                  <div className="my-2" key={v4()}>
                                    <div
                                      style={{
                                        boxShadow:
                                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                        borderRadius: "10px",
                                      }}
                                      className="py-2 px-3"
                                    >
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                                          <div className="item_author">
                                            <div className="author_list_pp">
                                              <span
                                                onClick={() =>
                                                  navigate(
                                                    `../user/${data.owner_id}`
                                                  )
                                                }
                                              >
                                                <img
                                                  className="lazy"
                                                  src={data.profile_pic}
                                                  alt=""
                                                />
                                                {data.verified && (
                                                  <i className="fa fa-check"></i>
                                                )}
                                              </span>
                                            </div>
                                            <div
                                              className="author_list_info"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <span
                                                className="d-block text-truncate"
                                                style={{ maxWidth: "17rem" }}
                                                onClick={() =>
                                                  navigate(
                                                    `../user/${data.owner_id}`
                                                  )
                                                }
                                              >
                                                {data.owner_id}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                          <h4 className="mx-3">
                                            Edition #
                                            {data.token_id
                                              .split(":")[1]
                                              .split(" ")
                                              .join("")}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="row d-flex justify-content-center align-items-center mt-1">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                          <h5
                                            style={{
                                              color: "#adb5bd",
                                              fontSize: "1rem",
                                            }}
                                            className="pt-3 mx-2"
                                          >
                                            Owner of NFT #{data.token_id}
                                          </h5>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                          <div
                                            className="btn-main mx-2"
                                            style={{ padding: "6px 40px" }}
                                            onClick={() =>
                                              navigate(
                                                `../token/${data.token_id}`
                                              )
                                            }
                                          >
                                            View
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <>
                                  <p>No owners available for this NFT.</p>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoginModalNew
                show={this.state.modalShow}
                onHide={() => this.setState({ modalShow: false })}
              />
            </section>
            <Modal
              show={this.state.dotClick}
              onHide={this.handleClose2}
              className="mint-model w-100"
              centered
            >
              <Modal.Body style={{ backgroundColor: "#212428" }}>
                <h4
                  className="mb-3 mt-2 modal_click"
                  style={{ cursor: "pointer", fontWeight: "100" }}
                  onClick={(e) => this.handleShare(e)}
                >
                  {this.state.copyText}
                </h4>
                <h4
                  className="my-3 modal_click"
                  style={{ cursor: "pointer", fontWeight: "100" }}
                  onClick={() => this.setState({ shareTo: true })}
                >
                  Share to...
                </h4>
                {this.state.accountId === this.state.token.owner_id &&
                  this.state.token_id && (
                    <h4
                      className="my-3 modal_click"
                      style={{ cursor: "pointer", fontWeight: "100" }}
                      onClick={() => this.setState({ transferToken: true })}
                    >
                      Transfer Token
                    </h4>
                  )}
                {this.state.accountId === this.state.token.owner_id &&
                  this.state.token_type_id &&
                  this.state.tokenMintable && (
                    <h4
                      className="my-3 modal_click"
                      style={{ cursor: "pointer", fontWeight: "100" }}
                      onClick={() => this.setState({ mintSelfToken: true })}
                    >
                      Mint to myself
                    </h4>
                  )}
                {this.state.accountId === this.state.token.owner_id &&
                  this.state.token_id && (
                    <h4
                      className="mt-3 modal_click"
                      style={{ cursor: "pointer", fontWeight: "100" }}
                      onClick={() => this.setState({ removeToken: true })}
                    >
                      Remove Token
                    </h4>
                  )}
                <h4
                  className="mt-3 modal_click"
                  style={{ cursor: "pointer", fontWeight: "100" }}
                  onClick={this.setRefreshData}
                >
                  Refresh Data
                </h4>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.shareTo}
              onHide={this.handleShareTo}
              className="mint-model w-100"
              centered
            >
              <Modal.Body style={{ backgroundColor: "#212428" }}>
                <Modal.Header style={{ backgroundColor: "#212428" }}>
                  <div>
                    <FacebookShareButton
                      className="Demo__some-network__share-button d-flex my-2"
                      url={this.state.shareUrl}
                      quote={this.state.metadata.reference}
                    >
                      <FacebookIcon size={32} round={true} />{" "}
                      <h4
                        className="mx-3"
                        style={{
                          cursor: "pointer",
                          fontWeight: "100",
                          marginTop: "2%",
                        }}
                      >
                        Facebook
                      </h4>
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={this.state.shareUrl}
                      title={this.state.metadata.reference}
                      className="Demo__some-network__share-button d-flex my-2"
                    >
                      <TwitterIcon size={32} round={true} />
                      <h4
                        className="mx-3"
                        style={{
                          cursor: "pointer",
                          fontWeight: "100",
                          marginTop: "3%",
                        }}
                      >
                        Twitter
                      </h4>
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={this.state.shareUrl}
                      title={this.state.metadata.reference}
                      className="Demo__some-network__share-button d-flex my-2"
                    >
                      <TelegramIcon size={32} round={true} />
                      <h4
                        className="mx-3"
                        style={{
                          cursor: "pointer",
                          fontWeight: "100",
                          marginTop: "3%",
                        }}
                      >
                        Telegram
                      </h4>
                    </TelegramShareButton>
                    <WhatsappShareButton
                      url={this.state.shareUrl}
                      title={this.state.metadata.reference}
                      separator=":: "
                      className="Demo__some-network__share-button d-flex my-2"
                    >
                      <WhatsappIcon size={32} round={true} />
                      <h4
                        className="mx-3"
                        style={{
                          cursor: "pointer",
                          fontWeight: "100",
                          marginTop: "3%",
                        }}
                      >
                        Whatsapp
                      </h4>
                    </WhatsappShareButton>
                  </div>
                </Modal.Header>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.transferToken}
              onHide={this.handleTransferToken}
              className="mint-model w-100"
              centered
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Confirm Transfer</h4>
                      <h5 className="mb20">
                        You are about to send{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference} #{this.state.copy}
                        </span>{" "}
                        to:
                      </h5>
                      <NftStyle
                        type="text"
                        placeholder="Account Id"
                        value={this.state.receiver}
                        onChange={(e) =>
                          this.setState({ receiver: e.target.value })
                        }
                        className="w-100 mb-2"
                        style={{ height: "3rem" }}
                      />
                      <p>
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button mb-2"
                    onClick={() => {
                      if (!this.state.receiver.length) {
                        return alert("Enter Account ID");
                      }
                      TransferToken(
                        this.context.state.account,
                        this.state.tokenId,
                        this.state.receiver,
                        this.state.sendData
                      );
                    }}
                  >
                    Transfer
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.mintSelfToken}
              onHide={this.handleMintSelfToken}
              className="mint-model w-100"
              centered
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Confirm Mint</h4>
                      <h5 className="mb20">
                        You are about to mint{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference}
                        </span>
                      </h5>
                      <Form.Check
                        className="mb-4 mt-1"
                        type="checkbox"
                        label="Mint to myself"
                        checked={this.state.checked}
                        onChange={() => {
                          this.setCheckboxData();
                        }}
                      />
                      {this.state.dataVisiable && (
                        <NftStyle
                          type="text"
                          placeholder="Account Id"
                          value={this.state.receiver2}
                          onChange={(e) =>
                            this.setState({ receiver2: e.target.value })
                          }
                          className={
                            this.state.dataVisiable ? "w-100 mb-2" : "d-none"
                          }
                          style={{ height: "3rem" }}
                          required
                        />
                      )}
                      <p>
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button mb-2"
                    onClick={() => {
                      if (!this.state.receiver2.length) {
                        return alert("Enter Account ID");
                      }
                      SelfMint(
                        this.context.state.account,
                        this.state.tokenId,
                        this.state.receiver2,
                        this.state.sendData
                      );
                    }}
                  >
                    Mint
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.removeToken}
              onHide={this.handleRemoveToken}
              className="mint-model w-100"
              centered
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>
                        Confirm Remove Token
                      </h4>
                      <h5 className="mb20">
                        You are about to remove{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference} #{this.state.copy}
                        </span>{" "}
                        token.
                      </h5>
                      <p>
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button mb-2"
                    onClick={() => {
                      removeSale(
                        this.context.state.account,
                        this.state.tokenId
                      );
                    }}
                  >
                    Confirm
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.removeInfo}
              onHide={this.handleInfo}
              className="mint-model seriesInfoModal w-100"
              centered
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-12">
                    <span className="text-left">
                      <h4
                        style={{ fontSize: "1.7rem" }}
                        className="text-center"
                      >
                        Owners of {this.state.metadata.reference}{" "}
                        <span
                          onClick={() =>
                            navigate(
                              `../token/${
                                this.state.token_type_id
                                  ? this.state.tokenId
                                  : this.state.token_id
                                      .split(":")[0]
                                      .split(" ")
                                      .join("")
                              }`
                            )
                          }
                          style={{
                            cursor: "pointer",
                            color: "#8364e2",
                            fontWeight: "bold",
                          }}
                        >
                          #
                          {this.state.token_type_id
                            ? this.state.tokenId
                            : this.state.token_id
                                .split(":")[0]
                                .split(" ")
                                .join("")}
                        </span>
                      </h4>
                      <div
                        style={{
                          height: "29rem",
                          overflowX: "hidden",
                          display: "block",
                        }}
                      >
                        {this.state.seriesList &&
                        this.state.seriesList.length > 0 ? (
                          this.state.seriesList &&
                          this.state.seriesList.map((data, index) => (
                            <div className="my-2" key={v4()}>
                              <div
                                style={{ background: "#181b1f" }}
                                className="py-2 px-3"
                              >
                                <div className="row d-flex justify-content-center align-items-center">
                                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                                    {/* <img src={profileImg} alt='img' style={{ width: '50px', height: '50px', borderRadius: '50%' }}></img> */}
                                    <span>
                                      <h4
                                        className="text-truncate"
                                        style={{ maxWidth: "17rem" }}
                                      >
                                        {data.owner_id}
                                      </h4>
                                    </span>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                    <h4 className="mx-3">
                                      Edition #
                                      {data.token_id
                                        .split(":")[1]
                                        .split(" ")
                                        .join("")}
                                    </h4>
                                  </div>
                                </div>
                                <div className="row d-flex justify-content-center align-items-center mt-1">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5
                                      style={{
                                        color: "#adb5bd",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      Owner of NFT #{data.token_id}
                                    </h5>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                    <div
                                      className="btn-main mx-2"
                                      style={{ padding: "6px 40px" }}
                                      onClick={() =>
                                        navigate(`../token/${data.token_id}`)
                                      }
                                    >
                                      View
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <>
                            <h3 className="text-center my-5 pt-5">
                              No Info Found
                            </h3>
                          </>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showModalCreateCollection}
              onHide={this.handleCloseCreateCollection}
              className="mint-model w-100 setCollection"
              centered
              backdrop="static"
            >
              <Modal.Header style={{ backgroundColor: "#212428" }} closeButton>
                <h2 className="mb-0">Create Collection</h2>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#212428" }}>
                <h4 className="mb-0">Logo</h4>
                <div className="d-create-file mt-3 p-4">
                  <div className="browse">
                    {!this.state.uploadLogoCollection ? (
                      <>
                        <p>Upload Logo</p>
                        <input
                          type="button"
                          id="get_file"
                          className="btn-main"
                          value="Browse"
                        />
                        <input
                          id="upload_file"
                          type="file"
                          onChange={this.uploadLogo}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ textAlign: "right", fontSize: "12px" }}>
                          <span
                            aria-hidden="true"
                            className="icon_close text-danger"
                            style={{
                              marginRight: "-2rem",
                              fontSize: "2.5rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({ uploadLogoCollection: false });
                            }}
                          ></span>
                        </span>
                        <img
                          src={this.state.uploadLogoCollection}
                          style={{ width: "100%", objectFit: "cover" }}
                          className="lazy nft__item_preview"
                          alt="cover"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="spacer-20"></div>
                <h5>Name</h5>
                <input
                  type="text"
                  name="item_title"
                  id="item_title"
                  className="form-control form-highlight"
                  placeholder="e.g. 'Crypto Funk"
                  onChange={(e) =>
                    this.setState({ collecectionCreateName: e.target.value })
                  }
                />
                <div className="spacer-10"></div>
                <h5>Description</h5>
                <textarea
                  data-autoresize
                  name="item_desc"
                  id="item_desc"
                  className="form-control form-highlight"
                  placeholder="e.g. 'This is a limited item'"
                  onChange={(e) =>
                    this.setState({ collectionCreateDesc: e.target.value })
                  }
                ></textarea>
                <div className="spacer-10"></div>
                <div className="d-flex justify-content-left">
                  <Btn
                    className="text-center modal-body-button mb-3"
                    onClick={() => {
                      this.CreateCollection(
                        this.state.collectionLogoCreate,
                        this.state.collecectionCreateName,
                        this.state.collectionCreateDesc,
                        this.context.state.account.accountId
                      );
                    }}
                  >
                    Create
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showModalCollection}
              onHide={this.handleCloseCollection}
              centered
              className="confirm-model"
              backdrop="static"
            >
              <Modal.Header
                style={{ backgroundColor: "#212428" }}
                closeButton
              ></Modal.Header>
              <Modal.Body style={{ backgroundColor: "#212428" }}>
                <div
                  className="container confirm py-3"
                  style={{ backgroundColor: "#212428" }}
                >
                  <div className="row confirm-row">
                    <div className="col-md-6 col-lg-6 col-sm-12 confirm-img">
                      {this.state.activeIndex ? (
                        <div
                          className="shadow-lg bg-gray p-3"
                          style={{
                            border: "1px solid #343a40",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="nft__item_wrap my-4">
                            <span className="confirm-preview otherImage">
                              <img
                                src={this.state.collectionImage}
                                alt="img"
                                height="150"
                                width="150"
                                style={{
                                  borderRadius: "50%",
                                  height: "150px",
                                  width: "150px",
                                  objectFit: "cover",
                                }}
                              />
                            </span>
                          </div>
                          <div className="text-center">
                            <h3>{this.state.collectionName}</h3>
                            <span
                              style={{
                                height: "5.5rem",
                                overflowX: "hidden",
                                display: "block",
                              }}
                            >
                              <p>{this.state.collectionDescription}</p>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="shadow-lg bg-gray p-3"
                          style={{
                            border: "1px solid #343a40",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="nft__item_wrap my-4">
                            <span className="confirm-preview">
                              <img
                                src="https://via.placeholder.com/250x350/212428/e3e3e3?text=Collection Preview"
                                alt="img"
                                style={{ height: "300px", width: "100%" }}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 confirm-info d-flex align-items-center">
                      <div className="confirm-info-body w-100">
                        <h2 style={{ fontSize: "27px", lineHeight: "27px" }}>
                          Choose Collection
                        </h2>
                        <h6>
                          Choose any one or create new collection before create
                          NFT
                        </h6>
                        <div className="d-flex justify-content-left mt-4 collectionButton">
                          <div
                            className="text-center modal-body-button mb-3"
                            onClick={this.handleShowCreateCollection}
                          >
                            {" "}
                            + Create New Collection
                          </div>
                        </div>
                        <span
                          style={{
                            height: "13.5rem",
                            overflowX: "hidden",
                            display: "block",
                          }}
                        >
                          {this.state.collectionData.map((data, index) => (
                            <div
                              className="d-flex justify-content-left collectionButton"
                              key={v4()}
                            >
                              <div
                                className={
                                  this.state.activeIndex === index + 1
                                    ? "modal-body-button mb-3 d-flex justify-content-start text-center align-items-center p-1 collectionActive"
                                    : "modal-body-button mb-3 d-flex justify-content-start text-center align-items-center p-1"
                                }
                                onClick={() =>
                                  this.handleBtnClick2(
                                    index + 1,
                                    data.name,
                                    data.description,
                                    data.logo,
                                    data.collection_id
                                  )
                                }
                                style={{ border: "3px solid transparent" }}
                              >
                                <img
                                  src={data.logo}
                                  alt=""
                                  style={{ height: "40px", width: "40px" }}
                                />
                                <h6 className="p-0 m-0 mx-2 text-capitalize">
                                  {data.name}
                                </h6>
                              </div>
                            </div>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-8 col-lg-8 col-sm-12">
                      <div className="d-flex justify-content-left mt-4">
                        <Btn
                          className="text-center modal-body-button modal-body-cancel-button mb-3"
                          style={{
                            backgroundColor: "#343a40",
                            border: "none",
                            padding: "12px",
                          }}
                          onClick={() => this.handleCloseCollection()}
                        >
                          Close
                        </Btn>
                        <Btn
                          className={
                            this.state.selectCollectionId &&
                            this.state.collectionName &&
                            this.state.collectionDescription &&
                            this.state.collectionImage
                              ? "text-center modal-body-button mb-3 mx-2"
                              : "disabled text-center modal-body-button mb-3 mx-2"
                          }
                          style={{ padding: "12px" }}
                          onClick={() => {
                            this.handleBtnClick3(
                              this.state.selectCollectionId,
                              this.state.collectionName,
                              this.state.collectionDescription,
                              this.state.collectionImage,
                              this.state.previous_token,
                              this.state.tokenId,
                              this.state.new_copy
                            );
                          }}
                        >
                          Confirm
                        </Btn>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.extraFeeBuy}
              onHide={this.handleExtraFee}
              className="mint-model w-100"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Confirm Buy</h4>
                      <h5 className="mb20">
                        You are about to purchase{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference}
                        </span>
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div className="text-sm">Price</div>
                        <div className="text" style={{ fontWeight: "bold" }}>
                          {this.state.BuyPrice} Ⓝ
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="text-sm">Storage Fee</div>
                        <div className="text" style={{ fontWeight: "bold" }}>
                          {extraFee} Ⓝ
                        </div>
                      </div>
                      <hr className="mt-3 mb-1" />
                      <div className="d-flex justify-content-between">
                        <div className="text-sm">Total</div>
                        <div className="text" style={{ fontWeight: "bold" }}>
                          {(
                            parseFloat(this.state.BuyPrice) +
                            parseFloat(extraFee)
                          ).toFixed(8)}{" "}
                          Ⓝ
                        </div>
                      </div>
                      <p className="text-center my-3">
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button my-2"
                    onClick={() =>
                      handleOffer(
                        this.context.state.account,
                        this.state.tokenId,
                        this.state.offerToken,
                        this.state.BuyPrice,
                        this.state.previous_token,
                        this.state.copy,
                        this.state.token.owner_id,
                        `${this.state.metadata.reference} #${this.state.copy}`,
                        this.state.is_auction,
                        this.state.sendData
                      )
                    }
                  >
                    Confirm
                  </Btn>
                  <Btn
                    className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                    style={{
                      backgroundColor: "#343a40",
                      border: "none",
                      padding: "12px",
                    }}
                    onClick={() => this.handleExtraFee()}
                  >
                    Close
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.extraFeeBuyOther}
              onHide={this.handleExtraFeeOther}
              className="mint-model w-100"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Confirm Buy</h4>
                      <h5 className="mb20">
                        You are about to purchase{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference}
                        </span>
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div className="text-sm">Price</div>
                        <div className="text" style={{ fontWeight: "bold" }}>
                          {this.state.BuyPrice} Ⓝ
                        </div>
                      </div>
                      <p className="text-center my-3">
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button my-2"
                    onClick={() =>
                      otherUserBuy(
                        this.context.state.account,
                        this.state.tokenId,
                        this.state.offerToken,
                        this.state.BuyPrice,
                        this.state.previous_token,
                        this.state.copy,
                        this.state.token.owner_id,
                        `${this.state.metadata.reference} #${this.state.copy}`,
                        this.state.is_auction
                      )
                    }
                  >
                    Confirm
                  </Btn>
                  <Btn
                    className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                    style={{
                      backgroundColor: "#343a40",
                      border: "none",
                      padding: "12px",
                    }}
                    onClick={() => this.handleExtraFeeOther()}
                  >
                    Close
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.extraFeeOffer}
              onHide={this.handleExtraFeeOffer}
              className="mint-model w-100"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Confirm Offer</h4>
                      <h5 className="mb20">
                        You are about to give an offer on{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference}
                        </span>
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div className="text-sm">Offer</div>
                        <div className="text" style={{ fontWeight: "bold" }}>
                          {this.state.offerPrice} Ⓝ
                        </div>
                      </div>
                      {this.context.state.views.marketPaidStatus && (
                        <>
                          <div className="d-flex justify-content-between">
                            <div className="text-sm">Storage Fee</div>
                            <div
                              className="text"
                              style={{ fontWeight: "bold" }}
                            >
                              {offerextraFee} Ⓝ
                            </div>
                          </div>
                          <hr className="mt-3 mb-1" />
                          <div className="d-flex justify-content-between">
                            <div className="text-sm">Total</div>
                            <div
                              className="text"
                              style={{ fontWeight: "bold" }}
                            >
                              {(
                                parseFloat(this.state.offerPrice) +
                                parseFloat(offerextraFee)
                              ).toFixed(8)}{" "}
                              Ⓝ
                            </div>
                          </div>
                        </>
                      )}

                      <p className="text-center my-3">
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button my-2"
                    onClick={() =>
                      addOffer(
                        this.context.state.account,
                        this.state.tokenId,
                        this.state.offerToken,
                        this.state.offerPrice,
                        this.state.previous_token,
                        this.state.copy,
                        this.state.token.owner_id,
                        `${this.state.metadata.reference} #${this.state.copy}`,
                        this.state.is_auction,
                        this.context.state.views.marketPaidStatus,
                        this.state.offerText !== ""
                          ? "update_offer"
                          : "new_offer",
                        this.state.offerType
                      )
                    }
                  >
                    Confirm
                  </Btn>
                  <Btn
                    className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                    style={{
                      backgroundColor: "#343a40",
                      border: "none",
                      padding: "12px",
                    }}
                    onClick={() => this.handleExtraFeeOffer()}
                  >
                    Close
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.setTokenPrice}
              onHide={this.handleTokenPrice}
              className="mint-model w-100"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Confirm Price</h4>
                      <h5 className="mb20">
                        You are about to set{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.price} Ⓝ
                        </span>{" "}
                        price of{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference}
                        </span>
                      </h5>
                      {!this.state.marketPaidStatus ? (
                        <div>
                          {/* <div className="d-flex justify-content-between">
														<div className="text-sm">Fee</div>
														<div className="text" style={{ fontWeight: 'bold' }}>{nftApprove} Ⓝ</div>
													</div> */}
                          <div className="d-flex justify-content-between">
                            <div className="text-sm">Storage Fee</div>
                            <div
                              className="text"
                              style={{ fontWeight: "bold" }}
                            >
                              {(
                                parseFloat(nftApproveStorageDeposit) +
                                parseFloat(nftApprove)
                              ).toFixed(5)}{" "}
                              Ⓝ
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <div className="text-sm">Fee</div>
                          <div className="text" style={{ fontWeight: "bold" }}>
                            {nftApprove} Ⓝ
                          </div>
                        </div>
                      )}

                      <p className="text-center my-3">
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button my-2"
                    onClick={() => {
                      if (!this.state.price.length) {
                        return alert("Enter a price");
                      }
                      const newSaleConditions = {
                        ...this.state.saleConditions,
                        [this.state.ft]: parseNearAmount(this.state.price),
                      };
                      this.setState({
                        saleConditions: newSaleConditions,
                        price: "",
                        ft: "near",
                      });
                      otherUserSale(
                        this.context.state.account,
                        this.state.tokenId,
                        newSaleConditions,
                        this.state.auctionCondition,
                        this.state.token.conditions,
                        this.state.previous_token,
                        this.state.copy,
                        this.state.marketPaidStatus
                      );
                    }}
                  >
                    Confirm
                  </Btn>
                  <Btn
                    className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                    style={{
                      backgroundColor: "#343a40",
                      border: "none",
                      padding: "12px",
                    }}
                    onClick={() => this.handleTokenPrice()}
                  >
                    Close
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.setSeriesPrice}
              onHide={this.handleSeriesPrice}
              className="mint-model w-100"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Confirm Price</h4>
                      <h5 className="mb20">
                        You are about to set{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.price} Ⓝ
                        </span>{" "}
                        price of{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference}
                        </span>
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div className="text-sm">Fee</div>
                        <div className="text" style={{ fontWeight: "bold" }}>
                          1 yoctoNear
                        </div>
                      </div>
                      <p className="text-center my-3">
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button my-2"
                    onClick={() => {
                      if (!this.state.price.length) {
                        return alert("Enter a price");
                      }
                      const newSaleConditions = {
                        ...this.state.saleConditions,
                        [this.state.ft]: parseNearAmount(this.state.price),
                      };
                      this.setState({
                        saleConditions: newSaleConditions,
                        price: "",
                        ft: "near",
                      });
                      handleSaleUpdate(
                        this.context.state.account,
                        this.state.tokenId,
                        newSaleConditions,
                        this.state.auctionCondition,
                        this.state.BuyPrice,
                        this.state.previous_token,
                        this.state.copy
                      );
                    }}
                  >
                    Confirm
                  </Btn>
                  <Btn
                    className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                    style={{
                      backgroundColor: "#343a40",
                      border: "none",
                      padding: "12px",
                    }}
                    onClick={() => this.handleSeriesPrice()}
                  >
                    Close
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.payStorageFee}
              onHide={this.handlePayStorageFee}
              className="mint-model w-100"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Pay Storage Fee</h4>
                      <h5 className="mb20">
                        You are about to pay{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {storage_fee} Ⓝ{" "}
                        </span>{" "}
                        storage fee.
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div className="text-sm">Storage Fee</div>
                        <div className="text" style={{ fontWeight: "bold" }}>
                          {storage_fee} Ⓝ
                        </div>
                      </div>
                      <p className="text-center my-3">
                        You will be redirected to NEAR Web Wallet to confirm
                        your transaction.
                      </p>
                    </span>
                  </div>
                  <Btn
                    className="text-center modal-body-button my-2"
                    onClick={() =>
                      handleRegisterStorage(this.context.state.account)
                    }
                  >
                    Confirm
                  </Btn>
                  <Btn
                    className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                    style={{
                      backgroundColor: "#343a40",
                      border: "none",
                      padding: "12px",
                    }}
                    onClick={() => this.handlePayStorageFee()}
                  >
                    Close
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size="lg"
              show={this.state.showUnlockable}
              onHide={this.handleShowUnlockable}
              className="mint-model w-100 unlockableModal"
              centered
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12">
                    <h4 className="text-center" style={{ fontSize: "1.7rem" }}>
                      Unlockable Content
                    </h4>
                    <p
                      className="text-center my-3 mt-4"
                      style={{
                        border: "1px solid #fff",
                        borderRadius: "5px",
                        padding: "1.2rem",
                      }}
                    >
                      {this.state.unlockable_content}
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <Btn
                      className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                      style={{
                        backgroundColor: "#343a40",
                        border: "none",
                        padding: "12px",
                      }}
                      onClick={() => this.handleShowUnlockable()}
                    >
                      Close
                    </Btn>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showBuyFeedback}
              onHide={this.handleShowBuyFeedback}
              className="mint-model w-100"
              centered
              backdrop="static"
            >
              <Modal.Header closeButton className="p-0"></Modal.Header>
              <Modal.Body>
                <div className="row px-3 mb-2">
                  <div className="col-12 d-flex justify-content-center p-0">
                    <span className="text-left">
                      <h4 style={{ fontSize: "1.7rem" }}>Purchase Success</h4>
                      <h5 className="mb20">
                        You now own a{" "}
                        <span style={{ color: "#8364e2", fontWeight: "bold" }}>
                          {this.state.metadata.reference}
                        </span>
                      </h5>
                      <div
                        style={{
                          display: this.state.SuccessloadingImg
                            ? "block"
                            : "none",
                          zIndex: "0",
                          width: "100%",
                        }}
                      >
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                          display="flex"
                        >
                          <Skeleton height={290} />
                        </SkeletonTheme>
                      </div>
                      <div
                        className={
                          this.state.SuccessloadingImg
                            ? "d-none"
                            : "d-flex justify-content-center"
                        }
                      >
                        <CardImage
                          src={this.state.compressed_url}
                          alt=""
                          onLoad={() =>
                            this.setState({ SuccessloadingImg: false })
                          }
                        />
                      </div>
                      <TxnCard className="my-3 p-3">
                        <h5 style={{ color: "#a2a2a2" }}>Transaction hash</h5>
                        <h3
                          className="text-truncate mb-0"
                          style={{ width: "290px", cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              `${transTxnUrl}/transactions/${this.state.txn_Url}`
                            )
                          }
                        >
                          {this.state.txn_Url}
                        </h3>
                      </TxnCard>
                      {/* <p className='text-center my-3'>You will be redirected to NEAR Web Wallet to confirm your transaction.</p> */}
                    </span>
                  </div>
                  {this.state.viewToken && (
                    <Btn
                      className="text-center modal-body-button my-2"
                      onClick={() => navigate(this.state.newTokenUrl)}
                    >
                      View Token
                    </Btn>
                  )}
                  <Btn
                    className="text-center modal-body-button modal-body-cancel-button mb-3 my-2"
                    style={{
                      backgroundColor: "#343a40",
                      border: "none",
                      padding: "12px",
                    }}
                    onClick={() => this.handleShowBuyFeedback()}
                  >
                    Close
                  </Btn>
                </div>
              </Modal.Body>
            </Modal>
            {/* <Footer /> */}
          </div>
        )}

        {!this.state.isStateLoaded && (
          <div
            style={{ height: "100vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner>Loading...</Spinner>
          </div>
        )}
      </div>
    );
  }
}
