import { navigate } from "@reach/router";
import axios from 'axios';
import Compressor from 'compressorjs';
import { create } from "ipfs-http-client";
import { CID } from "multiformats/cid";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Spinner } from "reactstrap";
import styled from "styled-components";
import Getconfig from '../../config';
import { appStore, onAppMount } from "../../state/app";
import Footer from "../components/footer";
import LoginModal from './../components/LoginModal';
export const {
	websiteUrl, apiUrl, networkId
} = Getconfig();

const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET_KEY;
const auth ='Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const storage = window?.sessionStorage;


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  height: 100%;
`;

const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});


const maxFileSize = 30 * 1024 * 1024;

const EditCollection = (props) => {
	const { state, dispatch } = useContext(appStore);
	const { account, wallet } = state;
	const onMount = () => {
		dispatch(onAppMount());
		setTimeout(() => setActivityDataLoading(false), 3000)
	};
	const { register, handleSubmit, formState: { errors } } = useForm();

	useEffect(onMount, []);

	const [singleCollectionData, setSingleCollectionData] = useState([]);
	const [title, setTitle] = useState("");
	const [desc, setDesc] = useState("");
	const [loadingImg, setLoading] = useState(true);
	const [collectionImage, setCollectionImage] = useState('');
	const [compressedImage, setCompressedImage] = useState('');
	const [coverImage, setCoverImage] = useState(false);
	const [titleCharcount, setTitleCharCount] = useState(0);
	const [descCharcount, setDescCharCount] = useState(0);
	const [activityDataLoading, setActivityDataLoading] = useState(true);
	const [error, setError] = useState(false);
	const [modalShow, setModalShow] = useState(true);
	const [currentUser, setCurrentUser] = useState(null);
	const [collectionOwner, setCollectionOwner] = useState(null);
	const [collectionId, setCollectionId] = useState(null);
	const [buttonActive, setButtonActive] = useState(false);
	const [cursorSet, setCursor] = useState('pointer');


	async function fetchCollectionData(collection_id) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_single_collections?collection_id=" + collection_id);
			if (result.data["status"] === "success") {
				const newList = (result.data['result'][0]);
				setSingleCollectionData(newList);
				setTitle(newList.name);
				setCollectionOwner(newList.user_id);
				setTitleCharCount(newList.name.length);
				setDesc(newList.description);
				setDescCharCount(newList.description.length);
				setCollectionImage(newList.logo);
			} else {
				setSingleCollectionData([]);
			}
		} catch (error) {
			console.error(error);
		}
	}


	async function uploadCompressedImage(files) {
		setCursor('notAllowed');
		try {
			setButtonActive(true);
			const added = await client.add(files);
			const v0 = CID.parse(added.path);
			const encodePath = v0.toV1().toString();
			const url = `https://ipfs.io/ipfs/${encodePath}`;
			console.log(url);
			setCompressedImage(url)
			setButtonActive(false);
			setCursor('pointer');
		} catch (error) {
			console.log("Error uploading file: ", error);
			setError(true);
		}
	}

	useEffect(() => {
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			setCurrentUser(user);
			setCollectionId(props.collectionId);
			fetchCollectionData(props.collectionId);
		}
	}, [wallet]);


	async function inputEvent(event) {
		if (event.target.value.length <= 50) {
			setTitleCharCount(event.target.value.length);
			setTitle(event.target.value);
		} else {
			setTitle((event.target.value).slice(0, 50));
			setTitleCharCount(50);
			document.getElementById('item_title').value = (event.target.value).slice(0, 50);
		}
	}

	async function inputEventDesc(event) {
		if (event.target.value.length <= 100) {
			setDescCharCount(event.target.value.length);
			setDesc(event.target.value);
		} else {
			setDesc((event.target.value).slice(0, 100));
			setDescCharCount(100);
			document.getElementById('item_desc').value = (event.target.value).slice(0, 100);
		}
	}

	function uploadCover(event) {
		const fileCoverData = event.target.files[0];
		if (fileCoverData.size > maxFileSize) {
			alert('*Please upload less than 30 mb file.');
			return false;
		} else {
			new Compressor(fileCoverData, {
				quality: 0.2,
				success(result) {
					uploadCompressedImage(result);
				},
				error(err) {
					console.log(err.message);
				},
			});
			setCoverImage(URL.createObjectURL(fileCoverData));
		}
	}


	function formSubmit(event) {
		event.preventDefault()
		if (title !== "" && desc !== "" && (coverImage) && (compressedImage) && (currentUser) && (collectionId)) {
			const CollectionData = JSON.stringify(
				{
					"name": title,
					"logo": compressedImage,
					"description": desc,
					"user_id": currentUser,
					"collection_id": collectionId
				}
			);
			fetch(apiUrl + "/api/v1/update_collection", {
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json', }),
				body: CollectionData
			}).then((response) => response.text())
				.then((responseText) => {
					console.log(responseText);
					navigate(storage.getItem('prevPath'))
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			alert('Please fill up the details');
		}
	}

	if (activityDataLoading) {
		return (
			<div
				style={{ height: "100vh" }}
				className="d-flex align-items-center justify-content-center"
			>
				<Spinner>Loading...</Spinner>
			</div>
		)
	} else {


		return (
			(wallet && wallet.signedIn) ?
				<div>
					<Helmet>
						<title>UniqART - Edit Collection</title>
						<link rel="canonical" href={websiteUrl + "/editCollection"} />
						<meta property="og:title" content={`UniqART - Edit Collection`} />
						<meta property="og:url" content={`${websiteUrl}/editCollection`} />
					</Helmet>
					{activityDataLoading && (
						<div
							style={{ height: "100vh" }}
							className="d-flex align-items-center justify-content-center"
						>
							<Spinner>Loading...</Spinner>
						</div>
					)}
					{error ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100vh",
								width: "100vw",
								position: "fixed",
								backgroundColor: "black",
								zIndex: 1111,
							}}
						>
							<div
								style={{
									backgroundColor: "white",
									padding: "50px",
									borderRadius: "50px",
								}}
							>
								<h4 style={{ color: "black" }}>Failed To Upload!</h4>
								<input
									type="button"
									id="submit"
									className="btn-main"
									value="Try Again!"
									style={{
										marginRight: "auto",
										marginLeft: "auto",
										marginTop: "20px",
									}}
									onClick={() => window.location.reload()}
								/>
							</div>
						</div>
					) : (
						""
					)}

					<section className="jumbotron breadcumb no-bg mt-auto mt-lg-5">
						<div
							className="mainbreadcumb create_breadcumb_mobile"
							style={{ padding: "100px 110px 60px 100px" }}
						>
							<div className="container">
								<div className="row m-10-hor">
									<div className="col-12">
										<h1 className="text-center">Edit Collection</h1>
									</div>
								</div>
							</div>
						</div>
					</section>
					{collectionOwner === currentUser ? <section className="container">
						<div className="row">
							<div className="col-lg-7 offset-lg-1 mb-5">
								<form id="form-create-item" className="form-border" action="#" onSubmit={formSubmit}>
									<div className="field-set">
										<h5>Upload file</h5>
										{
											<div className="d-create-file mt-3">
												<div className="browse d-flex justify-content-center">
													{!coverImage ? (
														<>

															<input
																type="button"
																id="get_file2"
																className="btn-main"
																value="Browse"
															/>
															<input
																id="upload_file"
																type="file"
																onChange={uploadCover}
																accept="image/png, image/gif, image/jpeg"
															/>
														</>
													) : (
														<div
															style={{ display: "flex", flexDirection: "column" }}
														>
															<span
																style={{ textAlign: "right", fontSize: "12px" }}

															>
																<span aria-hidden="true" className="icon_close text-danger" style={{ marginRight: '-2rem', fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => {
																	setCoverImage(false);
																}}></span>
															</span>
															<img
																src={coverImage}
																className="lazy nft__item_preview nft__item_preview22"
																alt="cover"
															/>
														</div>
													)}
												</div>
											</div>
										}

										<div className="spacer-single"></div>
										<h5 className="d-flex justify-content-between"><span>Name</span> <span style={{ color: '#6c757d', fontSize: '1rem' }}>{titleCharcount}/50 </span></h5>
										<input
											type="text"
											name="item_title"
											id="item_title"
											className="form-control form-highlight"
											value={title}
											placeholder="e.g. 'Crypto Funk"
											onChange={inputEvent}

										/>

										<div className="spacer-10"></div>
										<h5 className="d-flex justify-content-between"><span>Description</span><span style={{ color: '#6c757d', fontSize: '1rem' }}>{descCharcount}/100 </span></h5>
										<textarea
											data-autoresize
											name="item_desc"
											id="item_desc"
											className="form-control form-highlight"
											placeholder="e.g. 'This is very limited item'"
											onChange={inputEventDesc}
											value={desc}
										></textarea>

										<div className="spacer-10"></div>
										<input
											type="submit"
											id="submit"
											className="btn-main"
											value="Edit Collection"
											style={{
												marginRight: "auto",
												marginLeft: "auto",
												marginTop: "20px",
												cursor: cursorSet,
											}}
											disabled={buttonActive}
										/>
									</div>
								</form>
							</div>
							<div className="col-lg-3 col-sm-6 col-xs-12">
								<h5>Collection Preview</h5>
								<div className="nft__item">
									<span className="text-center">
										<div className="nft__item_wrap loaderSet">
											<span style={{ display: loadingImg ? "block" : "none", zIndex: "0" }}>
												<SkeletonTheme baseColor="#202020" highlightColor="#444" display='flex'>
													<Skeleton height={190} />
												</SkeletonTheme>
											</span>
											<Outer>
												<span style={{ display: loadingImg ? "none" : "block" }}>
													{
														<img style={{ cursor: "pointer", objectFit: 'cover' }} onLoad={() => setLoading(false)} src={coverImage ? coverImage : collectionImage} className="lazy nft__item_preview" alt="" />
													}
												</span>
											</Outer>
										</div>
										<div className="nft__item_info">
											<span>
												<h3 className='text-truncate' style={{ textTransform: "capitalize" }}>{title ? title : 'New Collection'}</h3>
												<p className='text-truncate mb-1'>{desc}</p>
											</span>
											{/* test start */}
											<div className="nft__item_action">
												Collection by <span>{currentUser ? currentUser : singleCollectionData.user_id}</span>
											</div>
											<p className='mt-2' style={{ fontWeight: 'bold' }}>0 items</p>
										</div>
									</span>
								</div>
							</div>
						</div>
					</section> : <div className="justify-content-center align-items-center text-center" style={{ height: '70vh', paddingTop: '10rem' }}>
						<h2>You are not Collection Owner !</h2>
						<p>Only Collection Owner can change the collection Data.</p>
					</div>}

					<Footer />
				</div>
				: <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
		);
	}
};

export default EditCollection;