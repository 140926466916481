import { navigate } from "@reach/router";
import React, { memo, useState, useContext, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from "styled-components";
import { FaEdit } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { appStore } from "../../state/app";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  height: 100%;
`;
//react functional component
const CollectionCard = ({ collection_id, name, description, logo, user_id, createdAtpath, path, nft_count, className = 'd-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4', height }) => {
	const { state } = useContext(appStore);
	const { account, wallet } = state;
	const [loadingImg, setLoading] = useState(true);
	const [isCollectionUser, setIsCollectionUser] = useState(false);

	useEffect(() => {
		if (wallet && wallet.signedIn && account.accountId === user_id) {
			setIsCollectionUser(true);
		} else {
			setIsCollectionUser(false);
		};
	}, [wallet]);

	return (
		<div className={className} key={collection_id}>
			<div className="nft__item">
				<span className="text-center">
					<div className="nft__item_wrap loaderSet" style={{ height: `${height}px` }}>
						{
							isCollectionUser &&
							<div
								style={{
									top: 10,
									right: 10,
									position: "absolute",
									cursor: "pointer",
									background: "#8364e27a",
									display: "flex",
									alignItems: "center",
									padding: "5px",
									borderRadius: "5px",
								}}
								onClick={() => {
									navigate(`/editCollection/${collection_id}`)
								}}
								data-tip="Edit Collection"
							>
								<FaEdit style={{ color: "#fff" }} />
								<ReactTooltip textColor='#000' backgroundColor='#fff' className='tooltipStyle' />
							</div>
						}
						<span style={{ display: loadingImg ? "block" : "none", zIndex: "0" }}>
							<SkeletonTheme baseColor="#202020" highlightColor="#444" display='flex'>
								<Skeleton height={300} />
							</SkeletonTheme>
						</span>
						<Outer>
							<span style={{ display: loadingImg ? "none" : "block" }}>
								{
									<img style={{ cursor: "pointer", objectFit: 'cover' }} onLoad={() => setLoading(false)} src={logo} className="lazy nft__item_preview" alt="" onClick={() => {
										navigate(path)
									}} />
								}
							</span>
						</Outer>
					</div>
					<div className="nft__item_info">
						<span onClick={() => {
							navigate(path)
						}}>
							<h3 className='text-truncate' style={{ textTransform: "capitalize" }}>{name ? name : 'New Collection'}</h3>
							<p className='text-truncate mb-1'>{description}</p>
						</span>
						{/* test start */}
						<div className="nft__item_action">
							Collection by <span onClick={() => {
								navigate(path)
							}}>{user_id}</span>
						</div>
						<p className='mt-2' style={{ fontWeight: 'bold' }}>{nft_count} items</p>
					</div>
				</span>
			</div>
		</div>
	);
};

export default memo(CollectionCard);