/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { parseNearAmount, token2symbol } from "../../state/near";
import { getMarketStoragePaid, loadItems } from "../../state/views";
import NftCardMarket from "./NftCardMarket";
import Getconfig from "../../config";
export const { websiteUrl, apiUrl } = Getconfig();

const ColumnNewThreeColReduxUserType = ({
  views,
  loading,
  contractAccount,
  account,
  dispatch,
  userInfo,
  collectionIdNew,
  filters,
  trait_type,
  trait_value,
}) => {
  if (!contractAccount) return null;
  filters =`collections=${collectionIdNew}&category=All&sell_type=All&price=All&verified=All&sort=All`;
  const [filterchange, setFilterChange] = useState([]);
  const [loadmorecountfliter, setloadmorecountfilter] = useState(0);
  const [filteritemsStatus, setFilterItemsStatus] = useState(true);
  const [userVerified, setUserVerified] = useState(false);
  const [profileImg2, setProfileImg2] = useState(
    websiteUrl + "/img/author/author-11.jpg"
  );

  const fetchnftCatdata = async (
    collectionIdNew,
    collectionId,
    loadmorecountfliter
  ) => {
    try {
      const result = await axios.get(
        `${apiUrl}/api/v1/nfts_with_rarity?trait_type=${trait_type}&value=${trait_value}&collection_id=${collectionIdNew}`
      );
      if (result.data["status"] === "Success") {
        const newListFilter = result.data["data"];
        setFilterChange(newListFilter);
      } else {
        setFilterItemsStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchData(user) {
    try {
      const result = await axios.get(
        apiUrl + "/api/v1/fetch_profile_pic?address=" + user
      );
      if (result.data["status"] === "Success") {
        const newList = result.data["results"];
        if (newList !== null) {
          setProfileImg2(newList.profile_pic);
          setUserVerified(newList.verified);
        } else {
          setProfileImg2(websiteUrl + "/img/author/author-11.jpg");
          setUserVerified(false);
        }
      } else {
        setProfileImg2(websiteUrl + "/img/author/author-11.jpg");
        setUserVerified(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [height, setHeight] = useState(0);

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };
  useEffect(() => {
    if (!loading) {
      dispatch(loadItems(account));
      dispatch(getMarketStoragePaid(account));
    }
  }, [loading]);

  const { marketStoragePaid } = views;

  let accountId = "";
  if (account) accountId = account.accountId;

  useEffect(() => {
    if (filters !== "") {
      setFilterItemsStatus(true);
      filterchange.length = 0;
      setloadmorecountfilter(0);
      fetchnftCatdata(collectionIdNew, filters, 0);
      setloadmorecountfilter(0);
      fetchData(userInfo);
    }
  }, [filters]);

  useEffect(() => {
    if (filters !== "") {
      if (loadmorecountfliter > 0) {
        fetchnftCatdata(collectionIdNew, filters, loadmorecountfliter);
      }
    }
  }, [filters, loadmorecountfliter]);

  const loadMoreFilter = () => {
    setloadmorecountfilter(loadmorecountfliter + 1);
  };

  return (
    <InfiniteScroll
      style={{ overflow: "hidden" }}
      dataLength={filterchange.length} //This is important field to render the next data
      next={loadMoreFilter}
      hasMore={filteritemsStatus}
      loader={<h4 className="text-center">Loading...</h4>}
      endMessage={
        <p style={{ textAlign: "center", margin: "10rem 0" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      <div className="row">
        {filterchange.map((person) => (
          <NftCardMarket
            key={person.nft_id}
            extra={person.extra}
            media={person.media_url}
            clicks={person.total_clicks}
            copies={person.no_copies}
            title={person.name}
            description={person.description}
            owner_id={person.seller_address}
            nft={person.media_url}
            token_id={person.sell_id}
            sale_conditions={{ near: parseNearAmount(person.min_sell_price) }}
            bids={{}}
            royalty={person.royalty}
            marketStoragePaid={marketStoragePaid}
            token2symbol={token2symbol}
            accountId={accountId}
            account={account}
            path={"/token/" + person.sell_id}
            onImgLoad={onImgLoad}
            height={height}
            className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4"
            current_copy={person.current_copy}
            is_auction={person.is_auction}
            collection_name={person.collection_name}
            collection_id={person.collection_id}
            profile_pic={profileImg2}
            verified={userVerified}
            is_unlockable={person.is_unlockable}
            nftObject={person}
            compressed_url={person.compressed_url}
            extension={person.extension}
            min_sell_price={person.min_sell_price}
            fav_count={person.fav_count}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
};

export default memo(ColumnNewThreeColReduxUserType);
