/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { concat } from 'lodash';
import { memo, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { parseNearAmount, token2symbol } from '../../state/near';
import { getMarketStoragePaid, loadItems } from '../../state/views';
import NftCardMarket from './NftCardMarket';
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

//react functional component
const Search = ({ showLoadMore = true, shuffle = false, app, views, update, loading, contractAccount, account, dispatch }) => {
	//test start
	if (!contractAccount) return null;
	const [allData, setAllData] = useState([]);
	const [loadmorecountfliter, setloadmorecountfilter] = useState(0);
	const [filteritemsStatus, setFilterItemsStatus] = useState(true);
	let urlParams = new URLSearchParams(window.location.search);

	const fetchSearchData = async (loadmorecountfliter, q) => {
		// console.log(loadmorecountfliter, q);
		try {
			const result = await axios.get(`${apiUrl}/api/v1/search_nft/${loadmorecountfliter}?search=${q}`);
			if (result.data["status"] === "success") {
				const newListFilter = concat(...allData, result.data['data']);
				setAllData(newListFilter);
			} else {
				setFilterItemsStatus(false);
			}
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		if (urlParams.get('q')) {
			const params = new URLSearchParams(window.location.search);
			const q = params.get('q');
			setFilterItemsStatus(true);
			fetchSearchData(0, q);
		}
	}, []);

	useEffect(() => {
		if (urlParams.get('q')) {
			const params = new URLSearchParams(window.location.search);
			const q = params.get('q');
			if (loadmorecountfliter > 0) {
				fetchSearchData(loadmorecountfliter, q);
			}
		}
	}, [loadmorecountfliter]);


	// test end
	const [height, setHeight] = useState(0);
	useEffect(() => {
		if (!loading) {
			dispatch(loadItems(account))
			dispatch(getMarketStoragePaid(account))
		}
	}, [loading]);

	if (!contractAccount) {
		return null;
	};

	const { marketStoragePaid } = views

	let accountId = '';
	if (account) accountId = account.accountId;

	const onImgLoad = ({ target: img }) => {
		let currentHeight = height;
		if (currentHeight < img.offsetHeight) {
			setHeight(img.offsetHeight);
		}
	}

	const loadMoreFilter = () => {
		setloadmorecountfilter(loadmorecountfliter + 1);
	}
	return (
		<>
			{allData.length > 0
				?
				<InfiniteScroll style={{ overflow: "hidden" }}
					dataLength={allData.length} //This is important field to render the next data
					next={loadMoreFilter}
					hasMore={filteritemsStatus}
					loader={<h4 className="text-center">Loading...</h4>}
					endMessage={
						<p style={{ textAlign: 'center', margin: '10rem 0' }}>
							<b>Yay! You have seen it all</b>
						</p>
					}
				>
					<div className='row'>
						{
							allData.map((person, i) => (
								<NftCardMarket key={i} extra={person.extra} media={person.media_url} clicks={person.total_clicks} copies={person.no_copies} title={person.name} description={person.description} owner_id={person.seller_address} nft={person.media_url} token_id={person.sell_id} sale_conditions={{ "near": parseNearAmount(person.min_sell_price) }} bids={{}} royalty={person.royalty} marketStoragePaid={marketStoragePaid} token2symbol={token2symbol} accountId={accountId} account={account} path={'/token/' + person.sell_id} onImgLoad={onImgLoad} height={height} className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4" current_copy={person.current_copy} is_auction={person.is_auction} collection_name={person.collection_name} collection_id={person.collection_id} profile_pic={person.profile_pic} verified={person.verified} is_unlockable={person.is_unlockable} nftObject={person} compressed_url={person.compressed_url} extension={person.extension} min_sell_price={person.min_sell_price} fav_count={person.fav_count} />
							))
						}
					</div>
				</InfiniteScroll>
				:
				(
					<div className='row'>
						<div className='text-center' style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "30rem" }}>
							<div>
								<h3>No data found</h3>
								<p>Try to search another item using card name,user description,user address.</p>
							</div>
						</div>
					</div>
				)}
		</>
	);
};

export default memo(Search);