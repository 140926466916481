import { navigate } from "@reach/router";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import 'react-tabs/style/react-tabs.css';

const MyofferComponent = ({ bid }) => {
	const [loadingImg, setLoading] = useState(true);
	const [compressedImageUrl, setCompressedImageUrl] = useState(null);
	const [FileExtension, setFileExtension] = useState(null);
	const [FileUrl, setFileUrl] = useState(null);
	const [imageType, setimageType] = useState(true);

	function isFileImage(file) {
		const acceptedImageTypes = ["gif", "jpeg", "png", "webp", "jpg", "avif", "apng", "svg+xml", "bmp", "x-icon", "tiff"];
		return (file && acceptedImageTypes.includes(file));
	}

	useEffect(() => {
		if (bid.extra) {
			if (bid.extra.includes("**")) {
				const myArray = bid.extra.split("**");
				setFileExtension(myArray[0]);
				setFileUrl(myArray[1]);
			} else {
				let fetchRes = fetch(bid.extra);
				fetchRes.then(res => res.json()).then(d => {
					setFileExtension(d.fileExtension);
					setFileUrl(d.filesCover);
					if(d.fileExtension !== "gif"){
						setCompressedImageUrl(d.compressedImage);
					}
				})
					.catch(e => {
						console.log('error in metadata loading', e)
					})
			}
		}
		if (FileExtension) {
			setimageType(isFileImage(FileExtension.toLowerCase()));
		} else {
			setimageType(true);
			setFileExtension('jpg');
		}
	}, [bid.extra]);

	return (
		<>
			<span style={{ display: loadingImg ? "block" : "none" }}>
				<SkeletonTheme baseColor="#202020" highlightColor="#444" display="flex">
					<Skeleton height={220} width={190} />
				</SkeletonTheme>
			</span>
			<span style={{ display: loadingImg ? "none" : "block" }}>
				{
					imageType ? (
						<img src={compressedImageUrl ? compressedImageUrl : bid.media_url} alt='' className="img-fluid" onLoad={() => setLoading(false)} onClick={() => navigate('/token/' + bid.sell_id)} />
					) : (
						<img src={compressedImageUrl ? compressedImageUrl : FileUrl} alt='' className="img-fluid" onLoad={() => setLoading(false)} onClick={() => navigate('/token/' + bid.sell_id)} />
					)
				}
			</span>
		</>
	)
}

export default MyofferComponent;