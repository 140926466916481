import { navigate } from "@reach/router";
import { memo, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import Getconfig from '../../config';
import ReactTooltip from 'react-tooltip';
import FileType from 'file-type/browser';
import axios from 'axios';
import { acceptedImageTypes, acceptedAudioTypes, acceptedVideoTypes } from "../../utils";
export const {
	websiteUrl, apiUrl
} = Getconfig();

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
`;


const HomepageCardPopular = ({ nft }) => {
	const [loadingImg, setLoading] = useState(true);
	const [compressedImageUrl, setCompressedImageUrl] = useState(null);
	const [FileExtension, setFileExtension] = useState(null);
	const [FileUrl, setFileUrl] = useState(null);
	const [imageType, setimageType] = useState(true);
	const [changedPrice, setChangedPrice] = useState(null);
	const [newCompressed, setNewCompressed] = useState(null);


	function isFileImage(file) {
		const acceptedImageTypes = ["gif", "jpeg", "png", "webp", "jpg", "avif", "apng", "svg+xml", "bmp", "x-icon", "tiff"];
		return (file && acceptedImageTypes.includes(file));
	}
	useEffect(() => {
		const storage = window?.sessionStorage;
		if (!storage) return;
		const NearToUSD = storage.getItem("NearToUSD");
		setChangedPrice(NearToUSD);
	}, [])
	useEffect(() => {
		if (nft.extra) {
			if (nft.extra.includes("**")) {
				const myArray = nft.extra.split("**");
				setFileExtension(myArray[0]);
				setFileUrl(myArray[1]);

			} else {
				let fetchRes = fetch(nft.extra);
				fetchRes.then(res => res.json()).then(d => {
					setFileExtension(d.fileExtension);
					setFileUrl(d.filesCover);
					if (d.fileExtension !== "gif") {
						setCompressedImageUrl(d.compressedImage);
					}
				})
					.catch(e => {
						console.log('error in metadata loading', e)
					})
			}
		}

		if (FileExtension) {
			setimageType(isFileImage(FileExtension.toLowerCase()));
		} else {
			setimageType(true);
			setFileExtension('jpg');
		}
	}, [nft.extra]);

	useEffect(() => {
		// new code start
		/* ------------------------------- test start ------------------------------- */
		const getMedia = async (data) => {
			try {
				const resp = await axios.get(`${data}`, {
					responseType: 'blob',
				});			
				const fileType = await FileType.fromBlob(resp.data);			
				const objectUrl = URL.createObjectURL(resp.data);
				// console.log(objectUrl,fileType,'file-type');
				setNewCompressed(objectUrl);
			} catch (err) {
				console.log(err,'err');
				setNewCompressed(data);
			}
		}
		/* -------------------------------- test end -------------------------------- */
		if (nft.extension !== "gif") {
			if(nft.compressed_url && nft.compressed_url !== ''){			
				getMedia(nft.compressed_url);
			}else{
				getMedia(nft.media_url);
			}		
		}else{
			getMedia(nft.media_url);
		}
		// new code end
	}, []);

	return (
		<div className="itm mt-5" index={nft.nft_id} key={nft.nft_token_id}>
			<div className="d-item">
				<div className="nft__item">
					{/* <div style={{ display: loadingImg ? "block" : "none" }}>
						<SkeletonTheme baseColor="#202020" highlightColor="#444">
							<Skeleton height={50} width={50} circle={true} />
							<Skeleton height={230} />
							<p>
								<Skeleton className="mt-2" />
							</p>
							<p>
								<Skeleton />
							</p>
						</SkeletonTheme>
					</div> */}
					<span>
						<div className='icontype d-flex'>
							{
								acceptedAudioTypes.includes(nft.extension) &&
								<i className="fa fa-headphones"></i>
							}
							{
								acceptedVideoTypes.includes(nft.extension) &&
								<i className="fa fa-video-camera"></i>
							}
							{
								(acceptedImageTypes.includes(nft.extension) || nft.extension == null || nft.extension === "") &&
								<i className="fa fa-image"></i>
							}
							{nft.is_unlockable &&
								<div className="d-flex justify-content-center align-items-center">
									<i
										className="fa fa-lock text-danger"
										data-tip="Includes unlockable content"
										style={{ fontSize: "1.1rem", paddingLeft: '8px' }}
									></i>
									<ReactTooltip
										textColor="#000"
										backgroundColor="#fff"
										className="tooltipStyle"
									/>
								</div>
							}
						</div>
						<div
							className="text-truncate pb-2 pt-2 text-center text-capitalize"
							style={{ fontWeight: "bold", fontSize: "1.1rem" }}
						>
							<span
								className="cardCollectionClick"
								onClick={() =>
									navigate(
										`user-collection/${nft.collection_id}`
									)
								}
							>
								{nft.collection_name
									? nft.collection_name
									: "New Collection"}
							</span>
						</div>
						<div className="author_list_pp" style={{ zIndex: '2' }}>
							<span
								onClick={() =>
									navigate(`user/${nft.seller_address}`)
								}
							>
								<img className="lazy" src={nft.profile_pic ? nft.profile_pic : websiteUrl + '/img/author/author-11.jpg'} alt="" />
								{nft.user_verified && <i className="fa fa-check"></i>}
							</span>
						</div>
						<div className="nft__item_wrap">
							<Outer>
								<div style={{ display: loadingImg ? "block" : "none" }}>
									<SkeletonTheme baseColor="#202020" highlightColor="#444" style={{ height: '200px' }}>
										<Skeleton height={180} width={200} style={{ display: 'flex' }} />
									</SkeletonTheme>
								</div>
								<span style={{ display: loadingImg ? "none" : "block" }}>
									{imageType ? (
										<img
											style={{ cursor: "pointer", objectFit: 'cover' }}
											onLoad={() => setLoading(false)}
											src={newCompressed ? newCompressed : nft.media_url}
											className="lazy nft__item_preview abc"
											alt=""
											onClick={() => navigate("/token/" + nft.sell_id)}
										/>
									) : (
										<img
											style={{ cursor: "pointer", objectFit: 'cover' }}
											onLoad={() => setLoading(false)}
											src={newCompressed ? newCompressed : FileUrl}
											className="lazy nft__item_preview"
											alt=""
											onClick={() => navigate("/token/" + nft.sell_id)}
										/>
									)}
								</span>
							</Outer>
						</div>
						<div className="nft__item_info">
							<span onClick={() => navigate("/token/" + nft.sell_id)}>
								<h4 className='text-truncate' style={{ textTransform: "capitalize" }}>
									{nft.name}
								</h4>
							</span>
							<div className="nft__item_price">
								{nft.min_sell_price} {"Ⓝ"}
								&nbsp;<small className="text-warning">(${parseFloat(changedPrice * nft.min_sell_price).toFixed(2)})</small>
								<span>{nft.current_copy == 0 ? (nft.no_copies ? nft.no_copies : '1') + ' copies' : `#${nft.current_copy} /${nft.no_copies ? nft.no_copies : '1'}`}</span>
							</div>
							<div className="nft__item_action">
								<span onClick={() => navigate("/token/" + nft.sell_id)}>
									{nft.is_auction ? "Place Bid" : "Buy Now"}
								</span>
							</div>
							<div className="nft__item_like" style={{cursor:'default'}}>
								<i className="fa fa-heart"></i>
								<span>{nft.fav_count}</span>
							</div>
						</div>
					</span>
				</div>
			</div>
		</div>
	)
}

export default memo(HomepageCardPopular);