import * as nearAPI from 'near-api-js';
import Getconfig from '../config';
import { getWallet } from '../utils/near-utils';
export const {
	GAS,
	explorerUrl,
	networkId, nodeUrl, walletUrl, nameSuffix,
	contractName: contractId, websiteUrl, apiUrl,
	extraFee
} = Getconfig();

export const marketId = 'market.' + contractId;

export const {
	utils: {
		format: {
			formatNearAmount, parseNearAmount
		}
	}
} = nearAPI;

const storage = window?.sessionStorage;


function UpdateNFT(raw) {
	fetch(`${apiUrl}/api/v1/update_nft_create`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			alert('done');
			window.location.replace(`collection`);
		})
		.catch((error) => {
			console.error(error);
		});
}

function CreateNewNFT(raw) {
	fetch(`${apiUrl}/api/v1/nft_create`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			// window.location.replace(storage.getItem('prevPath'));
		})
		.catch((error) => {
			console.error(error);
		});
}

function AddOrderNFT(raw) {
	fetch(`${apiUrl}/api/v1/add_order`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
		})
		.catch((error) => {
			console.error(error);
		});
}
/* --------------------------- set session code start --------------------------- */
const storeItem = async (key, item) => {
	try {
		window.sessionStorage.setItem(key, JSON.stringify(item));
	} catch (error) {
		console.log('error in storeItem', error);
	}
};

const retrieveItem = (key) => {
	try {
		const item = JSON.parse(window.sessionStorage.getItem(key));
		return item;
	} catch (error) {
		console.log('error in retrieveItem', error);
		return error;
	}
};

const removeItem = async (key) => {
	try {
		window.sessionStorage.removeItem(key);
		return true;
	} catch (exception) {
		return false;
	}
};

/* ---------------------------- set session code end ---------------------------- */

export const initNear = () => async ({ update, getState, dispatch }) => {
	console.log('near init started')
	const { near, wallet, contractAccount } = await getWallet();

	wallet.signIn = () => {
		wallet.requestSignIn(contractId, 'Blah Blah');
	};
	const signOut = wallet.signOut;
	wallet.signOut = () => {
		signOut.call(wallet);
		update('wallet.signedIn', false);
		update('', { account: null });
		update('app.tab', 1);
	};

	wallet.signedIn = wallet.isSignedIn();

	let account;
	if (wallet.signedIn) {
		account = wallet.account();
		wallet.balance = formatNearAmount((await wallet.account().getAccountBalance()).available, 4);
		await update('', { near, wallet, contractAccount, account });
	}

	await update('', { near, wallet, contractAccount, account });
	console.log('near init ended')
};

export const updateWallet = () => async ({ update, getState }) => {
	const { wallet } = await getState();
	wallet.balance = formatNearAmount((await wallet.account().getAccountBalance()).available, 2);
	await update('', { wallet });
};


export const token2symbol = {
	"near": "NEAR",
	// "dai": "DAI",
	// "usdc": "USDC",
	// "usdt": "USDT",
};

const allTokens = Object.keys(token2symbol);

export const getTokenOptions = (value, setter, accepted = allTokens) => (
	<select className='d-none' value={value} onChange={(e) => setter(e.target.value)}>
		{
			accepted.map((value) => <option key={value} value={value}>{token2symbol[value]}</option>)
		}
	</select>);


export const handleOffer = async (account, token_id, offerToken, offerPrice, previous_token, copy, seller_name, token_title, is_auction, tokenData) => {
	console.log(account, token_id, offerToken, offerPrice, previous_token, copy, seller_name, token_title, is_auction, tokenData);
	if (offerToken !== 'near') {
		return alert('currently only accepting NEAR offers');
	}
	const supplyToken = await account.viewFunction(contractId, 'nft_supply_for_type', { token_type_id: token_id }).catch(() => { });
	const nextCopy = (parseInt(supplyToken) + 1);
	const nextSupplyToken = `${token_id}:${(parseInt(supplyToken) + 1)}`;
	console.log('supplyToken', supplyToken, nextSupplyToken, tokenData);

	const { accountId } = account;
	if (offerToken === 'near') {
		let rawUpdate = '';
		if (is_auction) {
			rawUpdate = JSON.stringify({
				"nft_token_id": previous_token,
				"sell_id": token_id,
				"type": "sell",
				"status": "active",
				"current_copy": copy
			});
		} else {
			rawUpdate = JSON.stringify({
				"type": "mint",
				"status": "inactive",
				"min_sell_price": "0",
				"seller_address": account.accountId,
				"current_copy": nextCopy,
				"nft_token_id": previous_token,
				"name": tokenData.name,
				"description": tokenData.description,
				"royalty": tokenData.royalty,
				"royalty_address": tokenData.royalty_address,
				"media_url": tokenData.media_url,
				"extra": tokenData.extra,
				"extension": tokenData.extension,
				"creater_id": tokenData.creater_id,
				"no_copies": tokenData.no_copies,
				"is_auction": false,
				"sell_id": nextSupplyToken,
				"is_unlockable": tokenData.is_unlockable,
				"unlockable_desc": tokenData.unlockable_desc,
				"collection_id": tokenData.collection_id,
				"collection_name": tokenData.collection_name,
				"collection_desc": tokenData.collection_desc,
				"collection_logo": tokenData.collection_logo,
				"compressed_url": tokenData.compressed_url,
			});
			const rawAddOffer = JSON.stringify({
				"nft_title": token_title,
				"price": offerPrice,
				"seller": seller_name,
				"buyer": account.accountId,
				"nft_token_id": previous_token,
				"date": (new Date().toLocaleDateString()).toString(),
			});
			storeItem('addOrderNFT', rawAddOffer);
		}
		storeItem('saleNFT', rawUpdate);
		const totalOffer = (parseFloat(offerPrice) + parseFloat(extraFee)).toString();
		await account.functionCall({
			contractId,
			methodName: 'nft_buy',
			args: {
				token_type_id: token_id,
				receiver_id: accountId
			},
			gas: GAS,
			attachedDeposit: parseNearAmount(totalOffer)
		});
		// test end
	} else {
		/// todo ft_transfer_call
	}
};


var url_string = window.location.href;
var token_id_update = null;
const queryString = window.location.pathname.split('/');
if (queryString[2]) {
	const urlParams = (queryString[2]);
	token_id_update = urlParams;
}

if (token_id_update) {
	const aaa = `/token/${token_id_update}`;
	const ccc = `/token/${encodeURIComponent(token_id_update)}`;
	var IsFind = (((url_string.indexOf(aaa) > -1) && (url_string.indexOf('transactionHashes') > -1)) || ((url_string.indexOf(ccc) > -1) && (url_string.indexOf('transactionHashes') > -1)));;

	const bbb = `/token/${token_id_update}`;
	const ddd = `/token/${encodeURIComponent(token_id_update)}`;
	var IsErrorUpdate = (((url_string.indexOf(bbb) > -1) && (url_string.indexOf('errorCode') > -1)) || ((url_string.indexOf(ddd) > -1) && (url_string.indexOf('errorCode') > -1)));

	if (IsFind) {
		if (retrieveItem('addOrderNFT')) {
			AddOrderNFT(retrieveItem('addOrderNFT'));
			removeItem('addOrderNFT');
		}
		if (retrieveItem('saleNFT')) {
			CreateNewNFT(retrieveItem('saleNFT'));
			removeItem('saleNFT');
		}

	} else if (IsErrorUpdate) {
		removeItem('addOrderNFT');
		removeItem('saleNFT');
	}
}