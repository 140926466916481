import React, { Component } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { WaveformContianer, Wave, PlayButton } from './Waveform.styled';
import './disc.scss';
import { Spinner } from "reactstrap";

class AudioPlayer extends Component {
    state = {
        playing: false,
        loading: true,
    };

    componentDidMount() {
        const track = document.querySelector('#track');
        this.waveform = WaveSurfer.create({
            barWidth: 3,
            cursorWidth: 1,
            container: '#waveform',
            backend: 'WebAudio',
            height: 80,
            progressColor: '#8364e2',
            responsive: true,
            waveColor: '#EFEFEF',
            cursorColor: 'transparent',
        });
        this.waveform.load(track);
        this.waveform.on('finish', () => {
            this.setState({ playing: false });
            this.waveform.pause();
        });

        this.waveform.on('ready', () => {
            this.setState({ loading: false });
        });
    };

    componentWillUnmount() {
        this.setState({ playing: false });
        this.waveform.pause();
    }

    handlePlay = () => {
        this.setState({ playing: !this.state.playing });
        this.waveform.playPause();
    };
    render() {
        return (
            <>
                <div className="diskContainer d-flex justify-content-center">
                    <div className="otherContainer">
                        <div className="artwork">
                            <img src={this.props.cover} alt='album art' className={this.state.playing ? 'disc-rotate-start' : 'disc-rotate-stop'} />
                        </div>
                    </div>
                </div>
                <WaveformContianer>
                    {
                        this.state.loading ?
                            (
                                <PlayButton>
                                    <Spinner>
                                        Loading...
                                    </Spinner>
                                </PlayButton>
                            ) : (
                                <PlayButton onClick={this.handlePlay} >
                                    {
                                        !this.state.playing ? <i className="fa fa-play" style={{ fontSize: '1.5rem' }} /> : <i className="fa fa-pause" style={{ fontSize: '1.5rem' }} />
                                    }
                                </PlayButton>
                            )
                    }
                    <Wave id="waveform" />
                    <audio id="track" src={this.props.url} preload="auto" />
                </WaveformContianer>
            </>
        );
    }
};

export default AudioPlayer;