import axios from "axios";
import copy from "copy-to-clipboard";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Spinner } from "reactstrap";
import { createGlobalStyle } from "styled-components";
import { appStore } from "../../state/app";
import ColumnNewReduxUserType from "../components/ColumnNewReduxUserType";
import Footer from "../components/footer";
import Getconfig from "../../config";
import { FaEdit } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { navigate } from "@reach/router";
import { Modal, Button as Btn } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./extra.scss";
import SliderFilterNew from "../components/SliderFilterNew";
import ColumnNewReduxAttribute from "../components/ColumnNewReduxAttribute";
export const { websiteUrl, apiUrl } = Getconfig();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const UserCollectionType = function (props) {
  const { state, dispatch, update } = useContext(appStore);
  const { app, views, contractAccount, account, loading, wallet } = state;
  const [openMenu, setOpenMenu] = useState(true);
  const [openMenu1, setOpenMenu1] = useState(false);
  const [loader, setLoader] = useState(true);
  const [profileImg, setProfileImg] = useState(
    websiteUrl + "/img/author/author-11.jpg"
  );
  const [userInfo, setUserInfo] = useState("");
  const [userName, setUserName] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [userVerified, setUserVerified] = useState(false);
  const [collectionIdNew, setCollectionIdNew] = useState(null);
  const [Bio, setBio] = useState("");
  const [isCollectionUser, setIsCollectionUser] = useState(false);
  const [showModal_new, setShow2_new] = useState(false);
  const handleClose_new = () => setShow2_new(false);
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [filters, setFilters] = useState("");
  const [collectionBackground, setCollectionBackground] = useState(
    "./img/background/4.jpg"
  );
  const [loginUser, setLoginUser] = useState(null);
  const [showAttribute, setShowAttribute] = useState(false);
  const [trait_type, setTraitType] = useState(null);
  const [trait_value, setTraitValue] = useState(null);
  const cropperRef = useRef(null);

  useEffect(() => {
    if (props.collectionId) {
      let user = props.collectionId;
      setCollectionIdNew(user);
      fetchCollectionName(user).then(() => setLoader(false));
    } else {
      setLoader(false);
    }
    if (wallet && wallet.signedIn && account) {
      setLoginUser(account.accountId);
    }
    const queryParameters = new URLSearchParams(window.location.search);
    const trait_type_new = queryParameters.get("trait_type");
    const trait_value_new = queryParameters.get("trait_value");
    if (trait_type_new && trait_value_new) {
      setShowAttribute(true);
      setTraitType(trait_type_new);
      setTraitValue(trait_value_new);
    } else {
      setShowAttribute(false);
    }
  }, []);

  useEffect(() => {
    if (wallet && wallet.signedIn && account) {
      setLoginUser(account.accountId);
    }
  }, [wallet, account]);

  async function fetchCollectionName(collectionId) {
    try {
      const result = await axios.get(
        apiUrl +
          "/api/v1/fetch_single_collections?collection_id=" +
          collectionId
      );

      if (result.data["status"] === "success") {
        const newList = result.data["result"][0].name;
        if (newList !== null) {
          setCollectionName(newList);
          const UserDetails = result.data["result"][0].user_id.split(".");
          setUserName(UserDetails[0]);
          setUserInfo(result.data["result"][0].user_id);
          if (
            wallet &&
            wallet.signedIn &&
            account.accountId === result.data["result"][0].user_id
          ) {
            setIsCollectionUser(true);
          } else {
            setIsCollectionUser(false);
          }
          setProfileImg(result.data["result"][0].logo);
          setBio(result.data["result"][0].description);
        }
        if (result.data["result"][0].cover_image !== null) {
          setCollectionBackground(result.data["result"][0].cover_image);
        } else {
          setCollectionBackground("./img/background/4.jpg");
        }
        setUserVerified(result.data["user"].verified);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleBtnClick = () => {
    if (!openMenu) {
      setOpenMenu(!openMenu);
      setOpenMenu1(false);
      document.getElementById("Mainbtn").classList.add("active");
      document.getElementById("Mainbtn1").classList.remove("active");
    }
  };

  var de_nav = document.getElementsByClassName("de_nav");
  if (de_nav.length > 0) {
    if (!openMenu) {
      document.getElementById("Mainbtn1").classList.add("active");
      document.getElementById("Mainbtn").classList.remove("active");
    }
  }

  /* ----------------------------- new code start ----------------------------- */
  const showNewModal = () => {
    setShow2_new(true);
  };
  async function UploadCroppedImage(raw) {
    fetch(`${apiUrl}/api/v1/upload_images`, {
      method: "POST",
      body: raw,
    })
      .then((response) => response.text())
      .then((responseText) => {
        console.log(responseText);
        setShow2_new(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      const CropeedImageUrl = cropper.getCroppedCanvas().toDataURL();
      const file = dataURLtoFile(CropeedImageUrl, "bgCollection.jpg");
      //code start
      var formdata = new FormData();
      formdata.append("collection_id", collectionIdNew);
      formdata.append("image", file, file.name);
      //code end
      if (wallet && wallet.signedIn && account.accountId) {
        UploadCroppedImage(formdata);
      }
    }
  };
  const onChange = (e) => {
    setImage(null);
    setShow2_new(true);
    // setSelectedFile();
    // setPreview(null);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const resetCropData = () => {
    setImage(null);
    // setShow2_new(false);
    // setSelectedFile();
    // setPreview(null);
  };
  const BackData = () => {
    setImage(null);
    setShow2_new(false);
    // setSelectedFile();
    // setPreview(null);
  };

  const handleFilters = (filters, category) => {
    setFilters(filters.toString());
  };

  return (
    <div>
      <Helmet>
        <title>UniqART - {userName} Collection</title>
        <link rel="canonical" href={`${websiteUrl}/user/${userInfo}`} />
        <meta
          property="og:title"
          content={`UniqART - ${userName} Collection`}
        />
        <meta property="og:url" content={`${websiteUrl}/user/${userInfo}`} />
      </Helmet>
      <GlobalStyles />
      {loader && (
        <div
          style={{ height: "100vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner>Loading...</Spinner>
        </div>
      )}

      {!loader && (
        <section
          id="profile_banner"
          className="jumbotron breadcumb no-bg"
          style={{
            backgroundImage: `url(${collectionBackground})`,
            marginTop: "5.5rem",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {loginUser === userInfo && (
            <>
              <div
                className="collectionEditStyle collectionEditStyleCover d-flex"
                data-tip="Change profile cover"
                onClick={() => setShow2_new(true)}
              >
                <FaEdit />
              </div>
              <div className="mainbreadcumb"></div>
            </>
          )}
        </section>
      )}

      {!loader && (
        <section className="container d_coll no-top no-bottom">
          <div className="row">
            <div className="col-md-12">
              <div className="d_profile">
                <div className="profile_avatar">
                  <div className="d_profile_img setProfileStyle">
                    <img
                      src={
                        profileImg
                          ? profileImg
                          : websiteUrl + "/img/author/author-11.jpg"
                      }
                      alt=""
                    />
                    {userVerified && <i className="fa fa-check"></i>}
                  </div>
                  <div className="profile_name">
                    <h4>
                      <span
                        className="text-capitalize"
                        style={{ position: "relative" }}
                      >
                        {collectionName}
                        {isCollectionUser && (
                          <div
                            style={{
                              top: 3,
                              right: -40,
                              position: "absolute",
                              cursor: "pointer",
                              background: "#8364e27a",
                              display: "flex",
                              alignItems: "center",
                              padding: "5px",
                              borderRadius: "5px",
                              fontSize: "1rem",
                            }}
                            onClick={() => {
                              navigate(`/editCollection/${collectionIdNew}`);
                            }}
                            data-tip="Edit Collection"
                          >
                            <FaEdit style={{ color: "#fff" }} />
                            <ReactTooltip
                              textColor="#000"
                              backgroundColor="#fff"
                              className="tooltipStyle"
                            />
                          </div>
                        )}
                      </span>
                      <div className="clearfix"></div>
                      <span id="wallet" className="profile_wallet mx-2">
                        {userInfo ? userInfo : "Loading..."}
                      </span>
                      <span
                        className="changeCopyColor"
                        style={{
                          position: "absolute",
                          display: "inline-block",
                          fontSize: "12px",
                          margin: "0",
                          cursor: "pointer",
                        }}
                        onClick={() => copy(userInfo ? userInfo : "")}
                        title="Copy Text"
                      >
                        <i className="fa fa-copy m-0"></i>
                      </span>
                    </h4>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <div className="ProfileInfo">
                      <h5
                        className="text-center"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {Bio && Bio !== "" ? Bio : "Citizen of UniqArt"}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {!showAttribute ? (
        <>
          <section className="container no-top">
            {!loader && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="items_filter">
                    <ul className="de_nav">
                      <li id="Mainbtn" className="active">
                        <span onClick={handleBtnClick}>All NFT's</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 mb-lg-5 p-2">
                  <SliderFilterNew
                    handleFilters={(filters) => handleFilters(filters)}
                    defaultCategory={props.type ? props.type : null}
                    collectionId={props.collectionId}
                  />
                </div>
              </div>
            )}
            {openMenu && !loader && (
              <div id="zero1" className="onStep fadeIn">
                <ColumnNewReduxUserType
                  shuffle
                  showLoadMore={false}
                  {...{
                    app,
                    views,
                    update,
                    loading,
                    contractAccount,
                    account,
                    dispatch,
                    userInfo,
                    collectionIdNew,
                    filters,
                  }}
                />
              </div>
            )}
          </section>
        </>
      ) : (
        <>
          <section className="container no-top">
            {!loader && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="items_filter">
                    <ul className="de_nav">
                      <li id="Mainbtn" className="active">
                        <span
                          onClick={handleBtnClick}
                        >{`[{${trait_type} : ${trait_value}}]`}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {openMenu && !loader && (
              <div id="zero1" className="onStep fadeIn">
                <ColumnNewReduxAttribute
                  shuffle
                  showLoadMore={false}
                  {...{
                    app,
                    views,
                    update,
                    loading,
                    contractAccount,
                    account,
                    dispatch,
                    userInfo,
                    collectionIdNew,
                    filters,
                    trait_type,
                    trait_value,
                  }}
                />
              </div>
            )}
          </section>
        </>
      )}

      <Footer />
      {/* ------------------------- cover popup code start ------------------------- */}
      <Modal
        show={showModal_new}
        onHide={handleClose_new}
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login-model"
        backdrop="static"
      >
        <Modal.Header style={{ backgroundColor: "#212428" }} closeButton>
          <h2 className="mb-0">Edit Profile Cover</h2>
        </Modal.Header>
        <Modal.Body>
          {!image ? (
            <div className="d-create-file mb-3">
              <p id="file_name">PNG, JPG. Max 100 KB.</p>
              <div className="browse">
                <input
                  type="button"
                  id="get_file"
                  className="btn-main"
                  value="Browse"
                />
                <input
                  id="upload_file"
                  type="file"
                  onChange={onChange}
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
            </div>
          ) : (
            <>
              <span
                className="text-danger removeButtonStyle"
                onClick={resetCropData}
              >
                Remove
              </span>
              <div className="row p-3">
                <div className=" mb-3 col-12 d-flex justify-content-center">
                  <div>
                    <div style={{ width: "100%", height: "100%" }}>
                      <Cropper
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={16 / 3}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        aspectRatio={16 / 3}
                        ref={cropperRef}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                    </div>
                  </div>
                </div>
                <Btn
                  className="text-center modal-body-button mb-3"
                  onClick={getCropData}
                >
                  Crop
                </Btn>
              </div>
            </>
          )}
          <Btn
            className="text-center modal-body-button modal-body-cancel-button mb-3"
            onClick={BackData}
          >
            Back
          </Btn>
        </Modal.Body>
      </Modal>
      {/* ------------------------- cover popup code end ------------------------- */}
    </div>
  );
};
export default UserCollectionType;
