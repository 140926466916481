import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Getconfig from '../../config';
import Footer from '../components/footer';
import { navigate } from "@reach/router";
import Cookies from 'js-cookie';
import axios from 'axios';

var ip = require('ip');
export const { websiteUrl, apiUrl, nearRamp_url, contractId } = Getconfig();
const jwt = require('jsonwebtoken');

const privateKey = process.env.REACT_APP_RSA_SECRET_KEY; // RSA Private Key
const developerId = process.env.REACT_APP_DEVELOPER_ID;



function generateJWT(privateKey) {
	const exp = Math.floor(Date.now() / 1000) + (60 * 60); // token expiry window
	const token = jwt.sign({ exp }, privateKey, { algorithm: 'RS256' });
	return token;
}

const CreateAccount = () => {

	
	return (
		(
			<div>
				<Helmet>
					<title>UniqART - Create Account</title>
					<link rel="canonical" href={websiteUrl + "/create-account"} />
					<meta property="og:title" content={`UniqART - Create Account`} />
					<meta property="og:url" content={`${websiteUrl}/create-account`} />
					
				</Helmet>
				<section>
				<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSflZc5tr_HY2e9xrbOrb88JNDSOVvGr4aGC6-Iueu5r8uE8kg/viewform?embedded=true" width="100%" height="1521" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
				</section>
				{/* <section className='jumbotron breadcumb no-bg' >
					<div className='mainbreadcumb'>
						<div className='container'>
							<div className='row m-10-hor'>
								<div className='col-12'>
									<h1 className='text-center'>Create Account</h1>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='container'>
					
					<div className='col-12'>
						<p className='text-center'>You are getting this notification because either an account has already been created by this user or there has been some issue with the system. Please go to market and start exploring different NFTS to trade in.</p>
						<div className="mainside d-flex justify-content-center align-items-center">
							<a
								className="btn-main"
								onClick={() => navigate('/market')}
								style={{ cursor: "Pointer" }}
							>
								Go to Market
							</a>
						</div>

					</div>
				</section> */}
				<Footer />
			</div>
		)
	)
	
	
}

export default CreateAccount