export const categories = [
    {
        value: 'Art',
        label: 'Art'
    },
    {
        value: 'Music',
        label: 'Music'
    },
	{
        value: 'Short Reels',
        label: 'Short Reels'
    },
	{
        value: 'Abstraction',
        label: 'Abstraction'
    },
    {
        value: 'Illustration',
        label: 'Illustration'
    },
    {
        value: 'Album Art',
        label: 'Album Art'
    }
];

export const status = [
    {
        value: 'buy_now',
        label: 'Buy Now'
    },
    {
        value: 'on_auction',
        label: 'On Auction'
    },
    {
        value: 'has_offers',
        label: 'Has Offers'
    },
];

export const itemsType = [
    {
        value: 'single_items',
        label: 'Single Items'
    },
    {
        value: 'bundles',
        label: 'Bundles'
    }
];
