import axios from 'axios';
import copy from 'copy-to-clipboard';
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Spinner } from "reactstrap";
import { createGlobalStyle } from "styled-components";
import { appStore } from "../../state/app";
import ColumnNewReduxUser from "../components/ColumnNewReduxUser";
import ColumnNewReduxUserCollect from "../components/ColumnNewReduxUserCollect";
import ColumnNewReduxUserCollection from "../components/ColumnNewReduxUserCollection";
import Footer from "../components/footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import UserFavouriteNft from "../components/userFavouriteNft";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const UserCollection = function (props) {
	const { state, dispatch, update } = useContext(appStore);
	const { app, views, contractAccount, account, loading } = state;
	const [loader, setLoader] = useState(true);
	const [userInfo, setUserInfo] = useState('');
	const [userName, setUserName] = useState('');
	const [profileImg, setProfileImg] = useState(websiteUrl + '/img/author/author-11.jpg');
	const [userVerified, setUserVerified] = useState(false);
	const [Bio, setBio] = useState('');
	const [SocialWebsiteUrl, setSocialWebsiteUrl] = useState(null);
	const [SocialInstaUrl, setSocialInstaUrl] = useState(null);
	const [SocialTwitterUrl, setSocialTwitterUrl] = useState(null);
	const [collectionBackground, setCollectionBackground] = useState('./img/background/4.jpg');

	useEffect(() => {
		if ((props.userId)) {
			let user = (props.userId);
			const UserDetails = user.split('.');
			setUserName(UserDetails[0]);
			setUserInfo(user);
			fetchData(user).then(() => setLoader(false));
		} else {
			setLoader(false);
		}
	});


	async function fetchData(user) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_profile_pic?address=" + user);
			if (result.data["status"] === "Success") {
				const newList = (result.data['results']);
				if (newList !== null) {
					setProfileImg(newList.profile_pic);
					setUserVerified(newList.verified);
					setBio(newList.Bio);
					setSocialWebsiteUrl(newList.website_url);
					setSocialInstaUrl(newList.instagram_username);
					setSocialTwitterUrl(newList.twitter_username);
				} else {
					setProfileImg(websiteUrl + '/img/author/author-11.jpg');
					setUserVerified(false);
					setBio('');
					setSocialWebsiteUrl(null);
					setSocialInstaUrl(null);
					setSocialTwitterUrl(null);
				}
				if (result.data['results'].user_cover_img) {
					setCollectionBackground(result.data['results'].user_cover_img);
				} else {
					setCollectionBackground('./img/background/4.jpg')
				}
			} else {
				setProfileImg(websiteUrl + '/img/author/author-11.jpg');
				setUserVerified(false);
				setBio('');
				setSocialWebsiteUrl(null);
				setSocialInstaUrl(null);
				setSocialTwitterUrl(null);
			}
		} catch (error) {
			console.error(error);
		}

	}
	return (
		<div>
			<Helmet>
				<title>UniqART - {userName} Collection</title>
				<link rel="canonical" href={`${websiteUrl}/user/${userInfo}`} />
				<meta property="og:title" content={`UniqART - ${userName} Collection`} />
				<meta property="og:url" content={`${websiteUrl}/user/${userInfo}`} />
			</Helmet>
			<GlobalStyles />
			{loader &&
				<div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
					<Spinner>
						Loading...
					</Spinner>
				</div>
			}
			{!loader && (
				<>
					<section
						id="profile_banner"
						className="jumbotron breadcumb no-bg"
						style={{ backgroundImage: `url(${collectionBackground})`,marginTop: '5.5rem',
    backgroundPosition: 'center',backgroundRepeat:'no-repeat' }}
					>
						<div className="mainbreadcumb"></div>
					</section>
					<section className="container d_coll no-top no-bottom">
						<div className="row">
							<div className="col-md-12">
								<div className="d_profile">
									<div className="profile_avatar">
										<div className="d_profile_img">
											<img src={profileImg ? profileImg : websiteUrl + '/img/author/author-11.jpg'} alt="" />
											{userVerified && <i className="fa fa-check"></i>}
										</div>

										<div className="profile_name">
											<h4>
												<span className='text-capitalize'>{userName}</span>'s Collection
												<div className="clearfix"></div>
												<span id="wallet" className="profile_wallet mx-2">{(userInfo) ? (userInfo) : 'Loading...'}</span>
												<span className='changeCopyColor' style={{ position: 'absolute', display: 'inline-block', fontSize: '12px', margin: '0', cursor: 'pointer' }} onClick={() => copy((userInfo) ? (userInfo) : '')} title="Copy Text"><i className="fa fa-copy m-0"></i></span>
											</h4>
										</div>
										<div className='w-100 d-flex justify-content-center'>
											<div className='ProfileInfo'>
												<h5 className="text-center" style={{whiteSpace: 'pre-wrap'}}>{(Bio && Bio !== '') ? Bio : 'Citizen of UniqArt'}</h5>
												<div className="d-flex justify-content-center">
													{(SocialWebsiteUrl !== '' && SocialWebsiteUrl) && <i className="fa fa-link m-0 position-relative mx-2" style={{ background: '#383838' }} onClick={() => window.open(SocialWebsiteUrl, "_blank")}></i>}
													{(SocialInstaUrl !== '' && SocialInstaUrl) && <i className="fa fa-instagram m-0 position-relative mx-2" style={{ background: '#383838' }} onClick={() => window.open(`https://www.instagram.com/${SocialInstaUrl}`, "_blank")}></i>}
													{(SocialTwitterUrl !== '' && SocialTwitterUrl) && <i className="fa fa-twitter m-0 position-relative mx-2" style={{ background: '#383838' }} onClick={() => window.open(`https://twitter.com/${SocialTwitterUrl}`, "_blank")}></i>}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Tabs>
						<section className="container no-top">
							<TabList style={{ borderBottom: 'none', margin: '0 0 1rem 0', padding: '0' }}>
								<div className="row">
									<div className="col-lg-12">
										<div className="items_filter de_nav">
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Creations</span></Tab>
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Collectibles</span></Tab>
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Favourites</span></Tab>
											<Tab style={{ float: 'none', display: 'inline-block', marginRight: '5px', padding: '0', marginBottom: '1rem' }}><span>Collections</span></Tab>
										</div>
									</div>
								</div>
							</TabList>
							<TabPanel>
								<div id="zero1" className="onStep fadeIn">
									<ColumnNewReduxUser
										shuffle
										showLoadMore={false}
										{...{
											app,
											views,
											update,
											loading,
											contractAccount,
											account,
											dispatch,
											profileImg,
											userVerified,
											userInfo
										}}
									/>
								</div>
							</TabPanel>
							<TabPanel>
								<div id="zero2" className="onStep fadeIn">
									<ColumnNewReduxUserCollect
										shuffle
										showLoadMore={false}
										{...{
											app,
											views,
											update,
											loading,
											contractAccount,
											account,
											dispatch,
											profileImg,
											userVerified,
											userInfo
										}}
									/>
								</div>
							</TabPanel>
							<TabPanel>
							<div id="zero4" className="onStep fadeIn">
								<UserFavouriteNft
									shuffle
									showLoadMore={false}
									{...{
										app,
										views,
										update,
										loading,
										contractAccount,
										account,
										dispatch,
										profileImg,
										userVerified,
										userInfo
									}}
								/>
							</div>
						</TabPanel>
							<TabPanel>
								<div id="zero3" className="onStep fadeIn">
									<ColumnNewReduxUserCollection
										shuffle
										showLoadMore={false}
										{...{
											app,
											views,
											update,
											loading,
											contractAccount,
											account,
											dispatch,
											profileImg,
											userVerified,
											userInfo
										}}
									/>
								</div>
							</TabPanel>
						</section>
					</Tabs>
				</>
			)}
			<Footer />
		</div>
	);
};
export default UserCollection;
