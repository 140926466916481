import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import 'normalize.css/normalize.css';
import './HomepageSilder.scss';
import { navigate } from '@reach/router';
import { isMobile } from '../../utils';
import Getconfig from '../../config';
export const {
    websiteUrl, apiUrl
} = Getconfig();

const desktopContent = [
    // {
    //     title: 'UniqArt New Lauch Artist',
    //     description:
    //         'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.',
    //     button: 'View Collection',
    //     image: './img/Banner/banner-1.png',
    //     user: 'Luan Gjokaj',
    //     userProfile: 'https://i.imgur.com/JSW6mEk.png',
    //     link: 'https://twitter.com/uniqartnft/status/1518995145558937600?s=2'
    // },
    {
        title: 'UniqArt New Lauch Artist',
        description:
            'Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.',
        button: 'View Collection',
        image: './img/Banner/banner-2.png',
        user: 'Erich Behrens',
        userProfile: 'https://i.imgur.com/0Clfnu7.png',
        link: `${websiteUrl}/token/712`
    },
    // {
    //     title: 'UniqArt New Lauch Artist',
    //     description:
    //         'Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.',
    //     button: 'View Collection',
    //     image: './img/Banner/banner-4.JPG',
    //     user: 'Erich Behrens',
    //     userProfile: 'https://i.imgur.com/0Clfnu7.png',
    //     link: `${websiteUrl}/market/CleanIndiaCharity_2022`
    // }
];

const mobileContent = [
    // {
    //     title: 'UniqArt New Lauch Artist',
    //     description:
    //         'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.',
    //     button: 'View Collection',
    //     image: './img/Banner/mobile-banner-1.png',
    //     user: 'Luan Gjokaj',
    //     userProfile: 'https://i.imgur.com/JSW6mEk.png',
    //     link: 'https://twitter.com/uniqartnft/status/1518995145558937600?s=2'
    // },
    {
        title: 'UniqArt New Lauch Artist',
        description:
            'Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.',
        button: 'View Collection',
        image: './img/Banner/mobile-banner-2.JPG',
        user: 'Erich Behrens',
        userProfile: 'https://i.imgur.com/0Clfnu7.png',
        link: `${websiteUrl}/token/712`
    },
    // {
    //     title: 'UniqArt New Lauch Artist',
    //     description:
    //         'Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.',
    //     button: 'View Collection',
    //     image: './img/Banner/mobile-banner-4.PNG',
    //     user: 'Erich Behrens',
    //     userProfile: 'https://i.imgur.com/0Clfnu7.png',
    //     link: `${websiteUrl}/market/CleanIndiaCharity_2022`
    // }
];

function myFunction(link) {
    window.location.href = link;
}

const HomepageSlider = () => {
    if (isMobile()) {
        return (
            <Slider className="slider-wrapper" autoplay={3000} style={{ height: '100%' }} touchDisabled={true}>
                {mobileContent.map((item, index) => (
                    // <div
                    //     key={index}
                    //     className="slider-content"
                    //     style={{ background: `url('${item.image}') no-repeat center center` }}
                    // >
                    //     <div className="inner">
                    //         <button>{item.button}</button>
                    //     </div>
                    // </div>
                    <div
                        key={index}
                        className="slider-content"
                        style={{ background: `url('${item.image}') no-repeat center center`, cursor: "pointer" }}
                        onClick={() => myFunction(item.link)}
                    >
                        {/* <img className="w-100" src={item.image}/> */}

                    </div>
                ))}
            </Slider>
        )
    } else {
        return (
            <Slider className="slider-wrapper" autoplay={3000} style={{ height: '100%' }} touchDisabled={true}>
                {desktopContent.map((item, index) => (
                    // <div
                    //     key={index}
                    //     className="slider-content"
                    //     style={{ background: `url('${item.image}') no-repeat center center` }}
                    // >
                    //     <div className="inner">
                    //         <button>{item.button}</button>
                    //     </div>
                    // </div>
                    <div
                        key={index}
                        className="slider-content"
                        style={{ background: `url('${item.image}') no-repeat center center`, cursor: "pointer" }}
                        onClick={() => myFunction(item.link)}
                    >
                        {/* <img className="w-100" src={item.image}/> */}

                    </div>
                ))}
            </Slider>
        )
    }

}

export default HomepageSlider