import { navigate } from "@reach/router";
import axios from 'axios';
import { create } from "ipfs-http-client";
import { CID } from "multiformats/cid";
import React, { useContext, useEffect, useState } from "react";
import { Button as Btn, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import styled from "styled-components";
import uploadImage from "../../assets/images/upload.jpg";
import { handleMint } from "../../state/actions";
import { appStore, onAppMount } from "../../state/app";
import { isAccountTaken } from "../../utils/near-utils";
import Footer from "../components/footer";
import LoginModal from './../components/LoginModal';
import VerifyModal from './../components/VerifyModal';
import Compressor from 'compressorjs';
import { Spinner } from "reactstrap";
import { createGlobalStyle } from "styled-components";
import Getconfig from '../../config';
import { acceptedImageTypes, acceptedAudioTypes, acceptedVideoTypes } from "../../utils";
import ReactTooltip from "react-tooltip";
export const {
	websiteUrl, apiUrl, networkId
} = Getconfig();


const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET_KEY;
const auth ='Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const GlobalStyles = createGlobalStyle`
	.modal-backdrop.show {
		opacity: 1 !important;
	}
`;

const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});
const maxFileSize = 30 * 1024 * 1024;

const Create = () => {
	const { state, dispatch } = useContext(appStore);
	const { account, wallet } = state;
	const onMount = () => {
		dispatch(onAppMount());
		setTimeout(() => setActivityDataLoading(false), 3000)
	};
	const { register, handleSubmit, formState: { errors } } = useForm();

	useEffect(onMount, []);
	const [files, setFiles] = useState(uploadImage);
	const [name, setName] = useState("File name");
	const [title, setTitle] = useState("");
	const [desc, setDesc] = useState("");
	const [imageType, setImageType] = useState(true);
	const [imageTypeUpload, setImageTypeUpload] = useState(false);
	const [royalties, setRoyalties] = useState({});
	const [attribute, setAttribute] = useState({});
	const [royalty, setRoyalty] = useState([]);
	const [receiver, setReceiver] = useState(account ? account.accountId : []);
	const [errorRoyalties, setErrorRoyalties] = useState(false);
	const [errorRoyalties2, setErrorRoyalties2] = useState(false);
	const [errorAttribute2, setErrorAttribute2] = useState(false);
	const [loadingOn, setLoadingOn] = useState(false);
	const [error, setError] = useState(false);
	const [coverImage, setCoverImage] = useState(false);
	const [uploadLogoCollection, setUploadLogoCollection] = useState(false);
	const [cardItems, setCardItems] = useState(false);
	const [numberOfCopies, setNumberOfCopies] = useState(1);
	const [btnText, setbtnTest] = useState("In progress...")
	const [showModal, setShow] = useState(false);
	const [showModal2, setShow2] = useState(false);
	const [showModalCreateCollection, setShowCreateCollection] = useState(false);
	const [showModalCollection, setShowCollection] = useState(true);
	const [disable, setDisable] = useState(true);
	const [preview, setPreview] = useState(uploadImage);
	const [previewCover, setPreviewCover] = useState(uploadImage);
	const [previewUploadLogo, setPreviewUploadLogo] = useState('');
	const [fileExe, setFileExe] = useState('');
	const [filesCover, setFilesCover] = useState('https://via.placeholder.com/250x350/212428/e3e3e3?text=Cover');
	const [profileImg, setProfileImg] = useState(websiteUrl + '/img/author/author-11.jpg');
	const [modalShow, setModalShow] = useState(true);
	const [modalShow2, setModalShow2] = useState(true);
	const [token_type_title, setToken_Type_title] = useState('');
	const [activeIndex, setActiveIndex] = useState(0);
	const [collectionData, setCollectionData] = useState([]);
	const [collectionName, setCollectionName] = useState('');
	const [collectionDescription, setCollectionDescription] = useState('');
	const [collectionImage, setCollectionImage] = useState('');
	const [collectionLogoCreate, setCollectionLogoCreate] = useState('');
	const [collecectionCreateName, setCollectionCreateName] = useState('');
	const [collectionCreateDesc, setCollectionCreateDesc] = useState('');
	const [selectCollectionId, setSelectSelectionId] = useState('');
	const [useCollectionName, setUseCollectionName] = useState('');
	const [useCollectionDesc, setUseCollectionDesc] = useState('');
	const [useCollectionImage, setUseCollectionImage] = useState('');
	const [userVerifyStatus, setUserVerifyStatus] = useState(false);
	const [compressedImage, setCompressedImage] = useState('');
	const [activityDataLoading, setActivityDataLoading] = React.useState(true);
	const [isUnlockable, setIsUnlockable] = useState(false);
	const [unlockableDesc, setUnlockableDesc] = useState("");
	const [titleCharcount, setTitleCharCount] = useState(0);
	const [descCharcount, setDescCharCount] = useState(0);
	const [unlockableCharcount, setUnlockableCharcount] = useState(0);


	const retrieveItem = (key) => {
		try {
			const item = JSON.parse(window.sessionStorage.getItem(key));
			return item;
		} catch (error) {
			console.log('error in retrieveItem', error);
			return error;
		}
	};



	const [royaltyCount, setRoyaltyCount] = useState([]);
	const [attributeCount, setAttributeCount] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');

	const handleClose = () => setShow(false);
	const handleCloseCreateCollection = () => setShowCreateCollection(false);
	const handleCloseCollection = () => setShowCollection(false);
	const handleShow = () => {
		setShow(true);
	};

	const handleBtnClick = (index, name, description, image, collectionId) => {
		setActiveIndex(index);
		setCollectionName(name);
		setCollectionDescription(description);
		setCollectionImage(image);
		setSelectSelectionId(collectionId);
	};
	const handleBtnClick1 = (selectCollectionId, collectionName, collectionDescription, collectionImage) => {
		if (selectCollectionId && collectionName && collectionDescription && collectionImage) {
			setUseCollectionName(collectionName);
			setUseCollectionDesc(collectionDescription);
			setUseCollectionImage(collectionImage);
			setShowCollection(false);

		} else {
			alert('* Choose any one or create new collection before click confirm button')
		}
	};

	async function fetchData(owner_id) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_profile_pic?address=" + owner_id);
			if (result.data["status"] === "Success") {
				const isUserVerified = (result.data['results'].verified);
				if (isUserVerified) {
					setUserVerifyStatus(true);
				} else {
					setUserVerifyStatus(false);
				}
				const newList = (result.data['results'].profile_pic);
				if (newList !== null) {
					setProfileImg(newList)
				} else {
					setProfileImg(websiteUrl + '/img/author/author-11.jpg')
				}
			} else {
				setProfileImg(websiteUrl + '/img/author/author-11.jpg')
			}
		} catch (error) {
			console.error(error);
		}
	}
	async function fetchCollectionData(owner_id) {
		try {
			const result = await axios.get(apiUrl + "/api/v1/fetch_collections?user_id=" + owner_id);
			if (result.data["status"] === "success") {
				const newList = (result.data['result']);
				if (newList !== null) {
					setCollectionData(newList);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
	async function uploadImageIPFS(files) {
		try {
			const added = await client.add(files);
			const v0 = CID.parse(added.path);
			const encodePath = v0.toV1().toString();
			const url = `https://ipfs.io/ipfs/${encodePath}`;
			setFiles(url);
			console.log(url);
			setbtnTest('Success');
			setDisable(false);
			setLoadingOn(false);
		} catch (error) {
			console.log("Error uploading file: ", error);
			setError(true);
		}
	}


	async function uploadImageIPFSCover(files) {
		try {
			const added = await client.add(files);
			const v0 = CID.parse(added.path);
			const encodePath = v0.toV1().toString();
			const url = `https://ipfs.io/ipfs/${encodePath}`;
			console.log(url);
			setFilesCover(url)
		} catch (error) {
			console.log("Error uploading file: ", error);
			setError(true);
		}
	}

	async function uploadImageIPFSLogo(files) {
		try {
			const added = await client.add(files);
			const v0 = CID.parse(added.path);
			const encodePath = v0.toV1().toString();
			const url = `https://ipfs.io/ipfs/${encodePath}`;
			console.log(url);
			setCollectionLogoCreate(url)
		} catch (error) {
			console.log("Error uploading file: ", error);
			setError(true);
		}
	}
//new code start
async function UploadCroppedImage(raw) {
	fetch(`${apiUrl}/api/v1/upload_comp_images`, {
		method: 'POST',
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			setCompressedImage(JSON.parse(responseText).url);
		})
		.catch((error) => {
			console.error(error);
			setError(true);
		});
}
//new code end
	async function uploadCompressedImage(files) {
		try {
			var formdata = new FormData();
			formdata.append("image", files, files.name);
			UploadCroppedImage(formdata);
		} catch (error) {
			console.log("Error uploading file: ", error);
			setError(true);
		}
	}

	function isFileImage(file) {
		const acceptedImageTypes = [
			"image/gif",
			"image/jpeg",
			"image/png",
			"image/webp",
			"image/avif",
			"image/apng",
			"image/svg+xml",
			"image/bmp",
			"image/x-icon",
			"image/tiff"
		];
		return file && acceptedImageTypes.includes(file["type"]);
	}
	async function onChange(e) {
		const file = e.target.files[0];
		if (file.size > maxFileSize) {
			alert('*Please upload less than 30 mb file.');
			return false;
		} else {
			setLoadingOn(true);
			setCardItems(true);
			setImageType(isFileImage(file));
			setImageTypeUpload(isFileImage(file));
			var fileExtension = file.name.split(".").pop();
			console.log(fileExtension);
			setFileExe(fileExtension);
			// document.getElementById("file_name").style.display = "none";
			setPreview(URL.createObjectURL(file))
			// setFiles(URL.createObjectURL(files));
			setFiles(file);
			setName(file.name);
			setLoadingOn(false);
		}
	}


	useEffect(() => {
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			fetchData(user);
			fetchCollectionData(user);
		}
	}, [wallet]);



	const handleClose2 = () => {
		setShow2(false);
		setbtnTest('In progress...');
		setFiles(null);
		setPreview(uploadImage);
		setFilesCover(null);
		setCoverImage(false);
		setCardItems(false);
		setImageTypeUpload(false);
		setTitle('');
		setName('');
		document.getElementById('item_title').value = '';
	};
	const handleShow2 = () => {
		setShow(false)
		setShow2(true);
		uploadImageIPFS(files);
		if (!imageType) {
			uploadImageIPFSCover(previewCover)
		}
		if (imageType) {
			new Compressor(files, {
				quality: 0.5,
				success(result) {
					uploadCompressedImage(result);
				},
				error(err) {
					console.log(err.message);
				},
			});
		} else {
			new Compressor(previewCover, {
				quality: 0.5,
				success(result) {
					uploadCompressedImage(result);
				},
				error(err) {
					console.log(err.message);
				},
			});
		}
	};

	const handleShowCreateCollection = () => {
		setShowCreateCollection(true);
	};
	async function inputEvent(event) {
		if (event.target.value.length <= 100) {
			setTitleCharCount(event.target.value.length);
			setTitle(event.target.value);
		} else {
			setTitle((event.target.value).slice(0, 100));
			setTitleCharCount(100);
			document.getElementById('item_title').value = (event.target.value).slice(0, 100);
		}
	}
	async function inputEventDesc(event) {
		if (event.target.value.length <= 1000) {
			setDescCharCount(event.target.value.length);
			setDesc(event.target.value);
		} else {
			setDesc((event.target.value).slice(0, 1000));
			setDescCharCount(1000);
			document.getElementById('item_desc').value = (event.target.value).slice(0, 1000);
		}
	}
	async function inputEventDesc2(event) {
		if (event.target.value.length <= 500) {
			setUnlockableCharcount(event.target.value.length);
			setUnlockableDesc(event.target.value);
		} else {
			setUnlockableDesc((event.target.value).slice(0, 500));
			setUnlockableCharcount(500);
			document.getElementById('unlockable_desc').value = (event.target.value).slice(0, 500);
		}
	}

	function uploadCover(event) {
		const fileCoverData = event.target.files[0];
		if (fileCoverData.size > maxFileSize) {
			alert('*Please upload less than 30 mb file.');
			return false;
		} else {
			setPreviewCover(fileCoverData);
			setCoverImage(URL.createObjectURL(fileCoverData));
		}
	}
	function uploadLogo(event) {
		const fileLogoData = event.target.files[0];
		if (fileLogoData.size > maxFileSize) {
			alert('*Please upload less than 30 mb file.');
			return false;
		} else {
			new Compressor(fileLogoData, {
				quality: 0.2,
				success(result) {
					uploadImageIPFSLogo(result);
				},
				error(err) {
					console.log(err.message);
				},
			});
			setUploadLogoCollection(URL.createObjectURL(fileLogoData));
		}
	}
	function formSubmit(event) {
		event.preventDefault()
		if (title !== "" && desc !== "" && (imageTypeUpload || coverImage))
			setShow(true)
		else alert('Please fill up the details');
	}

	function CreateCollection(collectionLogoCreate, collecectionCreateName, collectionCreateDesc, user) {
		if (collectionLogoCreate && collecectionCreateName && collectionCreateDesc && user) {
			const CollectionData = JSON.stringify(
				{
					"name": collecectionCreateName,
					"logo": collectionLogoCreate,
					"description": collectionCreateDesc,
					"user_id": user
				}
			);
			fetch(apiUrl + "/api/v1/create_collection", {
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json', }),
				body: CollectionData
			}).then((response) => response.text())
				.then((responseText) => {
					console.log(responseText);
					fetchCollectionData(user);
					setUploadLogoCollection(false);
					setCollectionLogoCreate('');
					setCollectionCreateName('');
					setCollectionCreateDesc('');
					handleCloseCreateCollection();
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			alert('* Please fill all required details.');
		}
	}


	useEffect(() => {
		if (retrieveItem('token_type_title')) {
			setToken_Type_title((JSON.parse(retrieveItem('token_type_title')).token_type_title));
		}
	}, []);
	const ButtonStyle = styled.button`
	position: absolute;
	font-size: 12px;
	padding: 5px 10px;
	line-height: 1em;
	border: solid 1px #dddddd;
	display: inline-block;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	outline: none;
	margin-left: 1rem;
	&:hover {
		color: #fff;
		background: #8364e2;
		border: 0;
	}
`;
	const selectUnlockableContent = (e) => {
		const checked = e.target.checked;
		if (checked) {
			setIsUnlockable(true);
		} else {
			setIsUnlockable(false);
		}
	};
	console.log('checkbox style', isUnlockable);
	document.addEventListener("wheel", function (event) {
		if (document.activeElement.type === "number") {
			document.activeElement.blur();
		}
	});
	if (activityDataLoading) {
		return (
			<div
				style={{ height: "100vh" }}
				className="d-flex align-items-center justify-content-center"
			>
				<Spinner>Loading...</Spinner>
			</div>
		)
	} else {


		return (
			(wallet && wallet.signedIn) ?
				// userVerifyStatus ?
					<div>

						<Helmet>
							<title>UniqART - Create NFT</title>
							<link rel="canonical" href={websiteUrl + "/create"} />
							<meta property="og:title" content={`UniqART - Create NFT`} />
							<meta property="og:url" content={`${websiteUrl}/create`} />
						</Helmet>
						{activityDataLoading && (
							<div
								style={{ height: "100vh" }}
								className="d-flex align-items-center justify-content-center"
							>
								<Spinner>Loading...</Spinner>
							</div>
						)}
						{error ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "100vh",
									width: "100vw",
									position: "fixed",
									backgroundColor: "black",
									zIndex: 1111,
								}}
							>
								<div
									style={{
										backgroundColor: "white",
										padding: "50px",
										borderRadius: "50px",
									}}
								>
									<h4 style={{ color: "black" }}>Failed To Upload!</h4>
									<input
										type="button"
										id="submit"
										className="btn-main"
										value="Try Again!"
										style={{
											marginRight: "auto",
											marginLeft: "auto",
											marginTop: "20px",
										}}
										onClick={() => window.location.reload()}
									/>
								</div>
							</div>
						) : (
							""
						)}

						<section className="jumbotron breadcumb no-bg">
							<div
								className="mainbreadcumb create_breadcumb_mobile"
								style={{ padding: "100px 110px 60px 100px" }}
							>
								<div className="container">
									<div className="row m-10-hor">
										<div className="col-12">
											<h1 className="text-center">Create NFT</h1>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="container">
							<div className='row d-flex justify-content-center'>
								<div className="col-md-12">
									<div className="d_profile" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
										<div className="profile_avatar">
											<div className="d_profile_img">
												<img src={useCollectionImage} alt="" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
											</div>
										</div>
										<h4 className='mt-3'>{useCollectionName}</h4>
										<div className="nft__item_action mb-3">
											<span style={{ cursor: "pointer" }} onClick={() => { setShowCollection(true) }}>
												Change
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-7 offset-lg-1 mb-5">
									<form id="form-create-item" className="form-border" action="#" onSubmit={formSubmit}>
										<div className="field-set">
											<h5>Upload file</h5>

											<div className="d-create-file">
												{/* <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p> */}
												{/* {this.state.files.map(x => */}
												{/* )} */}
												<div className="browse">
													{!imageType ? (
														<div style={{ display: "flex", flexDirection: "column" }}>
															<span
																style={{ textAlign: "right", fontSize: "12px" }}

															>
																<span aria-hidden="true" className="icon_close text-danger" style={{ marginRight: '-2rem', fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => {
																	setImageTypeUpload(false);
																	setImageType(true);
																	setCardItems(false);
																	setCoverImage(false)
																}}></span>
															</span>
															<ReactPlayer
																url={preview}
																className="lazy nft__item_preview nft__item_preview2 setMobilePreview"
																id="get_file_2"
																width="300px"
																height="100%"
																controls={true}
															/>
														</div>
													) : imageTypeUpload ? (
														<>
															<p key="{index}">{name}</p>
															<div
																style={{ display: "flex", flexDirection: "column" }}
															>
																<span style={{ textAlign: "right", fontSize: "12px" }}

																>

																	<span aria-hidden="true" className="icon_close text-danger" style={{ marginRight: '-2rem', fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => {
																		setImageTypeUpload(false);
																		setCardItems(false);
																	}}></span>
																</span>
																<img
																	src={preview}
																	style={{ width: '100%' }}
																	id="get_file_2"
																	className="lazy nft__item_preview nft__item_preview2"
																	alt=""
																/>
															</div>
														</>
													) : (
														<>
															<p>PNG, JPG, GIF, WEBP or MP4. Max 30mb.</p>
															<input
																type="button"
																id="get_file"
																className="btn-main"
																value="Browse"
															/>
															<input
																id="upload_file"
																type="file"
																onChange={onChange}
															/>
														</>
													)}
												</div>
											</div>
											{!imageType && (
												<div className="d-create-file mt-3">
													<div className="browse">
														{!coverImage ? (
															<>
																<p>Upload Cover</p>
																<input
																	type="button"
																	id="get_file2"
																	className="btn-main"
																	value="Browse"
																/>
																<input
																	id="upload_file"
																	type="file"
																	onChange={uploadCover}
																	accept="image/png, image/gif, image/jpeg"
																/>
															</>
														) : (
															<div
																style={{ display: "flex", flexDirection: "column" }}
															>
																<span
																	style={{ textAlign: "right", fontSize: "12px" }}

																>
																	<span aria-hidden="true" className="icon_close text-danger" style={{ marginRight: '-2rem', fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => {
																		setCoverImage(false);
																	}}></span>
																</span>
																<img
																	src={coverImage}
																	className="lazy nft__item_preview nft__item_preview2"
																	alt="cover"
																/>
															</div>
														)}
													</div>
												</div>
											)}

											<div className="spacer-single"></div>

											<h5 className="d-flex justify-content-between"><span>Title</span> <span style={{ color: '#6c757d', fontSize: '1rem' }}>{titleCharcount}/100 </span></h5>
											<input
												type="text"
												name="item_title"
												id="item_title"
												className="form-control form-highlight"
												placeholder="e.g. 'Crypto Funk'"
												onChange={inputEvent}
											/>

											<div className="spacer-10"></div>

											<h5 className="d-flex justify-content-between"><span>Description</span><span style={{ color: '#6c757d', fontSize: '1rem' }}>{descCharcount}/1000 </span></h5>
											<textarea
												data-autoresize
												name="item_desc"
												id="item_desc"
												className="form-control form-highlight"
												placeholder="e.g. 'This is a limited item'"
												onChange={inputEventDesc}
											></textarea>

											<div className="spacer-10"></div>
											{/* add attribute code start */}
											<div className="spacer-10"></div>
											<div className="d-flex justify-content-between align-items-center">
											<h5>Attribute</h5>
											<div className="d-flex justify-content-between my-3">												
												<div className="nft__item_action">
													<span
														style={{ cursor: "pointer" }}
														onClick={async () => {
															if (attributeCount.length == 0) {
																let newAttrCount = attributeCount.concat(0)
																setAttributeCount(newAttrCount);
															} else {
																let newAttrCount = attributeCount.concat(attributeCount[attributeCount.length - 1] + 1)
																setAttributeCount(newAttrCount);
															}
														}}
													>
														<svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" style={{transform: "rotate(45deg)"}}><path fillRule="evenodd" clipRule="evenodd" d="M4.293 4.293a1 1 0 0 1 1.414 0L10 8.586l4.293-4.293a1 1 0 1 1 1.414 1.414L11.414 10l4.293 4.293a1 1 0 0 1-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L8.586 10 4.293 5.707a1 1 0 0 1 0-1.414z" fill="#E2E8F0"></path></svg>
													</span>
												</div>
											</div>
											</div>
																						
											<div style={{ overflowY: "scroll", maxHeight: "200px" }}>
												{attributeCount.map((item, index) => {
													return (
														<div key={item} id={`new${index}`}>
															<div className="d-flex justify-content-evenly align-items-center mt-2">
																<input className="form-control form-highlight" style={{ width: '40%' }} id={`user_attr${index}`} placeholder="Type" />
																<input
																	type="text"
																	name="item_attribute"
																	id="item_attribute"
																	className="form-control form-highlight"
																	style={{ width: '40%' }}
																	min="0"
																	placeholder="Value"
																	onChange={(e) => {
																		if (e.target.value === '') {
																			setErrorAttribute2(true);
																			delete attribute[document.getElementById(`user_attr${index}`).value]
																		}else {
																			setErrorAttribute2(false);							
																			setAttribute(
																				Object.assign({}, attribute, {
																					[document.getElementById(`user_attr${index}`).value]: e.target.value,
																				})
																			);											
																		}
																		console.log(attribute);
																	}}
																/>
																<span style={{ fontWeight: 'bold', marginBottom: "20px", color: "red", cursor: "pointer" }} id={`new${index}`} onClick={(event) => {
																	delete attribute[document.getElementById(`user_attr${index}`).value]
																	document.getElementById(event.target.id).remove();
																}}>X</span><br />
															</div>
															<p className={"item" + index} style={{ textAlign: 'center', color: 'red' }}></p>
														</div>
													)
												})}
											</div>											
											{/* add attribute code end */}

											<div className="spacer-10"></div>

											<h5>Royalties</h5>											
											<div style={{ overflowY: "scroll", maxHeight: "200px" }}>
												{royaltyCount.map((item, index) => {
													return (
														<div key={item} id={index}>
															<div className="d-flex justify-content-evenly align-items-center mt-2">
																<input className="form-control form-highlight" style={{ width: '40%' }} id={`user${index}`} placeholder="Account ID" onChange={async (e) => {
																	// setErrorRoyalties(true);
																	if (!e.target.value.length) {
																		document.querySelector('.item' + index).innerHTML = ""
																		// return setErrorRoyalties(false)

																	} else if ((e.target.value.includes('.' + networkId === 'mainnet') ? "near" : "testnet")) {
																		await isAccountTaken(e.target.value).then((result) => {
																			const exists = result;
																			if (!exists) {
																				document.querySelector('.item' + index).innerHTML = "This account does not exist"
																			}
																			else {
																				document.querySelector('.item' + index).innerHTML = ""
																			};
																		});

																	} else {
																		// setErrorRoyalties(true);
																		setErrorMessage(`The Account '${e.target.value}' does not exists`);
																		document.querySelector('.item' + index).innerHTML = "This account does not exist"
																	}

																}



																	// setRoyalties(
																	// 	Object.assign({}, royalties, {
																	// 		[receiver]: royalty,
																	// 	})
																	// );
																} />
																<input
																	type="number"
																	name="item_royalties"
																	id="item_royalties"
																	className="form-control form-highlight"
																	onChange={(e) => {
																		if (e.target.value === '') {
																			setErrorRoyalties2(true);
																			delete royalties[document.getElementById(`user${index}`).value]
																		}
																		else {
																			setErrorRoyalties2(false);
																			if (((document.getElementById(`user${index}`).value.includes('.' + networkId === 'mainnet')) ? "near" : "testnet")) {
																				isAccountTaken(document.getElementById(`user${index}`).value).then(result => {
																					if (result) {
																						console.log('Yeah')
																						setRoyalties(
																							Object.assign({}, royalties, {
																								[document.getElementById(`user${index}`).value]: e.target.value,
																							})
																						);
																					} else {
																						delete royalties[document.getElementById(`user${index}`).value]
																					}
																				})
																			}

																		}

																		console.log(royalties);
																	}}
																	style={{ width: '40%' }}
																	min="0"
																	placeholder="Enter royalty"
																/>
																<span style={{ fontWeight: 'bold', marginBottom: "20px", color: "red", cursor: "pointer" }} id={index} onClick={(event) => {
																	delete royalties[document.getElementById(`user${index}`).value]
																	document.getElementById(event.target.id).remove();
																}}>X</span><br />


															</div>
															<p className={"item" + index} style={{ textAlign: 'center', color: 'red' }}></p>
														</div>
													)
												})}
											</div>
											<div className="d-flex justify-content-between mt-3">
												<small>Suggested: 0, 10%, 20%. Max: 20% (Sum of all royalties should not exceed 20%)</small>
												<div className="nft__item_action">
													<span
														style={{ cursor: "pointer" }}
														onClick={async () => {
															if (royaltyCount.length == 0) {
																let newCount = royaltyCount.concat(0)
																setRoyaltyCount(newCount);
															} else {
																let newCount = royaltyCount.concat(royaltyCount[royaltyCount.length - 1] + 1)
																setRoyaltyCount(newCount);
															}

														}}
													>
														Add Royalty
													</span>
												</div>
											</div>
											<h5 className="mt-4"><i className="fa fa-lock" style={{ marginRight: '0.5rem' }}></i>Unlockable Content</h5>
											<div className="d-flex justify-content-between mt-3 align-content-center">
												<p className='mt-1'>Include unlockable content that can only be revealed by the owner of the item.</p>
												<div class="form-check form-switch">
													<input class="form-check-input" style={{ fontSize: '1.3rem', cursor: 'pointer' }} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e) => {
														selectUnlockableContent(e);
													}} />
												</div>
											</div>
											{
												isUnlockable &&
												(<>
													<h5 className="d-flex justify-content-end mt-2">
														<span style={{ color: '#6c757d', fontSize: '1rem' }}>{unlockableCharcount}/500 </span>
													</h5>
													<textarea
														data-autoresize
														name="unlockable_desc"
														id="unlockable_desc"
														className="form-control form-highlight"
														placeholder="Enter content (access key, code to redeem, link to a file, etc.)"
														onChange={inputEventDesc2}
														required
													></textarea></>)
											}
											<div className="spacer-10"></div>
											<h5 className="mt-4">Number Of Copies</h5>
											<input
												type="number"
												name="number_of_copies"
												id="number_of_copies"
												className="form-control form-highlight"
												onChange={(e) => setNumberOfCopies(e.target.value)}
												style={{ marginBottom: 0 }}
												value={numberOfCopies}
												min="1"
											/>
											<div className="spacer-10"></div>
											<input
												type="submit"
												id="submit"
												className="btn-main"
												value="Create NFT"
												style={{
													marginRight: "auto",
													marginLeft: "auto",
													marginTop: "20px",
													paddingTop: "15px",
													paddingBottom: "15px"
												}}
											/>
										</div>
									</form>
								</div>
								<div className="col-lg-3 col-sm-6 col-xs-12">
									<h5>Preview NFT</h5>
									<div className="nft__item m-0">
										<div className='icontype d-flex justify-content-center align-items-center'>
											{
												acceptedAudioTypes.includes(fileExe) &&
												<i className="fa fa-headphones"></i>
											}
											{
												acceptedVideoTypes.includes(fileExe) &&
												<i className="fa fa-video-camera"></i>
											}
											{
												(acceptedImageTypes.includes(fileExe) || fileExe == null || fileExe === "") &&
												<i className="fa fa-image"></i>
											}
											{isUnlockable &&
												<div className="d-flex justify-content-center align-items-center">
													<i
														className="fa fa-lock text-danger"
														data-tip="Includes unlockable content"
														style={{ fontSize: "1.1rem", paddingLeft: '8px' }}
													></i>
													<ReactTooltip
														textColor="#000"
														backgroundColor="#fff"
														className="tooltipStyle"
													/>
												</div>
											}
											{/* <i className="fa fa-shopping-basket"></i> */}
										</div>
										<div className="author_list_pp">
											<span>
												<img className="lazy" src={profileImg} alt="" />
												<i className="fa fa-check"></i>
											</span>
										</div>
										<div className="nft__item_wrap">
											<span className="h-100">
												{imageType && loadingOn ? (
													<div className="spinner-border text-light" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												) : imageType ? (
													<img
														src={preview}
														id="get_file_2"
														className="lazy nft__item_preview nft__item_preview2 h-100"
														height='250'
														width='200'
														alt=""
													/>
												) : loadingOn ? (
													<div className="spinner-border text-light" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												) : (
													<>
														<ReactPlayer
															url={preview}
															className="lazy nft__item_preview nft__item_preview2"
															id="get_file_2"
															width="100%"
															height="100%"
															controls={true}
														/>
													</>
												)}
											</span>
										</div>
										<div className="nft__item_info mb-0">
											{cardItems && (
												<>
													<span>
														<h4>{title}</h4>
													</span>
													<div className="nft__item_price">
														0.00 Ⓝ
														<span style={{ wordBreak: 'break-all' }}>#1 of {numberOfCopies}</span>
													</div>
													<div className="nft__item_action">
														<span>Buy Now</span>
													</div>
													<div className="nft__item_like">
														<i className="fa fa-eye"></i>
														<span>0</span>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</section>
						<Modal show={showModal2} onHide={handleClose2} className="mint-model w-100" centered backdrop="static">
							<Modal.Header style={{ backgroundColor: "#212428" }}>
								<h3 className="mb-0">Creating NFT</h3>
							</Modal.Header>
							<Modal.Body style={{ backgroundColor: "#212428" }}>
								<h4 className="mb-0">Upload</h4>
								<p>Uploading your image and meta data</p>
								<div className="d-flex justify-content-left btn-inprogress mb-5">
									<Btn style={{ backgroundColor: "#8364e2", width: "100%", borderRadius: "20px" }} className="text-center modal-body-button" onClick={handleClose2} disabled>
										{btnText}
									</Btn>
								</div>
								<h4 className="mb-0">Confirmation</h4>
								<p>Confirm your transaction on Near Wallet Small transaction fee is applied of 0.01 Ⓝ</p>
								<div className="d-flex justify-content-left">
									<Btn disabled={disable} className="text-center modal-body-button mb-3" onClick={() =>
										handleMint(account, royalties, files, title, desc, "true", fileExe, filesCover, numberOfCopies, selectCollectionId, useCollectionName, useCollectionDesc, useCollectionImage, compressedImage, isUnlockable, unlockableDesc,attribute)
									}>
										Confirm
									</Btn>
								</div>
								<div className="d-flex justify-content-left">
									<Btn className="text-center modal-body-button modal-body-cancel-button 		mb-3" onClick={handleClose2}>
										Cancel
									</Btn>
								</div>
							</Modal.Body>
						</Modal>
						<Modal show={showModal} onHide={handleClose} centered className="confirm-model" backdrop="static" >
							<Modal.Body style={{ backgroundColor: "#212428" }}>
								<div className="container confirm" style={{ backgroundColor: "#212428" }}>
									<div className="row confirm-row">
										<div className="col-md-6 col-lg-6 col-sm-12 confirm-img">
											<div className="shadow-lg bg-gray p-3 h-100">
												<div className="nft__item_wrap my-4">
													<span className="confirm-preview">
														{
															imageType ?
																<>
																	<img
																		src={preview}
																		id="get_file_2"
																		className="lazy nft__item_preview nft__item_preview2 confirm-preview-item"
																		alt=""
																		width="150"
																		height='200'
																		style={{ objectFit: 'cover' }}
																	/>
																</>
																:
																<>
																	<img
																		src={coverImage}
																		id="get_file_2"
																		className="lazy nft__item_preview nft__item_preview2 confirm-preview-item"
																		alt=""
																		height='200'
																		width="150"
																		style={{ objectFit: 'cover' }}
																	/>
																</>
														}

													</span>
												</div>
												<div className="nft__item_info">
													<span>
														<h4>{title}</h4>
													</span>
													<div className="nft__item_price">
														0.00 Ⓝ
														<span style={{ wordBreak: 'break-all' }}>#1 of {numberOfCopies}</span>
													</div>
													<div className="nft__item_action">
														<span>Buy Now</span>
													</div>
													<div className="nft__item_like">
														<i className="fa fa-eye"></i>
														<span>0</span>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-lg-6 col-sm-12 confirm-info d-flex align-items-center">
											<div className="confirm-info-body w-100">
												<h2 style={{ fontSize: "27px", lineHeight: "27px" }}>Create NFT</h2>
												<h5>Confirm NFT creation?</h5>
												<div className="d-flex justify-content-left mt-4">
													<Btn className="text-center modal-body-button mb-3" onClick={handleShow2}>Confirm</Btn>
												</div>
												<div className="d-flex justify-content-left">
													<Btn className="text-center modal-body-button modal-body-cancel-button mb-3" onClick={handleClose}>
														Cancel
													</Btn>
												</div>
											</div>
										</div>
									</div>
								</div>

							</Modal.Body>
						</Modal>
						<Modal show={showModalCreateCollection} onHide={handleCloseCreateCollection} className="mint-model w-100 setCollection" centered backdrop="static">
							<Modal.Header style={{ backgroundColor: "#212428" }} closeButton>
								<h2 className="mb-0">Create Collection</h2>
							</Modal.Header>
							<Modal.Body style={{ backgroundColor: "#212428" }}>
								<h4 className="mb-0">Logo</h4>
								<div className="d-create-file mt-3 p-4">
									<div className="browse">
										{!uploadLogoCollection ? (
											<>
												<p>Upload Logo</p>
												<input
													type="button"
													id="get_file"
													className="btn-main"
													value="Browse"
												/>
												<input
													id="upload_file"
													type="file"
													onChange={uploadLogo}
													accept="image/png, image/gif, image/jpeg"
												/>
											</>
										) : (
											<div
												style={{ display: "flex", flexDirection: "column" }}
											>
												<span
													style={{ textAlign: "right", fontSize: "12px" }}

												>
													<span aria-hidden="true" className="icon_close text-danger" style={{ marginRight: '-2rem', fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => {
														setUploadLogoCollection(false);
													}}></span>
												</span>
												<img
													src={uploadLogoCollection}
													style={{ width: '100%' }}
													className="lazy nft__item_preview nft__item_preview2"
													alt="cover"
												/>
											</div>
										)}
									</div>
								</div>
								<div className="spacer-20"></div>
								<h5>Name</h5>
								<input
									type="text"
									name="item_title"
									id="item_title"
									className="form-control form-highlight"
									placeholder="e.g. 'Crypto Funk'"
									onChange={(e) => setCollectionCreateName(e.target.value)}
								/>
								<div className="spacer-10"></div>
								<h5>Description</h5>
								<textarea
									data-autoresize
									name="item_desc2"
									id="item_desc2"
									className="form-control form-highlight"
									placeholder="e.g. 'This is a limited item'"
									onChange={(e) => setCollectionCreateDesc(e.target.value)}
								></textarea>
								<div className="spacer-10"></div>
								<div className="d-flex justify-content-left">
									<Btn className="text-center modal-body-button mb-3" onClick={() => { CreateCollection(collectionLogoCreate, collecectionCreateName, collectionCreateDesc, account.accountId) }}>
										Create
									</Btn>
								</div>
							</Modal.Body>
						</Modal>
						<Modal show={showModalCollection} onHide={handleCloseCollection} centered className="confirm-model" backdrop="static" >
							<Modal.Body style={{ backgroundColor: "#212428" }}>
								<div className="container confirm py-3" style={{ backgroundColor: "#212428" }}>
									<div className="row confirm-row">
										<div className="col-md-6 col-lg-6 col-sm-12 confirm-img">
											{activeIndex ?
												<div className="shadow-lg bg-gray p-3" style={{ border: '1px solid #343a40', borderRadius: '10px' }}>
													<div className="nft__item_wrap my-4">
														<span className="confirm-preview confirm-preview2">
															<img src={collectionImage} alt="img" height='200px' width='200px' style={{ borderRadius: '50%', height: '150px', width: '150px', objectFit: 'cover' }} />
														</span>
													</div>
													<div className="text-center">
														<h3>{collectionName}</h3>
														<span style={{ height: '5.5rem', overflowX: 'hidden', display: 'block' }}>
															<p>{collectionDescription}</p>
														</span>
													</div>
												</div>
												:
												<div className="shadow-lg bg-gray p-3" style={{ border: '1px solid #343a40', borderRadius: '10px' }}>
													<div className="nft__item_wrap my-4">
														<span className="confirm-preview">
															<img src='https://via.placeholder.com/250x350/212428/e3e3e3?text=Collection Preview' alt="img" style={{ height: '300px', width: '100%' }} />
														</span>
													</div>
												</div>}
										</div>
										<div className="col-md-6 col-lg-6 col-sm-12 confirm-info d-flex align-items-center">
											<div className="confirm-info-body w-100">
												<h2 style={{ fontSize: "27px", lineHeight: "27px" }}>Choose Collection</h2>
												<h6>Select an existing collection or create a new one</h6>
												<div className="d-flex justify-content-left mt-4 collectionButton">
													<div className="text-center modal-body-button mb-3" onClick={handleShowCreateCollection}> + Create New Collection</div>
												</div>
												<span style={{ height: '13.5rem', overflowX: 'hidden', display: 'block' }}>
													{collectionData.map((data, index) => (
														<div className="d-flex justify-content-left collectionButton" key={index}>
															<div className={activeIndex === (index + 1) ? 'modal-body-button mb-3 d-flex justify-content-start text-center align-items-center p-1 collectionActive' : 'modal-body-button mb-3 d-flex justify-content-start text-center align-items-center p-1'} onClick={() => handleBtnClick((index + 1), (data.name), (data.description), (data.logo), (data.collection_id))} style={{ border: '3px solid transparent' }}>
																<img src={data.logo} alt="" style={{ height: '40px', width: '40px' }} />
																<h6 className="p-0 m-0 mx-2">{data.name}</h6>
															</div>
														</div>
													))}
												</span>
											</div>
										</div>
									</div>
									<div className='row d-flex justify-content-end'>
										<div className='col-md-8 col-lg-8 col-sm-12'>
											<div className="d-flex justify-content-left mt-4">
												<Btn className="text-center modal-body-button modal-body-cancel-button mb-3" style={{ backgroundColor: '#343a40', border: 'none', padding: '12px' }} onClick={() => navigate('market')} >
													Back
												</Btn>
												<Btn className={(selectCollectionId && collectionName && collectionDescription && collectionImage) ? 'text-center modal-body-button mb-3 mx-2' : 'disabled text-center modal-body-button mb-3 mx-2'} style={{ padding: '12px' }} onClick={() => { handleBtnClick1(selectCollectionId, collectionName, collectionDescription, collectionImage) }}>Confirm</Btn>
											</div>

										</div>
									</div>
								</div>

							</Modal.Body>
						</Modal>
						<Footer />
					</div>
					// :
					// <VerifyModal show={modalShow2} onHide={() => setModalShow2(false)} />
				:
				<div>
					<GlobalStyles />
					<LoginModal show={modalShow} onHide={() => setModalShow(false)} />
				</div>

		);
	}
};

export default Create;