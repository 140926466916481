import axios from "axios";
import { concat } from 'lodash';
import React, { useEffect, useState, memo, useContext } from "react";
import { Helmet } from "react-helmet";
import InfiniteScroll from 'react-infinite-scroll-component';
import "react-loading-skeleton/dist/skeleton.css";
import { Spinner } from "reactstrap";
import { createGlobalStyle } from "styled-components";
import ActivityComponent from "../components/ActivityComponent";
import Footer from "../components/footer";
import { appStore } from "../../state/app";
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const MyActivity = function () {
	const { state } = useContext(appStore);
	const { wallet, account } = state;
	const [activityData, setData] = React.useState([]);
	const [activityDataLoading, setActivityDataLoading] = React.useState(false);
	const [loadmorecountfliter, setloadmorecountfilter] = useState(0);
	const [filteritemsStatus, setFilterItemsStatus] = useState(true);

	const fetchActivityData = async (loadmorecountfliter, user) => {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/fetch_user_activity/${loadmorecountfliter}?address=${user}`);
			if (result.data["status"] === "success") {
				const newListFilter = concat(...activityData, result.data['data']);
				setActivityDataLoading(false);
				setData(newListFilter);
			} else {
				setFilterItemsStatus(false);
			}

		} catch (error) {
			setActivityDataLoading(true);
			console.error(error);
		}
	}
	useEffect(() => {
		setActivityDataLoading(true);
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			fetchActivityData(0, user);
			setFilterItemsStatus(true);
		}
	}, [account]);


	useEffect(() => {
		if ((wallet && wallet.signedIn)) {
			const user = account.accountId;
			if (loadmorecountfliter > 0) {
				fetchActivityData(loadmorecountfliter, user);
			}
		}
	}, [loadmorecountfliter, account]);

	const loadMoreFilter = () => {
		setloadmorecountfilter(loadmorecountfliter + 1);
	}

	return (
		<div>
			<GlobalStyles />
			<Helmet>
				<title>UniqART - Activity</title>
				<link rel="canonical" href={websiteUrl + "/activity"} />
				<meta property="og:title" content={`UniqART - Activity`} />
				<meta property="og:url" content={`${websiteUrl}/activity`} />
			</Helmet>
			{activityDataLoading && (
				<div
					style={{ height: "100vh" }}
					className="d-flex align-items-center justify-content-center"
				>
					<Spinner>Loading...</Spinner>
				</div>
			)}

			{!activityDataLoading && (
				<section className="jumbotron breadcumb no-bg">
					<div className="mainbreadcumb">
						<div className="container">
							<div className="row m-10-hor">
								<div className="col-12">
									<h1 className="text-center">My Activity</h1>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}

			{!activityDataLoading && (
				<section className="container">
					<div className="row">
						<div className="col-md-12">
							<ul className="activity-list">
								<InfiniteScroll style={{ overflow: "hidden" }}
									dataLength={activityData.length} //This is important field to render the next data
									next={loadMoreFilter}
									hasMore={filteritemsStatus}
									loader={<h4 className="text-center">Loading...</h4>}
									endMessage={
										<p style={{ textAlign: 'center', margin: '10rem 0' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}
								>
									{activityData &&
										activityData.map((item, idx) => (
											<ActivityComponent key={idx} item={item} />
										))}
								</InfiniteScroll>
							</ul>
						</div>
					</div>
				</section>
			)}
			<Footer
				customStyles={{
					background: "#212529",
					position: "sticky",
					width: "100%",
					bottom: 0,
					zIndex: "25",
				}}
			/>
		</div>
	);
};
export default memo(MyActivity);
