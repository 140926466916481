import { navigate } from "@reach/router";
import { create } from "ipfs-http-client";
import { CID } from "multiformats/cid";
import React, { useContext, useEffect, useState } from 'react';
import { appStore, onAppMount } from "../../state/app";
import Footer from '../components/footer';
import Getconfig from '../../config';
export const {
	websiteUrl, apiUrl
} = Getconfig();
const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET_KEY;
const auth ='Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');


const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

const CreateCollection = () => {
	const { state, dispatch } = useContext(appStore);
	const { account, wallet } = state;
	const onMount = () => {
		dispatch(onAppMount());
	};
	const [collectionLogoCreate, setCollectionLogoCreate] = useState('');
	const [collecectionCreateName, setCollectionCreateName] = useState('');
	const [collectionCreateDesc, setCollectionCreateDesc] = useState('');
	const [uploadLogoCollection, setUploadLogoCollection] = useState(false);
	const [error, setError] = useState(false);
	useEffect(onMount, []);
	async function uploadImageIPFSLogo(files) {
		try {
			const added = await client.add(files);
			const v0 = CID.parse(added.path);
			const encodePath = v0.toV1().toString();
			const url = `https://ipfs.io/ipfs/${encodePath}`;
			console.log(url);
			setCollectionLogoCreate(url)
		} catch (error) {
			console.log("Error uploading file: ", error);
			setError(true);
		}
	}
	function CreateCollection(collectionLogoCreate, collecectionCreateName, collectionCreateDesc, user) {
		if (collectionLogoCreate && collecectionCreateName && collectionCreateDesc && user) {
			const CollectionData = JSON.stringify(
				{
					"name": collecectionCreateName,
					"logo": collectionLogoCreate,
					"description": collectionCreateDesc,
					"user_id": user
				}
			);
			fetch(apiUrl + "/api/v1/create_collection", {
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json', }),
				body: CollectionData
			}).then((response) => response.text())
				.then((responseText) => {
					console.log(responseText);
					setUploadLogoCollection(false);
					setCollectionLogoCreate('');
					setCollectionCreateName('');
					setCollectionCreateDesc('');
					navigate(`create`);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			alert('* Please fill all required details.');
		}
	}
	function uploadLogo(event) {
		uploadImageIPFSLogo(event.target.files[0]);
		setUploadLogoCollection(URL.createObjectURL(event.target.files[0]));
	}

	return (
		<div>
			<section className='jumbotron breadcumb no-bg' >
				<div className='mainbreadcumb'>
					<div className='container'>
						<div className='row m-10-hor'>
							<div className='col-12'>
								<h1 className='text-center'>Create Collection</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='container'>
				<div className='row d-flex justify-content-center'>
					<div className='col-6'>
						<h4 className="mb-0">Logo</h4>
						<div className="d-create-file mt-3">
							<div className="browse">
								{!uploadLogoCollection ? (
									<>
										<p>Upload Logo</p>
										<input
											type="button"
											id="get_file"
											className="btn-main"
											value="Browse"
										/>
										<input
											id="upload_file"
											type="file"
											onChange={uploadLogo}
											accept="image/png, image/gif, image/jpeg"
										/>
									</>
								) : (
									<div
										style={{ display: "flex", flexDirection: "column" }}
									>
										<span
											style={{ textAlign: "right", fontSize: "12px" }}

										>
											<span aria-hidden="true" className="icon_close text-danger" style={{ marginRight: '-2rem', fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => {
												setUploadLogoCollection(false);
											}}></span>
										</span>
										<img
											src={uploadLogoCollection}
											style={{ width: '100%' }}
											className="lazy nft__item_preview"
											alt="cover"
										/>
									</div>
								)}
							</div>
						</div>
						<div className="spacer-20"></div>
						<h5>Name</h5>
						<input
							type="text"
							name="item_title"
							id="item_title"
							className="form-control form-highlight"
							placeholder="e.g. 'Crypto Funk'"
							onChange={(e) => setCollectionCreateName(e.target.value)}
						/>
						<div className="spacer-10"></div>
						<h5>Description</h5>
						<textarea
							data-autoresize
							name="item_desc"
							id="item_desc"
							className="form-control form-highlight"
							placeholder="e.g. 'This is a limited item'"
							onChange={(e) => setCollectionCreateDesc(e.target.value)}
						></textarea>
						<div className="spacer-10"></div>
						<div className="d-flex justify-content-left">
							<button className="text-center modal-body-button mb-3" onClick={() => { CreateCollection(collectionLogoCreate, collecectionCreateName, collectionCreateDesc, account.accountId) }}>
								Create
							</button>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>

	)
};
export default CreateCollection;