/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { concat } from 'lodash';
import { memo, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from "reactstrap";
import Getconfig from '../../config';
import { parseNearAmount, token2symbol } from '../../state/near';
import { getMarketStoragePaid, loadItems } from '../../state/views';
import NftCardMarket from './NftCardMarket';
export const {
	websiteUrl, apiUrl
} = Getconfig();

const ColumnNewThreeColRedux = ({ app, views, loading, contractAccount, account, dispatch, filters }) => {
	if (!contractAccount) return null;
	const [filterchange, setFilterChange] = useState([]);
	const [loadmorecountfliter, setloadmorecountfilter] = useState(0);
	const [filteritemsStatus, setFilterItemsStatus] = useState(true);
	const [activityDataLoading, setActivityDataLoading] = useState(true);

	const fetchnftCatdata = async (type, loadmorecountfliter) => {
		try {
			const result = await axios.get(`${apiUrl}/api/v1/filter_nft/${loadmorecountfliter}?${type}`)
			if (result.data["status"] === "success") {
				const newListFilter = concat(...filterchange, result.data['data']);
				setFilterChange(newListFilter);
			} else {
				setFilterItemsStatus(false);
			}
			setActivityDataLoading(false)
		} catch (error) {
			console.error(error);
			setActivityDataLoading(false)
		}
	}


	useEffect(() => {
		if ((filters) !== '') {
			setFilterItemsStatus(true);
			filterchange.length = 0;
			setloadmorecountfilter(0);
			fetchnftCatdata(filters, 0);
			setloadmorecountfilter(0);
		}

	}, [filters]);

	useEffect(() => {
		if ((filters) !== '') {
			if (loadmorecountfliter > 0) {
				fetchnftCatdata(filters, loadmorecountfliter);
			}
		}
	}, [filters, loadmorecountfliter]);

	const [height, setHeight] = useState(0);

	const onImgLoad = ({ target: img }) => {
		let currentHeight = height;
		if (currentHeight < img.offsetHeight) {
			setHeight(img.offsetHeight);
		}
	}


	useEffect(() => {
		if (!loading) {
			dispatch(loadItems(account))
			dispatch(getMarketStoragePaid(account));
		}
	}, [loading]);


	const { marketStoragePaid } = views

	let accountId = '';
	if (account) accountId = account.accountId;


	const loadMoreFilter = () => {
		setloadmorecountfilter(loadmorecountfliter + 1);
	}
	return (
		activityDataLoading ?

			(<div
				style={{ height: "100vh" }}
				className="d-flex align-items-center justify-content-center"
			>
				<Spinner>Loading...</Spinner>
			</div>)
			:
			(
				<InfiniteScroll style={{ overflow: "hidden" }}
					dataLength={filterchange.length}
					next={loadMoreFilter}
					hasMore={filteritemsStatus}
					loader={<div
						style={{ height: "100vh" }}
						className="d-flex align-items-center justify-content-center"
					>
						<Spinner>Loading...</Spinner>
					</div>}
					endMessage={
						<p className="my-5" style={{ textAlign: 'center' }}>
							<b>Yay! You have seen it all</b>
						</p>
					}
				>
					<div className='row'>
						{
							filterchange.map(person => (
								<NftCardMarket key={person.nft_id} extra={person.extra} media={person.media_url} clicks={person.total_clicks} copies={person.no_copies} title={person.name} description={person.description} owner_id={person.seller_address} nft={person.media_url} token_id={person.sell_id} sale_conditions={{ "near": parseNearAmount(person.min_sell_price) }} bids={{}} royalty={person.royalty} marketStoragePaid={marketStoragePaid} token2symbol={token2symbol} accountId={accountId} account={account} path={'/token/' + person.sell_id} onImgLoad={onImgLoad} height={height} className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4" current_copy={person.current_copy} is_auction={person.is_auction} collection_name={person.collection_name} collection_id={person.collection_id} profile_pic={person.profile_pic} verified={person.verified} is_unlockable={person.is_unlockable} nftObject={person} compressed_url={person.compressed_url} extension={person.extension} min_sell_price={person.min_sell_price} fav_count={person.fav_count} />
							))
						}
					</div>
				</InfiniteScroll>
			)
	);
}
export default memo(ColumnNewThreeColRedux);