import { Link, navigate } from "@reach/router";
import axios from "axios";
import { create } from "ipfs-http-client";
import { useContext, useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import Breakpoint, {
	BreakpointProvider,
	setDefaultBreakpoints
} from "react-socks";
import Getconfig from '../../config';
import { appStore, onAppMount } from "../../state/app";
export const {
	websiteUrl, apiUrl, walletUrl
} = Getconfig();

function SetLogin(raw) {
	fetch(apiUrl + "/api/v1/login", {
		method: "POST",
		headers: new Headers({ "Content-Type": "application/json" }),
		body: raw,
	})
		.then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
		})
		.catch((error) => {
			console.error(error);
		});
}

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
	<Link
		{...props}
		getProps={({ isCurrent }) => {
			// the object returned here is passed to the
			// anchor element's props
			return {
				className: isCurrent ? "active" : "non-active",
			};
		}}
	/>
);

const Header = function () {
	const { state, dispatch, update } = useContext(appStore);
	const {
		app,
		views,
		app: { tab, snack },
		near,
		wallet,
		contractAccount,
		account,
		loading,
	} = state;

	const onMount = () => {
		dispatch(onAppMount());
	};
	useEffect(onMount, []);

	const [ProfileImage, setProfileImage] = useState("");
	const [openMenu1, setOpenMenu1] = useState(false);
	const [openMenu4, setOpenMenu4] = useState(false);


	var fetchProfile = async function fetchData(userAddress) {
		localStorage.setItem("username", userAddress);
		try {
			const result = await axios.get(
				apiUrl + "/api/v1/fetch_profile_pic?address=" + userAddress
			);
			if (result.data["status"] === "Success") {
				const newList = result.data["results"].profile_pic;
				if (newList !== null && newList.startsWith("./img")) {
					setProfileImage(websiteUrl + "/" + newList.substring(1));
				} else {
					setProfileImage(newList);
				}
			} else {
				setProfileImage("");
			}
		} catch (error) {
			console.error(error);
		}
	};
	const signedIn = wallet && wallet.signedIn;
	useEffect(() => {
		// test start

		if (signedIn) {
			var raw = JSON.stringify({
				address: account.accountId,
			});
			SetLogin(raw);
			if (account) {
				fetchProfile(account.accountId);
			}
		}
		// test end
	}, [signedIn]);

	/* ---------------------------- set session code end ---------------------------- */

	const handleBtnClick1 = () => {
		setOpenMenu1(!openMenu1);
	};

	const handleBtnClick4 = () => {
		setOpenMenu4(!openMenu4);
	};

	const closeMenu1 = () => {
		setOpenMenu1(false);
	};

	const closeMenu4 = () => {
		setOpenMenu4(false);
	};
	const ref1 = useOnclickOutside(() => {
		closeMenu1();
	});

	const ref4 = useOnclickOutside(() => {
		closeMenu4();
	});

	const [showmenu, btn_icon] = useState(false);

	function storePathValues() {
		const storage = window?.sessionStorage;
		if (!storage) return;
		const currPath = `${window?.location.pathname}${window?.location.search}`;
		const prevPath = storage.getItem("currentPath");
		if (prevPath !== currPath) {
			storage.setItem("prevPath", prevPath);
		}
		storage.setItem("currentPath", currPath);
	}
	useEffect(() => {
		storePathValues();
	});

	useEffect(() => {
		const header = document.getElementById("myHeader");
		const totop = document.getElementById("scroll-to-top");
		const sticky = header.offsetTop;

		const scrollCallBack = window.addEventListener("scroll", () => {
			btn_icon(false);
			if (window.pageYOffset > sticky) {
				header.classList.add("sticky");
				totop.classList.add("show");
			} else {
				header.classList.remove("sticky");
				totop.classList.remove("show");
			}
			if (window.pageYOffset > sticky) {
				// closeMenu();
			}
		});
		if (account) {
			fetchProfile(account.accountId);
			// localStorage.setItem('username', account.accountId)
		}

		return () => {
			window.removeEventListener("scroll", scrollCallBack);
		};
	}, []);

	const _handleKeyDown = async (e) => {
		if (e.key === "Enter") {
			navigate("/search?q=" + e.target.value);
		}
	};

	return (
		<>
			<header id="myHeader" className="navbar white">
				<div className="container">
					<div className="row w-100-nav">
						<div className="logo px-0">
							<div className="navbar-title navbar-item">
								<NavLink to="/">
									<img
										src={websiteUrl + "/img/logo-light.png"}
										className="img-fluid"
										alt="#"
									/>
								</NavLink>
							</div>
						</div>

						<div className="search mobile_search">
							<input
								id="quick_search"
								className="mobile_search_setting"
								name="quick_search"
								placeholder="Search in Marketplace"
								type="text"
								onKeyDown={_handleKeyDown}
							/>
						</div>

						<BreakpointProvider>
							<Breakpoint l down>
								{showmenu && (
									<div className="menu " ref={ref4}>
										<div className="navbar-item">
											<NavLink to="/market" onClick={() => btn_icon(!showmenu)}>
												Market
											</NavLink>
										</div>
										<div className="navbar-item">
											<NavLink
												to="/activity"
												onClick={() => btn_icon(!showmenu)}
											>
												Activity
											</NavLink>
										</div>
										<div className="navbar-item">
											<NavLink to="/collection">
												My Collection
												<span className="lines"></span>
											</NavLink>
										</div>
										<div className="navbar-item">
											<NavLink to="/create" onClick={() => btn_icon(!showmenu)}>
												Create
											</NavLink>
										</div>
										<div className="navbar-item">
											<a href="https://uniqart-io.notion.site/FAQs-1c2ad2a5cb5649bcb34afeb3bfaa5fd4" target="_blank">
												FAQ
												<span className="lines"></span>
											</a>
										</div>
										{wallet && wallet.signedIn ? (
											<div
												className="navbar-item setNavbar"
												style={{
													position: "relative",
													right: "100px",
												}}
											>
												<div >
													<div
														className="dropdown-custom dropdown-toggle btn"
														onMouseEnter={handleBtnClick4}
														onMouseLeave={closeMenu4}
													>
														{ProfileImage ?
															(<img
																src={ProfileImage}
																alt=""
																style={{
																	height: "2rem",
																	width: "2rem",
																	borderRadius: "50%",
																	// marginLeft: "15px",
																}}
															/>)
															:
															(<img
																src={websiteUrl + '/img/author/author-11.jpg'}
																alt=""
																style={{
																	height: "2rem",
																	width: "2rem",
																	borderRadius: "50%",
																	// marginLeft: "15px",
																}}
															/>)
														}
														{/* <i className="fa fa-user-circle fa-2x"></i> */}
														{openMenu4 && (
															<div className="item-dropdown">
																<div className="dropdown" onClick={closeMenu4}>
																	<NavLink to="/collection">
																		{!signedIn
																			? "Account not found"
																			: account.accountId}
																	</NavLink>
																	<a
																		href={walletUrl}
																		target="_blank"
																		rel="noreferrer noopener"
																	>
																		Balance : {wallet.balance} Ⓝ
																	</a>

																	<NavLink to="/createCollection">
																		Create Collection
																	</NavLink>
																	<NavLink to="/create">Create Card</NavLink>
																	<NavLink to="/verification-form">Be a verified creator</NavLink>
																	<NavLink to="/collection">My Collection</NavLink>
																	<NavLink to="/myActivity">My Activity</NavLink>
																	<NavLink to="/myOffers">My Offers</NavLink>
																	<a onClick={() => wallet.signOut()}>Logout</a>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										) : (
											""
										)}
									</div>
								)}
							</Breakpoint>

							<Breakpoint xl>
								<div
									className="menu"
									style={{ position: "relative", right: "30px" }}
								>
									<div className="navbar-item">
										<NavLink to="/market" onClick={() => btn_icon(!showmenu)}>
											Market<span className="lines"></span>
										</NavLink>
									</div>
									<div className="navbar-item">
										<NavLink to="/activity">
											Activity
											<span className="lines"></span>
										</NavLink>
									</div>
									<div className="navbar-item">
										<NavLink to="/collection">
											My Collection
											<span className="lines"></span>
										</NavLink>
									</div>
									<div className="navbar-item">
										<NavLink to="/create">
											Create
											<span className="lines"></span>
										</NavLink>
									</div>
									<div className="navbar-item">
										<a href="https://uniqart-io.notion.site/FAQs-1c2ad2a5cb5649bcb34afeb3bfaa5fd4" target="_blank">
											FAQ
											<span className="lines"></span>
										</a>
									</div>
									{(wallet && wallet.signedIn) &&
										<div className="navbar-item">
											<NavLink to="/myActivity">
												<i className="fa fa-bell" />
												<span className="lines"></span>
											</NavLink>
										</div>
									}
									{wallet && wallet.signedIn ? (
										<div className="navbar-item">
											<div ref={ref4}>
												<div
													className="dropdown-custom dropdown-toggle btn"
													onMouseEnter={handleBtnClick4}
													onMouseLeave={closeMenu4}
													style={{ position: "relative", right: "0px" }}
												>
													{ProfileImage ?
														(<img
															src={ProfileImage}
															alt=""
															style={{
																height: "2rem",
																width: "2rem",
																borderRadius: "50%",
																// marginLeft: "15px",
															}}
														/>)
														:
														(<img
															src={websiteUrl + '/img/author/author-11.jpg'}
															alt=""
															style={{
																height: "2rem",
																width: "2rem",
																borderRadius: "50%",
																// marginLeft: "15px",
															}}
														/>)
													}

													{openMenu4 && (
														<div className="item-dropdown" style={{ left: '-100%' }}>
															<div className="dropdown" onClick={closeMenu4}>
																<NavLink to="/collection">
																	{!signedIn
																		? "Account not found"
																		: account.accountId}
																</NavLink>
																<a
																	href={walletUrl}
																	target="_blank"
																	rel="noreferrer noopener"
																>
																	Balance : {wallet.balance} Ⓝ
																</a>
																<NavLink to="/createCollection">
																	Create Collection
																</NavLink>
																<NavLink to="/create">Create Card</NavLink>
																<NavLink to="/verification-form">Be a verified creator</NavLink>
																<NavLink to="/collection">My Collection</NavLink>
																<NavLink to="/myActivity">My Activity</NavLink>
																<NavLink to="/myOffers">My Offers</NavLink>
																<a onClick={() => wallet.signOut()}>Logout</a>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</Breakpoint>
						</BreakpointProvider>
						{!(wallet && wallet.signedIn) ? (
							<div className="mainside">
								<a
									className="btn-main"
									onClick={() => wallet.signIn()}
									style={{ cursor: "Pointer" }}
								>
									Connect Wallet
								</a>
							</div>
						) : (
							""
						)}
					</div>
					<button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
						<div className="menu-line white"></div>
						<div className="menu-line1 white"></div>
						<div className="menu-line2 white"></div>
					</button>
				</div>
			</header>
		</>
	);
};
export default Header;
