import { create } from "ipfs-http-client";
import JSBI from 'jsbi';
import { CID } from "multiformats/cid";
import * as nearAPI from 'near-api-js';
import { providers, transactions } from 'near-api-js';
import { contractId, GAS, marketId, parseNearAmount } from '../state/near';
import Getconfig from '../config';
import CryptoJS from 'crypto-js';
import { getWallet } from '../utils/near-utils';
import { createTransaction, functionCall } from 'near-api-js/lib/transaction'
import { base_decode } from 'near-api-js/lib/utils/serialize'
import { PublicKey } from 'near-api-js/lib/utils'
export const {
	websiteUrl, apiUrl, rpcNode, extraFee, offerextraFee, nftApprove, nftApproveStorageDeposit
} = Getconfig();

const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET_KEY;
const auth ='Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const { utils } = nearAPI;
const STORAGE_ADD_MARKET_FEE = '8590000000000000000000';
const GAS_FEE_150 = `150000000000000`;
const GAS_FEE_200 = `200000000000000`;
const GAS_FEE_300 = `300000000000000`;
const STORAGE_MINT_FEE = '11280000000000000000000';
const STORAGE_CREATE_SERIES_FEE = '8540000000000000000000';
const STORAGE_APPROVE_FEE = '760000000000000000000';
const TOKEN_DELIMETER = ':';
const CONTRACT_TOKEN_DELIMETER = '||';
const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

// create API start
const storage = window?.sessionStorage;

function CreateNFT(raw, token_type_id) {
	console.log('createnft')
	fetch(`${apiUrl}/api/v1/nft_create`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			removeItem('dataNft');
			window.location.replace('/token/' + token_type_id);
		})
		.catch((error) => {
			console.error(error);
		});
}
function CreateNewNFT(raw) {
	fetch(`${apiUrl}/api/v1/nft_create`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			window.location.replace(storage.getItem('prevPath'));
		})
		.catch((error) => {
			console.error(error);
		});
}

function CreateAddOffer(raw) {
	fetch(`${apiUrl}/api/v1/create_offer`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			window.location.replace(storage.getItem('prevPath'));
		})
		.catch((error) => {
			console.error(error);
		});
}


function UpdateNFT(raw) {
	fetch(`${apiUrl}/api/v1/update_nft_create`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			window.location.replace(storage.getItem('prevPath'));
		})
		.catch((error) => {
			console.error(error);
		});
}
/* ----------------------------- new code start ----------------------------- */
function UpdateNFTNew(raw) {
	fetch(`${apiUrl}/api/v1/update_nft_create`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			// window.location.replace(storage.getItem('prevPath'));
		})
		.catch((error) => {
			console.error(error);
		});
}
/* ------------------------------ new code end ------------------------------ */
function UpdateAddOffer(raw) {
	fetch(`${apiUrl}/api/v1/update_offer`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			window.location.replace(storage.getItem('prevPath'));
		})
		.catch((error) => {
			console.error(error);
		});
}

function AddOrderNFT(raw) {
	fetch(`${apiUrl}/api/v1/add_order`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
		})
		.catch((error) => {
			console.error(error);
		});
}

function deleteAddOfferUser(raw) {
	fetch(`${apiUrl}/api/v1/delete_buyer_offer`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			window.location.replace(storage.getItem('prevPath'));
		})
		.catch((error) => {
			console.error(error);
		});
}

function DeleteToken(raw) {
	fetch(`${apiUrl}/api/v1/delete_nft`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json', }),
		body: raw
	}).then((response) => response.text())
		.then((responseText) => {
			console.log(responseText);
			window.location.replace('/collection');
		})
		.catch((error) => {
			console.error(error);
		});
}

/* --------------------------- set session code start --------------------------- */
const storeItem = async (key, item) => {
	try {
		window.sessionStorage.setItem(key, JSON.stringify(item));
	} catch (error) {
		console.log('error in storeItem', error);
	}
};

const retrieveItem = (key) => {
	try {
		const item = JSON.parse(window.sessionStorage.getItem(key));
		return item;
	} catch (error) {
		console.log('error in retrieveItem', error);
		return error;
	}
};

const removeItem = async (key) => {
	try {
		window.sessionStorage.removeItem(key);
		return true;
	} catch (exception) {
		return false;
	}
};



// uploadFileIPFS(ccccccc);

/* ---------------------------- set session code end ---------------------------- */
console.log('console of contractId', contractId);
// test start
const provider = new providers.JsonRpcProvider(rpcNode);
async function getState(txHash, accountId) {
	const result = await provider.txStatus(txHash, accountId);
	const tokenCreateInfo = JSON.parse((result.receipts_outcome[0].outcome.logs[0]).split('EVENT_JSON:')[1]).params;
	console.log("Result: ", tokenCreateInfo);
	if (tokenCreateInfo.token_type_id) {
		const createData = JSON.stringify({
			"nft_token_id": tokenCreateInfo.token_type_id,
			"name": tokenCreateInfo.token_metadata.title,
			"description": tokenCreateInfo.token_metadata.description,
			"min_sell_price": "0",
			"royalty": '',
			"royalty_address": tokenCreateInfo.royalty,
			"media_url": tokenCreateInfo.token_metadata.media,
			"type": "create",
			"status": "inactive",
			"creater_id": tokenCreateInfo.creator_id,
			"seller_address": tokenCreateInfo.creator_id,
			"extra": tokenCreateInfo.token_metadata.extra,
			"no_copies": tokenCreateInfo.token_metadata.copies,
			"current_copy": 0,
			"is_auction": false,
			"sell_id": tokenCreateInfo.token_type_id,
		});
		const obj1 = JSON.parse(createData);
		const obj2 = JSON.parse(retrieveItem('dataNft'));
		const mergedObject = {
			...obj1,
			...obj2
		};
		CreateNFT(JSON.stringify(mergedObject), tokenCreateInfo.token_type_id);
	}
}
// test end

// create API END
export const handleMint = async (account, royalties, media, title, description, validMedia, fileExtension, filesCover, numberOfCopies, selectCollectionId, collectionName, collectionDesc, collectionImage, compressedImage, isUnlockable, unlockable_desc,attribute) => {
	
	let attribute_new = [];

    for (const key in attribute) {
        const person = new Object();
        person.trait_type = key;
        person.value = attribute[key];
        attribute_new.push(person);
    }

	let extra = null;
	let unlockableDesc = null;
	if (isUnlockable) {
		unlockableDesc = CryptoJS.AES.encrypt(unlockable_desc, process.env.REACT_APP_UNLOCKABLE_SECRET_KEY).toString();
	}

	let files = JSON.stringify({
		'is_unlockable': isUnlockable,
		'unlockable_desc': unlockableDesc,
		'description': description,
		'fileExtension': fileExtension,
		'filesCover': filesCover,
		'selectCollectionId': selectCollectionId,
		'collectionName': collectionName,
		'collectionImage': collectionImage,
		'compressedImage': compressedImage,
		"attributes":attribute_new
	});

	try {
		const added = await client.add(files);
		const v0 = CID.parse(added.path);
		const encodePath = v0.toV1().toString();
		const url = `https://ipfs.io/ipfs/${encodePath}`;
		console.log(url);
		extra = url;
	} catch (error) {
		console.log("Error uploading file: ", error);
	}

	if (!media.length || !validMedia) {
		alert('Please enter a valid Image Link. You should see a preview below!');
		return;
	}

	let royalty = Object.entries(royalties).map(([receiver, royalty]) => ({
		[receiver]: royalty * 100
	})).reduce((acc, cur) => Object.assign(acc, cur), {});
	if (Object.values(royalty).reduce((a, c) => a + c, 0) > 2000) {
		return alert('Cannot add more than 20% in perpetual NFT royalties when minting');
	}
	const now = Date.now().toString();
	const token_type_title = 'token-' + now;
	const metadata = {
		media,
		reference: title,
		title: title,
		issued_at: parseInt(Date.now()),
		extra,
		copies: parseInt(numberOfCopies),
	};
	const deposit = parseNearAmount('0.01');
	const token_id = token_type_title;

	const raw = JSON.stringify(
		{
			'description': description,
			"is_unlockable": isUnlockable,
			"unlockable_desc": unlockableDesc,
			"extension": fileExtension,
			"collection_id": selectCollectionId,
			"collection_name": collectionName,
			"collection_desc": collectionDesc,
			"collection_logo": collectionImage,
			"compressed_url": compressedImage,
			"attributes":attribute_new
		}
	);
	storeItem('dataNft', raw);

	await account.functionCall({
		contractId,
		methodName: 'nft_create_type',
		args: {
			metadata,
			price: null,
			royalty,
		},
		gas: GAS,
		attachedDeposit: deposit
	});
};

export const tokenMint = async (account, token_type_title, tokenData, fileExtension, nftCount, nftType) => {
	const accountId = account.accountId;
	// console.log(tokenData, nftCount, nftType);
	if (nftCount === 1 && nftType === 'create') {
		const raw = JSON.stringify(
			{
				"nft_token_id": token_type_title,
				"name": tokenData.name,
				"description": tokenData.description,
				"min_sell_price": "0",
				"royalty": tokenData.royalty,
				"royalty_address": tokenData.royalty_address,
				"media_url": tokenData.media_url,
				"type": "mint",
				"status": "inactive",
				"creater_id": tokenData.creater_id,
				"seller_address": tokenData.seller_address,
				"extra": tokenData.extra,
				"extension": tokenData.extension,
				"no_copies": tokenData.no_copies,
				"current_copy": nftCount,
				"is_auction": false,
				"sell_id": null,
				"collection_id": tokenData.collection_id,
				"collection_name": tokenData.collection_name,
				"collection_desc": tokenData.collection_desc,
				"collection_logo": tokenData.collection_logo,
				"compressed_url": tokenData.compressed_url
			}
		);
		storeItem('dataNft', raw);
	} else if (nftCount > 1) {
		const copies = parseInt(nftCount);
		if (copies <= parseInt(tokenData.no_copies)) {
			const raw = JSON.stringify(
				{
					"nft_token_id": token_type_title,
					"name": tokenData.name,
					"description": tokenData.description,
					"min_sell_price": "0",
					"royalty": tokenData.royalty,
					"royalty_address": tokenData.royalty_address,
					"media_url": tokenData.media_url,
					"type": "mint",
					"status": "inactive",
					"creater_id": tokenData.creater_id,
					"seller_address": tokenData.seller_address,
					"extra": tokenData.extra,
					"extension": tokenData.extension,
					"no_copies": tokenData.no_copies,
					"current_copy": copies,
					"is_auction": false,
					"sell_id": null,
					"collection_id": tokenData.collection_id,
					"collection_name": tokenData.collection_name,
					"collection_desc": tokenData.collection_desc,
					"collection_logo": tokenData.collection_logo,
					"compressed_url": tokenData.compressed_url
				}
			);
			storeItem('dataNft', raw);
		}
	}
	await account.functionCall({
		contractId,
		methodName: 'nft_mint_type',
		args: {
			token_type_title,
			receiver_id: accountId
		},
		gas: GAS,
		attachedDeposit: parseNearAmount('0.01')
	});
}


export const handleAcceptOffer = async (account, token_id, ft_token_id, previous_token, copy, offerPrice, token_title, seller_name) => {
	console.log(account, token_id, ft_token_id, previous_token, copy, offerPrice, token_title, seller_name);
	if (ft_token_id !== 'near') {
		return alert('currently only accepting NEAR offers');
	}
	const rawUpdateBid = JSON.stringify({
		"nft_token_id": previous_token,
		"sell_id": token_id,
		"type": "mint",
		"status": "inactive",
		"min_sell_price": offerPrice,
		"seller_address": account.accountId,
		"current_copy": copy
	});
	const rawAddOfferBid = JSON.stringify({
		"nft_title": token_title,
		"price": offerPrice,
		"seller": seller_name,
		"buyer": account.accountId,
		"nft_token_id": previous_token,
		"date": (new Date().toLocaleDateString()).toString(),
	});
	storeItem('saleNFTBid', rawUpdateBid);
	storeItem('addOrderNFTBid', rawAddOfferBid);
	await account.functionCall(marketId, 'accept_offer', {
		nft_contract_id: contractId,
		token_id,
		ft_token_id,
	}, GAS);
};

export const handleRegisterStorage = async (account) => {
	// WARNING this just pays for 10 "spots" to sell NFTs in marketplace vs. paying each time
	await account.functionCall(
		marketId,
		'storage_deposit',
		{
			receiver_id: account.accountId
		},
		GAS,
		parseNearAmount('0.1')
	);
};

export const TransferToken = async (account, token_id, receiver, tokenData) => {

	const rawTransferToken = JSON.stringify({
		"nft_token_id": tokenData.nft_token_id,
		"sell_id": token_id,
		"type": "mint",
		"status": "inactive",
		"min_sell_price": '0',
		"seller_address": receiver,
		"current_copy": tokenData.current_copy,
	});

	storeItem('transferToken', rawTransferToken);

	await account.functionCall({
		contractId: contractId,
		methodName: 'nft_transfer',
		args: {
			receiver_id: receiver,
			token_id,
		},
		gas: GAS,
		attachedDeposit: '1'
	});
};

export const removeSale = async (account, token_id) => {
	// console.log(account,token_id);
	const rawDeleteToken = JSON.stringify({
		"nft_token_id": token_id,
	});
	storeItem('deleteToken', rawDeleteToken);
	await account.functionCall(contractId, 'nft_burn_token', {
		token_id,
	},
		GAS,
		parseNearAmount('0.000000000000000000000001')
	);
}

export const handleSaleUpdate = async (account, token_id, newSaleConditions, auctionCondition, price_conditions, previous_token, copy) => {
	console.log(token_id, newSaleConditions, auctionCondition, price_conditions, previous_token, copy);
	const price = (utils.format.formatNearAmount(newSaleConditions["near"]));
	// console.log(newSaleConditions);
	// const sale = await account.viewFunction(contractId, 'nft_get_type_price', { token_type_id:token_id }).catch(() => { });
	const sale = price_conditions;

	if (sale) {
		const rawUpdate = JSON.stringify(
			{
				"nft_token_id": previous_token,
				"sell_id": token_id,
				"type": "sell",
				"status": "active",
				"min_sell_price": price,
				"is_auction": auctionCondition,
				"current_copy": '0'
			}
		);
		storeItem('saleNFT2', rawUpdate);
		try {
			await account.functionCall({
				contractId,
				methodName: 'nft_set_price',
				args: {
					token_type_id: token_id.toString(),
					price: parseNearAmount(price),
				},
				gas: GAS,
				attachedDeposit: parseNearAmount('0.000000000000000000000001')
			});
		} catch (e) {
			// swallow and keep iterating
			console.warn(e);
		}
	} else {
		const rawUpdate = JSON.stringify(
			{
				"nft_token_id": previous_token,
				"sell_id": token_id,
				"type": "sell",
				"status": "active",
				"min_sell_price": price,
				"is_auction": auctionCondition,
				"current_copy": '0'
			}
		);
		storeItem('saleNFT2', rawUpdate);

		// 	// test start
		// 	let sale_args = {
		// 		sale_conditions: newSaleConditions,
		// 		token_type: token_id.split(TOKEN_DELIMETER)[0],
		// 		is_auction: auctionCondition,
		// 	};
		// 	try {
		// 		await account.functionCall({
		// 			contractId: contractId,
		// 			methodName: 'nft_approve',
		// 			args: {
		// 				token_id,
		// 				account_id: marketId,
		// 				msg: JSON.stringify(sale_args)
		// 			},
		// 			GAS,
		// 			attachedDeposit: parseNearAmount('0.01')
		// 		});
		// 	} catch (e) {
		// 		// swallow and keep iterating
		// 		console.warn(e);
		// 	}

		// 	// test end
		// }

		//new code start
		try {
			await account.functionCall({
				contractId,
				methodName: 'nft_set_price',
				args: {
					token_type_id: token_id.toString(),
					price: parseNearAmount(price),
				},
				gas: GAS,
				attachedDeposit: parseNearAmount('0.000000000000000000000001')
			});
		} catch (e) {
			// swallow and keep iterating
			console.warn(e);
		}
		//new code end
	}
};


export const otherUserSale = async (account, token_id, newSaleConditions, auctionCondition, conditions, previous_token, copy, marketPaidStatus) => {
	console.log('other user sale', account, token_id, newSaleConditions, auctionCondition, conditions, previous_token, copy);
	const price = (utils.format.formatNearAmount(newSaleConditions["near"]));
	let sale_args = {
		price: parseNearAmount(price),
		market_type: 'sale',
		ft_token_id: 'near',
	};

	const rawUpdate = JSON.stringify(
		{
			"nft_token_id": previous_token,
			"sell_id": token_id,
			"type": "sell",
			"status": "active",
			"min_sell_price": price,
			"is_auction": auctionCondition,
			"current_copy": copy
		}
	);
	storeItem('setOtherPrice', rawUpdate);

	const depositParams = {
		receiver_id: account.accountId,
	}

	const params = {
		token_id: token_id.toString(),
		account_id: marketId.toString(),
		msg: JSON.stringify(sale_args)
	}

	if (marketPaidStatus) {
		try {
			await account.functionCall({
				contractId: contractId,
				methodName: 'nft_approve',
				args: {
					token_id: token_id.toString(),
					account_id: marketId.toString(),
					msg: JSON.stringify(sale_args)
				},
				gas: GAS,
				attachedDeposit: parseNearAmount(nftApprove),
			});
		} catch (e) {
			// swallow and keep iterating
			console.warn(e);
		}
	} else {

		const txs = []
		txs.push({
			receiverId: marketId,
			functionCalls: [
				{
					methodName: 'storage_deposit',
					contractId: marketId,
					args: depositParams,
					attachedDeposit: parseNearAmount(nftApproveStorageDeposit),
					gas: GAS,
				},
			],
		})
		txs.push({
			receiverId: contractId,
			functionCalls: [
				{
					methodName: 'nft_approve',
					contractId: contractId,
					args: params,
					attachedDeposit: parseNearAmount(nftApprove),
					gas: GAS,
				},
			],
		})
		const res = await executeMultipleTransactions(txs)
		console.log('response here', res)
		// await account.signAndSendTransaction({
		// 	receiverId: marketId,
		// 	actions: [
		// 		transactions.functionCall(
		// 			`storage_deposit`,
		// 			depositParams,
		// 			GAS,
		// 			parseNearAmount(offerextraFee)
		// 		),
		// 		transactions.functionCall(`nft_on_approve`,  {
		// 			token_id: token_id.toString(),
		// 			account_id: marketId.toString(),
		// 			msg: JSON.stringify(sale_args)
		// 		}, GAS, parseNearAmount('0')),
		// 	],
		// })
	}


}

const executeMultipleTransactions = async (transactions, callbackUrl) => {
	const { near, wallet, contractAccount } = await getWallet();
	const nearTransactions = await Promise.all(
		transactions.map((tx, i) =>
			createTransactions({
				receiverId: tx.receiverId,
				nonceOffset: i + 1,
				actions: tx.functionCalls.map((fc) =>
					functionCall(fc.methodName, fc.args, fc.gas, fc.attachedDeposit)
				),
			})
		)
	)

	return wallet.requestSignTransactions({
		transactions: nearTransactions,
		callbackUrl: callbackUrl,
	})
}

const createTransactions = async ({ receiverId, actions, nonceOffset = 1 }) => {
	const { near, wallet, contractAccount } = await getWallet();
	// console.log(near, wallet, contractAccount)
	const localKey = await near.connection.signer.getPublicKey(
		wallet.account().accountId,
		near.connection.networkId
	)
	const accessKey = await wallet
		.account()
		.accessKeyForTransaction(receiverId, actions, localKey)
	if (!accessKey) {
		throw new Error(`Cannot find matching key for transaction sent to ${receiverId}`)
	}

	const block = await near.connection.provider.block({ finality: 'final' })
	const blockHash = base_decode(block.header.hash)
	const publicKey = PublicKey.from(accessKey.public_key)
	const nonce = accessKey.access_key.nonce + nonceOffset

	return createTransaction(
		wallet.account().accountId,
		publicKey,
		receiverId,
		nonce,
		actions,
		blockHash
	)
}

export const otherUserBuy = async (account, token_id, offerToken, offerPrice, previous_token, copy, seller_name, token_title, is_auction) => {
	console.log(account, token_id, offerToken, offerPrice, previous_token, copy, seller_name, token_title, is_auction);
	// const price = (utils.format.formatNearAmount(newSaleConditions["near"]));

	const rawUpdateOther = JSON.stringify({
		"nft_token_id": previous_token,
		"sell_id": token_id,
		"type": "mint",
		"status": "inactive",
		"min_sell_price": '0',
		"seller_address": account.accountId,
		"current_copy": copy
	});
	const rawAddOfferOther = JSON.stringify({
		"nft_title": token_title,
		"price": offerPrice,
		"seller": seller_name,
		"buyer": account.accountId,
		"nft_token_id": previous_token,
		"date": (new Date().toLocaleDateString()).toString(),
	});

	storeItem('addOrderNFTOther', rawAddOfferOther);
	storeItem('saleNFTOther', rawUpdateOther);
	try {
		await account.functionCall({
			contractId: marketId,
			methodName: 'buy',
			args: {
				token_id: token_id.toString(),
				nft_contract_id: contractId,
				"ft_token_id": "near",
			},
			gas: GAS,
			attachedDeposit: parseNearAmount(offerPrice),
		});
	} catch (e) {
		// swallow and keep iterating
		console.warn(e);
	}
}

export const addOffer = async (account, token_id, auctionCondition, OfferPrice, previous_token, copy, sellerName, title, is_auction, marketPaidStatus, offerText, offerType) => {
	console.log(account, token_id, auctionCondition, OfferPrice, previous_token, copy, sellerName, title, is_auction, marketPaidStatus, offerText, offerType);
	try {
		const depositParams = {
			receiver_id: account.accountId,
		}
		let params = '';
		if (offerType === 'token') {
			params = {
				nft_contract_id: contractId,
				token_id: token_id.toString(),
				ft_token_id: 'near',
				price: parseNearAmount(OfferPrice)
			}
		} else if (offerType === 'tokenType') {
			params = {
				nft_contract_id: contractId,
				token_type_id: token_id.toString(),
				ft_token_id: 'near',
				price: parseNearAmount(OfferPrice)
			}
		} else {

		}

		const addUserOffer = JSON.stringify(
			{
				"buyer_id": account.accountId,
				"nft_contract_id": contractId.toString(),
				"token_id": token_id.toString(),
				"ft_token_id": "near",
				"price": (OfferPrice).toString(),
				"__sortIndex": (Date.now().toString())
			}
		);
		if (offerText === 'new_offer') {
			storeItem('addUserOffer', addUserOffer);
		} else if (offerText === 'update_offer') {
			storeItem('updateUserOffer', addUserOffer);
		} else {

		}

		if (marketPaidStatus) {
			await account.signAndSendTransaction({
				receiverId: marketId,
				actions: [
					transactions.functionCall(`add_offer`, params, GAS, parseNearAmount(OfferPrice)),
				],
			})
		} else {
			await account.signAndSendTransaction({
				receiverId: marketId,
				actions: [
					transactions.functionCall(
						`storage_deposit`,
						depositParams,
						GAS,
						parseNearAmount(offerextraFee)
					),
					transactions.functionCall(`add_offer`, params, GAS, parseNearAmount(OfferPrice)),
				],
			})
		}
	} catch (err) {
		console.warn(err)
	}
}


export const acceptOffer = async (account, token_id, offerToken, offerPrice, previous_token, copy, owner_id, token_title, is_auction, tokenData, buyer_id, offerType) => {

	const supplyToken = await account.viewFunction(contractId, 'nft_supply_for_type', { token_type_id: token_id }).catch(() => { });
	const nextCopy = (parseInt(supplyToken) + 1);
	const nextSupplyToken = `${token_id}:${(parseInt(supplyToken) + 1)}`;
	let userType = '';

	if (tokenData.creater_id === account.accountId) {
		userType = 'creater';
	} else {
		userType = 'owner';
	}

	const rawUpdate = JSON.stringify({
		"type": "mint",
		"status": "inactive",
		"min_sell_price": "0",
		"creater_id": tokenData.creater_id,
		"seller_address": buyer_id,
		"current_copy": nextCopy,
		"nft_token_id": previous_token,
		"name": tokenData.name,
		"description": tokenData.description,
		"royalty": tokenData.royalty,
		"royalty_address": tokenData.royalty_address,
		"media_url": tokenData.media_url,
		"extra": tokenData.extra,
		"extension": tokenData.extension,
		"no_copies": tokenData.no_copies,
		"is_auction": false,
		"sell_id": nextSupplyToken,
		"is_unlockable": tokenData.is_unlockable,
		"unlockable_desc": tokenData.unlockable_desc,
		"collection_id": tokenData.collection_id,
		"collection_name": tokenData.collection_name,
		"collection_desc": tokenData.collection_desc,
		"collection_logo": tokenData.collection_logo,
		"compressed_url": tokenData.compressed_url
	});

	const rawUpdate2 = JSON.stringify({
		"type": "mint",
		"status": "inactive",
		"min_sell_price": "0",
		"creater_id": tokenData.creater_id,
		"seller_address": buyer_id,
		"current_copy": tokenData.current_copy,
		"nft_token_id": tokenData.nft_token_id,
		"name": tokenData.name,
		"description": tokenData.description,
		"royalty": tokenData.royalty,
		"royalty_address": tokenData.royalty_address,
		"media_url": tokenData.media_url,
		"extra": tokenData.extra,
		"extension": tokenData.extension,
		"no_copies": tokenData.no_copies,
		"is_auction": false,
		"sell_id": token_id,
		"collection_id": tokenData.collection_id,
		"collection_name": tokenData.collection_name,
		"collection_desc": tokenData.collection_desc,
		"collection_logo": tokenData.collection_logo,
		"compressed_url": tokenData.compressed_url
	});

	const rawAddOffer = JSON.stringify({
		"nft_title": token_title,
		"price": offerPrice,
		"seller": owner_id,
		"buyer": buyer_id,
		"nft_token_id": previous_token,
		"date": (new Date().toLocaleDateString()).toString(),
	});

	const rawDeleteOffer = JSON.stringify({
		"token_id": token_id,
		"buyer_id": buyer_id,
	});

	storeItem('addOrderNFTOffer', rawAddOffer);
	storeItem('rawDeleteOffer', rawDeleteOffer);
	try {
		const params = {
			account_id: marketId,
		}
		if (userType === 'owner') {
			params.token_id = token_id
			params.msg = JSON.stringify({
				market_type: 'accept_offer',
				buyer_id: buyer_id,
				price: parseNearAmount(offerPrice),
			})
		} else if (userType === 'creater') {
			params.token_type_id = token_id
			params.msg = JSON.stringify({
				market_type: 'accept_offer_series',
				buyer_id: buyer_id,
				price: parseNearAmount(offerPrice),
			})
		} else {

		}

		if (offerType === 'token') {
			storeItem('saleNFTOffer2', rawUpdate2);
			await account.functionCall({
				contractId: contractId,
				methodName: `nft_approve`,
				args: params,
				gas: GAS_FEE_150,
				attachedDeposit: STORAGE_APPROVE_FEE,
			})
		} else if (offerType === 'tokenType') {
			storeItem('saleNFTOffer', rawUpdate);
			await account.functionCall({
				contractId: contractId,
				methodName: `nft_mint_single_and_approve`,
				args: params,
				gas: GAS_FEE_300,
				attachedDeposit: JSBI.add(
					JSBI.BigInt(STORAGE_APPROVE_FEE),
					JSBI.BigInt(STORAGE_MINT_FEE)
				).toString(),
			})
		} else {

		}
	} catch (e) {
		// swallow and keep iterating
		console.warn(e);
	}
}
export const deleteOffer = async (account, token_id, offerToken, offerPrice, previous_token, copy, owner_id, token_title, is_auction, tokenData, buyer_id, offerType) => {


	const rawDeleteOffer = JSON.stringify({
		"token_id": token_id,
		"buyer_id": buyer_id,
	});

	storeItem('rawDeleteOffer', rawDeleteOffer);

	if (offerType === 'tokenType') {
		try {
			await account.functionCall({
				contractId: marketId,
				methodName: 'delete_offer',
				args: {
					nft_contract_id: contractId,
					token_type_id: token_id.toString(),
				},
				gas: GAS,
				attachedDeposit: parseNearAmount('0.000000000000000000000001'),
			});
		} catch (e) {
			console.warn(e);
		}
	} else if (offerType === 'token') {
		try {
			await account.functionCall({
				contractId: marketId,
				methodName: 'delete_offer',
				args: {
					nft_contract_id: contractId,
					token_id: token_id.toString(),
				},
				gas: GAS,
				attachedDeposit: parseNearAmount('0.000000000000000000000001'),
			});
		} catch (e) {
			console.warn(e);
		}
	} else {

	}

}

export const SelfMint = async (account, token_type_id, reciever, tokenData) => {

	const supplyToken = await account.viewFunction(contractId, 'nft_supply_for_type', { token_type_id: token_type_id }).catch(() => { });
	const nextCopy = (parseInt(supplyToken) + 1);
	const nextSupplyToken = `${token_type_id}:${(parseInt(supplyToken) + 1)}`;

	const rawSelfMint = JSON.stringify(
		{
			"type": "mint",
			"status": "inactive",
			"min_sell_price": "0",
			"seller_address": reciever,
			"current_copy": nextCopy,
			"creater_id": tokenData.creater_id,
			"nft_token_id": tokenData.previous_token,
			"name": tokenData.name,
			"description": tokenData.description,
			"royalty": tokenData.royalty,
			"royalty_address": tokenData.royalty_address,
			"media_url": tokenData.media_url,
			"extra": tokenData.extra,
			"extension": tokenData.extension,
			"no_copies": tokenData.no_copies,
			"is_auction": false,
			"sell_id": nextSupplyToken,
			"is_unlockable": tokenData.is_unlockable,
			"unlockable_desc": tokenData.unlockable_desc,
			"collection_id": tokenData.collection_id,
			"collection_name": tokenData.collection_name,
			"collection_desc": tokenData.collection_desc,
			"collection_logo": tokenData.collection_logo,
			"compressed_url": tokenData.compressed_url
		}
	);
	storeItem('dataSelfMintNft', rawSelfMint);

	await account.functionCall({
		contractId,
		methodName: 'nft_mint_single',
		args: {
			token_type_id,
			receiver_id: reciever
		},
		gas: GAS,
		attachedDeposit: parseNearAmount('0.01')
	});
}



var url_string = window.location.href;
var url = new URL(url_string);
if ((window.location.href.indexOf("/create") > -1) && (window.location.href.indexOf("transactionHashes") > -1)) {
	var TX_HASH = url.searchParams.get("transactionHashes");
	console.log(TX_HASH);
	var IsError = url.searchParams.get("errorCode");
	if (TX_HASH) {
		const ACCOUNT_ID = contractId;
		getState(TX_HASH, ACCOUNT_ID);
	} else if (IsError) {
		removeItem('dataNft');
		window.location.replace('create');
	}
}

var token_id_update = null;
const queryString = window.location.pathname.split('/');
if (queryString[2]) {
	const urlParams = (queryString[2]);
	token_id_update = urlParams;
}

if (token_id_update) {
	const aa = `/token/${token_id_update}`;
	const cc = `/token/${encodeURIComponent(token_id_update)}`;
	var IsFind = (((url_string.indexOf(aa) > -1) && (url_string.indexOf('transactionHashes') > -1)) || ((url_string.indexOf(cc) > -1) && (url_string.indexOf('transactionHashes') > -1)));

	const bb = `/token/${token_id_update}`;
	const dd = `/token/${encodeURIComponent(token_id_update)}`;
	var IsErrorUpdate = (((url_string.indexOf(bb) > -1) && (url_string.indexOf('errorCode') > -1)) || ((url_string.indexOf(dd) > -1) && (url_string.indexOf('errorCode') > -1)));

	if (IsFind) {
		if (retrieveItem('saleNFTBid')) {
			AddOrderNFT(retrieveItem('addOrderNFTBid'));
			UpdateNFT(retrieveItem('saleNFTBid'));
			removeItem('addOrderNFTBid');
			removeItem('saleNFTBid');
		}
		if (retrieveItem('saleNFTOther')) {
			AddOrderNFT(retrieveItem('addOrderNFTOther'));
			UpdateNFTNew(retrieveItem('saleNFTOther'));
			removeItem('addOrderNFTOther');
			removeItem('saleNFTOther');
		}
		if (retrieveItem('addUserOffer')) {
			CreateAddOffer(retrieveItem('addUserOffer'));
			removeItem('addUserOffer');
		}
		if (retrieveItem('updateUserOffer')) {
			UpdateAddOffer(retrieveItem('updateUserOffer'));
			removeItem('updateUserOffer');
		}
		if (retrieveItem('saleNFT2')) {
			UpdateNFT(retrieveItem('saleNFT2'));
			removeItem('saleNFT2');
		}
		if (retrieveItem('setOtherPrice')) {
			UpdateNFT(retrieveItem('setOtherPrice'));
			removeItem('setOtherPrice');
		}
		if (retrieveItem('saleNFTOffer')) {
			AddOrderNFT(retrieveItem('addOrderNFTOffer'));
			CreateNewNFT(retrieveItem('saleNFTOffer'));
			deleteAddOfferUser(retrieveItem('rawDeleteOffer'))
			removeItem('saleNFTOffer');
			removeItem('addOrderNFTOffer');
			removeItem('rawDeleteOffer');
		}
		if (retrieveItem('saleNFTOffer2')) {
			AddOrderNFT(retrieveItem('addOrderNFTOffer'));
			UpdateNFT(retrieveItem('saleNFTOffer2'));
			deleteAddOfferUser(retrieveItem('rawDeleteOffer'))
			removeItem('saleNFTOffer2');
			removeItem('addOrderNFTOffer');
			removeItem('rawDeleteOffer');
		}
		if (retrieveItem('rawDeleteOffer')) {
			deleteAddOfferUser(retrieveItem('rawDeleteOffer'));
			removeItem('rawDeleteOffer');
		}
		if (retrieveItem('dataSelfMintNft')) {
			CreateNewNFT(retrieveItem('dataSelfMintNft'));
			removeItem('dataSelfMintNft');
		}
		if (retrieveItem('transferToken')) {
			UpdateNFT(retrieveItem('transferToken'));
			removeItem('transferToken');
		}
		if (retrieveItem('deleteToken')) {
			DeleteToken(retrieveItem('deleteToken'));
			removeItem('deleteToken');
		}

	} else if (IsErrorUpdate) {
		removeItem('addOrderNFT');
		removeItem('saleNFTBid');
		removeItem('saleNFT2');
		removeItem('addOrderNFTOther');
		removeItem('saleNFTOther');
		removeItem('addUserOffer');
		removeItem('saleNFTOffer');
		removeItem('addOrderNFTOffer');
		removeItem('rawDeleteOffer');
		removeItem('updateUserOffer');
		removeItem('saleNFTOffer2');
		removeItem('dataSelfMintNft');
		removeItem('transferToken');
		removeItem('setOtherPrice');
	}
}

if ((window.location.href.indexOf("/myOffers") > -1) && (window.location.href.indexOf("transactionHashes") > -1)) {
	if (retrieveItem('saleNFTBid')) {
		AddOrderNFT(retrieveItem('addOrderNFTBid'));
		UpdateNFT(retrieveItem('saleNFTBid'));
		removeItem('addOrderNFTBid');
		removeItem('saleNFTBid');
	}
	if (retrieveItem('saleNFTOther')) {
		AddOrderNFT(retrieveItem('addOrderNFTOther'));
		UpdateNFTNew(retrieveItem('saleNFTOther'));
		removeItem('addOrderNFTOther');
		removeItem('saleNFTOther');
	}
	if (retrieveItem('addUserOffer')) {
		CreateAddOffer(retrieveItem('addUserOffer'));
		removeItem('addUserOffer');
	}
	if (retrieveItem('setOtherPrice')) {
		UpdateNFT(retrieveItem('setOtherPrice'));
		removeItem('setOtherPrice');
	}
	if (retrieveItem('updateUserOffer')) {
		UpdateAddOffer(retrieveItem('updateUserOffer'));
		removeItem('updateUserOffer');
	}
	if (retrieveItem('rawDeleteOffer')) {
		deleteAddOfferUser(retrieveItem('rawDeleteOffer'));
		removeItem('rawDeleteOffer');
	}
	if (retrieveItem('dataSelfMintNft')) {
		CreateNewNFT(retrieveItem('dataSelfMintNft'));
		removeItem('dataSelfMintNft');
	}
	if (retrieveItem('saleNFTOffer')) {
		AddOrderNFT(retrieveItem('addOrderNFTOffer'));
		CreateNewNFT(retrieveItem('saleNFTOffer'));
		deleteAddOfferUser(retrieveItem('rawDeleteOffer'))
		removeItem('saleNFTOffer');
		removeItem('addOrderNFTOffer');
		removeItem('rawDeleteOffer');
	}
	if (retrieveItem('saleNFTOffer2')) {
		AddOrderNFT(retrieveItem('addOrderNFTOffer'));
		UpdateNFT(retrieveItem('saleNFTOffer2'));
		deleteAddOfferUser(retrieveItem('rawDeleteOffer'))
		removeItem('saleNFTOffer2');
		removeItem('addOrderNFTOffer');
		removeItem('rawDeleteOffer');
	}
	if (retrieveItem('rawDeleteOffer')) {
		deleteAddOfferUser(retrieveItem('rawDeleteOffer'));
		removeItem('rawDeleteOffer');
	}
	if (retrieveItem('transferToken')) {
		UpdateNFT(retrieveItem('transferToken'));
		removeItem('transferToken');
	}
}

else if ((window.location.href.indexOf("/myOffers") > -1) && window.location.href.indexOf("errorCode") > -1) {
	removeItem('rawDeleteOffer');
	removeItem('updateUserOffer');
	removeItem('addOrderNFT');
	removeItem('saleNFTBid');
	removeItem('saleNFT2');
	removeItem('addOrderNFTOther');
	removeItem('saleNFTOther');
	removeItem('addUserOffer');
	removeItem('saleNFTOffer');
	removeItem('addOrderNFTOffer');
	removeItem('saleNFTOffer2');
	removeItem('dataSelfMintNft');
	removeItem('transferToken');
	removeItem('setOtherPrice');
}
// update api end
